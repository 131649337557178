import { Grid2 } from "@mui/material";
import notEmptyPassword from "../../../utility/validations/notEmptyPassword";
import PassField from "../../UI/GeneralPurpose/PassField";
import { useWorker } from "../../../Hooks/useWorker";
import { loginWrkID } from "../Actions/loginToCloner";

const UIText = {
  "es-CL": { label: "Contraseña", placeholder: "**********" },
  "en-US": { label: "Password", placeholder: "**********" },
};

export const loginPasswordID = "password";

const Password = ({ login, hardValidation, disabled: dsbl }) => {
  const { working } = useWorker(loginWrkID);

  const disabled = dsbl || working;

  return (
    <Grid2 container {...{ rowGap: 1, size: 12, justifyContent: "center" }}>
      <PassField
        fullWidth
        tagError
        required
        {...{
          id: loginPasswordID,
          UIText,
          type: loginPasswordID,
          disabled,
          validation: notEmptyPassword,
          onKeyDown: hardValidation,
          submit: login,
          size: {
            lg: 3,
            md: 4,
            sm: 6,
            xs: 8,
          },
        }}
      />
    </Grid2>
  );
};

export default Password;
