import FilterPlusActions from "./Components/FilterPlusActions/FilterPlusActions";
import Core from "./Components/Core/Core";
import BasicContainer from "./BasicContainer";
import BasicHeader from "./Components/Header";
import BasicFooter from "./Components/BasicFooter";
import { Grid2 } from "@mui/material";
import BasicHeaders from "./Components/BasicHeaders";
import Loading from "./Components/Core/Loading";
import Error from "./Components/Core/Error";
import { Fragment } from "react";

const UITextUsers = {
  "es-CL": { message: "Error al cargar los usuarios" },
  "en-US": { message: "Error loading the users" },
};
const UITextDevices = {
  "es-CL": { message: "Error al cargar los dispositivos" },
  "en-US": { message: "Error loading the devices" },
};
const UITextRepositories = {
  "es-CL": { message: "Error al cargar los repositorios" },
  "en-US": { message: "Error loading the repositories" },
};

const FilesLayout = (props) => {
  const {
    header,
    plusActions,
    headersData,
    coreData,
    treeRoots = [],
    filesStatus = {
      usersStatus: {},
      devicesStatus: {},
      repositoriesStatus: {},
    },
    plusActionsGrid,
    overflowX,
    fixedWidth,
  onPaginationChange,
  } = props;
  const { usersStatus, devicesStatus, repositoriesStatus } = filesStatus;

  let workerId = "";
  let error = "";
  let UIText = { universal: {} };

  if (usersStatus.done && !!usersStatus.error) {
    workerId = usersStatus.worker;
    UIText = UITextUsers;
    error = usersStatus.error;
  } else if (devicesStatus.done && !!devicesStatus.error) {
    workerId = devicesStatus.worker;
    UIText = UITextDevices;
    error = devicesStatus.error;
  } else if (repositoriesStatus.done && !!repositoriesStatus.error) {
    workerId = repositoriesStatus.worker;
    UIText = UITextRepositories;
    error = repositoriesStatus.error;
  }

  let done =
    usersStatus.done && devicesStatus.done && repositoriesStatus.done;
  let working =
    usersStatus.working || devicesStatus.working || repositoriesStatus.working;

  return (
    <BasicContainer {...{ overflowX, fixedWidth }}>
      <BasicHeader>{header}</BasicHeader>
      <Error {...{ workerId, error, UIText }} />
      <Loading {...{ working }} />
      {done && !error ? (
        <Fragment>
          <FilterPlusActions noRecursive {...{ plusActionsGrid }}>
            {plusActions}
          </FilterPlusActions>
          <Grid2
            container
            {...{
              size: 3,
              sx: {
                borderRight: "1px solid lightgrey",
                alignContent: "start",
                padding: "2vh",
                paddingBottom: 0,
              },
            }}
          >
            <Grid2
              container
              {...{
                size: 12,
                sx: {
                  alignContent: "start",
                  overflowY: "auto",
                  overflowX: "auto",
                  scrollPaddingRight: 3,
                  scrollPaddingLeft: 2,
                  maxHeight: "64vh",
                },
              }}
            >
              {treeRoots}
            </Grid2>
          </Grid2>
          <Grid2 container {...{ size: 9, alignContent: "start" }}>
            {headersData ? <BasicHeaders {...headersData} /> : null}
            <Core hasHeaders={!!headersData} {...coreData} />
          </Grid2>
        </Fragment>
      ) : null}
      <BasicFooter {...{ onPaginationChange }}/>
    </BasicContainer>
  );
};

export default FilesLayout;
