import { Card, Grid2 } from "@mui/material";
import { RecoverySteps } from "../Components/Steps";
import headersData from "./Components/Headers";
import DeliverySummary from "./Components/DeliverySumary";
import DeviceRow from "./Components/DeviceRow/DeviceRow";
import Back from "./Components/Back";
import Send from "./Components/Send";
import { useEffect } from "react";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { submitRecoveryId } from "../../../../store/actions/recoveries/sendRecoveryRequest";
import { useWorker } from "../../../../Hooks/useWorker";
import useRecoveries from "../../../../Hooks/useRecoveries";
import { workerActions } from "../../../../store/slices/workers";
import StepsBar from "../RecoveryStep1/Components/StepsBar";
import BasicHeader from "../../../UI/BasicLayout/Components/Header";
import BasicHeaders from "../../../UI/BasicLayout/Components/BasicHeaders";
import BasicFooter from "../../../UI/BasicLayout/Components/BasicFooter";
import Core from "../../../UI/BasicLayout/Components/Core/Core";
import loadGroupsTree, { groupsTreeId, selectedGroupIdx2 } from "../../../GroupsTraveler/Actions/loadGroupsTree";
import useTrees from "../../../../Hooks/useTrees";

const RecoveryStep3 = () => {
  const { auth,dispatch, navigate, settings, UI } = useUIBp();
  const { done, error } = useWorker(submitRecoveryId);
  const requestData = useRecoveries() ?? {};
  const { recoveries } = requestData;

  useEffect(() => {
    dispatch(
      loadGroupsTree({
        ...auth,
        startGroup: parseInt(auth.group),
        mode: "modal",
      })
    );
  }, [dispatch, auth]);

  useEffect(() => {
    if (done && !error) {
      navigate("/dashboard/recovery/finished");
      dispatch(workerActions.clear(submitRecoveryId))
    }
  }, [dispatch, navigate, done, error]);

  const devices = recoveries.map((r) => {
    let full_size = 0;
    for (const repo of r.repositories) {
      full_size += repo.size;
    }

    return {
      id: r.id,
      user: r.user,
      mid: r.mid,
      device: r.device,
      full_size,
      lastBkp: r.lastBkp,
      repositories: r.repositories,
      mode: r.mode,
    };
  });

  const elements = devices.map((data, idx) => (
    <DeviceRow key={idx} {...data} />
  ));
  
  const coreData = {
    error: {
      UIText: { "es-CL": { message: "" }, "en-US": { message: "" } },
    },
    empty: {
      UIText: {
        "es-CL": { message: "No se ha agregado ninguna recuperación" },
        "en-US": { message: "No recovery has been added" },
      },
    },
    done: true,
    elements,
  };

  const steps = RecoverySteps[settings.locale];
  const header = steps[2];

  return (
    <Grid2
      container
      {...{
        sx: {
          padding: "2vh",
          paddingTop: 0,
          paddingBottom: 0,
          height: "92vh",
          overflow: "scroll",
        },
        alignContent: "start",
      }}
    >
      <StepsBar idx={2}/>
      <Grid2 container>
        <Card {...{ sx: { width: "100%" } }}>
          <Grid2
            container
            {...{
              sx: {
                width: "100%",
              },
            }}
          >
            <BasicHeader>{header}</BasicHeader>
            <DeliverySummary />
            {headersData ? <BasicHeaders {...headersData} /> : null}
            <Core hasHeaders={!!headersData} {...coreData} contentSize={0} />
            <BasicFooter />
          </Grid2>
        </Card>
      </Grid2>
      <Grid2
        container
        {...{
          justifyContent: "space-between",
          alignItems: "center",
          sx: { height: "6vh" },
          size:12
        }}
      >
        <Back />
        <Send />
      </Grid2>
    </Grid2>
  );
};

export default RecoveryStep3;
