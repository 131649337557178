import Name from "./Components/Name";
import Created from "./Components/Created";
import DownloadLink from "./Components/DownloadLink";
import Protected from "./Components/Protected";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import BasicRow from "../../../UI/BasicLayout/Components/BasicRow";
import RowActions from "./Components/RowActions";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };
const fontSize = 13;

const SharedRow = (props) => {
  let { id, name: filename, created, has_password } = props;
  const { settings } = useUIBp();
  const locale = settings.locale;

  const data = {
    id,
    elements: [
      { content: <Name {...{ name: filename, fontSize }} />, size: 3 },
      {
        content: <Protected {...{ has_password, locale, fontSize }} />,
        justify: "center",
        size: 1,
      },
      {
        content: <Created {...{ created, locale, fontSize }} />,
        size: 3,
      },
      {
        content: <DownloadLink {...{ id, fontSize }} />,
        size: "grow",
      },
      {
        content: <RowActions {...props} />,
        justify: "end",
        size: 0.5,
      },
    ],
  };

  return <BasicRow {...data} />;
};

export default SharedRow;
