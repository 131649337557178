import { createSlice } from "@reduxjs/toolkit";
import { defaultPagination } from "../../components/UI/GeneralPurpose/genericIDs";

const initialState = {
  UI: {},
};
const UISlice = createSlice({
  name: "UI",
  initialState,
  reducers: {
    //==================================== Basic actions ==================================================
    fullClear(state) {
      state.UI = {};
    },
    clear(state, action) {
      const id = action.payload;
      delete state.UI[id];
    },
    setValue(state, action) {
      const { id, value } = action.payload;
      state.UI[id] = value;
    },
    //==================================== Fields actions ==================================================
    setField(state, action) {
      const { id, value } = action.payload;
      state.UI[id] = {
        value,
        error: "",
        touched: true,
      };
    },
    setFieldError(state, action) {
      const { id, value } = action.payload;

      if (!state.UI[id]) {
        state.UI[id] = { value: "", error: "", touched: false };
      }
      state.UI[id].error = value;
    },
    //==================================== Modals ==================================================
    openModal(state, action) {
      const { type, payload } = action.payload;
      state.UI.modal = {
        type,
        open: true,
        payload,
      };
    },
    closeModal(state) {
      delete state.UI["modal"];
    },
    hideModal(state) {
      state.UI.modal.open = false;
    },
    openModal2(state, action) {
      const { type, payload } = action.payload;
      state.UI.modal2 = {
        type,
        open: true,
        payload,
      };
    },
    closeModal2(state) {
      delete state.UI["modal2"];
    },
    //==================================== Toggles ==================================================
    toggle(state, action) {
      const id = action.payload;
      if (state.UI[id]) {
        state.UI[id] = false;
      } else {
        state.UI[id] = true;
      }
    },
    toggleSort(state, action) {
      const { id, key } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = { value: "", descending: false };
      }
      state.UI[id].descending =
        state.UI[id].value === key ? !state.UI[id].descending : false;
      state.UI[id].value = key;
    },
    exclusiveOptionalToggle(state, action) {
      const { id, value } = action.payload;
      state.UI[id] = state.UI[id] === value ? "" : value;
    },
    //==================================== Pagination ==================================================
    setPagination(state, action) {
      const {
        id = defaultPagination,
        rowsPerPage = 50,
        count,
      } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = { count: 0, rowsPerPage: 0, page: 0 };
      }

      state.UI[id] = {
        count,
        rowsPerPage,
        page:
          state.UI[id].page >= Math.ceil(count / rowsPerPage)
            ? 0
            : state.UI[id].page,
        // : 0,
      };
    },
    nextPage(state, action) {
      const id = action.payload;
      const { page, count, rowsPerPage } = state.UI[id];
      if ((page + 1) * rowsPerPage < count) {
        state.UI[id].page++;
      }
    },
    previousPage(state, action) {
      const id = action.payload;
      const { page, rowsPerPage } = state.UI[id];
      if ((page + 1) * rowsPerPage > 0) {
        state.UI[id].page--;
      }
    },

    //==================================== Toggle Arrays ===================================================
    add(state, action) {
      const { id, key } = action.payload;
      // console.log('adding',id,key)

      if (!id || !key) {
        console.log("UISliceActions.add missing id or key!");
        return;
      }

      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      const found = state.UI[id].find((x) => x === key);
      if (!found) {
        state.UI[id].push(key);
      }
    },
    remove(state, action) {
      const { id, key } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      const found = state.UI[id].find((x) => x === key);
      if (found) {
        state.UI[id] = state.UI[id].filter((x) => x !== key);
      }
    },
    toggleSingle(state, action) {
      const { id, key } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      const found = state.UI[id].find((x) => x === key);
      if (typeof found !== "undefined") {
        state.UI[id] = state.UI[id].filter((x) => x !== key);
      } else {
        state.UI[id].push(key);
      }
    },
    toggleAll(state, action) {
      const { id, batch } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      if (state.UI[id].length === batch.length) {
        state.UI[id] = [];
      } else if (state.UI[id].length < batch.length) {
        state.UI[id] = batch;
      } else if (state.UI[id].length > batch.length) {
        console.error(
          "This case shouldn't be happening",
          state.UI[id].length,
          batch.length
        );
      }
    },
    //==================================== Complex Array ============================================
    addObj(state, action) {
      const { id, obj = {} } = action.payload;

      if (!id || !obj.id) {
        console.log("UISliceActions.add missing id or obj.id!");
        return;
      }

      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      const found = state.UI[id].find((x) => x.id === obj.id);
      if (!found) {
        state.UI[id].push(obj);
      }
    },
    removeObj(state, action) {
      const { id, key } = action.payload;

      if (!id || !key) {
        console.log("UISliceActions.add missing id or key!");
        return;
      }

      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      const found = state.UI[id].find((x) => x.id === key);
      if (found) {
        state.UI[id] = state.UI[id].filter((x) => x.id !== key);
      }
    },

    //==================================== Validations ==============================================
    validateField(state, action) {
      const { id, force, fn, locale } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = { value: "", error: "", touched: false };
      }
      if (force || state.UI[id].touched) {
        state.UI[id].error = fn(state.UI[id].value, locale);
      }
    },
    twinFieldValidate(state, action) {
      const { id1, id2, fn, errorMsg, force, locale } = action.payload;

      let UI1 = state.UI[id1] ?? {};
      let UI2 = state.UI[id2] ?? {};

      if (UI1.value || UI2.value || force) {
        if (UI1.touched || force) {
          UI1.error = fn(UI1.value, locale);
        }
        if (UI2.touched || force) {
          UI2.error = fn(UI2.value, locale);
        }
        if (
          !UI1.error &&
          !UI2.error &&
          UI1.value !== UI2.value &&
          UI1.touched &&
          UI2.touched
        ) {
          UI1.error = errorMsg[locale];
          UI2.error = errorMsg[locale];
        }
      } else {
        UI1.touched = false;
        UI1.error = "";
        UI2.touched = false;
        UI2.error = "";
      }
      
      state.UI[id1] = UI1
      state.UI[id2] = UI2
    },

    // // ===================================================================
  },
});

export const UIactions = UISlice.actions;
export const UI = UISlice.actions;
export default UISlice.reducer;
