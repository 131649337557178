import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../store/slices/UI";
import { default as CustomDevicePicker } from "../../../../UI/Specialized/DevicePicker";
import { userPickerId } from "../../../../UI/Specialized/UserPicker";
import { recoveryDateUI, recoveryOptionUI } from "./Options";

const DevicePicker = () => {
  const { dispatch,UI } = useUIBp();
  const { id: user } = UI[userPickerId] ?? {};
  const onChange = () => {
    dispatch(UIactions.clear(recoveryOptionUI));
    dispatch(UIactions.clear(recoveryDateUI));
  };
  return <CustomDevicePicker autoload {...{ user, onChange }} />;
};

export default DevicePicker;
