import useUIBp from "../../../Hooks/useUIBoilerplate";
import postClients from "../../../store/actions/microsoft365/postClients";
import GenericConfirmation2 from "./AllPurpose2/DialogConfirmation";

const PostM365ClientsConfirmation = () => {
  const { auth, dispatch, settings, UI } = useUIBp();
  const { cloner_key, M365server } = auth;
  const locale = settings.locale;
  const payload = UI.modal2.payload ?? { payloads: [] };

  let newClients = 0
  let modifiedClients = 0
  for (const p of payload.payloads){
    if (p.found){
      modifiedClients++
    } else {
      newClients++
    }
  }

  const UIText = {
    "es-CL": {
      header: "Esta seguro que desea crear estos usuarios?",
      message: `Se asociarán ${newClients} y se modificarán ${modifiedClients} usuarios de windows 365. Una vez finalizado el proceso será posible descargar un archivo PDF con el detalle de la operación.`,
    },
    "en-US": {
      header: "Are you sure you want to create these users?",
      message: `A total of ${newClients} and ${modifiedClients} windows 365 users will be associated and modified, respectively. Once finished a PDF file detailing the operation will be available to download.`,
    },
  };
  const { header, message } = UIText[locale];

  const confirmation = () => {
    dispatch(postClients({ payloads:payload.payloads, cloner_key, M365server, locale }));
  };


  return (
    <GenericConfirmation2
      {...{ header, message, severity: "info", confirmation }}
    />
  );
};

export default PostM365ClientsConfirmation;
