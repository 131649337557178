import { Grid2, Typography } from "@mui/material";
import IconButton from "./IconButton";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { defaultRadioSelect } from "./genericIDs";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";

const RadioSelect = (props) => {
  const {
    id = defaultRadioSelect,
    value,
    UIText,
    size,
    onClick = () => {},
    allowEmpty,
    disabled,
    labelLeft,
    labelProps,
  } = props;
  const { dispatch, UI, settings } = useUIBp();
  const selected = UI[id];
  const locale = settings.locale;
  const { label } = UIText[locale] ?? UIText.universal;

  const icon =
    selected === value ? (
      <RadioButtonChecked color="primary" />
    ) : (
      <RadioButtonUnchecked />
    );

  const handleClick = () => {
    if (selected !== value) {
      dispatch(UIactions.setValue({ id, value }));
    } else if (allowEmpty) {
      dispatch(UIactions.clear(id));
    }
    onClick(selected, value, allowEmpty);
  };

  return (
    <Grid2 container {...{ wrap: "nowrap", alignItems: "center", size }}>
      {labelLeft ? (
        <Typography
          {...{ fontWeight: "bold", fontSize: "0.9rem", ...labelProps }}
        >
          {label}
        </Typography>
      ) : null}
      <IconButton
        {...{ disabled, onClick: handleClick, icon, UIText: { universal: {} } }}
      />
      {labelLeft ? null : (
        <Typography
          {...{ fontWeight: "bold", fontSize: "0.9rem", ...labelProps }}
        >
          {label}
        </Typography>
      )}
    </Grid2>
  );
};

export default RadioSelect;
