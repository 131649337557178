import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import store, { persistSessionID } from "../../../store";
import workersSlice from "../../../store/slices/workers";
import recoveriesSlice from "../../../store/slices/recoveries";
import warehouseSlice from "../../../store/slices/warehouse";
import settingsSlice from "../../../store/slices/settings";
import UISlice from "../../../store/slices/UI";
import newTreesSlice from "../../../store/slices/trees";
import authenticationSlice from "../../../store/slices/authentication";

const swapReducer = (selected) => {
  return async () => {
    const authPersistConfig = {
      key: "auth",
      storage: selected ? storage : storageSession,
      stateReconciler: hardSet,
    };

    const sessionPersistConfig = {
      key: "root",
      storage: storageSession,
      stateReconciler: hardSet,
    };

    const persistedAuthReducer = persistReducer(
      authPersistConfig,
      authenticationSlice,
    );

    const combinedReducers = combineReducers({
      workers: workersSlice,
      recoveries: recoveriesSlice,
      warehouse: warehouseSlice,
      settings: settingsSlice,
      UI: UISlice,
      newTrees: newTreesSlice,
      authentication: persistedAuthReducer,
    });

    const rootPersistedReducer = persistReducer(
      sessionPersistConfig,
      combinedReducers
    );

    store.replaceReducer(rootPersistedReducer);
    if (selected) {
      localStorage.setItem(persistSessionID, true);
      sessionStorage.clear("persist:auth");
    } else {
      localStorage.setItem(persistSessionID, false);
      localStorage.clear("persist:auth");
    }
  };
};

export default swapReducer;
