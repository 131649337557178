import { CloudSync } from "@mui/icons-material";
import ActionButton from "../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../Hooks/useWorker";
import restoreMails, { restoreMailsId } from "../../Actions/restoreMails";

const UIText = {
  "es-CL": { label: "Restaurar correos" },
  "en-US": { label: "Restore emails" },
};

const Restore = () => {
  const { auth, dispatch, UI, settings } = useUIBp();
  const { working } = useWorker(restoreMailsId);
  const selected = UI.selected ?? [];

  const onClick = () => {
    dispatch(
      restoreMails({
        ...auth,
        mailIDs: [...selected],
        locale: settings.locale,
      })
    );
  };

  return (
    <ActionButton
      {...{
        disabled: !selected.length,
        onClick,
        UIText,
        working,
        icon: <CloudSync />,
      }}
    />
  );
};

export default Restore;
