import axios from "axios";
import { workerActions } from "../../../store/slices/workers";
import { warehouseActions } from "../../../store/slices/warehouse";

export const getSharedDataId = "getSharedData";
export const sharedFileData ='sharedFile'

export const getSharedData = (data) => {
  const { user: login, id } = data;
  return async (dispatch) => {
    dispatch(workerActions.start(getSharedDataId));
    try {
      const url = "https://login.cloner.cl/api/server";
      const response = await axios.get(url, { params: { login } });
      const server = response.data;

      const url2 = server + "api/share";
      const response2 = await axios.get(url2, { params: { id } });

      const value = { ...response2.data, server };
      dispatch(warehouseActions.load({ id: sharedFileData, data:value }));
      dispatch(workerActions.finish({ id: getSharedDataId }));
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id: getSharedDataId, error:e.message }));
    }
  };
};
