import { Grid2, Typography } from "@mui/material";
import { humanBytes, timeDiff } from "../../../../../utility/misc/humanizers";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { devicePickerId } from "../../../../UI/Specialized/DevicePicker";
import { devicesDataId } from "../../../../../store/actions/devices/getDevices";
import { viewmodeUI } from "../../../../../App";

const UIText = {
  "es-CL": { subtitle: "Detalles", size: "Tamaño", bkp: "Último respaldo" },
  "en-US": { subtitle: "Details", size: "Size", bkp: "Last backup" },
};
const Details = ({ fullSize, lastBkp }) => {
  const { settings, UI, warehouse } = useUIBp();
  const viewmode = UI[viewmodeUI] ?? "desktop";
  const locale = settings.locale;
  const { mid } = UI[devicePickerId] ?? {};
  const devices = warehouse[devicesDataId] ?? [];
  const device = devices.find((x) => x.mid === mid) ?? {};
  const { size, bkp } = UIText[locale];

  return (
    <Grid2 container {...{ rowGap: 2, size: 12 }}>
      <Grid2 container {...{ size: 12, rowSpacing: 2 }}>
        <Grid2
          container
          {...{ size: viewmode === "mobile" ? 12 : 6, columnGap: 2 }}
        >
          <Typography fontWeight="bold">{size}:</Typography>
          <Typography>
            {" "}
            {humanBytes(fullSize ?? device.space_active)}
          </Typography>
        </Grid2>
        <Grid2
          container
          {...{ size: viewmode === "mobile" ? 12 : 6, columnGap: 2 }}
        >
          <Typography fontWeight="bold">{bkp}:</Typography>
          <Typography>
            {" "}
            {timeDiff(Date.now() - (lastBkp ?? device.lastBkp), locale)}
          </Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Details;
