import { LocalShipping } from "@mui/icons-material";
import { devicesData } from "../../Devices";
import addRecoveries, {
  addRecoveriesUI,
} from "../../../../../../store/actions/recoveries/addRecoveries";
import { useEffect } from "react";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import useRecoveries from "../../../../../../Hooks/useRecoveries";
import { useWorker } from "../../../../../../Hooks/useWorker";
import { recoveriesActions } from "../../../../../../store/slices/recoveries";
import { UIactions } from "../../../../../../store/slices/UI";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";

const UIText = {
  "es-CL": { label: "Recuperar", fixedTooltip: "Recuperar los dispositivos" },
  "en-US": { label: "Recover", fixedTooltip: "Recover the devices" },
};

const Recover = () => {
  const { auth, dispatch, navigate, UI, warehouse, settings } = useUIBp();
  const requestData = useRecoveries();
  const { done, working, error } = useWorker(addRecoveriesUI);
  const { initialized } = requestData;
  const selected = UI.selected ?? [];
  const devices = warehouse[devicesData] ?? {};
  const locale = settings.locale;

  useEffect(() => {
    if (done && !error) {
      dispatch(recoveriesActions.dontAskAgain());
      navigate("/dashboard/recovery/step1");
    }
  }, [dispatch, navigate, done, error]);

  const onClick = () => {
    let selectedDevices = [];
    for (const mid of selected) {
      const device = devices[mid];
      selectedDevices.push(device);
    }

    if (!initialized) {
      dispatch(recoveriesActions.reset());
      dispatch(
        addRecoveries({
          ...auth,
          locale,
          devices: selectedDevices,
        })
      );
    } else {
      dispatch(
        UIactions.openModal({
          type: "new-devices-recovery-request",
          payload: {
            devices: selectedDevices,
          },
        })
      );
    }
  };

  return (
    <ActionButton
      {...{
        working,
        disabled: !selected.length,
        onClick,
        UIText,
        icon: <LocalShipping />,
      }}
    />
  );
};

export default Recover;
