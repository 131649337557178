import { Grid2 } from "@mui/material";
import { useRef } from "react";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { getMailId, mailData } from "../../../Actions/getMail";
import { useWorker } from "../../../../../Hooks/useWorker";
import { UIactions } from "../../../../../store/slices/UI";

export const iframeHeightUI = "frameHeight";

const Mail = () => {
  const iframeRef = useRef(null);
  const { dispatch, UI, warehouse } = useUIBp();
  const { done, error } = useWorker(getMailId);
  const mailContent = warehouse[mailData] ?? {};
  const { payload = {} } = UI.modal ?? {};
  const { subject } = payload;
  const height = UI[iframeHeightUI] ?? 0;

  const frameLoaded = () => {
    const iframeElement = iframeRef.current;
    const value =
      iframeElement.contentWindow.document.body.scrollHeight + 30 + "px";
    dispatch(UIactions.setValue({ id: iframeHeightUI, value }));
  };

  if (done && !error) {
    return (
      <Grid2
        container
        {...{ sx: { maxHeight: "75vh", overflow: "scroll" }, size: 12 }}
      >
        <iframe
          title={subject}
          ref={iframeRef}
          onLoad={frameLoaded}
          style={{ height }}
          srcDoc={mailContent.content}
          width="100%"
          frameBorder="0"
        ></iframe>
      </Grid2>
    );
  }

  return null;
};

export default Mail;
