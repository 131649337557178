import notEmptyField from "../../../../../../../../utility/validations/notEmpty";
import TextField from "../../../../../../../UI/GeneralPurpose/SimpleTextField";

const UIText = {
  "es-CL": { label: "Numero", placeholder: "1234" },
  "en-US": { label: "Number", placeholder: "1234" },
};
export const deliveryStreetNumberUI = "deliveryStreetNumber";

const DeliveryStreetNumber = () => {
  return (
    <TextField
      required
      {...{
        id: deliveryStreetNumberUI,
        validation: notEmptyField,
        UIText,
        size: {
          xl: 3,
          lg: 3,
          md: 6,
          sm: 6,
          xs: 6,
        },
      }}
    />
  );
};

export default DeliveryStreetNumber;
