import { Grid2 } from "@mui/material";
import { useWorker } from "../../../../Hooks/useWorker";
import Button from "../../../UI/GeneralPurpose/Button";
import { loginWrkID } from "../../Actions/loginToCloner";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { loginUserID } from "../User/User";
import { loginPasswordID } from "../Password";

const UIText = {
  "es-CL": { label: "Ingresar" },
  "en-US": { label: "Login" },
};

const LoginBtn = ({ hardValidation: onMouseDown, login: onClick }) => {
  const { UI } = useUIBp();
  const { working } = useWorker(loginWrkID);
  const user = UI[loginUserID] ?? {};
  const password = UI[loginPasswordID] ?? {};

  const disabled = !!user.error || !!password.error;

  return (
    <Grid2 container {...{ size: 12, justifyContent: "center" }}>
      <Button
        fullWidth
        {...{
          UIText,
          onMouseDown,
          onClick,
          working,
          disabled,
          size: {
            xl: 3,
            lg: 3,
            md: 4,
            sm: 6,
            xs: 8,
          },
        }}
      />
    </Grid2>
  );
};

export default LoginBtn;
