import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { devicesDataId, getDevicesId } from "../../../../../store/actions/devices/getDevices";
import { UIactions } from "../../../../../store/slices/UI";
import { warehouseActions } from "../../../../../store/slices/warehouse";
import { workerActions } from "../../../../../store/slices/workers";
import { devicePickerId } from "../../../../UI/Specialized/DevicePicker";
import { default as CustomUserPicker } from "../../../../UI/Specialized/UserPicker";
import { recoveryDateUI, recoveryOptionUI } from "./Options";

const UserPicker = () => {
  const { dispatch } = useUIBp();

  const onChange = () => {
    dispatch(UIactions.clear(devicePickerId));
    dispatch(UIactions.clear(recoveryOptionUI));
    dispatch(UIactions.clear(recoveryDateUI));
    dispatch(warehouseActions.unload(devicesDataId));
    dispatch(workerActions.clear(getDevicesId));
  };

  return <CustomUserPicker autoload {...{ onChange }} />;
};

export default UserPicker;
