import axios from "axios";
import { workerActions } from "../../slices/workers";
import { treesActions } from "../../slices/trees";
import { warehouseActions } from "../../slices/warehouse";

export const getRepoId = "getRepositories";
export const repoDataId = "repositories";

const getRepositories = ( { server, cloner_key, user, mid, controller }) => {
  const id = getRepoId;

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      const url = server + "api/repositories";
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
        params: { id: mid, user },
      });
      const repositories = response.data.repositories;
      for (const repo of repositories) {
        dispatch(
          treesActions.createTree({
            id: "repo-" + repo.id,
            type: "navigator",
            data: repo,
            root: {
              id: repo.root,
              type: 99,
              name: repo.name,
              deleted: repo.deleted,
            },
          })
        );
      }
      dispatch(warehouseActions.load({ id: repoDataId, data: repositories }));
      dispatch(workerActions.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        dispatch(workerActions.clear(id));
        return;
      }
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};

export default getRepositories;
