import { Grid2 } from "@mui/material";
import Recover from "./Restore";

export const plusActionsGrid = {
  xl: 6,
  lg: 6,
  md: 6,
  sm: 6,
  xs: 6,
};

const PlusActions = () => {
  return (
    <Grid2 container {...{ size: 6, justifyContent: "end", columnGap: 2 }}>
      <Recover />
    </Grid2>
  );
};

export default PlusActions;
