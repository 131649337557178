import { Grid2, Typography } from "@mui/material";
import { options } from "./options";
import MultiSelect from "../../../../../UI/GeneralPurpose/MultiSelect";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": { header: "Rutas problematicas" },
  "en-US": { header: "Troublesome paths" },
};

const UIText2 = {
  "es-CL": { label: "Rutas" },
  "en-US": { label: "Paths" },
};

export const problematicUI = "problematic";

const Problematic = () => {
  const { settings } = useUIBp();
  const { header } = UIText[settings.locale];

  return (
    <Grid2
      container
      {...{
        padding: 2,
        size: 12,
        rowGap: 1,
        columnSpacing: 2,
        alignItems: "center",
      }}
    >
      <Grid2 container {...{ size: "auto" }}>
        <Typography fontSize="1.1rem">{header}</Typography>
      </Grid2>
      <Grid2 container {...{ size: 12 }}>
        <MultiSelect
          {...{ id: problematicUI, UIText: UIText2, options, size: {md:6, sm:9, xs:12} }}
        />
      </Grid2>
    </Grid2>
  );
};

export default Problematic;
