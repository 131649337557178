import { Grid2 } from "@mui/material";
import { useEffect } from "react";
import notEmptyPassword from "../../../../../../../utility/validations/notEmptyPassword";
import { postDeviceId } from "../../../../../../../store/actions/devices/postDeviceInfo";
import HelpTooltip from "../../../../../../UI/GeneralPurpose/HelpTooltip";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import { configDataId } from "../../../../../../../store/actions/devices/getConfig";
import { UIactions } from "../../../../../../../store/slices/UI";
import TextField from "../../../../../../UI/GeneralPurpose/SimpleTextField";
import Switch from "../../../../../../UI/GeneralPurpose/Switch";
import SlimAlert from "../../../../../../UI/GeneralPurpose/SlimAlert";

const UIText2 = {
  "es-CL": {
    label: "Bloqueo de selección",
    tooltip:
      "Impide el cambio de selección de archivos del dispositivo a menos que se proporcione la contraseña establecida",
    warning: "El bloqueo de cambio de selección requiere una contraseña",
  },
  "en-US": {
    label: "Selection block",
    tooltip:
      "Prevents the change of file selection from the device unless the established password is provided",
    warning: "The selection block requieres a password",
  },
};
const UIText = {
  "es-CL": { label: "Contraseña", placeholder: "********" },
  "en-US": { label: "Password", placeholder: "********" },
};

export const cpasswordActiveUI = "cpasswordActive";
export const cpasswordUI = "cpassword";

const Multiupload = ({ locale }) => {
  const { dispatch, UI, warehouse } = useUIBp();
  const { working } = useWorker(postDeviceId);
  const config = warehouse[configDataId] ?? {};
  const cpassword = config.cpassword;
  const active = UI[cpasswordActiveUI];
  const field = UI[cpasswordUI] ?? {};
  const { value, error } = field;
  const { warning } = UIText2[locale];

  useEffect(() => {
    if (!!cpassword && !value) {
      dispatch(UIactions.clear(cpasswordUI));
    }
  }, [dispatch, cpassword, value]);

  const reset = () => {
    dispatch(UIactions.clear(cpasswordUI));
  };

  const Warning = !cpassword && !value && active && !error ? warning : null;

  return (
    <Grid2 container {...{ size: 12, alignItems: "center" }}>
      <Grid2 container {...{ size: 7, alignItems: "center", columnGap: 1 }}>
        <Switch
          clearOnUnmount
          labelRight
          {...{
            id: cpasswordActiveUI,
            UIText,
            disabled: working,
            onChange: reset,
            size: "auto",
            labelProps: { sx: { fontSize: undefined, fontWeight: undefined } },
          }}
        />
        <HelpTooltip {...{ UIText }} />
      </Grid2>
      <TextField
        clearOnUnmount
        {...{
          id: cpasswordUI,
          type: "password",
          UIText,
          disabled: working,
          hide: !active,
          validation: notEmptyPassword,
          size: 5,
        }}
      />
      <SlimAlert
        {...{
          messages: [Warning ?? error],
          severity: Warning ? "warning" : undefined,
          paddingTop: 1,
        }}
      />
    </Grid2>
  );
};

export default Multiupload;
