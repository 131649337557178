import { Card, Grid2 } from "@mui/material";
import { useEffect } from "react";
import getMachineStatus, {
  rootsDataId,
  tunnelTokenId,
} from "../../Actions/getMachineStatus";
import { sortObjByKey } from "../../../../../../utility/misc/sortObjByKey";
import Repository from "./Repository";
import { selectionBlockUI } from "../../RemoteSelection";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { devicePickerId } from "../../../../../UI/Specialized/DevicePicker";
import CollapseGrid from "../../../../../UI/GeneralPurpose/CollapseGrid";

const SelectDisplay = ({ user }) => {
  const { auth, dispatch, UI, warehouse } = useUIBp();
  const devicePicker = UI[devicePickerId] ?? { mid: "" };
  const tunnelToken = UI[tunnelTokenId] ?? "";
  const { mid } = devicePicker;
  const roots = warehouse[rootsDataId] ?? [];
  const blocked = UI[selectionBlockUI] ?? false

  useEffect(() => {
    if (mid && !tunnelToken) {
      dispatch(
        getMachineStatus({
          ...auth,
          user,
          mid,
        })
      );
    }
  }, [dispatch, auth, mid, user, tunnelToken]);

  const rootsItems = [...roots]
    .sort((a, b) => sortObjByKey(a, b, "root"))
    .map((data, idx) => <Repository key={idx} {...data} />);

    console.log("SelectDisplay.js: rootsItems", roots);
    console.log("SelectDisplay.js: blocked", blocked);

  return (
    <CollapseGrid open={!blocked} outerProps={{ size: 12 }}>
      <Card
        {...{
          wrap: "nowrap",
          sx: {
            borderColor: "LightGray",
            borderStyle: "solid",
            borderWidth: "1px",
            boxShadow: "none",
            padding: "0.5rem",
            maxHeight: 400,
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%",
          },
        }}
      >
        <Grid2 container>{rootsItems}</Grid2>
      </Card>
    </CollapseGrid>
  );
};

export default SelectDisplay;
