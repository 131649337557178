import { Tooltip } from "@mui/material";
import useUIBp from "../../../Hooks/useUIBoilerplate";

const AutoHelp = (props) => {
  const {
    tooltip,
    fixedTooltip,
    disabled,
    placement,
    enterDelay = 1000,
  } = props;
  const { UI } = useUIBp();
  const helpMode = UI["helpMode"] ?? false;

  if (!disabled && ((helpMode && tooltip) || fixedTooltip)) {
    return (
      <Tooltip
        {...{
          title: helpMode && tooltip ? tooltip : fixedTooltip,
          placement,
          enterDelay: helpMode?200:enterDelay,
        }}
      >
        {props.children}
      </Tooltip>
    );
  }
  return props.children;
};

export default AutoHelp;
