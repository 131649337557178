import { useEffect, useMemo } from "react";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import {
  default as CustomUserPicker,
  userPickerId,
} from "../../../../../UI/Specialized/UserPicker";
import { UIactions } from "../../../../../../store/slices/UI";
import { warehouseActions } from "../../../../../../store/slices/warehouse";
import { workerActions } from "../../../../../../store/slices/workers";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import { treesActions } from "../../../../../../store/slices/trees";
import {
  getUsersId,
  usersDataId,
} from "../../../../../../store/actions/users/getAllUsers";
import { devicePickerId } from "../../../../../UI/Specialized/DevicePicker";
import { devicesDataId } from "../../../../../../store/actions/devices/getAllDevices";
import {
  getRepoId,
  repoDataId,
} from "../../../../../../store/actions/repositories/getRepositories";
import { getDevicesId } from "../../../../../../store/actions/devices/getDevices";

const UserPicker = () => {
  const { auth, dispatch, navigate, UI, warehouse } = useUIBp();
  const { group, admin } = auth;
  const { user } = useURLQuery();
  const userPicker = UI[userPickerId] ?? {};
  const users = useMemo(() => {
    return warehouse[usersDataId] ?? [];
  }, [warehouse]);

  // user changes and does not match current picked user, overwrite
  useEffect(() => {
    if (!!user && !!userPicker.id && user !== userPicker.id) {
      const usr = users.find((u) => u.login === user) ?? {}
      const value = { id: usr.login, label: usr.name };
      dispatch(UIactions.setValue({ id: userPickerId, value }));
      dispatch(UIactions.clear(devicePickerId));
      dispatch(warehouseActions.unload(devicesDataId));
      dispatch(warehouseActions.unload(repoDataId));
      dispatch(workerActions.clear(getDevicesId));
      dispatch(workerActions.clear(getRepoId));
      navigate(`?user=${encodeURIComponent(value.id)}`, { replace: true });
    }
  }, [dispatch, navigate, user, userPicker.id, users]);

  const onLoaded = (users) => {
    if (user) {
      // if url user is set and the picker is not set yet
      const option = users.find((u) => u.id === user);

      // if user found set it, if not set the first entry
      const value = option ?? users[0];
      dispatch(UIactions.setValue({ id: userPickerId, value }));
      dispatch(UIactions.clear(devicePickerId));

      // if user not found change url to match
      if (!option) {
        navigate("?user=" + encodeURIComponent(value.id), { replace: true });
      }
    } else if (!user && users.length > 0) {
      // no url user. set first entry and modify URL
      const value = { label: users[0].name, id: users[0].login };
      dispatch(UIactions.setValue({ id: userPickerId, value }));
      dispatch(UIactions.clear(devicePickerId));
      navigate("?user=" + encodeURIComponent(value.id), { replace: true });
    }
  };

  const onChange = (value) => {
    dispatch(UIactions.clear(devicePickerId));
    dispatch(warehouseActions.unload(devicesDataId));
    dispatch(warehouseActions.unload(repoDataId));
    dispatch(treesActions.clearForest());
    dispatch(workerActions.clear(getDevicesId));
    dispatch(workerActions.clear(getRepoId));
    navigate(`?user=${encodeURIComponent(value.id)}`);
  };

  useEffect(() => {
    return () => {
      dispatch(warehouseActions.unload(usersDataId));
      dispatch(UIactions.clear(userPickerId));
      dispatch(workerActions.clear(getUsersId));
    };
  }, [dispatch]);

  return (
    <CustomUserPicker
      autoload
      {...{ onChange, onLoaded, size: 4, disabled: admin !== group }}
    />
  );
};

export default UserPicker;
