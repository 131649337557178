import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import SingleTwinSelect from "../Utilities/SingleTwinSelect";
import { Grid2, IconButton as MUIIconButton } from "@mui/material";
import { defaultSelected } from "../GeneralPurpose/genericIDs";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";

const CheckButton = (props) => {
  const {
    arrayId = defaultSelected,
    fullArray = [],
    size = "small",
    xs,
    fontSize,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "xs",
    "arrayId",
    "sourceId",
    "warehouseId",
    "fullArray",
    "size",
  );
  const { dispatch, UI } = useUIBp();
  const selected = UI[arrayId] ?? [];
  const total = fullArray;

  const handleClick = () => {
    dispatch(UIactions.toggleAll({ id: arrayId, batch: total }));
  };
  const isSelected = selected.length === total.length && selected.length > 0;

  const icon = SingleTwinSelect(
    <CheckBoxOutlineBlank {...{ sx: { fontSize: `${fontSize}rem` } }} />,
    <CheckBox color="primary" {...{ sx: { fontSize: `${fontSize}rem` } }} />,
    isSelected
  );

  return (
    <Grid2 size={{ xs }}>
      <MUIIconButton {...filteredProps} {...{ onClick: handleClick, size }}>
        {icon}
      </MUIIconButton>
    </Grid2>
  );
};

export default CheckButton;
