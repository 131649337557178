import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { humanBytes } from "../../../../../utility/misc/humanizers";

const UIText = {
  "es-CL": { key: "Llave", value: "Valor" },
  "en-US": { key: "Key", value: "Value" },
};

const Data = ({ data, locale }) => {
  const { key, value } = UIText[locale];
  const trimmedData = {
    name: data.name,
    repository: data.repo,
    metafile: data.id,
    deleted: data.deleted ? "true" : "false",
    created: new Date(data.created).toLocaleString(locale),
    size: data.size,
    human_size: humanBytes(data.size),
  };

  let rows = [];
  for (const key in trimmedData) {
    rows.push(
      <TableRow key={key}>
        <TableCell>{key}</TableCell>
        <TableCell sx={{ wordBreak: "break-all" }}>{trimmedData[key]}</TableCell>
      </TableRow>
    );
  }

  return (
    <TableContainer sx={{ overflow: "auto" }}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{key}</TableCell>
            <TableCell>{value}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default Data;
