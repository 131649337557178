import notEmptyField from "../../../../../../../../utility/validations/notEmpty";
import TextField from "../../../../../../../UI/GeneralPurpose/SimpleTextField";

const UIText = {
  "es-CL": { label: "Oficina/Depto", placeholder: "A-123" },
  "en-US": { label: "Office/Apartment", placeholder: "A-123" },
};
export const deliveryOfficeUI = "deliveryOffice";

const DeliveryOffice = () => {
  return (
    <TextField
      {...{
        id: deliveryOfficeUI,
        validation: notEmptyField,
        UIText,
        size: {
          xl: 3,
          lg: 3,
          md: 6,
          sm: 6,
          xs: 6,
        },
      }}
    />
  );
};

export default DeliveryOffice;
