import { useEffect } from "react";
import { deleteGroupId } from "../Actions/deleteGroup";
import { useLocation } from "react-router-dom";
import { getGroupsListId, groupsTreeId, selectedGroupId, selectedGroupIdx } from "../../../../GroupsTraveler/Actions/loadGroupsTree";
import useTrees from "../../../../../Hooks/useTrees";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../Hooks/useURLQuery";
import { useWorker } from "../../../../../Hooks/useWorker";
import { UIactions } from "../../../../../store/slices/UI";
import { workerActions } from "../../../../../store/slices/workers";
import DeleteGroupConfirmation from "./DeleteGroupConfirmation";

const DeleteGroup = () => {
  const { dispatch, navigate, settings } = useUIBp();
  const location = useLocation()
  const { group } = useURLQuery();
  const {done,error} = useWorker(deleteGroupId)
  const trees = useTrees()??{}
  const groups = trees[groupsTreeId]??[]
  const selectedGroup = groups.find(g=>g.id===parseInt(group))
  const { name, parentId:parent } = selectedGroup;
  const locale = settings.locale;

  const UIText = {
    "es-CL": {
      header: `¿Esta seguro que desea eliminar el grupo "${name}"?`,
      message:
        "Importante: Ten en cuenta que esta operación es definitiva e irreversible. Al confirmar, el grupo será eliminado y no podrá ser recuperado",
    },
    "en-US": {
      header: `Are you sure you want delete the group "${name}"?`,
      message:
        "Important: Beware that this operation is definitive and irreversible. Once confirmed, the group will be deleted and cannot be recovered",
    },
  };
  const { header, message } = UIText[locale];

  useEffect(()=>{
    if (done && !error){
      dispatch(UIactions.closeModal())
      dispatch(workerActions.clear(getGroupsListId))
      dispatch(UIactions.clear(selectedGroupId))
      dispatch(UIactions.clear(selectedGroupIdx))
      navigate(`${location.pathname}?group=${parent}`)
    }
  },[dispatch,navigate,done,error, parent, location.pathname])

  useEffect(()=>{
    return ()=>{
      dispatch(workerActions.clear(deleteGroupId))
    }
  },[dispatch])

  const confirmation = () => {
    dispatch(
      UIactions.openModal2({
        type: "delete-group-confirmation",
        payload: { group, parent },
      })
    );
  };


  return (
    <DeleteGroupConfirmation
      alternativeText
      {...{ header, message, severity: "warning", confirmation, workerId:deleteGroupId }}
    />
  );
};

export default DeleteGroup;
