import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { Grid2, TextField } from "@mui/material";
import { defaultDatetime } from "./genericIDs";
import { UIactions } from "../../../store/slices/UI";
import { useEffect, useMemo } from "react";

const DateTime = ({
  id = defaultDatetime,
  disabled,
  size = 12,
  componentSize = "small",
  clearOnUnmount,
  maxDateTime,
  UIText,
  noDefault,
}) => {
  const { dispatch, UI, settings } = useUIBp();
  const field = useMemo(() => UI[id], [UI, id]);
  const { label } = UIText[settings.locale]

  useEffect(() => {
    if (!field && !noDefault) {
      dispatch(UIactions.setField({ id, value: dayjs() }));
    }
  }, [dispatch, field, id, noDefault]);

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        dispatch(UIactions.clear(id));
      }
    };
  }, [dispatch, clearOnUnmount, id]);

  const handleChange = (value, err) => {
    console.log("id", id);
    dispatch(UIactions.setField({ id, value }));
  };

  const handleError = (err) => {
    if (!!err) {
      dispatch(UIactions.setFieldError({ id, value: err }));
    }
  };

  return (
    <Grid2 container {...{ size }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          {...{
            ampm: false,
            disabled,
            size: componentSize,
            onChange: handleChange,
            value: !!field ? dayjs(field.value) : dayjs(),
            maxDateTime,
            sx: { width: "100%" },
            formatDensity: "dense",
            slots: { textField: TextField },
            onError: handleError,
            slotProps: {
              textField: {
                label,
                size: componentSize,
                sx: { width: "100%" },
              },
            },
          }}
        />
      </LocalizationProvider>
    </Grid2>
  );
};

export default DateTime;
