import axios from "axios";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

export const getM365OrgId = "microsoft365organization";
export const m365orgData = "microsoft365organization";

export const getOrganization = ({ M365server, cloner_key, groupId, group }) => {
  const id = getM365OrgId;

  console.log("trying to get organization");
  return async (dispatch) => {
    dispatch(workerActions.start(id));
    dispatch(warehouseActions.unload("o365org"));
    const group_id = groupId ?? group;
    try {
      const url = `${M365server}mailapi/organization`;
      const response = await axios.get(url, {
        params: { group_id },
        headers: { cloner_key },
      });

      const org = response.data.data;

      dispatch(warehouseActions.load({ id: m365orgData, data: org }));
      dispatch(workerActions.finish({ id }));
    } catch (e) {
      if (e.response.status === 404) {
        console.log("Organization not found");
      } else {
        console.error(e);
      }
      // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};
