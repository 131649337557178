import { Grid2, Typography } from "@mui/material";
import { Fragment } from "react";
import ContactName, { contactNameUI } from "./ContactName";
import ContactEmail, { contactEmailUI } from "./ContactEmail";
import ContactPhone, { contactPhoneUI } from "./ContactPhone";
import SlimAlert from "../../../../../../../UI/GeneralPurpose/SlimAlert";
import useUIBp from "../../../../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": "Información de contacto",
  "en-US": "Contact information",
};

const ContactInfo = () => {
  const { settings, UI } = useUIBp();
  const locale = settings.locale;
  const subtitle = UIText[locale];

  const { error: e1 } = UI[contactNameUI]??{}
  const { error: e2 } = UI[contactPhoneUI]??{}
  const { error: e3 } = UI[contactEmailUI]??{}

  const errors = [e1, e2, e3];

  return (
    <Fragment>
      <Grid2 container size={12}>
        <Typography fontWeight="bold">{subtitle}</Typography>
      </Grid2>
      <ContactName />
      <ContactPhone />
      <ContactEmail />
      <SlimAlert {...{ errors, size: 12 }} />
    </Fragment>
  );
};

export default ContactInfo;
