const headersData = {
  headers: [
    {
      UIText: {
        "es-CL": { label: "Usuarios" },
        "en-US": { label: "Users" },
      },
      filterKey: "user",
      size: 1.6,
    },
    {
      UIText: {
        "es-CL": { label: "Dispositivos" },
        "en-US": { label: "Devices" },
      },
      filterKey: "name",
      size: 1.6,
    },
    {
      UIText: {
        "es-CL": { label: "Tamaño" },
        "en-US": { label: "Size" },
      },
      filterKey: "space_active",
      size: 1,
    },
    {
      UIText: {
        "es-CL": { label: "Último respaldo" },
        "en-US": { label: "Last backup" },
      },
      filterKey: "lastBkp",
      size: 1.5,
    },
    {
      UIText: {
        "es-CL": { label: "Último envío" },
        "en-US": { label: "Last update" },
      },
      filterKey: "lastChanges",
      size: 1.5,
    },
    {
      UIText: {
        "es-CL": { label: "---" },
        "en-US": { label: "???" },
        // "es-CL": { label: "Ancho de banda" },
        // "en-US": { label: "Bandwidth" },
      },
      justify: "center",
      filterKey: "bandwidth",
      size: "grow",
    },
    {
      UIText: {
        "es-CL": { label: "Versión" },
        "en-US": { label: "Version" },
      },
      justify: "center",
      filterKey: "croppedVersion",
      size: 1,
    },
    {
      UIText: {
        "es-CL": { label: "Conexión" },
        "en-US": { label: "Connection" },
      },
      justify: "center",
      filterKey: "connected",
      size: 0.9,
    },
    {
      UIText: {
        "es-CL": { label: "Estado" },
        "en-US": { label: "Status" },
      },
      justify: "center",
      filterKey: "status",
      size: 0.9,
    },
  ],
};

export default headersData;
