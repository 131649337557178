import { enqueueSnackbar } from "notistack";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { recoveriesActions } from "../../../../../store/slices/recoveries";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": {
    label: "Iniciar nueva solicitud",
    success: "Recuperación creada exitosamente",
  },
  "en-US": {
    label: "Start new request",
    success: "Recovery created successfully",
  },
};

const Continue = ({ newRecovery }) => {
  const { dispatch, navigate, settings } = useUIBp();
  const locale = settings.locale;
  const { success } = UIText[locale];

  const handleClick = () => {
    dispatch(recoveriesActions.reset());
    dispatch(recoveriesActions.newRecovery(newRecovery));
    dispatch(recoveriesActions.initialize());
    dispatch(recoveriesActions.dontAskAgain());
    dispatch(UIactions.closeModal());
    enqueueSnackbar(success, { variant: "success" });
    navigate("/dashboard/recovery/step2");
  };

  return <Button {...{ UIText, onClick: handleClick }} />;
};

export default Continue;
