import axios from "axios";
import { createMachineData } from "./utils";
import { devicesDataId } from "./getAllDevices";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

export const devicesLoadedId = "devicesLoaded";

const getRootDevices = ({
  cloner_key,
  server,
  group,
  includeEmpty,
  controller,
}) => {
  const id = devicesLoadedId;
  console.log("Getting root devices!");

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    const getDevicesController = new AbortController();
    try {

      // Getting all devices through the /api/groups handler
      const url = server + "api/groups";
      const response = await axios.get(url, {
        signal: controller.signal,
        params: { group },
        headers: { cloner_key },
      });
      const data = response.data;

      // Iterating over the users and getting their machines
      for (const user of data.users) {
        if (user.machines) {

          // Getting the machines for the user through the /api/machines handler
          const url = server + "api/machines";
          const response = await axios.get(url, {
            signal: controller.signal,
            headers: { cloner_key },
            params: { user: user.login },
          });
          for (const machine of response.data.machines_info) {

            // Parsing the machine data into a more usable form
            const newDevice = createMachineData(user.login, 0, machine);

            // Filling the warehouse with the new device
            dispatch(
              warehouseActions.add({
                id: devicesDataId,
                key: newDevice.mid,
                data: newDevice,
              })
            );
          }
        } else if (includeEmpty) {

          // Filling the warehouse with an empty device
          dispatch(
            warehouseActions.add({
              id: devicesDataId,
              key: user.login,
              data: {
                bandwidth: 0,
                connected: false,
                croppedVersion: "",
                lastBkp: 0,
                lastChanges: 0,
                lastConnected: 0,
                lastConnectedAdjusted: 0,
                mid: "",
                name: "",
                space_active: 0,
                status: 0,
                user: user.login,
                version: "",
              },
            })
          );
        }
      }

      dispatch(workerActions.finish({ id }));
    } catch (e) {
      getDevicesController.abort();
      if (e.code === "ERR_CANCELED") {
        return;
      }
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
      getDevicesController.abort();
    }
  };
};

export default getRootDevices;
