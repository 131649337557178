import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import BasicHeaders from "../../../../../../UI/BasicLayout/Components/BasicHeaders";

const headersData = [
  {
    UIText: {
      "es-CL": { label: "Dispositivo" },
      "en-US": { label: "Device" },
    },
    filterKey: "name",
    size: 3,
  },
  {
    UIText: {
      "es-CL": { label: "Usuario" },
      "en-US": { label: "User" },
    },
    filterKey: "user",
    size: "grow",
  },
  {
    UIText: {
      "es-CL": { label: "Tamaño" },
      "en-US": { label: "Size" },
    },
    filterKey: "space_active",
    size: 2,
  },
  {
    UIText: {
      "es-CL": { label: "Último respaldo" },
      "en-US": { label: "Last backup" },
    },
    filterKey: "lastBkp",
    size: 3,
  },
];

export const selectedDevicesUI = "selectedDevices";
export const exclusionDevicesUI = "exclusionDevices";
export const currentExclusionDevicesUI = "currentExclusionDevices";

const Headers = () => {
  const { UI } = useUIBp();
  const fullArray = UI[currentExclusionDevicesUI];
  return (
    <BasicHeaders
      {...{ arrayId:exclusionDevicesUI, fullArray, headers: headersData }}
    />
  );
};

export default Headers;
