import "./App.css";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Root from "./components/Root";
import RootReroute from "./components/Reroute/RootReroute";
//====================================================
import Kanit from "../src/components/Resources/Kanit/Kanit-Regular.ttf";
//====================================================
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import Login from "./components/Login/Login";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UIactions } from "./store/slices/UI";
import PassRecovery from "./components/PassRecovery/PassRecovery";
import Dashboard from "./components/Dashboard/Dashboard";
import Settings from "./components/Settings/Settings";
import Reports from "./components/Reports/Reports";
import SharedDownload from "./components/SharedDownload/SharedDownload";
import SharedFiles from "./components/SharedFiles/SharedFiles";
import DevicesExclusions from "./components/Devices/View/Exclusions/DevicesExclusions";
import RecoveryRequest from "./components/RecoveryRequest/RecoveryRequest";
import RecoveryStep1 from "./components/RecoveryRequest/Views/RecoveryStep1/RecoveryStep1";
import AdvancedDeviceOptions from "./components/RecoveryRequest/Views/AdvancedDeviceOptions/AdvancedDeviceOptions";
import RecoveryStep2 from "./components/RecoveryRequest/Views/RecoveryStep2/RecoveryStep2";
import RecoveryStep3 from "./components/RecoveryRequest/Views/RecoveryStep3/RecoveryStep3";
import Finished from "./components/RecoveryRequest/Views/Finished/Finished";
import Mails from "./components/Mails/View/Mails";
import Devices from "./components/Devices/View/Main/Devices";
import Users from "./components/Users/Views/Main/Users";
import Files from "./components/Files/Views/Files";

const font = "Kanit,arial";
const clonerTheme = {
  palette: {
    action: {
      hover: "rgba(0, 167, 132, 0.2)",
      focus: "rgba(0, 167, 132, 0.2)",
    },
    primary: { main: "#00a784" },
    secondary: { main: "#0a1e37", light: "rgb(80,99,130)" },
    ivory: { main: "#f0f0f0" },
  },
  typography: {
    fontFamily: font,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          cursor: "default",
        },
      },
      defaultProps: {
        fontFamily: font,
      },
    },

    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Kanit';
          src: url(${Kanit})
        }
      `,
    },
  },
};

const pretheme = createTheme(clonerTheme);

export const theme = createTheme(pretheme);
export const viewportHeightUI = "viewportHeight";
export const viewmodeUI = "viewmode";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/*" element={<Root />}>
      <Route path="login" element={<Login />} />
      <Route path="newpass" element={<PassRecovery />} />
      <Route path="shared" element={<SharedDownload />} />
      <Route path="dashboard/*" element={<Dashboard />}>
        <Route path="reports" element={<Reports />} />
        <Route path="users" element={<Users />} />
        <Route path="devices" element={<Devices />} />
        <Route path="files" element={<Files />} />
        <Route path="settings" element={<Settings />} />
        <Route path="shared_files" element={<SharedFiles />} />
        <Route path="devices/exclusions" element={<DevicesExclusions />} />
        <Route path="recovery/*" element={<RecoveryRequest />}>
          <Route path="step1" element={<RecoveryStep1 />} />
          <Route path="advanced" element={<AdvancedDeviceOptions />} />
          <Route path="step2" element={<RecoveryStep2 />} />
          <Route path="step3" element={<RecoveryStep3 />} />
          <Route path="review" element={<AdvancedDeviceOptions readOnly />} />
          <Route path="finished" element={<Finished />} /> 
        </Route>
        <Route path="mails/*" element={<Mails />} />
      </Route>
      <Route path="*" element={<RootReroute />} />
    </Route>
  )
);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#00a784",
  },
}));

function App() {
  const dispatch = useDispatch();
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.up("xs"));

  useEffect(() => {
    if (!matchesSM && matchesXS) {
      dispatch(UIactions.setValue({ id: viewmodeUI, value: "mobile" }));
    } else if (!matchesMD && matchesSM) {
      dispatch(UIactions.setValue({ id: viewmodeUI, value: "verycompact" }));
    } else if (!matchesLG && matchesMD) {
      dispatch(UIactions.setValue({ id: viewmodeUI, value: "compact" }));
    } else {
      dispatch(UIactions.setValue({ id: viewmodeUI, value: "desktop" }));
    }
  }, [dispatch, matchesSM, matchesXS, matchesMD, matchesLG]);

  useEffect(() => {
    const handleResize = () =>
      dispatch(
        UIactions.setValue({
          id: viewportHeightUI,
          value: window.innerHeight,
        })
      );
    window.addEventListener("resize", handleResize);
    dispatch(
      UIactions.setValue({
        id: viewportHeightUI,
        value: window.innerHeight,
      })
    );
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  return (
    <ThemeProvider {...{ theme }}>
      <CssBaseline />
      <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
      />
      <RouterProvider {...{ router }} />
    </ThemeProvider>
  );
}

export default App;
