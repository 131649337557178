import { Grid2, Typography } from "@mui/material";

const UIText = {
  "es-CL": { label: "Manejo de carga" },
  "en-US": { label: "Upload throttling" },
};

const Load = ({locale}) => {
  const { label } = UIText[locale];

  return (
    <Grid2 container size={12}>
      <Typography fontWeight="bold">{label}</Typography>
    </Grid2>
  );
};

export default Load;
