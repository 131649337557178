const UIText = {
  "es-CL": {
    less:'menos de 1 minuto',
    minute: "minuto",
    minutes: "minutos",
    hour: "hora",
    hours: "horas",
    day: "día",
    days: "días",
    week: "semana",
    weeks: "semanas",
    year: "año",
    years: "años",
  },
  "en-US": {
    less:'less than a minute',
    minute: "minute",
    minutes: "minutes",
    hour: "hour",
    hours: "hours",
    day: "day",
    days: "days",
    week: "week",
    weeks: "weeks",
    year: "year",
    years: "years",
  },
};

export const timeDiff = (ms, locale) => {
  const txt = UIText[locale]??UIText['es-CL'] // TODO: avoid this hard coded case

  const secs = Math.round(ms / 1000);
  if (secs < 60) {
    return txt.less
  }
  const mins = Math.round(secs / 60);
  if (mins === 1) {
    return `${mins} ${txt.minute}`;
  } else if (mins < 60) {
    return `${mins} ${txt.minutes}`;
  }
  const hrs = Math.round(mins / 60);
  if (hrs === 1) {
    return `${hrs} ${txt.hour}`;
  }
  if (hrs < 24) {
    return `${hrs} ${txt.hours}`;
  }
  const dias = Math.round(hrs / 24);
  if (dias === 1) {
    return `${dias} ${txt.day}`;
  } else if (dias < 7) {
    return `${dias} ${txt.days}`;
  } else if (dias < 14) {
    return `1 ${txt.week}`;
  } else if (dias < 365) {
    return `${Math.round(dias / 7)} ${txt.weeks}`;
  }
  const years = Math.round(dias / 365);
  if (years === 1) {
    return `${years} ${txt.year}`;
  }
  return `${years} ${txt.years}`;
};

export const humanBandwidth = (bytes) => {
  const bps = bytes * 8;
  if (bps === 0) {
    return "--";
  } else if (bps < 1024) {
    return `${bps} bps`;
  }
  const kbps = Math.round(bps / 1024);
  if (kbps < 1024) {
    return `${kbps} kbps`;
  }
  const Mbps = kbps / 1024;
  if (Mbps < 1024) {
    return `${Mbps > 100 ? Mbps.toFixed(0) : Mbps.toFixed(1)} Mbps`;
  }
  const Gbps = Mbps / 1024;
  if (Gbps < 1024) {
    return `${Gbps > 100 ? Gbps.toFixed(0) : Gbps.toFixed(1)} Gbps`;
  }
};

export const humanBytes = (bytes) => {
  if (bytes === 0) {
    return `0 B`;
  } else if (bytes < 1024) {
    return `${bytes} B`;
  }
  const kB = bytes / 1024;
  if (kB < 1024) {
    return `${kB.toFixed(0)} kB`;
  }
  const MB = kB / 1024;
  if (MB < 1024) {
    return `${MB > 100 ? MB.toFixed(0) : MB.toFixed(1)} MB`;
  }
  const GB = MB / 1024;
  if (GB < 1024) {
    return `${GB > 100 ? GB.toFixed(0) : GB.toFixed(1)} GB`;
  }
  const TB = GB / 1024;
  if (TB < 1024) {
    return `${TB > 100 ? TB.toFixed(0) : TB.toFixed(1)} TB`;
  }
};
