import { Grid2, List, ThemeProvider, createTheme } from "@mui/material";
import useTrees from "../../../../Hooks/useTrees";
import ListItem from "./ListItem";
import { theme } from "../../../../App";
import {
  groupsTreeId,
  selectedGroupIdx2,
} from "../../../GroupsTraveler/Actions/loadGroupsTree";
import useUIBp from "../../../../Hooks/useUIBoilerplate";

const Picker = ({ hideGroup }) => {
  const { UI } = useUIBp();
  const selectedGroup = UI[selectedGroupIdx2];
  const tree = useTrees()[groupsTreeId];

  let currentNode;
  let nodeIsRoot;
  let nodeHasChild;
  if (typeof selectedGroup === "number" && tree) {
    currentNode = tree[selectedGroup];
    nodeIsRoot = currentNode.parentIdx === null;
    nodeHasChild = currentNode.childrenIdx.length > 0;
  }

  let parentNode;
  let parentIsRoot;
  if (currentNode && !nodeIsRoot) {
    parentNode = tree[currentNode.parentIdx];
    parentIsRoot = parentNode.parentIdx === null;
  }

  let GpNode;
  let GpIsRoot;
  if (parentNode && !parentIsRoot) {
    GpNode = tree[parentNode.parentIdx];
    GpIsRoot = GpNode.parentIdx === null;
  }

  let GgpNode;
  if (GpNode && !GpIsRoot) {
    GgpNode = tree[GpNode.parentIdx];
  }

  let firstColumn = [];
  let secondColumn = [];
  let thirdColumn = [];

  if (nodeIsRoot && currentNode) {
    firstColumn.push(
      <ListItem
        selected
        key={currentNode.idx}
        {...currentNode}
        {...{ selectedGroup }}
      />
    );
    for (const idx of currentNode.childrenIdx) {
      if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
        secondColumn.push(
          <ListItem key={idx} {...tree[idx]} {...{ selectedGroup }} />
        );
      }
    }
  } else if (parentIsRoot && parentNode) {
    firstColumn.push(
      <ListItem
        key={parentNode.idx}
        {...parentNode}
        {...{ trail: true, selectedGroup }}
      />
    );
    for (const idx of parentNode.childrenIdx) {
      if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
        secondColumn.push(
          <ListItem
            key={idx}
            {...tree[idx]}
            {...{ selected: idx === currentNode.idx, selectedGroup }}
          />
        );
      }
    }
    for (const idx of currentNode.childrenIdx) {
      if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
        thirdColumn.push(
          <ListItem key={idx} {...tree[idx]} {...{ selectedGroup }} />
        );
      }
    }
  } else if (GpIsRoot && GpNode) {
    if (nodeHasChild) {
      for (const idx of GpNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          firstColumn.push(
            <ListItem
              key={idx}
              {...tree[idx]}
              {...{ trail: idx === parentNode.idx, selectedGroup }}
            />
          );
        }
      }
      for (const idx of parentNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          secondColumn.push(
            <ListItem
              key={idx}
              {...tree[idx]}
              {...{
                selected: idx === currentNode.idx,
                selectedGroup,
              }}
            />
          );
        }
      }
      for (const idx of currentNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          thirdColumn.push(
            <ListItem key={idx} {...tree[idx]} {...{ selectedGroup }} />
          );
        }
      }
    } else {
      firstColumn.push(
        <ListItem
          key={GpNode.idx}
          {...GpNode}
          {...{ trail: true, selectedGroup }}
        />
      );
      for (const idx of GpNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          secondColumn.push(
            <ListItem
              key={idx}
              {...tree[idx]}
              {...{ trail: idx === parentNode.idx, selectedGroup }}
            />
          );
        }
      }
      for (const idx of parentNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          thirdColumn.push(
            <ListItem
              key={idx}
              {...tree[idx]}
              {...{
                selected: idx === currentNode.idx,
                selectedGroup,
              }}
            />
          );
        }
      }
    }
  } else if (GgpNode) {
    if (nodeHasChild) {
      for (const idx of GpNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          firstColumn.push(
            <ListItem
              key={idx}
              {...tree[idx]}
              {...{ trail: idx === parentNode.idx, selectedGroup }}
            />
          );
        }
      }
      for (const idx of parentNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          secondColumn.push(
            <ListItem
              key={idx}
              {...tree[idx]}
              {...{
                selected: idx === currentNode.idx,
                selectedGroup,
              }}
            />
          );
        }
      }
      for (const idx of currentNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          thirdColumn.push(
            <ListItem key={idx} {...tree[idx]} {...{ selectedGroup }} />
          );
        }
      }
    } else {
      for (const idx of GgpNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          firstColumn.push(
            <ListItem
              key={idx}
              {...tree[idx]}
              {...{ trail: idx === GpNode.idx, selectedGroup }}
            />
          );
        }
      }
      for (const idx of GpNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          secondColumn.push(
            <ListItem
              key={idx}
              {...tree[idx]}
              {...{ trail: idx === parentNode.idx, selectedGroup }}
            />
          );
        }
      }
      for (const idx of parentNode.childrenIdx) {
        if (!(tree[idx].id === hideGroup || tree[idx].parentId === hideGroup)) {
          thirdColumn.push(
            <ListItem
              key={idx}
              {...tree[idx]}
              {...{
                selected: idx === currentNode.idx,
                selectedGroup,
              }}
            />
          );
        }
      }
    }
  }

  const newTheme = createTheme(theme, {
    palette: {
      action: {
        hover: "rgba(0, 167, 132, 0.25)",
        focus: "rgba(0, 0, 0,0.05)",
      },
    },
  });

  return (
    <ThemeProvider {...{ theme: newTheme }}>
      <Grid2 container {...{ padding: 1, sx: { height: 300 }, size: 12 }}>
        <Grid2
          {...{
            size: 4,
            sx: {
              height: "100%",
              borderRight: "1px #e0e0e0 solid",
              overflowY: "auto",
              overflowX: "hidden",
            },
          }}
        >
          <List dense >{firstColumn}</List>
        </Grid2>
        <Grid2
          {...{
            size: 4,
            sx: {
              height: "100%",
              borderRight: "1px #e0e0e0 solid",
              overflowY: "auto",
              overflowX: "hidden",
            },
          }}
        >
          <List dense>{secondColumn}</List>
        </Grid2>
        <Grid2
          {...{
            size: 4,
            sx: { height: "100%", overflowY: "auto", overflowX: "hidden" },
          }}
        >
          <List dense>{thirdColumn}</List>
        </Grid2>
      </Grid2>
    </ThemeProvider>
  );
};

export default Picker;
