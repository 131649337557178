import axios from "axios";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

export const getPlatformAndServicesId = "platform&services";
export const platformServices = "platformServices";

export const getPlatformsAndServices = ({ M365server, cloner_key }) => {
  const id = getPlatformAndServicesId;

  console.log("trying to get platforms and services");

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      const url = `${M365server}mailapi/platform`;
      const response = await axios.get(url, {
        headers: { cloner_key },
      });

      const data = response.data;

      let services = [];

      for (const platform of data) {
        const url = `${M365server}mailapi/services?platform_id=${platform.id}`;
        const response2 = await axios.get(url, {
          headers: { cloner_key },
        });

        for (const service of response2.data) {
          services.push(service);
        }
      }

      dispatch(warehouseActions.load({ id: platformServices, data: services }));

      dispatch(workerActions.finish({ id }));
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};
