import useUIBp from "../../../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../../../Hooks/useWorker";
import changeM365GroupLock from "../../../../Actions/changeM365Lock";
import { updateM365OrgId } from "../../../../Actions/changeM365Sharing";
import Button from "../../../../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Confirmar" },
  "en-US": { label: "Confirm" },
};

const Confirm = ({ payload, locale }) => {
  const { auth, dispatch } = useUIBp();
  const { working } = useWorker(updateM365OrgId);

  const onClick = () => {
    dispatch(
      changeM365GroupLock({
        ...auth,
        payload,
        locale,
      })
    );
  };

  return <Button {...{ UIText, onClick, working }} />;
};

export default Confirm;
