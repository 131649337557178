import {
  Button as MUIButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import { useEffect, useState } from "react";
import useUIBp from "../../../Hooks/useUIBoilerplate";

// TimeoutButton component
const TimeoutButton = (props) => {
  const {
    size = "small",
    variant = "contained",
    navTo,
    onClick,
    navReplace,
    submitting,
    timeout = 5500,
    UIText = {},
    typographyProps,
    disabled,
    icon,
  } = props;
  const [progress, setProgress] = useState(0);
  const [waiting, setWaiting] = useState(true);
  const { navigate, settings } = useUIBp();
  const locale = settings["locale"];
  const filteredProps = filterObjKeys(
    props,
    "tooltip",
    "navTo",
    "navReplace",
    "submitting",
    "label",
    "labelSx",
    "icon",
    "UIText"
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((p) => (p += 1));
    }, timeout / 110);

    setTimeout(() => {
      clearInterval(timer);
      setWaiting(false);
    }, timeout);
  }, [timeout]);

  const { label } = UIText[locale];

  let startIcon = icon;
  if (waiting) {
    startIcon = (
      <CircularProgress variant="determinate" value={progress} size={20} />
    );
  } else if (submitting) {
    startIcon = <CircularProgress size={14} />;
  }

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (navTo) {
      navigate(navTo, { replace: navReplace });
    }
  };

  return (
    <MUIButton
      {...filteredProps}
      {...{
        size,
        variant,
        onClick: handleClick,
        startIcon,
        disabled: disabled || submitting || waiting,
      }}
    >
      <Typography noWrap {...typographyProps}>
        {label}
      </Typography>
    </MUIButton>
  );
};

export default TimeoutButton;
