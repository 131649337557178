import {
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import AutoHelp from "../Wrappers/AutoHelp";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";

const Select = (props) => {
  const {
    id,
    UIText,
    options = [],
    required,
    disabled,
    defaultIdx,
    size = 12,
    onChange = () => {},
    hide,
    fontSize = 14,
    clearOnUnmount,
  } = props;
  const { dispatch, settings, UI } = useUIBp();
  const locale = settings.locale;
  const filteredProps = filterObjKeys(
    props,
    "clearOnUnmount",
    "UIText",
    "size",
    "required",
    "defaultIdx",
    "onChange",
    "hide",
    "fontSize"
  );
  const { label, tooltip, fixedTooltip } = UIText[locale];
  let value = UI[id] ?? (defaultIdx >= 0 && options[defaultIdx].value) ?? "";

  const x = options.findIndex((opt) => opt.value === value);
  if (x === -1) {
    value = "";
  }


  useEffect(() => {
    if (defaultIdx >= 0) {
      dispatch(UIactions.setValue({ id, value: options[defaultIdx].value }));
    }
  }, [dispatch, defaultIdx, id, options]);

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        dispatch(UIactions.clear(id));
      }
    };
  }, [dispatch, clearOnUnmount, id]);

  const items = useMemo(() => {
    return options.map((x, idx) => {
      const { label } = x[locale] ?? x.universal;
      return (
        <MenuItem key={idx} {...{ value: x.value }}>
          {label}
        </MenuItem>
      );
    });
  }, [options, locale]);

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(UIactions.setValue({ id, value }));
    onChange(e);
  };

  return (
    <Grid2 container size={size}>
      <AutoHelp {...{ tooltip, fixedTooltip, disabled }}>
        <FormControl
          fullWidth
          {...{
            size: "small",
            required,
            sx: { visibility: hide ? "hidden" : undefined },
          }}
        >
          <InputLabel sx={{ fontSize }}>{label}</InputLabel>
          <MUISelect
            fullWidth
            {...filteredProps}
            {...{
              onChange: handleChange,
              MenuProps: { PaperProps: { sx: { maxHeight: 200 } } },
              label,
              value,
              sx: { fontSize, height: 40 },
            }}
          >
            {items}
          </MUISelect>
        </FormControl>
      </AutoHelp>
    </Grid2>
  );
};

export default Select;
