import axios from "axios";
import { workerActions } from "../../../store/slices/workers";
import { warehouseActions } from "../../../store/slices/warehouse";

export const getMailId = "getMail";
export const mailData = "mailData";

const getMail = ({ mailId, M365server, cloner_key, controller }) => {
  const id = getMailId;
  console.log("Getting mail")

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {

      // Getting the mail through the /api/message handler
      const url = M365server + "mailapi/message/" + mailId;
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
      });
      const data = JSON.parse(response.data);

      // Loading the mail in the warehouse
      dispatch(warehouseActions.load({ id: mailData, data }));
      dispatch(workerActions.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        dispatch(workerActions.clear(id));
        return;
      }
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};

export default getMail;
