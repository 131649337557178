import axios from "axios";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

export const getUsersId = "getAllUsers";
export const usersDataId = "users";

export const getAllUsers = ({ server, cloner_key, group }) => {
  return async (dispatch) => {
    const id = getUsersId;
    dispatch(workerActions.start(id));
    try {
      const response = await axios.get(server + "api/all_users", {
        headers: { cloner_key },
        params: { group },
      });

      dispatch(warehouseActions.load({ id: usersDataId, data: response.data }));
      dispatch(workerActions.finish({ id }));
    } catch (e) {
      console.error(e.message);
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};
