import {  Grid2 } from "@mui/material";
import Back from "./Components/Back";
import Save from "./Components/Save";


const Bottom = () => {
  return (
    <Grid2 container {...{size:12, padding:2, justifyContent:"end", spacing:2}}>
      <Back />
      <Save />
    </Grid2>
  );
};

export default Bottom;
