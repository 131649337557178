import { Grid2 } from "@mui/material";
import { pass1UI } from "./Password1";
import { pass2UI } from "./Password2";
import { useDispatch } from "react-redux";
import Switch from "../../../../../UI/GeneralPurpose/Switch";
import { UIactions } from "../../../../../../store/slices/UI";

const UIText = {
  "es-CL": { label: "Cambiar contraseña" },
  "en-US": { label: "Change password" },
};

export const changePasswordUI = "changePassword";

const ChangePassword = () => {
  const dispatch = useDispatch()

  const onChange = () => {
    dispatch(UIactions.clear(pass1UI));
    dispatch(UIactions.clear(pass2UI));
  };

  return (
    <Grid2 container {...{size:12, alignItems:"center", columnGap:2}}>
      <Switch
        labelRight
        {...{
          id: changePasswordUI,
          UIText,
          onChange,
          labelProps: {
            fontSize: "1.1rem",
            fontWeight: "bold",
          },
          size: "auto",
        }}
      />
    </Grid2>
  );
};

export default ChangePassword;
