import { Grid2, Tooltip, Typography } from "@mui/material";

const Repositories = ({ fontSize, repositories }) => {
  let allRepoNames = repositories
    .map((repo, idx) => {
      return repo.name;
    })
    .join(", ");

    const L = repositories.length;

  let repoText = repositories.map((repo, idx) => {
    return (
      <Typography
        key={idx}
        {...{
          fontSize,
          sx: {
            cursor: "default",
            textDecoration: repo.recover ? "none" : "line-through",
            color: repo.recover ? "black" : "grey",
          },
        }}
      >
        {repo.name}
        {L > 1 && idx+1 < L ? "," : ""}
      </Typography>
    );
  });

  return (
    <Tooltip title={allRepoNames}>
      <Grid2 container {...{ size: 12, alignItems: "center", columnGap: 1 }}>
        {repoText}
      </Grid2>
    </Tooltip>
  );
};

export default Repositories;
