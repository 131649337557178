import TextField from "../../../../../UI/GeneralPurpose/SimpleTextField";

const UIText = {
  "es-CL": {
    label: "Contraseña de selección",
    placeholder: "********",
  },
  "en-US": {
    label: "Selection password",
    placeholder: "********",
  },
};

export const passFieldId = "password";

const PassField = ({ onSubmit: submit }) => {
  return (
    <TextField autoFocus {...{ id: passFieldId, submit, type: "password", UIText }} />
  );
};

export default PassField;
