import Button from "../../../GeneralPurpose/Button";

const ActionButton = ({ working, disabled, UIText,navTo, icon, onClick, variant = "text" }) => {
  return (
    <Button
      {...{
        working,
        disabled,
        UIText,
        variant,
        startIcon: icon,
        onClick,
        navTo,
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
      }}
    />
  );
};

export default ActionButton;
