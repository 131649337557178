import useUIBp from "../../../Hooks/useUIBoilerplate";
import deleteClient from "../../../store/actions/microsoft365/deleteClient";
import GenericConfirmation2 from "./AllPurpose2/DialogConfirmation";

const DeleteM365ClientConfirmation = () => {
  const { auth, dispatch, settings, UI } = useUIBp();
  const { cloner_key, M365server } = auth;
  const locale = settings.locale;
  const payload = UI.modal2.payload ?? { };

  const UIText = {
    "es-CL": {
      header: "Esta seguro que desea desactivar el respaldo de Microsoft365?",
      message: `Se desactivará el usuario de Microsoft 365. La información respaldada previamente podrá ser accesada posteriormente, pero se detendrá cualquier respaldo del sistema externo Microsoft365. Una vez finalizado el proceso será posible descargar un archivo PDF con el detalle de la operación.`,
    },
    "en-US": {
      header: "Are you sure you want to deactivate the Microsoft365 backup?",
      message: `The Microsoft 365 user will be deactivated. The previously backed up information will be accessible later, but any backup of the Microsoft365 external system will be stopped. Once finished a PDF file detailing the operation will be available to download.`,
    },
  };
  const { header, message } = UIText[locale];

  const confirmation = () => {
    dispatch(deleteClient({ user:payload.user, cloner_key, M365server, locale }));
  };


  return (
    <GenericConfirmation2
      {...{ header, message, severity: "error", confirmation }}
    />
  );
};

export default DeleteM365ClientConfirmation;