import useUIBp from "../../../Hooks/useUIBoilerplate";
import DeleteDevicesConfirmation from "../Confirmations/DeleteDevicesConfirmation";
import DeleteSharedLinksConfirmation from "../Confirmations/DeleteSharedLinkConfirmation";
import DeleteGroupConfirmation from "../../Dashboard/Components/TopBar/Dialogs/DeleteGroupConfirmation";
import FileDataPop from "../../Files/Dialogs/FilesDataPop/FilesDataPop";
import GroupsTravelerModal from "../../GroupsTravelerModal/GroupsTravelerModal";
import ClearW365Org from "../../Users/Dialogs/OrgCloudBackup/Components/Windows365/ClearW365Org/ClearW365Org";
import M365GroupLockToggle from "../../Users/Dialogs/OrgCloudBackup/Components/Windows365/ToggleGroupLock/ToggleGroupLock";
import M365SharingToggle from "../../Users/Dialogs/OrgCloudBackup/Components/Windows365/ToggleSharing/ToggleSharing";
import Microsoft365OrgEnable from "../../Users/Dialogs/Windows365OrgEnable/Microsoft365OrgEnable";
import DeleteM365ClientsConfirmation from "../Confirmations/DeleteM365ClientsConfirmation";
import DeleteM365ClientConfirmation from "../Confirmations/DeleteM365ClientConfirmation";
import DeleteUsersConfirmation from "../Confirmations/DeleteUsersConfirmation";
import CreateUsersConfirmation from "../Confirmations/CreateUsersConfirmation";
import PostM365ClientsConfirmation from "../Confirmations/PostM365ClientsConfirmation";
import CreateClientsAfterM365Post from "../../Users/Dialogs/CreateClientsAfterPost";

const DialogsSecondaryOutlet = () => {
  const { UI } = useUIBp();
  const modal = UI.modal2 ?? { type: "" };
  const { type: mType, open } = modal;

  if (open) {
    switch (mType) {
      case "groups-traveler":
        return <GroupsTravelerModal />
      case "m365-fetch":
        return <M365GroupLockToggle />;
      case "m365-share":
        return <M365SharingToggle />;
      case "m365-org-delete":
        return <ClearW365Org />;
      case "file-details":
        return <FileDataPop {...{ modal: "2" }} />;
      case "get-m365code":
        return <Microsoft365OrgEnable />;
      case "download-M365-post-results":
        return <CreateClientsAfterM365Post />;
      //============================================ Confirmations ===================================
      case "delete-shared-links-confirmation":
        return <DeleteSharedLinksConfirmation />;
      case "delete-devices-confirmation":
        return <DeleteDevicesConfirmation />;
      case "delete-users-confirmation":
        return <DeleteUsersConfirmation />;
      case "delete-group-confirmation":
        return <DeleteGroupConfirmation />;
      case "create-users-confirmation":
        return <CreateUsersConfirmation />;
      case "post-m365-clients-confirmation":
        return <PostM365ClientsConfirmation />;
      case "delete-m365-clients-confirmation":
        return <DeleteM365ClientsConfirmation />;
      case "delete-m365-client-confirmation":
        return <DeleteM365ClientConfirmation />;
      default:
        console.error("modal type does not match with any known modal");
        return;
    }
  }
  return;
};
export default DialogsSecondaryOutlet;
