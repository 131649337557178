import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../../../../store/slices/workers";
import { getM365OrgId } from "../../../../../store/actions/microsoft365/getOrganization";

export const updateM365OrgId = "updateM365Organization";

const UIText1 = {
  "es-CL": {
    success:
      "La organización de Microsoft 365 ahora está siendo compartida a los subgrupos",
    error: "No se pudo compartir la organizacion de Microsoft 365: ",
  },
  "en-US": {
    success: "The microsoft 365 organization is being shared to child subgroups",
    error: "Microsoft 365 organization couldn't be shared: ",
  },
};
const UIText2 = {
  "es-CL": {
    success:
      "Se dejó de compartir la organización de Microsoft 365 a los subgrupos",
    error: "No se pudo dejar de compartir la organizacion de Microsoft 365: ",
  },
  "en-US": {
    success: "Microsoft 365 organization sharing was stopped",
    error: "Microsoft 365 organization sharing couln't be stopped: ",
  },
};

const changeM365Sharing = ({ M365server, cloner_key, locale, payload }) => {
  const id = updateM365OrgId;
  return async (dispatch) => {
    const UIText = payload.prevent_propagation ? UIText2 : UIText1;

    const { success, error } = UIText[locale];
    const bodyData = JSON.stringify(payload);
    dispatch(workerActions.start(id));
    try {
      const url = M365server + "mailapi/organization";
      await axios.put(url, bodyData, { headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(workerActions.finish({ id }));
      dispatch(workerActions.clear(getM365OrgId));
    } catch (e) {
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(workerActions.finish({ id, error }));
    }
  };
};

export default changeM365Sharing;
