import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";
import { getM365ClientId, m365clientData } from "./getClient";

const UIText = {
  "es-CL": {
    success: "Usuario de Windows365 deshabilitado en Cloner",
    error: "Error al intentar deshabilitar usuario de Windows365 en Cloner: ",
  },
  "en-US": {
    success: "Windows365 Cloner user inactivated successfully",
    error: "Error deactivating Windows365 Cloner user: ",
  },
};

export const deleteClientId = "deleteW365client";

const deleteClient = ({ M365server, cloner_key, user, locale }) => {
  const id = deleteClientId;
  const { success, error } = UIText[locale];
  console.log("Deleting Microsoft 365 client");

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      // Deleting the client from C365 through the Cloner API
      const url = `${M365server}mailapi/client/${encodeURIComponent(user)}`;
      await axios.delete(url, { headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(workerActions.finish({ id }));
      dispatch(workerActions.clear(getM365ClientId));
      dispatch(warehouseActions.unload(m365clientData));
    } catch (e) {
      const messsage = `${error} ${e.message}`;
      enqueueSnackbar(messsage, { variant: "error" });
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};

export default deleteClient;
