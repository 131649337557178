const headersData = {
    headers:[
        {
            UIText:{
                'es-CL':{label:'Nombre'},
                'en-US':{label:'Name'}},
            filterKey:'name',
            size:3,
        },
        {
            UIText:{
                'es-CL':{label:'Protegido'},
                'en-US':{label:'Protected'}},
            filterKey:'has_password',
            justify:'center',
            size:1,
        },
        {
            UIText:{
                'es-CL':{label:'Fecha de creación'},
                'en-US':{label:'Creation date'}},
            filterKey:'created',
            size:3,
        },
        {
            UIText:{
                'es-CL':{label:'Enlace de descarga'},
                'en-US':{label:'Download link'}},
            filterKey:'',
            size:"grow",
        },
        {
            UIText:{
                'es-CL':{label:'Mas'},
                'en-US':{label:'More'}},
            size:0.5,
            justify:'end',
        },
    ]

}


export default headersData