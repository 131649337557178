import {  Grid2, Typography } from "@mui/material";
import {  Folder, InsertDriveFile, Storage } from "@mui/icons-material";
import { Fragment } from "react";
import AutoHelp from "../../../../../UI/Wrappers/AutoHelp";

const Name = ({ fontSize, name, deleted, type }) => {
  let icon;
  if (type === 0) {
    icon = <Folder color={deleted?"error":"primary"} />;
  } else if (type === 1) {
    icon = <InsertDriveFile sx={{color:deleted?"error":"secondary.light"}} />;
  } else {
    icon = <Storage color="primary" />;
  }

  return (
    <Fragment>
      <Grid2 container size={1}>
        {icon}
      </Grid2>
      <Grid2 container {...{ size: 11 }}>
        <AutoHelp {...{ fixedTooltip: name }}>
          <Typography
            noWrap
            {...{
              fontSize,
              sx: { cursor: "default", color: deleted ? "red" : undefined },
            }}
          >
            {name}
          </Typography>
        </AutoHelp>
      </Grid2>
    </Fragment>
  );
};

export default Name;
