import { useEffect, useMemo } from "react";
import GreylistPicker, {
  greylistPickerId,
} from "../../../../../UI/Specialized/GreylistPicker";
import { repositoryPickerId } from "../../../../../UI/Specialized/RepositoryPicker";
import { greylistDataId } from "../../../../../UI/Specialized/Actions/getGreylist";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { treesActions } from "../../../../../../store/slices/trees";
import { workerActions } from "../../../../../../store/slices/workers";

export const greylistTreeReady = "greylistTreeReady";

const Greylist = () => {
  const { dispatch, UI, warehouse } = useUIBp();
  const repoId = UI[repositoryPickerId] ?? "";
  const currentGreylist = UI[greylistPickerId] ?? 0;

  const greylist = useMemo(() => {
    const greylists = warehouse[greylistDataId] ?? [];
    return greylists.find((gl) => {
      return gl.milliseconds === currentGreylist;
    });
  }, [warehouse, currentGreylist]);

  useEffect(() => {
    if (greylist && repoId) {
      dispatch(treesActions.removeTreeGroup("greylist"));
      dispatch(
        treesActions.createTree({
          id: "greylist-" + repoId,
          type: "history",
          data: {
            created: greylist.created,
            local: greylist.localCreated,
            milliseconds: greylist.milliseconds,
            repository: greylist.repo,
            size: greylist.size,
          },
          root: {},
        })
      );
      dispatch(
        treesActions.loadGreylistTree({
          id: "greylist-" + repoId,
          rawTree: greylist.tree,
        })
      );
    }
    dispatch(workerActions.finish({ id: greylistTreeReady }));
  }, [dispatch, greylist, repoId]);

  const onChange = () => {
    dispatch(workerActions.clear(greylistTreeReady));
  };

  return <GreylistPicker {...{ repoId, onChange, xs: true }} />;
};

export default Greylist;
