const HeadersData = [
  {
    UIText: {
      "es-CL": { label: "Usuario" },
      "en-US": { label: "User" },
    },
    filterKey:'mail',
    size: 3,
  },
  {
    UIText: {
      "es-CL": { label: "Nombre" },
      "en-US": { label: "Name" },
    },
    filterKey:'name',
    size: 3,
  },
  {
    UIText: {
      "es-CL": { label: "Permisos" },
      "en-US": { label: "Permissions" },
    },
    size: 3,
  },
  {
    UIText: {
      "es-CL": { label: "Contraseña" },
      "en-US": { label: "Password" },
    },
    size: "grow",
  },
];

export default HeadersData