import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { getM365OrgId, m365orgData } from "./getOrganization";
import { warehouseActions } from "../../slices/warehouse";
import { workerActions } from "../../slices/workers";
import { UIactions } from "../../slices/UI";
import { w365switchUI } from "../../../components/Users/Dialogs/OrgCloudBackup/Components/Windows365/Windows365";

export const UIText = {
  "es-CL": {
    success: "Organizacion de office 365 eliminada exitosamente",
    error: "Error al intentar eliminar organizacion de Office 365: ",
  },
  "en-US": {
    success: "Office 365 organization deleted successfully",
    error: "Error deleting Office 365 organization: ",
  },
};
export const deleteOrgId = "deleteOrganization";

export const deleteOrganization = ({
  M365server,
  cloner_key,
  orgId,
  locale,
}) => {
  const id = deleteOrgId;
  const { success, error } = UIText[locale];

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      // Deleting the organization from C365 through the Cloner API
      const url = `${M365server}mailapi/organization/${orgId}`;
      await axios.delete(url, { headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(workerActions.finish({ id }));
      dispatch(workerActions.clear(getM365OrgId));
      dispatch(UIactions.clear(w365switchUI));
      dispatch(warehouseActions.unload(m365orgData));
    } catch (e) {
      const messsage = `${error} ${e.message}`;
      enqueueSnackbar(messsage, { variant: "error" });
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};
