import useUIBp from "../../../Hooks/useUIBoilerplate";
import { currentGroupnameId } from "../../GroupsTraveler/Actions/loadGroupsTree";
import BasicLayout from "../../UI/BasicLayout/BasicLayout";
import headersData from "./headers";
import ReportRow from "./ReportRow/ReportRow";
import PlusActions, { plusActionsGrid } from "./PlusActions/PlusActions";
import { viewmodeUI } from "../../../App";
import { UIactions } from "../../../store/slices/UI";
import { resetScrollId } from "../../UI/BasicLayout/Components/Core/Core";

const Core = ({ devices, allDevices, done, working, error, workerId }) => {
  const { dispatch,auth, settings, UI } = useUIBp();
  const viewmode = UI[viewmodeUI] ?? "desktop";
  const groupname = UI[currentGroupnameId];

  const UIText = {
    "es-CL": `Reporte del grupo ${groupname}`,
    "en-US": `Report from group ${groupname}`,
  };
  const header = UIText[settings.locale];
  const plusActions = <PlusActions />;

  const elements = devices.map((data, idx) => (
    <ReportRow key={idx} {...data} />
  ));

  let fixedWidth 

  if (viewmode === "compact"){
    fixedWidth = "100vw"
  } else if (viewmode === "verycompact"){
    fixedWidth = "125vw"
  } else if (viewmode === "mobile") {
    fixedWidth = "250vw"
  }

  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: {
        "es-CL": {
          message: "Error al cargar el reporte: " + error,
        },
        "en-US": { message: "Error loading the report: " + error },
      },
    },
    empty: {
      isEmpty: !devices.length && done && !error,
      UIText: {
        "es-CL": { message: "No hay items disponibles en el reporte" },
        "en-US": { message: "There are no available items on the report" },
      },
    },
    done,
    elements,
  };

  return (
    <BasicLayout
      {...{
        plusActions,
        plusActionsGrid,
        header,
        headersData,
        fullArray: auth.admin === 1 ? allDevices : undefined,
        coreData,
        overflowX: viewmode === "desktop" ? "hidden" : "auto",
        fixedWidth,
        onPaginationChange: () => {
          dispatch(UIactions.setValue({id:resetScrollId, value:true}));
        },
      }}
    />
  );
};

export default Core;
