import Block from "./Block";
import AditionalOptions, {
  aditionalOptionUI,
  recoveryDateUI,
} from "./AditionalOptions";
import { Grid2 } from "@mui/material";
import { humanBytes } from "../../../../../../../utility/misc/humanizers";
import AditionalDetails, { aditionalInfoUI } from "./AditionalDetails";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../store/slices/UI";
import Switch from "../../../../../../UI/GeneralPurpose/Switch";
import Divider from "../../../../../../UI/GeneralPurpose/Divider";

const UIText = {
  "es-CL": {
    repoLabel: "Repositorio:",
    sizeLabel: "Tamaño:",
  },
  "en-US": {
    repoLabel: "Repository:",
    sizeLabel: "Size:",
  },
};

const UITextSwitch = {
  "es-CL": { label: "Recuperar repositorio" },
  "en-US": { label: "Recover repository" },
};

export const recoverRepoUI = "recoverRepo";

const Repository = (props) => {
  const { id, name, size, readOnly } = props;
  const { dispatch, settings, UI } = useUIBp();
  const { repoLabel, sizeLabel } = UIText[settings.locale];
  const recover = UI[`${recoverRepoUI}-${id}`];

  const onChange = () => {
    dispatch(UIactions.clear(`${aditionalOptionUI}-${id}`));
    dispatch(UIactions.clear(`${recoveryDateUI}-${id}`));
    dispatch(UIactions.clear(`${aditionalInfoUI}-${id}`));
  };

  return (
    <Grid2 container size={12}>
      <Divider />
      <Grid2
        container
        {...{
          paddingTop: 2,
          paddingLeft: 2,
          paddingRight: 2,
          size: 12,
          rowGap: 2,
          alignItems: "center",
        }}
      >
        <Block {...{ label: repoLabel, value: name, size:{xl:6,lg:6,md:12,sm:12,xs:12} }} />
        <Block {...{ label: sizeLabel, value: humanBytes(size),size:{xl:3,lg:3,md:3,sm:6,xs:12} }} />
        <Switch
          {...{
            id: `${recoverRepoUI}-${id}`,
            disabled: readOnly,
            UIText: UITextSwitch,
            onChange,
            size:"auto",
          }}
        />
        {recover ? <AditionalOptions {...{ id, readOnly }} /> : null}
        {recover ? <AditionalDetails {...{ id, readOnly }} /> : null}
      </Grid2>
    </Grid2>
  );
};

export default Repository;
