import { Grid2 } from "@mui/material";
import SlimAlert from "../../../GeneralPurpose/SlimAlert";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

// const x = {
//     isEmpty: true,
//     UIText: {
//         universal: { message: "????" }
//     }
// };

const Empty = ({ isEmpty, UIText = { universal: {} } }) => {
  const { settings } = useUIBp();
  const { message } = UIText[settings.locale] ?? UIText.universal;

  if (!isEmpty) {
    return;
  }

  return (
    <Grid2
      container
      {...{
        height: "30vh",
        alignItems: "center",
        justifyContent: "center",
        size: { xs: 12, },
      }}
    >
      <SlimAlert
        notCollapse
        {...{ errors: [message], severity: "info", size: { xs: 6 } }}
      />
    </Grid2>
  );
};

export default Empty;
