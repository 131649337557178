import { UIactions } from "../../../../../../../store/slices/UI";
import Button from "../../../../../../UI/GeneralPurpose/Button";
import { customExtensionsUI } from "./NewExtension";
import { useDispatch } from "react-redux";

const UIText = {
  "es-CL": { label: "Eliminar extensiones" },
  "en-US": { label: "Delete extensions" },
};

const Clear = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.clear(customExtensionsUI));
  };

  return (
    <Button
      {...{
        UIText,
        onClick,
        variant: "text",
        size: "auto",
        btnSize: "medium",
        labelSx: { fontSize: 14 },
      }}
    />
  );
};

export default Clear;
