import { Grid2, Typography } from "@mui/material";
import { options3 as options } from "./selectValues";
import { postDeviceId } from "../../../../../../../store/actions/devices/postDeviceInfo";
import HelpTooltip from "../../../../../../UI/GeneralPurpose/HelpTooltip";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import Select from "../../../../../../UI/GeneralPurpose/Select";
import SlimAlert from "../../../../../../UI/GeneralPurpose/SlimAlert";

const UIText = {
  "es-CL": {
    label: "Intervalo de respaldo",
    tooltip:
      "El intervalo de respaldo se refiere al tiempo de espera entre el termino de un proceso de respaldo y el inicio del siguiente",
  },
  "en-US": {
    label: "Backup intervalo",
    tooltip:
      "The backup interval refers to the time to wait between the end of a backup process and the start of the next one",
  },
};

const UIText2 = {
  "es-CL": {
    warning:
      "El intervalo de respaldo actual no coincide con ninguna de las opciones disponibles. Valor actual: ",
  },
  "en-US": {
    warning:
      "The current backup interval does not match with any available option. Actual value: ",
  },
};

export const intervalUI = "intervalValue";

const Interval = ({ locale }) => {
  const { UI } = useUIBp();
  const { working } = useWorker(postDeviceId);
  const { label } = UIText[locale];
  const interval = UI[intervalUI];
  const { warning } = UIText2[locale];

  const found = options.findIndex((opt) => opt.value === interval);

  const warningFull =
    found === -1 && typeof interval === "number"
      ? [warning + interval + " min"]
      : [];

  return (
    <Grid2 container {...{ size: 12, alignItems: "center" }}>
      <Grid2 container {...{ size: 7, alignItems: "center", columnGap: 1 }}>
        <Typography>{label}</Typography>
        <HelpTooltip {...{ UIText }} />
      </Grid2>
      <Grid2 {...{ size: { xl: 5, xs: 5 } }}>
        <Select
          clearOnUnmount
          {...{
            id: intervalUI,
            UIText,
            disabled: working,
            options,
            defaultIdx: 5,
          }}
        />
      </Grid2>
      <SlimAlert
        {...{ errors: warningFull, severity: "warning", paddingTop: 1 }}
      />
    </Grid2>
  );
};

export default Interval;
