import { Dialog, Grid2 } from "@mui/material";
import GroupName, { groupNameId } from "./Components/GroupName";
import Cancel from "./Components/Cancel";
import { useEffect } from "react";
import { postGroupId } from "../../../../store/actions/users/postGroup";
import Send from "./Components/Send";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import GroupButton from "./Components/GroupButton";
import loadGroupsTree, { selectedGroupId2, selectedGroupIdx2 } from "../../../GroupsTraveler/Actions/loadGroupsTree";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";
import { workerActions } from "../../../../store/slices/workers";

const UIText = {
  "es-CL": { header: "Crear nuevo grupo" },
  "en-US": { header: "Create new group" },
};
const UIText2 = {
  "es-CL": { header: "Editar grupo" },
  "en-US": { header: "Edit group" },
};

const GroupForm = (props) => {
  const { isNew } = props;
  const { auth, dispatch, settings, UI } = useUIBp();
  const { payload } = UI.modal;
  const { group, name, selfGroupId } = payload;
  const { header } = isNew ? UIText[settings.locale] : UIText2[settings.locale];

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(UIactions.setField({ id: groupNameId, value: name }));
    }
  }, [dispatch, isNew, name]);

  useEffect(() => {
    if (auth.group !== selfGroupId) {
      dispatch(
        loadGroupsTree({
          ...auth,
          startGroup: parseInt(group),
          mode:'modal',
        })
      );
    }
  }, [dispatch, auth, group, selfGroupId]);

  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(postGroupId));
      dispatch(UIactions.clear(selectedGroupId2));
      dispatch(UIactions.clear(selectedGroupIdx2));
      dispatch(UIactions.clear(groupNameId));
    };
  }, [dispatch]);

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2
          container
          {...{ paddingTop: 0.5, rowGap: 2, sx: { width: "100%" } }}
        >
          <GroupName />
          {auth.group === selfGroupId ? null : (
            <GroupButton
              {...{
                disabled: isNew,
                startGroup: group,
                hideGroup: !isNew ? selfGroupId : undefined,
              }}
            />
          )}
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel />
        <Send {...{ isNew, groupId: selfGroupId }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default GroupForm;
