import { useDispatch } from "react-redux";
import { getGreylistId, greylistDataId } from "../../../../../UI/Specialized/Actions/getGreylist";
import { greylistPickerId } from "../../../../../UI/Specialized/GreylistPicker";
import { repositoryPickerId } from "../../../../../UI/Specialized/RepositoryPicker";
import { greylistTreeReady } from "./GreylstPicker";
import { workerActions } from "../../../../../../store/slices/workers";
import { UIactions } from "../../../../../../store/slices/UI";
import { treesActions } from "../../../../../../store/slices/trees";
import { getRepoId, repoDataId } from "../../../../../../store/actions/repositories/getRepositories";
import { warehouseActions } from "../../../../../../store/slices/warehouse";
import DevicePicker from "../../../../../UI/Specialized/DevicePicker";

const Device = (props) => {
    const {user} = props
    const dispatch = useDispatch()

  const onChange = ()=>{
    dispatch(workerActions.clear(getRepoId))
    dispatch(workerActions.clear(getGreylistId))
    dispatch(workerActions.clear(greylistTreeReady))
    dispatch(warehouseActions.unload(repoDataId))
    dispatch(warehouseActions.unload(greylistDataId))
    dispatch(UIactions.clear(repositoryPickerId))
    dispatch(UIactions.clear(greylistPickerId))
    dispatch(treesActions.clearForest())
  }

  return <DevicePicker autoload {...{ user, onChange, size: 12 }} />;
};

export default Device;