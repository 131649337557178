import { Grid2 } from "@mui/material";
import { Fragment } from "react";
import Divider from "../../UI/GeneralPurpose/Divider";
import { Add } from "@mui/icons-material";
import { selectedGroupId } from "../Actions/loadGroupsTree";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";
import Button from "../../UI/GeneralPurpose/Button";
import GroupFinder from "../../UI/Specialized/GroupFinder";

const UIText = {
  "es-CL": { label: "Crear grupo" },
  "en-US": { label: "Create group" },
};

const Top = () => {
  const { dispatch, UI } = useUIBp();
  const selected = UI[selectedGroupId];

  const onClick = () => {
    dispatch(
      UIactions.openModal({
        type: "create-new-group",
        payload: { group: selected },
      })
    );
  };

  return (
    <Fragment>
      <Grid2
        container
        {...{
          justifyContent: "space-between",
          columnSpacing: 2,
          padding: 2,
          size: 12,
        }}
      >
        <GroupFinder {...{ size: 6, travelerType: "topbar" }} />
        <Button
          {...{
            UIText,
            startIcon: <Add />,
            variant: "text",
            labelSx: {
              fontSize: 13,
              fontWeight: "bold",
            },
            onClick,
            size: 3,
          }}
        />
      </Grid2>
      <Divider />
    </Fragment>
  );
};

export default Top;
