import { Grid2, Typography } from "@mui/material";
import VerificationCodeField from "./VerificationCodeField";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": "Codigo de verificación",
  "en-US": "Verification code",
};

const VerificationCode = () => {
    const {settings} = useUIBp()
    const label = UIText[settings.locale]

  return (
    <Grid2
      container
      size={12}
      justifyContent="space-between"
    >
      <Grid2 container size={4} alignItems="center">
        <Typography>{label}</Typography>
      </Grid2>
      <VerificationCodeField {...{ idIdx: 0 }} />
      <VerificationCodeField {...{ idIdx: 1 }} />
      <VerificationCodeField {...{ idIdx: 2 }} />
      <VerificationCodeField {...{ idIdx: 3 }} />
      <VerificationCodeField {...{ idIdx: 4 }} />
      <VerificationCodeField {...{ idIdx: 5 }} />
    </Grid2>
  );
};

export default VerificationCode;
