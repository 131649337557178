import {
  ContentCopy,
  Delete,
  Download,
  RemoveModerator,
  Shield,
} from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { UIactions } from "../../../../../store/slices/UI";
import MoreActions from "../../../../UI/BasicLayout/Components/MoreActions/MoreActions";
import downloadFile from "../../../../../store/actions/shared/downloadFile";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const UIText1 = {
  "es-CL": { label: "Proteger", fixedTooltip: "Proteger enlace de descarga" },
  "en-US": { label: "Protect", fixedTooltip: "Protect download link" },
};
const UIText2 = {
  "es-CL": { label: "Desproteger", fixedTooltip: "Remover protección" },
  "en-US": { label: "Unprotect", fixedTooltip: "Remove protection" },
};
const UIText3 = {
  "es-CL": { success: "Enlace de descarga copiado al portapapeles" },
  "en-US": { success: "Download link copied to the clipboard" },
};

const RowActions = (props) => {
  let {
    id,
    repo_id: repoId,
    file: fileId,
    name: filename,
    has_password,
  } = props;

  const { auth, dispatch, settings } = useUIBp();
  const locale = settings.locale;
  const { success } = UIText3[settings.locale];
  const UIText = has_password ? UIText2 : UIText1;

  let baseURL = "";
  if (typeof window !== "undefined") {
    baseURL = window.location.origin;
  }
  const downloadLink =
    baseURL + "/shared?id=" + id + "&user=" + encodeURIComponent(auth.user);

  const icon = has_password ? (
    <Shield {...{ sx: { color: "primary.main" } }} />
  ) : (
    <RemoveModerator {...{ sx: { color: "grey" } }} />
  );

  const handleDownload = () => {
    dispatch(
      downloadFile({
        ...auth,
        repoId,
        fileId,
        filename,
        locale,
      })
    );
  };

  const handleProtect = () => {
    if (has_password) {
      dispatch(
        UIactions.openModal({
          type: "deprotect-shared-link",
          payload: { id },
        })
      );
    } else {
      dispatch(
        UIactions.openModal({
          type: "protect-shared-link",
          payload: { id },
        })
      );
    }
  };

  const data = {
    id,
    buttonsData: [
      {
        icon,
        UIText,
        onClick: handleProtect,
      },
      {
        divider: true,
      },
      {
        icon: <ContentCopy sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Copiar enlace" },
          "en-US": { label: "Copy link" },
        },
        onClick: () => {
          navigator.clipboard.writeText(downloadLink);
          enqueueSnackbar(success, { variant: "success" });
        },
      },
      {
        icon: <Download sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Descargar" },
          "en-US": { label: "Download" },
        },
        onClick: handleDownload,
      },
      {
        icon: <Delete sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Eliminar" },
          "en-US": { label: "Delete" },
        },
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "delete-shared-links",
              payload: {links:[props]},
            })
          );
        },
      },
    ],
  };

  return <MoreActions {...data} />;
};

export default RowActions;
