import { Download } from "@mui/icons-material";
import {
  downloadTemplate,
  downloadTemplateUI,
} from "../../Actions/downloadTemplate";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../Hooks/useWorker";
import Button from "../../../../../UI/GeneralPurpose/Button";

const UITextDownload = {
  "es-CL": {
    label: "Descargar .xlsx",
    fixedTooltip: "Descargar hoja .xslx templado para la carga de usuarios",
  },
  "en-US": {
    label: "Download .xlsx",
    fixedTooltip: "Download .xslx template sheet needed to upload users",
  },
};

const DownloadSheet = () => {
  const { dispatch, settings } = useUIBp();
  const { working } = useWorker(downloadTemplateUI);

  const onClick = () => {
    dispatch(downloadTemplate(settings.locale));
  };

  return (
    <Button
      {...{
        onClick,
        UIText: UITextDownload,
        working,
        variant: "text",
        startIcon: <Download />,
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
        size: "auto",
      }}
    />
  );
};

export default DownloadSheet;
