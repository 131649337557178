import { rowErrorsUI } from "./UsersList/UserRow";
import { newUsersUI } from "./TopBar/UploadSheet";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../Hooks/useWorker";
import { postUserId } from "../../../../../store/actions/users/postUsers";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Continuar" },
  "en-US": { label: "Next" },
};

const Create = () => {
  const { dispatch, UI } = useUIBp();
  const { working } = useWorker(postUserId);
  const errors = UI[rowErrorsUI] ?? [];
  const users = UI[newUsersUI] ?? [];

  const onClick = () => {
    dispatch(UIactions.openModal2({ type: "create-users-confirmation" }));
  };

  const disabled = !!errors.length || !users.length;

  return <Button {...{ disabled, UIText, onClick, working }} />;
};

export default Create;
