import { Delete } from "@mui/icons-material";
import { defaultSelected } from "../../../../../UI/GeneralPurpose/genericIDs";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { recoveriesActions } from "../../../../../../store/slices/recoveries";
import { UIactions } from "../../../../../../store/slices/UI";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";

const UIText = {
  "es-CL": { label: "Eliminar" },
  "en-US": { label: "Delete" },
};

const DeleteBtn = () => {
  const { dispatch, UI } = useUIBp();
  const selected = UI.selected ?? [];

  const onClick = () => {
    console.log("selected", selected);
    dispatch(recoveriesActions.deleteRecoveries(selected));
    dispatch(recoveriesActions.removeUnusedRepositories())
    dispatch(UIactions.clear(defaultSelected))
  };

  return (
    <ActionButton
      {...{
        disabled: !selected.length,
        onClick,
        UIText,
        icon: <Delete />,
      }}
    />
  );
};

export default DeleteBtn;
