import { Grid2 } from "@mui/material";
import useVH from "../../../../../../Hooks/useVH";
import Reset from "./Reset";
import Next from "./Next";

const BottomButtons = () => {
  const vh4 = useVH(6);

  return (
    <Grid2
      container
      {...{
        justifyContent: "space-between",
        alignItems: "center",
        sx: { height: vh4 },
        size: 12,
      }}
    >
      <Reset />
      <Next />
    </Grid2>
  );
};

export default BottomButtons;