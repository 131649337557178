import {
  CircularProgress,
  Collapse,
  Grid2,
  List,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { theme, viewmodeUI } from "../../../../App";
import Logo, { closeDrawerUI } from "./Components/Logo";
import MailCategories from "./Components/MailCategories/MailCategories";
import Reports from "./Components/Botones/Reports";
import Users from "./Components/Botones/Users";
import Devices from "./Components/Botones/Devices";
import Files from "./Components/Botones/Files";
import Shared from "./Components/Botones/Shared";
import Mails from "./Components/Botones/Mails";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import Recovery from "./Components/Botones/Recovery";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../Hooks/useWorker";
import { getMailCategoriesId } from "../../../Mails/Actions/getMailCategories";
import { UIactions } from "../../../../store/slices/UI";

export const forceOpen = "forceDrawer";

const CustomDrawer = () => {
  const { auth, dispatch, UI } = useUIBp();
  const location = useLocation();
  const { working } = useWorker(getMailCategoriesId);
  const viewmode = UI[viewmodeUI];
  const path = location.pathname.split("/")[2];
  const close = UI[closeDrawerUI];
  const open2 = UI[forceOpen];

  const debouncedChange = debounce((c) => {
    if (c === "enter") {
      dispatch(UIactions.setValue({ id: forceOpen, value: true }));
    } else if (c === "leave") {
      dispatch(UIactions.setValue({ id: forceOpen, value: false }));
    }
  }, 250);

  const onMouseEnter = () => {
    debouncedChange("enter");
  };
  const onMouseLeave = () => {
    debouncedChange("leave");
  };

  const newTheme = createTheme(theme, {
    palette: {
      action: {
        hover: "rgba(255, 255, 255, 0.05)",
      },
      primary: { main: "#0a1e37", light: "rgb(80,99,130)" },
      secondary: { main: "#00a784" },
      select: { main: "#18365a", accent: "#f0f0f0" },
    },
  });

  return (
    <ThemeProvider {...{ theme: newTheme }}>
      <Grid2
        container
        {...{
          size: "auto",
          sx: {
            height: "100vh",
            backgroundColor: "primary.main",
          },
        }}
      >
        <Collapse
          {...{
            orientation: "horizontal",
            in:
              (!close || open2) &&
              (viewmode === "desktop" || viewmode === "compact"),
            collapsedSize: 61,
          }}
        >
          <Grid2 container direction="column" sx={{ width: 250 }}>
            <Logo
              open={
                (!close || open2) &&
                (viewmode === "desktop" || viewmode === "compact")
              }
            />
            <Grid2 {...{ size: 12, onMouseEnter, onMouseLeave }}>
              <List sx={{ width: 250, height: "42vh" }} disablePadding>
                {auth.admin ? <Reports /> : null}
                {auth.admin ? <Users /> : null}
                {auth.admin ? <Devices /> : null}
                <Files />
                <Shared />
                {auth.admin ? <Recovery /> : null}
                <Mails />
              </List>
              {path === "mails" ? <MailCategories /> : null}
              {working ? (
                <Grid2 container {...{ size: 12, sx: { padding: 2 } }}>
                  <CircularProgress color="secondary" size={20} />
                </Grid2>
              ) : null}
            </Grid2>
          </Grid2>
        </Collapse>
      </Grid2>
    </ThemeProvider>
  );
};

export default CustomDrawer;
