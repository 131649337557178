import axios from "axios";
import { bkpMailCountId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { bkpDriveSizeId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { workerActions } from "../../slices/workers";
import { UIactions } from "../../slices/UI";

export const getM365ClientsSizeId = "getW365ClientsSize";

const getClientsSize = ({ M365server, cloner_key, orgID, user, userMail }) => {
  const id = getM365ClientsSizeId;

  return async (dispatch) => {
    dispatch(workerActions.start(id));

    try {
      let url = `${M365server}mailapi/organization/${orgID}/client/${userMail}/space`;
      const response = await axios.get(url, { headers: { cloner_key } });
      const data2 = response.data.data;

      dispatch(
        UIactions.setValue({
          id: bkpMailCountId + user,
          value: data2.count_mails,
        })
      );
      dispatch(
        UIactions.setValue({
          id: bkpDriveSizeId + user,
          value: data2.drive,
        })
      );

    } catch (error) {
      console.error(error);
    }

    dispatch(workerActions.finish({ id }));
  };
};

export default getClientsSize;
