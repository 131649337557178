import { Grid2 } from "@mui/material";
import Subtitle from "./Subtitle";
import Password1 from "./Password1";
import Password2 from "./Password2";
import ChangePassword, { changePasswordUI } from "./ChangePassword";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import CollapseGrid from "../../../../../UI/GeneralPurpose/CollapseGrid";

const Password = ({ isNew, users }) => {
  const { UI, settings } = useUIBp();
  const locale = settings.locale;
  const changePassword = UI[changePasswordUI];

  return (
    <Grid2 container size={12}>
      {users.length > 1 ? (
        <ChangePassword {...{ locale }} />
      ) : (
        <Subtitle {...{ locale }} />
      )}
      <CollapseGrid
        open={changePassword || users.length === 1 || isNew}
        outerProps={{ size: 12 }}
        innerProps={{ rowGap: 2, paddingTop: 0.5 }}
      >
        <Password1 {...{ isNew }} />
        <Password2 {...{ isNew }} />
      </CollapseGrid>
    </Grid2>
  );
};

export default Password;
