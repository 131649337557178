import { MenuItem, Typography } from "@mui/material";
import AutoHelp from "../../../Wrappers/AutoHelp";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const MoreActionsButtons = (props) => {
  const {
    onClick = () => {},
    navTo,
    disabled,
    icon,
    UIText,
    fontSize = "0.9rem",
  } = props;
  const { navigate, settings } = useUIBp();
  const { label, tooltip, fixedTooltip } =
    UIText[settings.locale] ?? UIText.universe;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (navTo) {
      navigate(navTo);
    }
  };

  return (
    <AutoHelp {...{ tooltip, fixedTooltip, disabled, placement: "left" }}>
      <MenuItem
        {...{
          onClick: handleClick,
          disabled,
          sx: { display: "flex", alignItems: "center" },
        }}
      >
        {icon}
        <Typography {...{ sx: { marginLeft: "1rem", cursor:'pointer' }, fontSize }}>
          {label}
        </Typography>
      </MenuItem>
    </AutoHelp>
  );
};

export default MoreActionsButtons;
