import { useDispatch } from "react-redux";
import { contactNameUI } from "./Form/Components/ContactInfo/ContactName";
import { contactPhoneUI } from "./Form/Components/ContactInfo/ContactPhone";
import { contactEmailUI } from "./Form/Components/ContactInfo/ContactEmail";
import { deliveryOfficeUI } from "./Form/Components/AddressInfo/DeliveryOffice";
import { deliveryStreetNumberUI } from "./Form/Components/AddressInfo/DeliveryStreetNumber";
import { deliveryStreetUI } from "./Form/Components/AddressInfo/DeliveryStreet";
import { regionSelectUI } from "./Form/Components/AddressInfo/Region";
import { districtSelectUI } from "./Form/Components/AddressInfo/District";
import { citySelectUI } from "./Form/Components/AddressInfo/City";
import {
  deliveryWindowFromUI,
  deliveryWindowToUI,
} from "./Form/Components/DeliveryWindow";
import { otherReasonUI } from "./Form/Components/Reason/OtherReason";
import { reasonUI } from "./Form/Components/Reason/Reasons";
import { miscErrorsUI } from "./Next";
import { Grid2 } from "@mui/material";
import { defaultRadioSelect } from "../../../../UI/GeneralPurpose/genericIDs";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Resetear formulario" },
  "en-US": { label: "Reset form" },
};

const Reset = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(UIactions.clear(contactNameUI));
    dispatch(UIactions.clear(contactPhoneUI));
    dispatch(UIactions.clear(contactEmailUI));
    dispatch(UIactions.clear(deliveryOfficeUI));
    dispatch(UIactions.clear(deliveryStreetUI));
    dispatch(UIactions.clear(deliveryStreetNumberUI));
    dispatch(UIactions.clear(regionSelectUI));
    dispatch(UIactions.clear(districtSelectUI));
    dispatch(UIactions.clear(citySelectUI));
    dispatch(UIactions.clear(deliveryWindowFromUI));
    dispatch(UIactions.clear(deliveryWindowToUI));
    dispatch(UIactions.clear(otherReasonUI));
    dispatch(UIactions.clear(reasonUI));
    dispatch(UIactions.clear(miscErrorsUI));
    dispatch(UIactions.setValue({ id: defaultRadioSelect, value: "link" }));
  };

  return (
    <Grid2 container size={4} justifyContent="center">
      <Button {...{ UIText, onClick: handleClick, variant: "outlined" }} />
    </Grid2>
  );
};

export default Reset;
