import axios from "axios";
import { parseGroupsRecursive } from "../users/utils";
import { devicesLoadedId } from "./getRootDevices";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

export const devicesDataId = "devices";

const getAllDevices = ({
  server,
  cloner_key,
  group,
  controller,
  includeEmpty,
}) => {
  const id = devicesLoadedId;

  return async (dispatch) => {
    console.log("Getting group data!");
    dispatch(workerActions.start(id));

    try {

      // Getting all devices through groups recursive
      const url = server + "api/groups_recursive";
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
        params: { group },
      });

      // Parsing the data to change it from tree form into an ordered list
      const parsedData = parseGroupsRecursive(response.data);

      // Filling the warehouse with the parsed data
      for (const newDevice of parsedData) {
        if (newDevice.mid || includeEmpty) {
          dispatch(
            warehouseActions.add({
              id: "devices",
              key: newDevice.mid ? newDevice.mid : newDevice.user,
              data: newDevice,
            })
          );
        }
      }

      dispatch(workerActions.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        dispatch(workerActions.clear(id));
        return;
      }
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};

export default getAllDevices;
