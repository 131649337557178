import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";
import { recoveriesActions } from "../../slices/recoveries";

const UIText = {
  "es-CL": {
    info: "Creando solicitud de recuperación",
    success: "Solicitud de recuperación creada exitosamente",
    error: "Error al obtener los repositorios",
    empty: "El usuario no tiene dispositivos asociados. Usuario:",
  },
  "en-US": {
    info: "Creating recovery request",
    success: "Successfully created recovery request",
    error: "Error fetching repositories",
    empty: "User has no associated devices. User:",
  },
};

export const createUsersRequestID = "createUsersRequest";

const createUsersRequest = ( { server, cloner_key, users = [], locale }) => {
  const id = createUsersRequestID
  const { success, error, info, empty } = UIText[locale];

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    enqueueSnackbar(info, { variant: "info" });

    for (const user of users) {
      const url = server + "api/machines";
      const response = await axios.get(url, {
        headers: { cloner_key },
        params: { user },
      });
      const devices = response.data.machines_info;
      if (devices.length === 0) {
        enqueueSnackbar(`${empty}${user}`, { variant: "error" });
        console.error(`${empty}${user}`);
        continue;
      }
      
      for (const device of devices) {
        const { mid, space_active, lastBkp } = device;
        let repositories = [];
        try {
          const url = server + "api/repositories";
          const response = await axios.get(url, {
            headers: { cloner_key },
            params: { id: mid, user },
          });
          for (const repo of response.data.repositories) {
            repositories.push({
              id: repo.id,
              metafile: repo.root,
              name: repo.name,
              last_backup: repo.last_backup,
              size: repo.active_space,
              recover: true,
            });
          }
          dispatch(
            recoveriesActions.newRecovery({
              user,
              mid,
              device: device.name,
              repositories,
              fullSize: space_active,
              lastBkp,
              mode: "simple",
            })
          );
        } catch (e) {
          enqueueSnackbar(`${error}: ${e.message}`, { variant: "error" });
        }
      }
    }
    dispatch(recoveriesActions.initialize());
    enqueueSnackbar(success, { variant: "success" });
    dispatch(workerActions.finish({ id }));
  };
};

export default createUsersRequest;
