import { Chip, Grid2, LinearProgress, Typography } from "@mui/material";
import Button from "../../../UI/GeneralPurpose/Button";
import { ChevronRight } from "@mui/icons-material";
import AutoHelp from "../../../UI/Wrappers/AutoHelp";
import { humanBytes } from "../../../../utility/misc/humanizers";
import Divider from "../../../UI/GeneralPurpose/Divider";

const Row = ({
    login, 
    repository,
    path,
    size,
    uploaded,
}) => {

  const UIText = {
    universal: {
      label: login,
    },
  };

  const handleClick = () => {
    console.log("goto repo", repository);
  }

  var humanSize = humanBytes(size);
  var humanUploaded = humanBytes(uploaded);
  var percentage = (uploaded / size) * 100
  var percentageStr = ((uploaded / size) * 100).toFixed(1);

  return (
    <Grid2 container {...{ size: 12, rowSpacing: 1 }}>
      <Grid2
        container
        {...{
          size: 12,
          justifyContent: "space-between",
          alignItems: "center",
          columnSpacing: 2,
          paddingTop:2
        }}
      >
        <Grid2
          container
          {...{ size: 10, alignItems: "center", wrap: "nowrap" }}
        >
          <Button
            {...{ UIText, variant: "outlined", color: "primary", size: "auto", labelSx: { fontSize: "0.8rem", textTransform:"lowercase" } }}
          />
          <ChevronRight />
          <AutoHelp {...{ fixedTooltip: path }}>
            <Typography {...{ variant: "body2", noWrap: true }}>
              {path}
            </Typography>
          </AutoHelp>
        </Grid2>
        <Grid2 {...{ size: "auto", fontSize:"0.8rem" }}> {humanUploaded}/{humanSize}</Grid2>
      </Grid2>
      <Grid2
        container
        {...{ size: 12, alignItems: "center", columnSpacing: 2 }}
      >
        <Grid2 {...{ size: "grow" }}>
          <LinearProgress
            {...{ variant: "determinate", value: percentage, sx: { height: 10 } }}
          />
        </Grid2>
        <Grid2 {...{ size: "auto" }}>
          <Chip {...{ label: `${percentageStr}%`, color: "primary", variant: "outlined" }} />
        </Grid2>
      </Grid2>
      <Divider />
    </Grid2>
  );
};

export default Row;
