import { useEffect, useMemo } from "react";
import { sortObjByKey } from "../../../utility/misc/sortObjByKey";
import DynamicSelect from "../GeneralPurpose/DynamicSelect";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import getRepositories, { getRepoId, repoDataId } from "../../../store/actions/repositories/getRepositories";
import { useWorker } from "../../../Hooks/useWorker";
import { UIactions } from "../../../store/slices/UI";
import { warehouseActions } from "../../../store/slices/warehouse";
import { workerActions } from "../../../store/slices/workers";

const UIText = {
  "es-CL": {
    normalTxt: "Repositorio",
    loadingTxt: "Cargando...",
    errorTxt: "Error al obtener repositorios",
    emptyTxt: "No se encontró repositorios",
  },
  "en-US": {
    normalTxt: "Repository",
    loadingTxt: "Loading...",
    errorTxt: "Error loading repositories",
    emptyTxt: "No repositories found",
  },
};

export const repositoryPickerId = "repositoryPicker";

const RepositoryPicker = ({
  mid = "",
  user = "",
  onChange,
  onLoaded,
  onDismount,
  size,
}) => {
  const { auth, dispatch, UI, warehouse } = useUIBp();
  const { done, working, error } = useWorker(getRepoId);
  const repositoryPicker = UI[repositoryPickerId];

  let options = useMemo(() => {
    const repositories = warehouse[repoDataId] ?? [];

    return [...repositories]
      .sort((a, b) => sortObjByKey(a, b, "label"))
      .map((x) => {
        return { universal: { value: x.id, label: x.name } };
      });
  }, [warehouse]);

  // Load data
  useEffect(() => {
    const controller = new AbortController();
    if (!done && mid) {
      dispatch(getRepositories({ ...auth, user, mid, controller }));
    }
    return () => {
      if (!done) {
        controller.abort();
      }
    };
  }, [dispatch, done, auth, user, mid]);

  // Fill blank
  useEffect(() => {
    if (done && !repositoryPicker && !!options.length) {
      const value = options[0].universal.value;
      dispatch(UIactions.setValue({ id: repositoryPickerId, value }));
      if (onLoaded) {
        onLoaded();
      }
    }
  }, [dispatch, done, repositoryPicker, options, onLoaded]);

  // Cleanup
  useEffect(() => {
    return () => {
      dispatch(warehouseActions.unload(repoDataId));
      dispatch(UIactions.clear(repositoryPickerId));
      dispatch(workerActions.clear(getRepoId));
      if (onDismount) {
        onDismount();
      }
    };
  }, [dispatch, onDismount]);

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(UIactions.setValue({ id: repositoryPickerId, value }));
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <DynamicSelect
      fullWidth
      {...{
        id: repositoryPickerId,
        UIText,
        options,
        disabled: options.length <= 1,
        onChange: handleChange,
        size,
        done,
        working,
        error,
      }}
    />
  );
};

export default RepositoryPicker;
