import { Avatar, Grid2, Typography } from "@mui/material";
import Switch from "../../../../../UI/GeneralPurpose/Switch";
import { useEffect, useMemo } from "react";
import {
  getM365OrgId,
  getOrganization,
  m365orgData,
} from "../../../../../../store/actions/microsoft365/getOrganization";
import getClient, {
  getM365ClientId,
  m365clientData,
} from "../../../../../../store/actions/microsoft365/getClient";
import {
  getPlatformAndServicesId,
  getPlatformsAndServices,
} from "../../../../../../store/actions/microsoft365/getPlatformsAndServices";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../Hooks/useWorker";
import { UIactions } from "../../../../../../store/slices/UI";

const UIText = {
  "es-CL": { label: "Microsoft365", noDomain: "Grupo sin dominio" },
  "en-US": { label: "Microsoft365", noDomain: "Group lacks domain" },
};

export const m365Switch = "m365Switch";

const M365 = ({user}) => {
  const { auth, dispatch, settings, warehouse, UI } = useUIBp();
  const { M365server, cloner_key } = auth;
  const { done: done1, working } = useWorker(getM365OrgId);
  const { done, working: w2 } = useWorker(getM365ClientId);
  const { done: done3 } = useWorker(getPlatformAndServicesId);
  const locale = settings.locale;
  const { label, noDomain } = UIText[locale];
  const { payload } = UI.modal;
  const { users = [] } = payload;

  const m365org = useMemo(() => warehouse[m365orgData] ?? {}, [warehouse]);

  const own = m365org.own ?? {};
  const parent = m365org.parent ?? {};

  const availableCert = own.client_id ? own : parent;
  const domain = availableCert.mail_account
    ? availableCert.mail_account.split("@")[1]
    : null;

  const client = warehouse[m365clientData] ?? {};

  useEffect(() => {
    if (!done3) {
      dispatch(getPlatformsAndServices({ M365server, cloner_key, locale }));
    }
  }, [dispatch, done3, M365server, cloner_key, locale]);

  useEffect(() => {
    if (!done1) {
      dispatch(
        getOrganization({
          ...auth,
          groupId: user.group,
        })
      );
    }
  }, [dispatch, auth, done1, user.group]);

  useEffect(() => {
    if (!done && done1 && !!m365org.own) {
      dispatch(
        getClient({
          ...auth,
          user: users[0].login,
          orgID: m365org.own.client_id ? m365org.own.id : m365org.parent.id,
        })
      );
    }
  }, [dispatch, auth, done, done1, users, m365org]);

  useEffect(() => {
    if (client.client_mail) {
      dispatch(UIactions.setValue({ id: m365Switch, value: true }));
    } else {
      dispatch(UIactions.setValue({ id: m365Switch, value: false }));
    }
  }, [dispatch, client.client_mail]);

  return (
    <Grid2
      container
      {...{
        alignItems: "center",
        columnGap: 2,
        sx: { paddingBottom: 6, height: "5vh", width: "100%" },
      }}
    >
      <Grid2
        container
        {...{ size: 1, alignItems: "center", justifyContent: "center" }}
      >
        <Switch
        noLabel
          {...{ id: m365Switch, disabled: !domain, UIText }}
        />
      </Grid2>
      <Avatar
        alt="Windows365-icon"
        src="https://upload.wikimedia.org/wikipedia/commons/0/0e/Microsoft_365_%282022%29.svg"
        variant="rounded"
        sx={{ height: 30, width: 27.2 }}
      />
      <Typography fontSize={16}>
        {label}
        {working || w2
          ? null
          : ` ( ${domain ? "@" : ""}${domain ?? noDomain} )`}
      </Typography>
    </Grid2>
  );
};

export default M365;
