import { useDispatch } from "react-redux";
import { usernameUI } from "../Username";
import { userUI } from "../User";
import { pass1UI } from "../Password/Password1";
import { pass2UI } from "../Password/Password2";
import { permissionsUI } from "../Permissions/PermissionsSelect";
import { UIactions } from "../../../../../../store/slices/UI";
import Button from "../../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Borrar" },
  "en-US": { label: "Clear" },
};

const Clear = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.clear(usernameUI));
    dispatch(UIactions.clear(userUI));
    dispatch(UIactions.clear(pass1UI));
    dispatch(UIactions.clear(pass2UI));
    dispatch(UIactions.clear(permissionsUI));
  };

  return <Button {...{ UIText, onClick, variant:'text' }} />;
};

export default Clear;
