import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import { deleteClientsId } from "../../../../../../../store/actions/microsoft365/deleteClients";
import { postClientsId } from "../../../../../../../store/actions/microsoft365/postClients";
import { suggestMailId } from "../../../../../../../store/actions/microsoft365/suggestM365Mails";
import { validateMailId } from "../../../../../../../store/actions/microsoft365/validateM365Mails";
import { UIactions } from "../../../../../../../store/slices/UI";
import MoreActions from "../../../../../../UI/BasicLayout/Components/MoreActions2/MoreActions";
import { bkpMailArrayId } from "../../UsersList/UserRow/BackupMail";
import { bkpOneArrayId } from "../../UsersList/UserRow/BackupOnedrive";
import { arrayId } from "../../UsersList/UsersList";

const UITextMore = {
  "es-CL": { label: "Más", },
  "en-US": { label: "More", },
};

export const newUsersUI = "newUsers";

const More = () => {
  const { dispatch, UI } = useUIBp();
  const { working: w1 } = useWorker(postClientsId);
  const { working: w2 } = useWorker(suggestMailId);
  const { working: w3 } = useWorker(validateMailId);
  const { working: w4 } = useWorker(deleteClientsId);
  const selected = UI[arrayId] ?? [];

  const buttonsData = [
    {
      noIcon: true,
      UIText: {
        "es-CL": { label: "Respaldar correo" },
        "en-US": { label: "Backup mail" },
      },
      onClick: () => {
        for (const user of selected) {
          dispatch(UIactions.add({ id: bkpMailArrayId, key: user }));
        }
      },
    },
    {
      noIcon: true,
      UIText: {
        "es-CL": { label: "No respaldar correo" },
        "en-US": { label: "Don't backup mail" },
      },
      onClick: () => {
        for (const user of selected) {
          dispatch(UIactions.remove({ id: bkpMailArrayId, key: user }));
        }
        //
      },
    },
    {
      noIcon: true,
      UIText: {
        "es-CL": { label: "Respaldar Onedrive" },
        "en-US": { label: "Backup mail" },
      },
      onClick: () => {
        for (const user of selected) {
          dispatch(UIactions.add({ id: bkpOneArrayId, key: user }));
        }
      },
    },
    {
      noIcon: true,
      UIText: {
        "es-CL": { label: "No respaldar Onedrive" },
        "en-US": { label: "Don't backup mail" },
      },
      onClick: () => {
        for (const user of selected) {
          dispatch(UIactions.remove({ id: bkpOneArrayId, key: user }));
        }
      },
    },
  ];

  return (
    <MoreActions
      {...{
        id: "multiIntegrations",
        disabled: w1 || w2 || w3 || w4 || selected.length === 0,
        buttonsData,
        UIText: UITextMore,
        startIcon: null,
      }}
    />
  );
};

export default More;
