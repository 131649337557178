import { Dialog } from "@mui/material";
import BasicDialogTitle from "../../../../../../UI/BasicDialog/BasicDialogTitle";
import Cancel from "./Components/Cancel";
import BasicDialogActions from "../../../../../../UI/BasicDialog/BasicDialogActions";
import Confirm from "./Components/Confirm";
import { useEffect } from "react";
import { w365fetchUI } from "../GroupLock";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { updateM365OrgId } from "../../../Actions/changeM365Sharing";
import { UIactions } from "../../../../../../../store/slices/UI";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import { workerActions } from "../../../../../../../store/slices/workers";

const M365GroupLockToggle = () => {
  const { dispatch, settings, UI } = useUIBp();
  const { done, error } = useWorker(updateM365OrgId);
  const active = UI[w365fetchUI];
  const locale = settings.locale;
  const { payload: p } = UI.modal2;
  const { payload } = p;

  const UITextActivate = {
    "es-CL": {
      header: `Obtener las credenciales de Microsoft 365 compartidas por el grupo padre?`,
    },
    "en-US": {
      header: `Fetch Microsoft 365 credentials shared by the parent group?`,
    },
  };
  const UITextDeactivate = {
    "es-CL": {
      header: `Dejar de obtener las credenciales de Microsoft 365 del grupo padre?`,
    },
    "en-US": {
      header: `Stop fetching Microsoft 365 credentials from the parent group?`,
    },
  };
  const UIText = active ? UITextDeactivate : UITextActivate;
  const { header } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal2());
      dispatch(workerActions.clear(updateM365OrgId));
    }
  }, [dispatch, done, error]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal2());
    }
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogActions>
        <Cancel />
        <Confirm {...{ payload, locale }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default M365GroupLockToggle;
