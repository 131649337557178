import { Grid2, Typography } from "@mui/material";
import { Fragment } from "react";

const UIText = {
  "es-CL": { label: "Estado del dispositivo" },
  "en-US": { label: "Device status" },
};

const Status = ({ service_status, locale }) => {
  const { label } = UIText[locale];

  return (
    <Fragment>
      <Grid2 container size={6}>
        <Typography>{label}</Typography>
      </Grid2>
      <Grid2 container size={6}>
        <Typography>{service_status ? "Suspendido" : "Activo"}</Typography>
      </Grid2>
    </Fragment>
  );
};

export default Status;
