import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import TextField from "../../../../UI/GeneralPurpose/SimpleTextField";

const UIText = {
  "universal":{},
}


const DownloadLink = ({ id, fontSize }) => {
  const { auth } = useUIBp();

  let baseURL = "";
  if (typeof window !== "undefined") {
    baseURL = window.location.origin;
  }
  const downloadLink =
    baseURL + "/shared?id=" + id + "&user=" + encodeURIComponent(auth.user);

  return (
    <TextField
      fullWidth
      disabled
      {...{
        UIText,
        value: downloadLink,
        fontSize,
        size: 12,
      }}
    />
  );
};

export default DownloadLink;
