import notEmptyPassword from "../../../../../../utility/validations/notEmptyPassword";
import PassField from "../../../../../UI/GeneralPurpose/PassField";
import { pass1UI } from "./Password1";

const UIText = {
  "es-CL": {
    label: "Repetir contraseña",
    placeholder: "*********",
    tooltip:
      "Campo de contraseña. Debe tener al menos 10 caracteres que incluyan mayusculas, minusculas y numeros",
  },
  "en-US": {
    label: "Repeat password",
    placeholder: "**********",
    tooltip:
      "Password field. It must have at least characters including uppercase, lowercase and numbers",
  },
};

export const pass2UI = "password2";

const Password2 = (props) => {
  const { isNew } = props;

  return (
      <PassField
        fullWidth
        tagError
        {...{
          id: pass2UI,
          type: "password",
          UIText,
          twinId: pass1UI,
          twinValidation: notEmptyPassword,
          required: isNew,
          size: 12,
        }}
      />
  );
};

export default Password2;
