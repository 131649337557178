import { Dialog } from "@mui/material";
import BasicDialogTitle from "../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../UI/BasicDialog/BasicDialogActions";
import useUIBp from "../../Hooks/useUIBoilerplate";
import Buttons from "./Components/Buttons";
import Listing from "./Components/Listing";
import { useEffect } from "react";
import fetchActivities from "./Actions/fetchActivities";
import Header, { activitiesSelectID } from "./Components/Header/Header";

const Activities = () => {
  const { auth, dispatch, settings, UI } = useUIBp();
  const { server, cloner_key } = auth;
  const locale = settings.locale;
  const activitiesRefreshRate = UI[activitiesSelectID]

  console.log("Activities.js: activitiesRefreshRate", activitiesRefreshRate);

  // Fetch activities on mount
  useEffect(() => {
    dispatch(fetchActivities({ server, cloner_key }));
  }, [dispatch, server, cloner_key]);

  // Fetch activities every activitiesRefreshRate seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchActivities({ server, cloner_key }));
    }, activitiesRefreshRate*1000); // 10000 milliseconds = 10 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [dispatch, activitiesRefreshRate, server, cloner_key]);

  const handleClose = () => {};

  return (
    <Dialog
      fullWidth
      {...{
        open: true,
        onClose: handleClose,
        maxWidth: "lg",
        PaperProps:{
          sx: {
            margin: 0,
            position: "absolute",
            top: 40, // Adjust the top position as needed
            left: "50%",
            transform: "translateX(-50%)",
          },
        }
      }}
    >
      <BasicDialogTitle {...{ header: <Header />, locale }} />
      <BasicDialogContent>
        <Listing />
      </BasicDialogContent>
      <BasicDialogActions>
        <Buttons />
      </BasicDialogActions>
    </Dialog>
  );
};

export default Activities;
