import { Typography } from "@mui/material";
import { timeDiff } from "../../../../../../../../../utility/misc/humanizers";

const UIText = {
  "es-CL": { text: "Nunca completado" },
  "en-US": { text: "Never completed" },
};

const Backup = ({ fontSize, lastBkp, settings }) => {
  const { text } = UIText[settings.locale];
  let txtBkp = "";
  if (lastBkp < 0) {
    txtBkp = text;
  } else {
    txtBkp = timeDiff(Date.now() - lastBkp);
  }
  return (
    <Typography {...{ fontSize, sx: { cursor: "default" } }}>
      {txtBkp}
    </Typography>
  );
};

export default Backup;
