import React, { useEffect, useRef } from "react";
import { Grid2, TextField } from "@mui/material";
import PersistSession from "./PersistSession";
import { debounce } from "lodash";
import notEmptyUser from "../../../../utility/validations/notEmptyUser";
import { UIactions } from "../../../../store/slices/UI";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { loginWrkID } from "../../Actions/loginToCloner";
import SlimAlert from "../../../UI/GeneralPurpose/SlimAlert";
import { useWorker } from "../../../../Hooks/useWorker";

const UIText = {
  "es-CL": {
    label: "Usuario",
    placeholder: "usuario@cloner.cl",
  },
  "en-US": {
    label: "User",
    placeholder: "user@cloner.cl",
  },
};

export const loginUserID = "user";

const User = (props) => {
  const { hardValidation, login, disabled: dsbl } = props;
  const { dispatch, UI, settings } = useUIBp();
  const locale = settings.locale ?? "es-CL";
  const { working } = useWorker(loginWrkID);
  const ref = useRef(null);
  const userField = UI[loginUserID] ?? {};
  const { value = "" } = userField;

  useEffect(() => {
    ref.current.value = value;
  }, [value]);

  const { label, placeholder } = UIText[locale];

  const handleValidation = () => {
    const payload = { id: loginUserID, fn: notEmptyUser, locale };
    dispatch(UIactions.validateField(payload));
  };

  const debouncedChange = debounce((e) => {
    const value = e.target.value;
    dispatch(UIactions.setField({ id: loginUserID, value }));
  }, 200);

  const handleChange = (e) => {
    debouncedChange(e);
  };

  const debouncedHardValidation = debounce((e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      hardValidation();
    }
  }, 200);

  const handleHardValidation = (e) => {
    debouncedHardValidation(e);
  };

  const debouncedLogin = debounce((e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      login();
    }
  }, 200);
  const handleLogin = (e) => {
    debouncedLogin(e);
  };

  const disabled = working || dsbl;

  return (
    <Grid2 container {...{size:12, justifyContent:"center"}}>
      <Grid2 container {...{size: 12, justifyContent:"center"}}>
        <Grid2 container size={{ lg: 3, md: 4, sm: 6, xs: 8 }}>
          <TextField
            fullWidth
            required
            autoFocus
            {...{
              label,
              placeholder,
              inputRef: ref,
              error: !!userField.error,
              onKeyDown: handleHardValidation,
              onKeyUp: handleLogin,
              onBlur: handleValidation,
              onChange: handleChange,
              disabled,
              size: "small",
              InputProps: {
                endAdornment: <PersistSession {...{ disabled }} />,
                sx: {
                  paddingRight: 0.3,
                  borderRadius: !!userField.error
                    ? "4px 4px 0px 0px"
                    : undefined,
                },
              },
            }}
          />
        </Grid2>
      </Grid2>
      <Grid2 container {...{ justifyContent: "center", size: 12  }}>
        <Grid2 container {...{ size: { lg: 3, md: 4, sm: 6, xs: 8 } }}>
          <SlimAlert messages={[userField.error]} size={ 12 } fuseTop />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default User;
