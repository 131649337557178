import Username from "./Components/Username";
import Actions from "./Components/Actions";
import Device from "./Components/Device";
import Size from "./Components/Size";
import Repositories from "./Components/Repositories";
import BasicRow from "../../../../../UI/BasicLayout/Components/BasicRow";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };
const fontSize = 13;

const DeviceRow = (props) => {
  const {id } = props;

  const data = {
    id,
    elements: [
      { content: <Username {...props} {...{ fontSize }} />, size: 3 },
      { content: <Device {...props} {...{ fontSize }} />, size: 3 },
      { content: <Repositories {...props} {...{ fontSize }} />, size: 3 },
      { content: <Size {...props} {...{ fontSize }} />, size: 1.5 },
      { content: <Actions {...props} />, justify: "end", size: "grow" },
    ],
  };

  return <BasicRow {...data} />;
};

export default DeviceRow;
