import { Dialog, Grid2 } from "@mui/material";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import Close from "./Components/Close";
import Continue from "./Components/Continue";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";
import SlimAlert from "../../../UI/GeneralPurpose/SlimAlert";

const NewDevicesRecoveryRequest = () => {
  const { dispatch, settings, UI } = useUIBp();
  const { payload } = UI.modal ?? {};
  const { devices = [] } = payload ?? {};
  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  const UIText = {
    "es-CL": {
      header: `Iniciar una nueva solicitud de recuperacion con el equipo "${devices[0].name}?"`,
      header2: `Iniciar una nueva solicitud de recuperacion con los ${devices.length} equipos seleccionados?`,
      alert:
        "Actualmente existe una solicitud de recuperación que no ha sido enviada. Si continua se iniciará una nueva solicitud de recuperación y la solicitud actual se perderá.",
    },
    "en-US": {
      header: `Start a new recovery request with the device "${devices[0].name}"?`,
      header2: `Start a new recovery request with the ${devices.length} selected devices?`,
      alert:
        "Currently exists a recovery request that hasn't been sent. If you continue a new recovery request will be started and the current request will be lost.",
    },
  };

  const { header, header2, alert } = UIText[settings.locale];

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle
        {...{ header: devices.length > 1 ? header2 : header }}
      />
      <BasicDialogContent>
        <Grid2
          container
          {...{ rowGap: 2, paddingTop: 2, sx: { width: "100%" } }}
        >
          <SlimAlert
            notCollapse
            {...{ size: 12 }}
            {...{ errors: [alert], severity: "warning" }}
          />
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        <Continue {...{ devices }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default NewDevicesRecoveryRequest;
