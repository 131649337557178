import { Grid2 } from "@mui/material";
import { useDispatch } from "react-redux";
import { permissionsUI } from "./PermissionsSelect";
import { UIactions } from "../../../../../../store/slices/UI";
import Switch from "../../../../../UI/GeneralPurpose/Switch";

const UIText = {
  "es-CL": { label: "Cambiar permisos" },
  "en-US": { label: "Change permissions" },
};

export const changePermissionsUI = "changePermissions";

const ChangePermissions = () => {
  const dispatch = useDispatch();

  const onChange = () => {
    dispatch(UIactions.clear(permissionsUI));
  };

  return (
    <Grid2 container {...{ size: 12, alignItems: "center", columnGap: 2 }}>
      <Switch
        labelRight
        {...{
          id: changePermissionsUI,
          UIText,
          onChange,
          labelProps: {
            fontSize: "1.1rem",
            fontWeight: "bold",
          },
          size: "auto",
        }}
      />
    </Grid2>
  );
};

export default ChangePermissions;
