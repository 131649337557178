import { Grid2 } from "@mui/material";
import {
  disableTFA,
  disableTFAId,
} from "../../../store/actions/TFA/disableTFA";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../Hooks/useWorker";
import { UIactions } from "../../../store/slices/UI";
import Switch from "../../UI/GeneralPurpose/Switch";

const UIText = {
  "es-CL": { label: "Autentificación de 2 factores" },
  "en-US": { label: "Two factor authentication" },
};

export const tfaSwitch = "tfaSwitch";

const TFA = () => {
  const { auth, dispatch, settings } = useUIBp();
  const { working } = useWorker(disableTFAId);
  const locale = settings.locale;

  const onChange = (value) => {
    if (value) {
      dispatch(UIactions.setValue({ id: tfaSwitch, value: true }));
      dispatch(UIactions.openModal({ type: "enable-tfa", payload: {} }));
    } else {
      dispatch(disableTFA({ ...auth, locale }));
    }
  };

  return (
    <Grid2
      container
      {...{
        size: 12,
        alignItems: "center",
        columnGap: 2,
        sx: { height: "5vh" },
      }}
    >
      <Switch
        disableDefault
        labelRight
        clearOnUnmount
        {...{ id: tfaSwitch, working, UIText, onChange, size: 12 }}
      />
    </Grid2>
  );
};

export default TFA;
