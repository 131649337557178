import { Grid2, Step, StepLabel, Stepper } from "@mui/material";
import { RecoverySteps } from "../../Components/Steps";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import useVH from "../../../../../Hooks/useVH";

const StepsBar = ({ idx }) => {
  const { settings } = useUIBp();
  const vh2 = useVH(12);
  const steps = RecoverySteps[settings.locale];

  return (
    <Grid2
      container
      {...{
        alignItems: "center",
        sx: { height: vh2 },
        size: 12,
      }}
    >
      <Stepper activeStep={idx} alternativeLabel sx={{ width: "100%" }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid2>
  );
};

export default StepsBar;
