import { Typography } from "@mui/material";
import { humanBandwidth } from "../../../../../utility/misc/humanizers";

const Bandwidth = ({ fontSize, mid, bandwidth }) => {
  return (
    <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
      {mid ? humanBandwidth(bandwidth) : "--"}
    </Typography>
  );
};

export default Bandwidth;
