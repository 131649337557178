import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { tfaSwitch } from "../../../components/Settings/Components/TFA";
import { workerActions } from "../../slices/workers";
import { authActions } from "../../slices/authentication";
import { UIactions } from "../../slices/UI";

const UIText = {
  "es-CL": {
    success: "Autentificación de dos factores desactivada exitosamente",
    error: "Error al intentar desactivar la autentificación: ",
  },
  "en-US": {
    success: "Two factor authentication successfully activated",
    error: "Error while trying to deactivate authentication: ",
  },
};

export const disableTFAId = "disableTFA";

export const disableTFA = ({
  server,
  cloner_key,
  user: login,
  name,
  locale,
}) => {
  const { success, error } = UIText[locale];
  const id = disableTFAId;

  return async (dispatch) => {
    const payload = { name, email: "", option: "0", login, server };
    const bodyData = JSON.stringify(payload);
    dispatch(workerActions.start(id));
    try {
      const url = server + "api/users";
      await axios.post(url, bodyData, {
        headers: { "Content-Type": "application/json", cloner_key },
      });
      enqueueSnackbar(success, { variant: "success" });
      dispatch(authActions.setTFA(0));
      dispatch(workerActions.finish({ id }));
      dispatch(UIactions.setValue({ id: tfaSwitch, value: false }));
    } catch (e) {
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};
