import { Collapse, Grid2 } from "@mui/material";

const CollapseGrid = ({
  open,
  outerProps,
  innerProps,
  children,
  unmountOnExit,
}) => {
  return (
    <Grid2 container {...outerProps} >
      <Collapse
        {...{ in: open, sx: { width: "100%" }, unmountOnExit: unmountOnExit }}
      >
        <Grid2 container {...innerProps}>
          {children}
        </Grid2>
      </Collapse>
    </Grid2>
  );
};

export default CollapseGrid;
