import { useDispatch } from "react-redux";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Cerrar" },
  "en-US": { label: "Close" },
};

const Close = ({ working }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.closeModal());
  };
  return (
    <Button
      {...{
        disabled: working,
        UIText,
        onClick,
        labelSx: { fontSize: 13, fontWeight: "bold" },
      }}
    />
  );
};

export default Close;
