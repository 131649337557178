import { ExitToApp } from "@mui/icons-material";
import Button from "../../../../UI/GeneralPurpose/Button";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": { label: "Regresar" },
  "en-US": { label: "Return" },
};

const Return = () => {
  const { auth } = useUIBp();

  return (
    <Button
      {...{
        UIText,
        navTo: `/dashboard/reports?group=${auth.group}`,
        startIcon: <ExitToApp />,
      }}
    />
  );
};

export default Return;
