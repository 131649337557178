import { Divider, Grid2 } from "@mui/material";
import DeleteBtn from "./Delete";
import AddDevice from "./AddDevice";
import EditBtn from "./Edit";
import Export from "./Export";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { viewmodeUI } from "../../../../../../App";

export const plusActionsGrid = {
  xl: 5,
  lg: 6,
  md: 12,
  sm: 12,
  xs: 12,
};

const PlusActions = () => {
  const { auth, UI } = useUIBp();
  const viewmode = UI[viewmodeUI] ?? "desktop";
  const { group, admin } = auth;

  return (
    <Grid2
      container
      {...{
        columnGap: 2,
        justifyContent: viewmode === "desktop" ? "end" : "space-between",
        size: { xl: 7, lg: 6, md: 12, sm: 12, xs: 12 },
      }}
    >
      {group === 1 && admin === 1 ? <Export /> : null}
      <AddDevice />
      <Divider orientation="vertical" variant="middle" flexItem />
      <EditBtn />
      <DeleteBtn />
    </Grid2>
  );
};

export default PlusActions;
