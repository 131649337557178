import {
  ChevronRight,
  KeyboardDoubleArrowLeft,
  Laptop,
  MoreHoriz,
  Storage,
} from "@mui/icons-material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../Hooks/useURLQuery";
import { Grid2 } from "@mui/material";
import Button from "../../../../UI/GeneralPurpose/Button";
import { UIactions } from "../../../../../store/slices/UI";
import IconButton from "../../../../UI/GeneralPurpose/IconButton";

const UIText = {
  "es-CL": { fixedTooltip: "Volver al inicio" },
  "en-US": { fixedTooltip: "Return home" },
};
const UIText2 = {
  "es-CL": { fixedTooltip: "Expandir el rastro" },
  "en-US": { fixedTooltip: "Expand breadcrumbs" },
};
const UIText3 = {
  "es-CL": { fixedTooltip: "Colapsar el rastro" },
  "en-US": { fixedTooltip: "Collapse breadcrumbs" },
};

// export const breadcrumbsId = 'breadcrumbs'
const expandedBC = "expanded";

const Breadcrumbs = () => {
  const { dispatch, navigate, warehouse, UI } = useUIBp();
  const { user, mid, repo, file } = useURLQuery();
  const { breadcrumbs = [] } = warehouse;
  const expanded = UI[expandedBC];

  let items = [];
  let first = true;
  if (file && !!breadcrumbs.length) {
    for (const idx in breadcrumbs) {
      const onClick = () => {
        // dispatch(UIactions.clear(filesSortId));
        // dispatch(UIactions.clear(filesFilterId));
        // dispatch(UIactions.clear("versionsFilter"));
        navigate(
          `?user=${encodeURIComponent(user)}&mid=${mid}&repo=${repo}&file=${
            breadcrumbs[idx].id
          }`
        );
      };

      const isLast = breadcrumbs.length - 1 === parseInt(idx);
      const key = breadcrumbs[idx].id;
      const labelSx = { textTransform: "none" };

      let label = "";
      if (breadcrumbs[idx].name === "/") {
        label = "[Root]";
      } else {
        label = breadcrumbs[idx].name;
      }

      const UIText = {
        "es-CL": { label },
        "en-US": { label },
      };

      items.push(
        <Grid2
          container
          key={`div-${idx}`}
          {...{ size: "auto", alignItems: "center" }}
        >
          <ChevronRight color="disabled" />
        </Grid2>
      );
      if (first) {
        first = false;
        items.push(
          <Button
            key={key}
            {...{
              UIText,
              variant: "text",
              startIcon: <Storage />,
              onClick,
              labelSx,
              size: "auto",
            }}
          />
        );
      } else {
        items.push(
          <Button
            key={key}
            {...{
              UIText,
              variant: "text",
              onClick,
              labelSx,
              size: "auto",
              disabled: isLast,
            }}
          />
        );
      }
    }
  }

  const handleHomeClick = () => {
    // dispatch(UIactions.clear(filesSortId));
    // dispatch(UIactions.clear(filesFilterId));
    // dispatch(UIactions.clear("versionsFilter"));
    navigate(`?user=${encodeURIComponent(user)}&mid=${mid}`);
  };

  const toggleBC = () => {
    dispatch(UIactions.toggle(expandedBC));
  };

  let finalItems = [];
  // console.log()
  if (!expanded && items.length > 6) {
    finalItems.push(
      <Grid2
        container
        key={`collapse1`}
        {...{ size: "auto", alignItems: "center" }}
      >
        <ChevronRight color="disabled" />
      </Grid2>
    );
    finalItems.push(
      <Grid2
        container
        key={`collapse2`}
        {...{ size: "auto", alignItems: "center" }}
      >
        <IconButton
          {...{
            onClick: toggleBC,
            UIText: UIText3,
            icon: <MoreHoriz sx={{ fontSize: 18 }} />,
            sx: { borderRadius: "5px" },
          }}
        />
      </Grid2>
    );
    finalItems = [finalItems, ...items.slice(items.length - 6)];
    // console.log('sliced',items.slice(4))
  } else {
    finalItems = items;
    if (items.length > 6) {
      finalItems.push(
        <Grid2
          container
          key={`collapse2`}
          {...{ size: "auto", alignItems: "center" }}
        >
          <IconButton
            {...{
              onClick: toggleBC,
              UIText: UIText2,
              icon: <KeyboardDoubleArrowLeft sx={{ fontSize: 18 }} />,
            }}
          />
        </Grid2>
      );
    }
  }

  return (
    <Grid2
      container
      {...{
        size: "grow",
        alignContent: "center",
        sx: {
          overflowY: "hidden",
          overflowX: "auto",
          borderRight: expanded ? "1px solid lightgrey" : undefined,
        },
        wrap: "nowrap",
      }}
    >
      <Grid2 {...{ size: "auto" }}>
        <IconButton
          {...{
            UIText,
            icon: <Laptop />,
            color: "primary",
            onClick: handleHomeClick,
            disabled: !file,
            sx: { borderRadius: "5px" },
          }}
        />
      </Grid2>
      {finalItems}
    </Grid2>
  );
};

export default Breadcrumbs;
