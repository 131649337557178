import Actions, { plusActionsGrid } from "./PlusActions/PlusActions";
import headersData from "./Headers";
import Header from "./Header/Header";
import { useMemo } from "react";
import Node from "./TreeNode/Node";
import useTrees from "../../../../Hooks/useTrees";
import useURLQuery from "../../../../Hooks/useURLQuery";
import { useWorker } from "../../../../Hooks/useWorker";
import { getUsersId } from "../../../../store/actions/users/getAllUsers";
import { getDevicesId } from "../../../../store/actions/devices/getDevices";
import { getRepoId } from "../../../../store/actions/repositories/getRepositories";
import FilesLayout from "../../../UI/BasicLayout/FilesLayout";
import { UIactions } from "../../../../store/slices/UI";
import { viewmodeUI } from "../../../../App";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { resetScrollId } from "../../../UI/BasicLayout/Components/Core/Core";
import { sortObjByKey } from "../../../../utility/misc/sortObjByKey";
import FilesRow from "./FilesRow/FilesRow";

const Core = ({ files, allFiles }) => {
  const { dispatch, UI } = useUIBp();
  const trees = useTrees();
  const { file } = useURLQuery();
  const viewmode = UI[viewmodeUI] ?? "desktop"; 

  let workerId = "";
  if (file) {
    workerId = "file-" + file;
  } else {
    workerId = getRepoId;
  }
  const { done: d1, working: w1, error: e1 } = useWorker(getUsersId);
  const { done: d2, working: w2, error: e2 } = useWorker(getDevicesId);
  const { done: d3, working: w3, error: e3 } = useWorker(getRepoId);
  const { done, working, error } = useWorker(workerId);

  const filesStatus = {
    usersStatus: { done: d1, working: w1, error: e1, worker: getUsersId },
    devicesStatus: { done: d2, working: w2, error: e2, worker: getDevicesId },
    repositoriesStatus: { done: d3, working: w3, error: e3, worker: getRepoId },
  };

  const header = <Header />;
  const plusActions = <Actions {...{allFiles}}/>;

  let fixedWidth 

  if (viewmode === "compact"){
    fixedWidth = "90vw"
  } else if (viewmode === "verycompact"){
    fixedWidth = "110vw"
  } else if (viewmode === "mobile") {
    fixedWidth = "160vw"
  }

  // console.log("all files",allFiles)
  // console.log("files", files)
  let elements = useMemo(() => {
    let directories = [];
    let Files = [];
    // console.log("files?", files);
    for (const item of files) {
      if (item.type === 0) {
        directories.push(<FilesRow key={item.id} {...item} />);
      } else {
        Files.push(<FilesRow key={item.id} {...item} />);
      }
    }
    return [...directories, ...Files];
  }, [files]);
  // console.log("elements", elements);

  let treeRoots = useMemo(() => {
    let items = [];
    for (const key in trees) {
      if (key.match("repo-")) {
        let newNode = { ...trees[key], name: trees[key].data.name };
        items.push(newNode);
      }
    }

    const roots = items
    .sort((a, b) => sortObjByKey(a, b, "name"))
    .map((tree, key) => (
      <Node
      key={key}
        {...tree.nodes[0]}
        {...{ level: 0, repository: tree.data.id }}
      />
    ));
    return roots;
  }, [trees]);


  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: {
        "es-CL": { message: "Error al obtener archivos" },
        "en-US": { message: "Error fetching files" },
      },
    },
    empty: {
      isEmpty: !files.length,
      UIText: {
        "es-CL": { message: "No hay archivos o directorios disponibles" },
        "en-US": { message: "There are no available files or directories" },
      },
    },
    done,
    elements: elements,
  };

  return (
    <FilesLayout
      {...{
        plusActions,
        plusActionsGrid,
        header,
        headersData,
        coreData,
        treeRoots,
        filesStatus,
        overflowX: viewmode === "desktop" ? "hidden" : "auto",
        fixedWidth,
        onPaginationChange: () => {
          dispatch(UIactions.setValue({id:resetScrollId, value:true}));
        },
      }}
    />
  );
};

export default Core;
