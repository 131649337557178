import { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import {
  QRData,
  getQRCode,
  getQRCodeId,
} from "../../../../store/actions/TFA/getGoogleQRCode";
import QRScan from "./Components/QRScan";
import Activate from "./Components/Activate";
import Cancel from "./Components/Cancel";
import notEmptyField from "../../../../utility/validations/notEmpty";
import { enableTFAId } from "../../../../store/actions/TFA/enableTFA";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../Hooks/useWorker";
import { UIactions } from "../../../../store/slices/UI";
import { warehouseActions } from "../../../../store/slices/warehouse";
import { workerActions } from "../../../../store/slices/workers";
import TextField from "../../../UI/GeneralPurpose/SimpleTextField";

const UIText1 = {
  "es-CL": {
    header: "Autentificación de 2 factores",
    text: "Escanee el codigo QR con google authenticator e introduzca el código para activar la autentificación de dos factores:",
  },
  "en-US": {
    header: "Two factor authentication",
    text: "Scan the QR code with Google Authenticator and type the code to activate two factor authentication",
  },
};

const UIText = {
  "es-CL": { label: "Ingresar codigo", placeholder: "123456" },
  "en-US": { label: "Enter code", placeholder: "123456" },
};

export const gauthCodeUI = "gauthCode";

const TwoFactorAuthentication = () => {
  const { auth, dispatch, settings } = useUIBp();
  const { done: d2 } = useWorker(getQRCodeId);
  const { done, error } = useWorker(enableTFAId);
  const { header, text } = UIText1[settings.locale];

  useEffect(() => {
    if (!d2) {
      dispatch(getQRCode({ ...auth }));
    }
  }, [dispatch, auth, d2]);

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
    }
  }, [dispatch, done, error]);

  useEffect(() => {
    return () => {
      dispatch(warehouseActions.unload(QRData));
      dispatch(workerActions.clear(getQRCodeId));
      dispatch(workerActions.clear(enableTFAId));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "xs" }}>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>
        <Grid2 container sx={{ padding: 1, rowGap: 2 }}>
          <QRScan />
          <Grid2 size={12}>
            <Typography align="justify">{text}</Typography>
          </Grid2>
          <TextField
            tagError
            clearOnUnmount
            {...{ id: gauthCodeUI, UIText, validation: notEmptyField, xs: 12 }}
          />
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Cancel />
        <Activate />
      </DialogActions>
    </Dialog>
  );
};

export default TwoFactorAuthentication;
