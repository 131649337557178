import { useEffect } from "react";
import { suggestMailId } from "../../../../../../store/actions/microsoft365/suggestM365Mails";
import { validateMailId } from "../../../../../../store/actions/microsoft365/validateM365Mails";
import { m365clientData } from "../../../../../../store/actions/microsoft365/getClient";
import {
  bkpDriveSwitchId,
  bkpMailSwitchId,
  m365MailId,
} from "../M365/M365Userform";
import { m365orgData } from "../../../../../../store/actions/microsoft365/getOrganization";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../Hooks/useWorker";
import postClient, {
  postClientId,
} from "../../../../../../store/actions/microsoft365/postClient";
import { UIactions } from "../../../../../../store/slices/UI";
import { m365Switch } from "../M365/M365";
import Button from "../../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

export const m365validated = "m365validated";

const Save = (props) => {
  const { users } = props;
  const { auth, dispatch, UI, settings, warehouse } = useUIBp();
  const { M365server, cloner_key } = auth;
  const { done, working, error: e1 } = useWorker(postClientId);
  const { working: w2 } = useWorker(suggestMailId);
  const { working: w3 } = useWorker(validateMailId);
  const validated = UI[m365validated] ?? false;
  const clientData = warehouse[m365clientData] ?? {};
  const bkpMail = UI[bkpMailSwitchId] ?? false;
  const bkpDrive = UI[bkpDriveSwitchId] ?? false;
  const isNew = !clientData.client_mail;
  const active = UI[m365Switch] ?? false;
  const w365org = warehouse[m365orgData] ?? {};
  const mailField = UI[m365MailId + "-" + users[0].login] ?? {};
  const mail = mailField.value;

  const own = w365org.own ?? { mail_account: "" };
  const parent = w365org.parent ?? { mail_account: "" };
  const availableCert = own.client_id ? own : parent;
  const domain = availableCert.mail_account
    ? availableCert.mail_account.split("@")[1]
    : null;

  const locale = settings.locale;

  const onClick = () => {
    if (!isNew && !active) {
      dispatch(
        UIactions.openModal2({
          type: "delete-m365-client-confirmation",
          payload: {
            user: clientData.cloner_user,
          },
        })
      );
    } else if (!isNew) {
      const services = [
        { id: "1", enabled: bkpDrive },
        { id: "2", enabled: bkpMail },
      ];
      const payload = {
        organization_id: clientData.organization_id,
        client_mail: clientData.client_mail,
        cloner_user: users[0].login,
        services,
      };
      dispatch(
        postClient({
          M365server,
          cloner_key,
          payload,
          locale,
          reload: false,
        })
      );
    } else if (isNew) {
      const payload = {
        organization_id: availableCert.id,
        client_mail: mail + "@" + domain,
        cloner_user: users[0].login,
        services: [
          { id: "1", enabled: bkpDrive, },
          { id: "2", enabled: bkpMail, },
        ],
      };
      dispatch(
        postClient({
          M365server,
          cloner_key,
          payload,
          locale,
        })
      );
    }
  };

  useEffect(() => {
    if (done && !e1) {
      dispatch(UIactions.closeModal());
    }
  }, [dispatch, done, e1]);

  const error = false;

  return (
    <Button
      {...{
        disabled:
          !!error ||
          (isNew && !validated) ||
          w2 ||
          w3 ||
          (isNew && !bkpMail && !bkpDrive),
        UIText,
        onClick,
        working,
      }}
    />
  );
};

export default Save;
