import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { worker } from "../../../store/slices/workers";

const UIText = {
  "es-CL": {
    mailError: "Envío del correo de recuperación falló: ",
    success: "Correo de recuperación enviado",
  },
  "en-US": {
    mailError: "Recovery mail sending failed: ",
    success: "Recovery mail sent",
  },
};

export const passRecoveryId = "passRecovery";

const resetPassword = (data) => {
  console.log("Asking for password reset!");
  return async (dispatch) => {
    const { mailError, success } = UIText[data.locale];
    const bodyData = JSON.stringify(data);
    dispatch(worker.start(passRecoveryId));
    try {
      // GET login server
      const url = "https://login.cloner.cl/api/server";
      const response = await axios.get(url, { params: data });

      // POST reset password
      const loginServer = response.data;
      const url2 = loginServer + "api/recovery_token";
      await axios.post(url2, bodyData);
      dispatch(worker.finish({ id: passRecoveryId }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      dispatch(worker.finish({ id: passRecoveryId, error: e.message }));
      enqueueSnackbar(mailError + e.message, { variant: "error" });
    }
  };
};

export default resetPassword;
