import { Mail } from "@mui/icons-material";
import { Grid2 } from "@mui/material";
import { adminNameUI, adminUserUI, organizationUI } from "./DeliverySumary";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../Hooks/useWorker";
import sendRecoveryRequest, {
  submitRecoveryId,
} from "../../../../../store/actions/recoveries/sendRecoveryRequest";
import useRecoveries from "../../../../../Hooks/useRecoveries";
import Button from "../../../../UI/GeneralPurpose/Button";
import { groupsTreeId, selectedGroupIdx2 } from "../../../../GroupsTraveler/Actions/loadGroupsTree";
import useTrees from "../../../../../Hooks/useTrees";

const UIText = {
  "es-CL": { label: "Enviar solicitud" },
  "en-US": { label: "Send request" },
};

const Send = () => {
  const { auth, dispatch, UI } = useUIBp();
  const { working } = useWorker(submitRecoveryId);
  const adminNameField = UI[adminNameUI] ?? {};
  const adminUserField = UI[adminUserUI] ?? {};
  const organizationField = UI[organizationUI] ?? {};
  const requestData = useRecoveries() ?? {};
  let groupID = UI[selectedGroupIdx2]
  const tree = useTrees()[groupsTreeId];
  const groupObj = typeof groupID === "number" && tree ? tree[groupID] : null;
  const groupname = groupObj ? groupObj.name : "Loading...";

  groupID = groupID === 0 ? auth.group : groupID;

  const handleClick = () => {
    const senderData = {
      adminName: adminNameField.value ?? auth.name,
      adminUser: adminUserField.value ?? auth.user,
      organization: organizationField.value ?? auth.groupName,
    };
    dispatch(
      sendRecoveryRequest({
        ...auth,
        requestData,
        senderData,
        organization:{
          id: groupID,
          name: groupname,
        }
      })
    );
  };

  return (
    <Grid2 container {...{ size: 4, justifyContent: "end" }}>
      <Button
        {...{
          working,
          UIText,
          onClick: handleClick,
          startIcon: <Mail />,
        }}
      />
    </Grid2>
  );
};

export default Send;
