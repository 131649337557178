import { useDispatch } from "react-redux";
import Button from "../../../../UI/GeneralPurpose/Button";
import { UIactions } from "../../../../../store/slices/UI";

const UIText = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};

const Cancel = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    console.log("?????")
    dispatch(UIactions.closeModal2());
  };
  return (
    <Button
      {...{
        UIText,
        onClick,
        variant: "text",
        labelSx: { fontSize: 13, fontWeight: "bold" },
      }}
    />
  );
};

export default Cancel;
