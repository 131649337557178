import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { bkpMailCountId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { bkpDriveSizeId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { m365validated } from "../../../components/Users/Dialogs/UserCloudIntegrations/Components/Buttons/Save";
import { workerActions } from "../../slices/workers";
import { UIactions } from "../../slices/UI";

export const suggestMailId = "windows365suggestions";

const UIError = {
  "es-CL": {
    error: "Error al sugerir correo",
    notFound: "No se encontró una sugerencia para el correo",
  },
  "en-US": {
    error: "Error suggesting mail",
    notFound: "No suggestion found for the mail",
  },
};

const suggestM365Mails = ({ M365server, cloner_key, tryouts, locale }) => {
  const id = suggestMailId;

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    for (let attempt of tryouts) {
      const newMail = attempt.login
      const url = `${M365server}mailapi/organization/${attempt.org.id}/client/${newMail}`;
      try {
        const response = await axios.get(url, { headers: { cloner_key } });

        let url2 = `${M365server}mailapi/organization/${attempt.org.id}/client/${newMail}/space`;
        const response2 = await axios.get(url2, { headers: { cloner_key } });
        const data = response2.data.data;

        dispatch(
          UIactions.setValue({
            id: bkpMailCountId + attempt.login,
            value: data.count_mails,
          })
        );
        dispatch(
          UIactions.setValue({
            id: bkpDriveSizeId + attempt.login,
            value: data.drive,
          })
        );

        dispatch(
          UIactions.setField({
            id: "365mail-" + attempt.login,
            value: response.data.data.mail.toLowerCase(),
          })
        );
        dispatch(
          UIactions.setValue({
            id: m365validated,
            value: true,
          })
        );
      } catch (e) {
        console.log("error", e);
        dispatch(UIactions.clear(bkpMailCountId + attempt.login));
        dispatch(UIactions.clear(bkpDriveSizeId + attempt.login));
        if (e.response.status === 404) {
          enqueueSnackbar(UIError[locale].notFound + ": " + newMail, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(UIError[locale].error + ": " + newMail, {
            variant: "error",
          });
        }
        // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
        // dispatch(worker.finish({ id, error: e.message }));
      }
    }

    dispatch(workerActions.finish({ id }));
  };
};

export default suggestM365Mails;