import { Download } from "@mui/icons-material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import useRecoveries from "../../../../../../Hooks/useRecoveries";
import exportRecovery from "../../../../../../store/actions/recoveries/exportRecovery";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";

const UIText = {
  "es-CL": { label: "Exportar" },
  "en-US": { label: "Export" },
};

const Export = () => {
  const { dispatch, UI, settings } = useUIBp();
  const selected = UI.selected ?? [];
  const recoveries = useRecoveries().recoveries ?? [];

  const onClick = () => {
    let devices = [];

    for (const item of selected) {
      let recovery = recoveries.find((x) => x.id === item);

      for (const repo of recovery.repositories) {
        let newDevice = {
          user: recovery.user,
          device: recovery.device,
          drive: repo.name,
          size: repo.size,
          root: repo.id,
          metafile: repo.metafile,
        };
        devices.push(newDevice);
      }
    }
    dispatch(exportRecovery({ devices, locale: settings.locale }));
  };

  return (
    <ActionButton
      {...{
        onClick,
        UIText,
        icon: <Download />,
        variant: "outlined",
      }}
    />
  );
};

export default Export;
