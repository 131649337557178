import { rowErrorsUI } from "./UsersList/UserRow/UserRow";
import { bkpMailArrayId } from "./UsersList/UserRow/BackupMail";
import { bkpOneArrayId } from "./UsersList/UserRow/BackupOnedrive";
import { m365orgsData} from "../../../../../store/actions/microsoft365/getOrganizations";
import { m365clientsData } from "../../../../../store/actions/microsoft365/getClients";
import { enqueueSnackbar } from "notistack";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { postClientsId } from "../../../../../store/actions/microsoft365/postClients";
import { platformServices } from "../../../../../store/actions/microsoft365/getPlatformsAndServices";
import { useWorker } from "../../../../../Hooks/useWorker";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const UIText2 = {
  "es-CL": { noChanges: "No hay cambios que guardar" },
  "en-US": { noChanges: "There are no changes to save" },
}

const Create = ({ users }) => {
  const { auth, dispatch, UI, warehouse, settings } = useUIBp();
  const { M365server, cloner_key } = auth;
  const { locale } = settings;
  const { working } = useWorker(postClientsId);
  const { noChanges } = UIText2[locale];
  const errors = UI[rowErrorsUI] ?? [];
  let orgs = warehouse[m365orgsData] ?? {};
  let services = warehouse[platformServices] ?? [];
  const bkpMails = UI[bkpMailArrayId] ?? [];
  const bkpDrives = UI[bkpOneArrayId] ?? [];
  const clients = warehouse[m365clientsData] ?? {};

  const onClick = () => {
    let payloads = [];

    for (const user of users) {
      let m365mailfield = UI["365mail-" + user.login] ?? {};
      let org = orgs[user.group] ?? {};
      let domain = org.domain.toLowerCase() ?? "";
      let bkpMail = bkpMails.find((x) => x === user.login) ?? "";
      let bkpDrive = bkpDrives.find((x) => x === user.login) ?? "";

      let m365servicemail = services.find((x) => {
        if (x.name === "Microsoft Mail") {
          return true;
        }
        return false;
      });
      let m365servicedrive = services.find((x) => {
        if (x.name === "Microsoft Drive") {
          return true;
        }
        return false;
      });

      payloads.push({
        client_mail: m365mailfield.value + "@" + domain,
        organization_id: org.id,
        cloner_user: user.login,
        services: [
          {
            id: m365servicemail.id,
            enabled: !!bkpMail,
          },
          {
            id: m365servicedrive.id,
            enabled: !!bkpDrive,
          },
        ],
      });
    }

    let payloadsPlus = [];
    for (const payload of payloads) {
      let found = false;
      for (const client of clients) {
        if (payload.cloner_user === client.cloner_user) {
          let post = false;
          
          for (const service of payload.services) {
            for (const cService of client.services) {
              if (service.id === cService.service_id) {
                if (service.enabled !== cService.enabled) {
                  post = true;
                }
              }
            }
          }
          if (post) {
            payloadsPlus.push({
              client_mail: payload.client_mail,
              organization_id: payload.organization_id,
              cloner_user: payload.cloner_user,
              services: payload.services,
              post: true,
              found: true,
            });
          }
          found = true;
          break;
        }
      }
      if (!found) {
        if (payload.client_mail.split("@")[0] === "undefined" || !payload.client_mail.split("@")[0] ) {
          continue;
        }
        payloadsPlus.push({
          client_mail: payload.client_mail,
          organization_id: payload.organization_id,
          cloner_user: payload.cloner_user,
          services: payload.services,
          found: false,
        });
      }
    }

    if (!payloadsPlus.length) {
      enqueueSnackbar(noChanges, { variant: "warning" });
      return;
    }

    dispatch(
      UIactions.openModal2({
        type: "post-m365-clients-confirmation",
        payload: { payloads: payloadsPlus, cloner_key, M365server, locale},
      })
    );
  };

  const disabled = !!errors.length;

  return <Button {...{ disabled, UIText, onClick, working }} />;
};

export default Create;
