import { Grid2, ToggleButton as MUIToggleButton } from "@mui/material";
import AutoHelp from "../Wrappers/AutoHelp";
import { UIactions } from "../../../store/slices/UI";
import useUIBp from "../../../Hooks/useUIBoilerplate";

// const UIText = {
//     'es-CL':{
//         label:'',
//         tooltip:''
//     }
// }

const ToggleButton = (props) => {
  const {
    id,
    startIcon,
    endIcon,
    UIText = {},
    disabled,
    xs,
    value = "placeholder",
    color,
    size = "small",
    fullWidth,
    onClick,
  } = props;
  const { dispatch, UI, settings } = useUIBp();
  const locale = settings.locale;
  const selected = UI[id] ?? false;

  const { label, tooltip, fixedTooltip } = UIText[locale] ?? UIText.universal;

  const handleChange = (e, value) => {
    if (onClick) {
      onClick();
    } else {
      dispatch(UIactions.toggle(id));
    }
  };

  return (
    <Grid2 container size={{ xs }}>
      <AutoHelp {...{ disabled, tooltip, fixedTooltip }}>
        <MUIToggleButton
          {...{
            disabled,
            fullWidth: xs || fullWidth ? true : false,
            value,
            selected,
            onChange: handleChange,
            color,
            size,
          }}
        >
          {startIcon}
          {label}
          {endIcon}
        </MUIToggleButton>
      </AutoHelp>
    </Grid2>
  );
};

export default ToggleButton;
