import { Grid2, Typography } from "@mui/material";
import { options } from "./selectValues";
import { selectedCommonUI } from "../Category/Category";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../store/slices/UI";
import Button from "../../../../../UI/GeneralPurpose/Button";
import Select from "../../../../../UI/GeneralPurpose/Select";
import FilterField from "../../../../../UI/GeneralPurpose/FilterField";

const UIText = {
  "es-CL": { placeholder: "Buscar..." },
  "en-US": { placeholder: "Search..." },
};
const UIText2 = {
  "es-CL": { label: "Filtrar por categoría" },
  "en-US": { label: "Filter by category" },
};
const UIText3 = {
  "es-CL": { label: "Borrar selección" },
  "en-US": { label: "Clear selection" },
};
const UIText4 = {
  "es-CL": { header: "Extensiones comunes" },
  "en-US": { header: "Common extensions" },
};

export const categoryFilterUI = "categoryFilter";

const Filters = () => {
  const { dispatch, settings } = useUIBp();
  const { header } = UIText4[settings.locale];

  const clearAllSelections = () => {
    dispatch(UIactions.clear(selectedCommonUI));
  };

  return (
    <Grid2 container {...{ padding: 2, size: 12, rowGap: 1, columnSpacing: 2, alignItems: "center" }}>
      <Grid2 container size={{lg:"auto",md:12,sm:12,xs:12}} >
        <Typography fontSize="1.1rem">{header}</Typography>
      </Grid2>
      <FilterField {...{ UIText, size: {lg:"auto",md:3,sm:4, xs:3} }} />
      <Select
        {...{
          id: categoryFilterUI,
          UIText: UIText2,
          options,
          size: { lg:3, md:3, sm: 4, xs:3},
          fontSize: 16,
        }}
      />
      <Button
        {...{
          UIText: UIText3,
          onClick: clearAllSelections,
          size: "auto",
          variant: "text",
          labelSx: { fontSize: 14 },
        }}
      />
    </Grid2>
  );
};

export default Filters;
