import { Fragment } from "react";
import Divider from "../../UI/GeneralPurpose/Divider";
import { Grid2 } from "@mui/material";
import { useLocation } from "react-router-dom";
import { selectedGroupId } from "../Actions/loadGroupsTree";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import Button from "../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Ingresar" },
  "en-US": { label: "Enter" },
};

const Bottom = ({ close }) => {
  const { UI } = useUIBp();
  const location = useLocation();
  const group = UI[selectedGroupId];

  const navTo = `${location.pathname}?group=${group}`;
  const onClick = () => {
    close();
  };

  return (
    <Fragment>
      <Divider />
      <Grid2 container padding={2} justifyContent="end">
        <Button
          {...{
            UIText,
            onClick,
            navTo,
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
      </Grid2>
    </Fragment>
  );
};

export default Bottom;
