import { Grid2 } from "@mui/material";
import { Fragment } from "react";
import { options2 as options } from "./selectValues";
import { postDeviceId } from "../../../../../../../store/actions/devices/postDeviceInfo";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../store/slices/UI";
import Switch from "../../../../../../UI/GeneralPurpose/Switch";
import Select from "../../../../../../UI/GeneralPurpose/Select";
import HelpTooltip from "../../../../../../UI/GeneralPurpose/HelpTooltip";
import { useWorker } from "../../../../../../../Hooks/useWorker";

const UIText = {
  "es-CL": { label: "Archivos simultaneos" },
  "en-US": { label: "Simultaneous files" },
};

export const multiUploadActiveUI = "multiUpActive";
export const multiUploadUI = "multiUpload";

const Multiupload = () => {
  const { dispatch, UI } = useUIBp();
  const { working } = useWorker(postDeviceId);
  const active = UI[multiUploadActiveUI];

  const reset = (value) => {
    if (value) {
      dispatch(UIactions.setValue({ id: multiUploadUI, value: 2 }));
    } else {
      dispatch(UIactions.setValue({ id: multiUploadUI, value: 1 }));
    }
  };

  return (
    <Fragment>
      <Grid2 container {...{ size: 7, alignItems: "center", columnGap: 1 }}>
        <Switch
          clearOnUnmount
          labelRight
          {...{
            id: multiUploadActiveUI,
            UIText,
            disabled: working,
            onChange: reset,
            size: "auto",
            labelProps: { sx: { fontSize: undefined, fontWeight: undefined } },
          }}
        />
        <HelpTooltip {...{ UIText }} />
      </Grid2>
      <Grid2 container alignItems="center" size={5}>
        <Select
        clearOnUnmount
          {...{
            id: multiUploadUI,
            UIText,
            disabled: working,
            options,
            hide: !active,
          }}
        />
      </Grid2>
    </Fragment>
  );
};

export default Multiupload;
