import axios from "axios";
import { workerActions } from "../../../../../store/slices/workers";
import { treesActions } from "../../../../../store/slices/trees";

const loadRepositoryNode = ({
  id,
  root,
  idx,
  server,
  tunnelToken: tunnel_token,
  path,
}) => {
  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      console.log("fetching node!");
      const response = await axios.get(server.slice(0, -1) + ":5107/files", {
        headers: { tunnel_token },
        params: { path: encodeURIComponent(path) },
      });

      const children = response.data.children;

      for (const child of children) {
        dispatch(treesActions.addRSnode({ id: root, idx, node: child }));
      }

      dispatch(treesActions.RSnodeLoaded({ id: root, idx }));
      dispatch(workerActions.finish({ id }));
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
      return;
    }
  };
};

export default loadRepositoryNode;
