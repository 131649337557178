import { Grid2 } from "@mui/material";
import GroupButton from "./GroupButton";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";

const Group = () => {
  const { auth } = useUIBp();

  return (
    <Grid2
      container
      {...{
        size: {
          xl: 2,
          lg: 3,
          md: 4,
          sm: 12,
          xs: 12,
        },
      }}
    >
      <GroupButton init startGroup={auth.group} />
    </Grid2>
  );
};

export default Group;
