import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

export const getConfigId = "deviceConfig";
export const configDataId = "deviceConfig";

const getDeviceConfig = ({ server, cloner_key, controller, payload }) => {
  const id = getConfigId;

  return async (dispatch) => {
    console.log("Getting device config");
    dispatch(workerActions.start(id));

    try {
      const bodyData = JSON.stringify(payload);

      // Getting the device config
      const url = server + "api/user/getconf";
      const response = await axios.post(url, bodyData, {
        signal: controller.signal,
        headers: { cloner_key },
      });

      // Filling the warehouse with device config
      dispatch(
        warehouseActions.load({ id: configDataId, data: response.data })
      );
      dispatch(workerActions.finish({ id }));
    } catch (e) {
      console.error(e);
      enqueueSnackbar(e.message, { variant: "error" });
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};

export default getDeviceConfig;
