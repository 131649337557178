import { Grid2 } from "@mui/material";
import Interval from "./Fields/Interval";
import Status from "./Fields/Status";
import Version from "./Fields/Version";
import StealthMode from "./Fields/StealthMode";
import Bandwidth from "./Fields/Bandwidth";
import Upload from "./Fields/Upload";
import SelectionBlock from "./Fields/SelectionBlock";
import General from "./Fields/General";
import Load from "./Fields/Load";
import Security from "./Fields/Security";
import Divider from "../../../../UI/GeneralPurpose/Divider";

const Details = (props) => {
  const {
    scan_interval,
    service_status,
    version,
    stealth_mode,
    throttling,
    network_packet_size,
    cpassword,
    multiple = 1,
    locale,
  } = props;

  return (
    <Grid2 container {...{ sx: { width: "100%" } }}>
      <Grid2 container {...{ rowGap: 2, alignItems: "center" }}>
        <General {...{ locale }} />
        <Grid2 container {...{ size: 12, sx: { paddingLeft: 2 } }}>
          <Interval {...{ scan_interval, locale }} />
        </Grid2>
        <Grid2 container {...{ size: 12, sx: { paddingLeft: 2 } }}>
          <Status {...{ service_status, locale }} />
        </Grid2>
        <Grid2 container {...{ size: 12, sx: { paddingLeft: 2 } }}>
          <Version {...{ version, locale }} />
        </Grid2>
        <Grid2 container {...{ size: 12, sx: { paddingLeft: 2 } }}>
          <StealthMode {...{ stealth_mode, locale }} />
        </Grid2>
        <Divider />
        <Load {...{ locale }} />
        <Grid2 container {...{ size: 12, sx: { paddingLeft: 2 } }}>
          <Bandwidth {...{ network_packet_size, throttling, locale }} />
        </Grid2>
        <Grid2 container {...{ size: 12, sx: { paddingLeft: 2 } }}>
          <Upload {...{ multiple, locale }} />
        </Grid2>
        <Divider />
        <Grid2 container {...{ size: 12, sx: { paddingLeft: 2 } }}>
          <Security {...{ locale }} />
        </Grid2>
        <Grid2 container {...{ size: 12, sx: { paddingLeft: 2 } }}>
          <SelectionBlock {...{ cpassword, locale }} />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Details;
