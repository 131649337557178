import { Grid2, Typography } from "@mui/material";
import React from "react";

const UIText = {
  "es-CL": { label: "Ingresar" },
  "en-US": { label: "Login" },
};

const Header = ({ locale }) => {
  const { label } = UIText[locale];

  return (
    <Grid2 container {...{ size: 12, justifyContent: "center" }}>
      <Typography
        {...{
          fontSize: "1.5rem",
          textTransform: "uppercase",
          component: "h1",
          variant: "h5",
        }}
      >
        {label}
      </Typography>
    </Grid2>
  );
};

export default Header;
