import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Confirmar" },
  "en-US": { label: "Confirm" },
};
const UIText2 = {
  "es-CL": { label: "Continuar" },
  "en-US": { label: "Continue" },
};

const Confirm = ({ confirmation, alternativeText, working }) => {

  const onClick = () => {
    confirmation();
  };
  return (
    <Button
      {...{
        working,
        UIText: alternativeText ? UIText2 : UIText,
        onClick,
        labelSx: { fontSize: 13, fontWeight: "bold" },
      }}
    />
  );
};

export default Confirm;
