import { Grid2, TextField } from "@mui/material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": { label: "Nombre del dispositivo" },
  "en-US": { label: "Device name" },
};

const DeviceName = ({ device }) => {
  const { settings } = useUIBp();
  const locale = settings.locale;
  const { label } = UIText[locale];

  return (
    <Grid2 container size={12}>
      <TextField disabled fullWidth {...{ label, value: device, size:'small' }} />
    </Grid2>
  );
};

export default DeviceName;
