import { Grid2, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import SlimAlert from "../../../../UI/GeneralPurpose/SlimAlert";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../store/slices/UI";
import DateTime from "../../../../UI/GeneralPurpose/DateTime";
import dayjs from "dayjs";
import { devicesDataId } from "../../../../../store/actions/devices/getDevices";
import RadioSelect from "../../../../UI/GeneralPurpose/RadioSelect";

const UIText = {
  "es-CL": {
    subtitle: "Opciones",
    warning:
      "La recuperación ha sido configurada en modo avanzado, por lo que recomendamos utilizar esa modalidad para alterar esta recuperación.",
    warning2:
      " Los repositorios tienen distintas opciones de recuperación y fechas de respaldo. Si guarda sobreescribirá las opciones de recuperación de todos los dispositivos",
    opt1: "Incluir archivos eliminados",
    opt2: "Recuperar una fecha especifica",
  },
  "en-US": {
    subtitle: "Options",
    warning:
      "The recovery has been configured in advanced mode, so we recommend using that mode to alter this recovery.",
    warning2:
      " The repositories have different recovery options and backup dates if you save you will overwrite the recovery options of all devices",
    opt1: "Include deleted files",
    opt2: "Recover a specific date",
  },
};

const UITextDeleted = {
  "es-CL": { label: "Incluir archivos eliminados" },
  "en-US": { label: "Include deleted files" },
};

const UITextDate = {
  "es-CL": { label: "Fecha de recuperacion" },
  "en-US": { label: "Recovery date" },
};

export const recoveryDateUI = "recoveryDate";
export const recoveryOptionUI = "recoveryOptions";

const Options = ({ recoveries = [] }) => {
  const { dispatch, settings, UI, warehouse } = useUIBp();
  const selected = UI[recoveryOptionUI];
  const locale = settings.locale;
  const { subtitle, warning, warning2 } = UIText[locale];
  let devices = warehouse[devicesDataId] ?? [];

  const { option, bkpDate } = useMemo(() => {
    const blueprintRecovery = recoveries[0] ?? { repositories: [""] };
    const blueprint = blueprintRecovery.repositories[0];
    return { option: blueprint.option, bkpDate: blueprint.bkpDate };
  }, [recoveries]);

  const { anyDifferent, anyAdvanced } = useMemo(() => {
    let anyDifferent = false;
    let anyAdvanced = false;

    for (let i = 0; i < recoveries.length; i++) {
      if (recoveries[i].repositories.length === 0) {
        anyDifferent = true;
        continue;
      }
      if (recoveries[i].mode === "advanced") {
        anyAdvanced = true;
      }

      for (let j = 0; j < recoveries[i].repositories.length; j++) {
        const repo = recoveries[i].repositories[j];

        for (let k = 0; k < recoveries.length; k++) {
          if (k === i) {
            continue;
          }
          for (let l = 0; l < recoveries[k].repositories.length; l++) {
            const repo2 = recoveries[k].repositories[l];
            if (
              repo.option !== repo2.option ||
              repo.bkpDate !== repo2.bkpDate
            ) {
              anyDifferent = true;
              break;
            }
          }
        }
      }
    }
    return { anyDifferent, anyAdvanced };
  }, [recoveries]);

  useEffect(() => {
    if (!anyDifferent) {
      dispatch(
        UIactions.setValue({
          id: recoveryOptionUI,
          value: option,
        })
      );
      dispatch(
        UIactions.setField({
          id: recoveryDateUI,
          value: bkpDate,
        })
      );
    }
  }, [dispatch, anyDifferent, option, bkpDate]);

  const handleClick = () => {
    dispatch(UIactions.clear(recoveryDateUI));
  };

  let errors = [];

  if (anyAdvanced) {
    errors.push(warning);
  }
  if (anyDifferent) {
    errors.push(warning2);
  }

  return (
    <Grid2 container {...{ rowGap: 1, size: 12 }}>
      <SlimAlert
        {...{
          severity: "warning",
          errors,
        }}
      />
      <Grid2 container size={12}>
        <Typography fontWeight="bold">{subtitle}</Typography>
      </Grid2>
      <RadioSelect
        allowEmpty
        {...{
          id: recoveryOptionUI,
          disabled: devices.length === 0 && recoveries.length === 0,
          value: "deleted",
          UIText: UITextDeleted,
          onClick: handleClick,
          labelProps: { noWrap: true },
          size: 12,
        }}
      />
      <Grid2
        container
        {...{
          size: 12,
          alignItems: "center",
          justifyContent: "space-between",
          columnSpacing: 2,
        }}
      >
        <RadioSelect
          allowEmpty
          {...{
            id: recoveryOptionUI,
            disabled: devices.length === 0 && recoveries.length === 0,
            value: "dated",
            UIText: UITextDate,
            onClick: handleClick,
            labelProps: { noWrap: true },
            size: 6,
          }}
        />
        {selected === "dated" ? (
          <DateTime
            clearOnUnmount
            {...{
              id: recoveryDateUI,
              clearOnUnmount: true,
              size: "grow",
              maxDateTime: dayjs(),
              UIText: UITextDate,
            }}
          />
        ) : null}
      </Grid2>
    </Grid2>
  );
};

export default Options;
