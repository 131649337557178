import { Grid2 } from "@mui/material";
import HeadersData from "./Headers";
import { newUsersUI } from "../TopBar/UploadSheet";
import UserRow from "./UserRow";
import { sortObjByKey } from "../../../../../../utility/misc/sortObjByKey";
import { useMemo } from "react";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import BasicHeaders from "../../../../../UI/BasicLayout/Components/BasicHeaders";

export const sortId = "createdSort";

const UsersList = () => {
  const { UI } = useUIBp();

  // TODO: enable filters & update .xlsx template
  const usersRows = useMemo(() => {
    let users = [...(UI[newUsersUI] ?? [])];
    const sort = UI[sortId] ?? { value: "mail", descending: false };

    return users
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending))
      .map((data, idx) => <UserRow {...data} key={idx} />);
  }, [UI]);

  return (
    <Grid2 container  {...{ size: 12 }}>
      <BasicHeaders {...{ sortId, headers: HeadersData }} />
      <Grid2
        container
        {...{
          paddingLeft: 2,
          paddingRight: 2,
          size: 12,
          sx: { height: 400, overflow: "scroll" },
        }}
        alignContent="start"
      >
        {usersRows}
      </Grid2>
    </Grid2>
  );
};

export default UsersList;
