import notEmptyUser from "../../../../../utility/validations/notEmptyUser";
import TextField from "../../../../UI/GeneralPurpose/SimpleTextField";


const UIText = {
  "es-CL": {
    label: "Usuario",
    placeholder: "usuario1@cloner.cl",
  },
  "en-US": {
    label: "User",
    placeholder: "user1@cloner.cl",
  },
};

export const userUI = "userLogin";

const User = ({isNew}) => {

  return (
    <TextField
      tagError
      {...{
        id: userUI,
        UIText,
        required: isNew,
        disabled: !isNew,
        validation: notEmptyUser,
        size: 12,
        sx: { marginTop: 1, paddingBottom: 2 },
      }}
    />
  );
};

export default User;
