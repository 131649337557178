import { Grid2 } from "@mui/material";
import FilterField from "../../../UI/GeneralPurpose/FilterField";
import Select from "../../../UI/GeneralPurpose/Select";
import { useDispatch } from "react-redux";

const UIText = {
  "es-CL": {
    title: "Actividades",
  },
  "en-US": {
    title: "Activities",
  },
};

const UITextFilter = {
  "es-CL": { placeholder: "Filtrar" },
  "en-US": { placeholder: "Filter" },
};

const UITextSelect = {
  "es-CL": { label: "Intervalo" },
  "en-US": { label: "Interval" },
};

const options = [
  { universal: { label: "2s" }, value:2 },
  { universal: { label: "5s" }, value:5 },
  { universal: { label: "10s" }, value: 10 },
  { universal: { label: "15s" }, value: 15 },
  { universal: { label: "30s" }, value: 30 },
  { universal: { label: "45s" }, value: 45 },
  { universal: { label: "60s" }, value: 60 },
];

export const activitiesFilterID = "activitiesFilter";
export const activitiesSelectID = "activitiesSelect";

const Header = (props) => {
  const dispatch = useDispatch();
  const { locale } = props;
  const { title } = UIText[locale] ?? UIText["es-CL"];

  return (
    <Grid2
      container
      {...{ alignItems: "center", justifyContent: "space-between" }}
    >
      <Grid2 {...{ fontSize: "1.5rem", size: 6 }}>{title}</Grid2>
      <Grid2 container {...{ size: 6, justifyContent: "end", alignItems: "center", columnSpacing: 1 }}>
        <Select
          clearOnUnmount
          {...{
            id: activitiesSelectID,
            UIText: UITextSelect,
            defaultIdx: 2,
            options,
            size: 3,
          }}
        />
        <FilterField
          {...{ id: activitiesFilterID, UIText: UITextFilter, size: 6 }}
        />
      </Grid2>
    </Grid2>
  );
};

export default Header;
