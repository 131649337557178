import { Typography } from "@mui/material";
import { timeDiff } from "../../../../../../../utility/misc/humanizers";

const Backup = ({ fontSize, lastBkp, settings }) => {
  const UIText = {
    "es-CL": { neverCompleted: "Nunca completado" },
    "en-US": { neverCompleted: "Never completed" },
  };

  const { neverCompleted } = UIText[settings.locale];
  let txtBkp = "";
  if (lastBkp < 0) {
    txtBkp = neverCompleted;
  } else {
    txtBkp = timeDiff(Date.now() - lastBkp);
  }
  return (
    <Typography {...{ fontSize, sx: { cursor: "default" } }}>
      {txtBkp}
    </Typography>
  );
};

export default Backup;
