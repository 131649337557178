import { Grid2 } from "@mui/material";
import Select from "../../../../UI/GeneralPurpose/Select";
import { about, requirements } from "./options";
import notEmptyUser from "../../../../../utility/validations/notEmptyUser";
import notEmptyField from "../../../../../utility/validations/notEmpty";
import { useEffect } from "react";
import SlimAlert from "../../../../UI/GeneralPurpose/SlimAlert";
import BasicDialogContent from "../../../../UI/BasicDialog/BasicDialogContent";
import TextField from "../../../../UI/GeneralPurpose/SimpleTextField";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../store/slices/UI";

const UIText = {
  "es-CL": { label: "Requerimiento" },
  "en-US": { label: "Requirement" },
};
const UIText2 = {
  "es-CL": { label: "Respecto a" },
  "en-US": { label: "About" },
};
const UIText3 = {
  "es-CL": { label: "Correo de contacto", placeholder: "usuario@cloner.cl" },
  "en-US": { label: "Contact email", placeholder: "user@cloner.cl" },
};
const UIText4 = {
  "es-CL": {
    label: "Detalles adicionales",
    placeholder: "El equipo no está respaldando!",
  },
  "en-US": {
    label: "Aditional details",
    placeholder: "The device is not doing the backups!",
  },
};
const UIText6 = {
  "es-CL": {
    warning:
      "Por favor verifique que el correo de contacto existe y puede recibir correos externos",
  },
  "en-US": {
    warning:
      "Please make sure that the contact email exists and can receive external emails",
  },
};

export const requirementsUI = "requirements";
export const supportFocusUI = "supportFocus";
export const contactEmailUI = "contactEmail";
export const detailsUI = "details";

const Content = () => {
  const { auth, dispatch, settings } = useUIBp();
  const { warning } = UIText6[settings.locale];

  useEffect(() => {
    dispatch(UIactions.setField({ id: contactEmailUI, value: auth.user }));
  }, [dispatch, auth]);

  return (
    <BasicDialogContent sx={{ padding: 0 }}>
      <Grid2 container {...{ columnSpacing: 2, rowGap: 2 }}>
        <SlimAlert
          notCollapse
          {...{
            errors: [warning],
            severity: "info",
            size:12,
            sx: { paddingLeft: 2 },
          }}
        />
        <Select
          clearOnUnmount
          {...{ id: requirementsUI, UIText, size: 6, options: requirements }}
        />
        <Select
          clearOnUnmount
          {...{ id: supportFocusUI, UIText: UIText2, size: 6, options: about }}
        />
        <TextField
          required
          tagError
          clearOnUnmount
          {...{
            id: contactEmailUI,
            UIText: UIText3,
            size: 12,
            validation: notEmptyUser,
          }}
        />
        <TextField
          required
          multiline
          tagError
          clearOnUnmount
          {...{
            id: detailsUI,
            UIText: UIText4,
            size: 12,
            minRows: 4,
            validation: notEmptyField,
          }}
        />
      </Grid2>
    </BasicDialogContent>
  );
};

export default Content;
