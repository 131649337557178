import { ChevronRight } from "@mui/icons-material";
import { contactNameUI } from "./Form/Components/ContactInfo/ContactName";
import { contactPhoneUI } from "./Form/Components/ContactInfo/ContactPhone";
import { contactEmailUI } from "./Form/Components/ContactInfo/ContactEmail";
import { deliveryOfficeUI } from "./Form/Components/AddressInfo/DeliveryOffice";
import { deliveryStreetUI } from "./Form/Components/AddressInfo/DeliveryStreet";
import { deliveryStreetNumberUI } from "./Form/Components/AddressInfo/DeliveryStreetNumber";
import { regionSelectUI } from "./Form/Components/AddressInfo/Region";
import { districtSelectUI } from "./Form/Components/AddressInfo/District";
import { citySelectUI } from "./Form/Components/AddressInfo/City";
import {
  deliveryWindowFromUI,
  deliveryWindowToUI,
} from "./Form/Components/DeliveryWindow";
import { reasonUI } from "./Form/Components/Reason/Reasons";
import { otherReasonUI } from "./Form/Components/Reason/OtherReason";
import notEmptyField from "../../../../../utility/validations/notEmpty";
import notEmptyUser from "../../../../../utility/validations/notEmptyUser";
import minutesToTime from "../../../../../utility/misc/minutesToTime";
import { Grid2 } from "@mui/material";
import { defaultRadioSelect } from "../../../../UI/GeneralPurpose/genericIDs";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";
import { recoveriesActions } from "../../../../../store/slices/recoveries";

const UIText = {
  "es-CL": { label: "Guardar y continuar" },
  "en-US": { label: "Save and continue" },
};

export const miscErrorsUI = "miscErrors";
export const emptyRegionUI = "emptyRegion";
export const emptyDistrictUI = "emptyDistrict";
export const emptyCityUI = "emptyCity";
export const emptyMotiveUI = "emptyMotive";

const Next = () => {
  const { dispatch, navigate, UI, settings } = useUIBp();
  const mode = UI[defaultRadioSelect] ?? "link";
  const locale = settings.locale;

  const { error: e1 } = UI[contactNameUI] ?? {};
  const { error: e2 } = UI[contactPhoneUI] ?? {};
  const { error: e3 } = UI[contactEmailUI] ?? {};
  const { error: e4 } = UI[deliveryOfficeUI] ?? {};
  const { error: e5 } = UI[deliveryStreetUI] ?? {};
  const { error: e6 } = UI[deliveryStreetNumberUI] ?? {};
  const regionSelect = UI[regionSelectUI] ?? "";
  const districtSelect = UI[districtSelectUI] ?? "";
  const citySelect = UI[citySelectUI] ?? "";
  const { error: e7 } = UI[deliveryWindowFromUI] ?? {};
  const { error: e8 } = UI[deliveryWindowToUI] ?? {};
  const reason = UI[reasonUI] ?? "";
  const { error: e9 } = UI[otherReasonUI] ?? {};

  const handleMouseDown = () => {
    dispatch(
      UIactions.validateField({
        id: contactNameUI,
        force: true,
        fn: notEmptyField,
        locale,
      })
    );
    dispatch(
      UIactions.validateField({
        id: contactPhoneUI,
        force: true,
        fn: notEmptyField,
        locale,
      })
    );
    dispatch(
      UIactions.validateField({
        id: contactEmailUI,
        force: true,
        fn: notEmptyUser,
        locale,
      })
    );
    if (!regionSelect) {
      dispatch(UIactions.add({ id: miscErrorsUI, key: emptyRegionUI }));
    }
    if (!districtSelect && regionSelect) {
      dispatch(UIactions.add({ id: miscErrorsUI, key: emptyDistrictUI }));
    }
    if (!citySelect && districtSelect) {
      dispatch(UIactions.add({ id: miscErrorsUI, key: emptyCityUI }));
    } else if (citySelect && mode === "disc") {
      dispatch(
        UIactions.validateField({
          id: deliveryStreetUI,
          force: true,
          fn: notEmptyField,
          locale,
        })
      );
      dispatch(
        UIactions.validateField({
          id: deliveryStreetNumberUI,
          force: true,
          fn: notEmptyField,
          locale,
        })
      );
    }
    if (!reason) {
      dispatch(UIactions.add({ id: miscErrorsUI, key: emptyMotiveUI }));
    } else if (reason === "Otro") {
      dispatch(
        UIactions.validateField({
          id: otherReasonUI,
          force: true,
          fn: notEmptyField,
          locale,
        })
      );
    }
  };

  const disabled =
    !!e1 ||
    !!e2 ||
    !!e3 ||
    !!e4 ||
    !!e5 ||
    !!e6 ||
    !!e7 ||
    !!e8 ||
    !!e9 ||
    (!regionSelect && mode === "disc") ||
    (!districtSelect && mode === "disc") ||
    (!citySelect && mode === "disc") ||
    !reason;

  const handleClick = () => {

    console.log(UI[deliveryWindowFromUI].value, minutesToTime(UI[deliveryWindowFromUI].value))

    const newAdress = {
      contactName: UI[contactNameUI].value,
      contactPhone: UI[contactPhoneUI].value,
      contactEmail: UI[contactEmailUI].value,
      deliveryMethod: mode,
      deliveryOffice: UI[deliveryOfficeUI] ? UI[deliveryOfficeUI].value : "",
      deliveryStreet: UI[deliveryStreetUI] ? UI[deliveryStreetUI].value : "",
      deliveryStreetNumber: UI[deliveryStreetNumberUI]
        ? UI[deliveryStreetNumberUI].value
        : "",
      region: UI[regionSelectUI],
      district: UI[districtSelectUI],
      city: UI[citySelectUI],
      deliveryWindowFrom: UI[deliveryWindowFromUI].value,
      deliveryWindowTo: UI[deliveryWindowToUI].value,
      reason: UI[reasonUI],
      otherReason: reason === "Otro" ? UI[otherReasonUI].value : undefined,
    };
    dispatch(recoveriesActions.setDeliveryData(newAdress));
    navigate("/dashboard/recovery/step3");
  };

  return (
    <Grid2 container {...{ size: 4, justifyContent: "end" }}>
      <Button
        {...{
          disabled,
          UIText,
          onClick: handleClick,
          onMouseDown: handleMouseDown,
          endIcon: <ChevronRight />,
        }}
      />
    </Grid2>
  );
};

export default Next;
