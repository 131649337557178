import { Delete, Tune } from "@mui/icons-material";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../store/slices/UI";
import { recoveriesActions } from "../../../../../../../store/slices/recoveries";
import { defaultSelected } from "../../../../../../UI/GeneralPurpose/genericIDs";
import MoreActions from "../../../../../../UI/BasicLayout/Components/MoreActions/MoreActions";

const Actions = (props) => {
  const {id} = props
  const { dispatch, navigate } = useUIBp();

  let data = {
    id,
    buttonsData: [
      // {
      //   icon: <Edit sx={{ color: "grey" }} />,
      //   UIText: {
      //     "es-CL": { label: "Editar" },
      //     "en-US": { label: "Edit" },
      //   },
      //   onClick: () => {
      //     dispatch(
      //       UIactions.openModal({
      //         type: "create-update-recoveries",
      //         payload: [props],
      //       })
      //     );
      //   },
      // },
      {
        icon: <Tune sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Avanzado" },
          "en-US": { label: "Advanced" },
        },
        onClick: () => {
          navigate(`/dashboard/recovery/advanced?id=${id}`);
        },
      },
      {
        icon: <Delete sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Remover" },
          "en-US": { label: "Remove" },
        },
        onClick: () => {
          dispatch(recoveriesActions.deleteRecoveries([id]));
          dispatch(recoveriesActions.removeUnusedRepositories());
          dispatch(UIactions.remove({ id: defaultSelected, key: id }));
        },
      },
    ],
  };

  return <MoreActions {...data} />;
};

export default Actions;
