import { Search } from "@mui/icons-material";
import { Grid2, InputAdornment, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import ClearInsert from "../TextFieldInserts/ClearInsert";
import AutoHelp from "../Wrappers/AutoHelp";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";

// const UIText = [
//   {
//     "es-CL": {
//       label: "",
//       placeholder: "",
//       tooltip: "",
//     },
//   },
// ];

const FilterField = (props) => {
  const {
    id = "filter",
    size,
    componentSize = "small",
    onPressEnter,
    onChange,
    UIText = {},
    disabled,
    fontSize,
    forceLowerCase,
    innerPadding,
    fullWidth,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "size",
    "componentSize",
    "gridSize",
    "onPressEnter",
    "UIText",
    "fontSize",
    "forceLowerCase",
    "innerPadding"
  );
  const { dispatch, settings, UI } = useUIBp();
  const ref = useRef(null);
  const locale = settings.locale;
  const value = UI[id] ?? "";

  useEffect(() => {
    ref.current.value = value;
  }, [value]);

  const { tooltip, label, placeholder } = UIText[locale];

  const debouncedChange = debounce((e) => {
    if (onChange) {
      onChange(e);
    } else {
      const value = e.target.value;
      dispatch(UIactions.setValue({ id, value }));
    }
  }, 300);

  const handleChange = (e) => {
    debouncedChange(e);
  };

  const debouncedPressEnter = debounce((e) => {
    if (onPressEnter && (e.key === "Enter" || e.key === "NumpadEnter")) {
      onPressEnter(e);
    }
  }, 300);

  const handleEnter = (e) => {
    debouncedPressEnter(e);
  };

  return (
    <Grid2 container size={size}>
      <AutoHelp {...{ tooltip, disabled }}>
        <TextField
          {...filteredProps}
          {...{
            label,
            placeholder,
            fullWidth: size || fullWidth ? true : undefined,
            inputRef: ref,
            onChange: handleChange,
            onKeyUp: handleEnter,
            size:componentSize,
            InputProps: {
              ...filteredProps.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search {...{ sx: { fontSize: fontSize + 2 } }} />
                </InputAdornment>
              ),
              endAdornment: value ? (
                <ClearInsert {...{ id, fontSize }} />
              ) : null,
              sx: { fontSize, paddingRight: 0.5 },
            },
            InputLabelProps: {
              sx: { fontSize: 15 },
            },
            inputProps: {
              sx: {
                textTransform: forceLowerCase ? "lowercase" : undefined,
                padding: innerPadding,
              },
            },
          }}
        />
      </AutoHelp>
    </Grid2>
  );
};

export default FilterField;
