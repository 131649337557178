import Button from "../../../../../UI/GeneralPurpose/Button";
import { validateMailId } from "../../../../../../store/actions/microsoft365/validateM365Mails";
import suggestM365Mails, { suggestMailId } from "../../../../../../store/actions/microsoft365/suggestM365Mails";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../Hooks/useWorker";

const UITextSuggest = {
  "es-CL": {
    label: "Sugerir correo",
    fixedTooltip:
      "Sugiere un correo para Microsoft 365 valido, utilizando el usuario como referencia",
  },
  "en-US": {
    label: "Suggest mail",
    fixedTooltip:
      "Suggest a valid Microsoft 365 email, using the user as reference",
  },
};

export const newUsersUI = "newUsers";

const SuggestMail = ({ login, org }) => {
  const { auth, dispatch, settings } = useUIBp();
  const { working } = useWorker(suggestMailId);
  const { working: w2 } = useWorker(validateMailId);
  const { cloner_key, M365server } = auth;
  const { locale } = settings;

  let payload = [
    {
      login,
      org,
    },
  ];

  const handleSuggest = (e) => {
    dispatch(
      suggestM365Mails({ tryouts: payload, cloner_key, M365server, locale })
    );
  };

  return (
    <Button
      {...{
        disabled: w2,
        UIText: UITextSuggest,
        onClick: handleSuggest,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
        size: 6,
      }}
    />
  );
};

export default SuggestMail;
