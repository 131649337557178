import { HelpOutline } from "@mui/icons-material"
import AutoHelp from "../Wrappers/AutoHelp"
import useUIBp from "../../../Hooks/useUIBoilerplate"

const HelpTooltip = ({UIText, fontSize='1.2rem'})=>{
    const {settings} = useUIBp()
    const {tooltip:fixedTooltip} = UIText[settings.locale]

    return (
        <AutoHelp {...{fixedTooltip}}>
            <HelpOutline color="primary" sx={{fontSize}}/>
        </AutoHelp>
    )
}

export default HelpTooltip