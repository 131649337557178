import { Grid2, TextField } from "@mui/material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../store/slices/UI";

const VerificationCodeField = ({ idIdx }) => {
  const { dispatch, UI } = useUIBp();
  const value = UI["vc" + idIdx] ?? "";

  const onlyNumbers = (value = "") => {
    if (!value.match(/\D/) || "") {
      return true;
    } else {
      return false;
    }
  };

  const genericChange = (e, n) => {
    const value = e.target.value.trim();
    if (onlyNumbers(value)) {
      if (value.length === 0) {
        dispatch(UIactions.clear("vc" + n));
      } else if (value.length === 1) {
        dispatch(UIactions.setValue({ id: "vc" + n, value }));
        if (n < 5) {
          document.getElementById("vc" + (n + 1)).focus();
        } else if (n === 5) {
          document.getElementById("vc" + n).blur();
        }
      } else if (value.length === 2) {
        dispatch(UIactions.setValue({ id: "vc" + n, value: value[1] }));
        if (n < 5) {
          document.getElementById("vc" + (n + 1)).focus();
        } else {
          document.getElementById("vc" + n).blur();
        }
      } else if (value.length > 2) {
        for (const idx in value) {
          if (parseInt(idx) + n > 5) {
            break;
          }
          dispatch(
            UIactions.setValue({
              id: "vc" + (parseInt(idx) + n),
              value: value[idx],
            })
          );
        }
        document.getElementById("vc" + n).blur();
      }
    }
  };

  const onChange = (e) => {
    genericChange(e, idIdx);
  };

  const onKeyDown = (e) => {
    if (e.key === "Backspace" && value === "" && idIdx !== 0) {
      dispatch(UIactions.setValue({ id: "vc" + (idIdx - 1), value: "" }));
      document.getElementById("vc" + (idIdx - 1)).focus();
    }
  };

  return (
    <Grid2 size={1.2}>
      <TextField
        fullWidth
        {...{
          id: "vc" + idIdx,
          onChange,
          onKeyDown,
          size: "small",
          InputProps: {
            inputProps: {
              style: { textAlign: "center" },
            },
          },
          value,
        }}
      />
    </Grid2>
  );
};

export default VerificationCodeField;
