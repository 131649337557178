import { Dialog } from "@mui/material";
import Close from "./Components/Close";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import Data from "./Components/Data";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";

const UIText = {
  "es-CL": { repo: "Repositorio", file: "Archivo", dir: "Directorio" },
  "en-US": { repo: "Repository", file: "File", dir: "Directory" },
};

const FileDataPop = ({modal}) => {
  const { dispatch, settings, UI } = useUIBp();
  const locale = settings.locale;

  let payload;
  if (modal === "1") {
    const { payload: p } = UI.modal;
    payload = p;
  } else if (modal === "2") {
    const { payload: p } = UI.modal2;
    payload = p;
  }
  const { data } = payload;
  const { repo, file, dir } = UIText[locale];

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      if (modal === "1") {
        dispatch(UIactions.closeModal());
      } else if (modal === "2") {
        dispatch(UIactions.closeModal2());
      }
    }
  };

  let fType = "";
  if (data.type === 99) {
    fType = repo;
  } else if (data.type === 1) {
    fType = file;
  } else if (data.type === 0) {
    fType = dir;
  }

  const header = `${fType}: ${data.name}`;

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header, locale }} />
      <BasicDialogContent>
        <Data {...{ data, locale }} />
      </BasicDialogContent>
      <BasicDialogActions>
        <Close {...{ modal }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default FileDataPop;
