import axios from "axios";
import { workerActions } from "../../../../../store/slices/workers";
import { UIactions } from "../../../../../store/slices/UI";
import { warehouseActions } from "../../../../../store/slices/warehouse";

export const tunnelTokenId = "tunnelToken";
export const rootsDataId = "roots";

const getMachineStatus = ({ user, mid, server, cloner_key }) => {
  return async (dispatch) => {
    dispatch(workerActions.start(tunnelTokenId));
    try {
      let formData = new FormData();
      formData.append("user", user);
      formData.append("machine", mid);
      const response = await axios.post(
        server.slice(0, -1) + ":5107/startSession",
        formData,
        { headers: { "Content-Type": "multipart/form-data", cloner_key } }
      );
      dispatch(
        UIactions.setValue({ id: tunnelTokenId, value: response.data.token })
      );

      const tunnel_token = response.data.token;
      const response2 = await axios.get(server.slice(0, -1) + ":5107/status", {
        headers: { tunnel_token },
      });

      const response3 = await axios.get(server.slice(0, -1) + ":5107/roots", {
        headers: { tunnel_token },
      });

      const repos = response2.data.repos;
      const roots = response3.data.roots;

      let newRoots = [];
      for (const root of roots) {
        const found = repos.find((x) => x.name === root);
        let newRoot = { id: null, root };
        if (found) {
          newRoot.id = found.id;
        }
        newRoots.push(newRoot);
      }
      dispatch(warehouseActions.load({ id: rootsDataId, data: newRoots }));

    } catch (e) {
      console.error(e)
      dispatch(workerActions.finish({ id: tunnelTokenId, error: e.message }));
    }
    dispatch(workerActions.finish({ id: tunnelTokenId}));
  };
};

export default getMachineStatus;
