import useTrees from "../../../Hooks/useTrees";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../Hooks/useURLQuery";
import { UIactions } from "../../../store/slices/UI";
import BasicLayout from "../../UI/BasicLayout/BasicLayout";
import { resetScrollId } from "../../UI/BasicLayout/Components/Core/Core";
import headersData from "./Headers";
import MailRow from "./MailRow/MailRow";
import PlusActions, { plusActionsGrid } from "./PlusActions/PlusActions";

const Core = (props) => {
  const { mails, allMails, done, working, error, workerId, workerId2 } = props;
  const { dispatch } = useUIBp();
  const { folderId, idx } = useURLQuery();
  const trees = useTrees() ?? {};
  const tree = trees[`mails-${folderId}`] ?? [];
  const node = tree[idx] ?? {};

  const header = node.displayName;
  const plusActions = <PlusActions />;

  const elements = mails.map((data, idx) => <MailRow key={idx} {...data} />);

  const coreData = {
    working,
    error: {
      workerId,
      workerId2,
      error,
      UIText: {
        "es-CL": { message: "Error al cargar los correos: " + error },
        "en-US": { message: "Error loading emails: " + error },
      },
    },
    empty: {
      isEmpty: !mails.length && done && !error,
      UIText: {
        "es-CL": { message: "No hay correos disponibles" },
        "en-US": { message: "There are no emails available" },
      },
    },
    done,
    elements,
  };

  return (
    <BasicLayout
      noRecursive
      {...{
        plusActions,
        plusActionsGrid,
        header,
        headersData,
        fullArray: allMails,
        coreData,
        onPaginationChange: () => {
          dispatch(UIactions.setValue({id:resetScrollId, value:true}));
        },
      }}
    />
  );
};

export default Core;
