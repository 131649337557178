import { Grid2, Typography } from "@mui/material";
import CheckButton from "../../../../../../UI/Specialized/Check";
import { humanBytes } from "../../../../../../../utility/misc/humanizers";
import { useEffect } from "react";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../store/slices/UI";

export const bkpOneArrayId = "bkpOne";
export const bkpDriveSizeId = "bkpDriveSize-";

const BackupOnedrive = ({ login }) => {
  const { dispatch, UI } = useUIBp();
  const driveSize = UI[bkpDriveSizeId + login] ?? 0;

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(bkpDriveSizeId + login));
    };
  }, [dispatch, login]);

  return (
    <Grid2 container {...{ size: 12, alignItems: "center" }}>
      <CheckButton
        {...{
          id: login,
          arrayId: bkpOneArrayId,
          size: "auto",
          fontSize: 1,
          clearOnExit: true,
        }}
      />
      <Grid2 container {...{ size: "auto", justifyContent: "center" }}>
        <Typography sx={{ fontSize: "0.8rem" }}>
          {driveSize ? `(${humanBytes(driveSize)})` : null}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default BackupOnedrive;
