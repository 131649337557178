import { Chip, Grid2, Typography } from "@mui/material";
import NewExtension, { customExtensionsUI } from "./Top/NewExtension";
import Clear from "./Top/Clear";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../store/slices/UI";

const UIText = {
  "es-CL": { header: "Extensiones personalizadas" },
  "en-US": { header: "Custom extensions" },
};

const CustomExtensions = () => {
  const { dispatch, settings, UI } = useUIBp();
  const { header } = UIText[settings.locale];
  const extensions = UI[customExtensionsUI] ?? [];

  const items = extensions.map((ext, idx) => {
    return (
      <Chip
        key={idx}
        {...{
          color: "primary",
          variant: "outlined",
          sx: { backgroundColor: "rgba(0, 167, 132, 0.1)", border: "none" },
          label: ext.toUpperCase(),
          onDelete: () => {
            dispatch(
              UIactions.toggleSingle({ id: customExtensionsUI, key: ext })
            );
          },
        }}
      />
    );
  });

  return (
    <Grid2
      container
      {...{
        padding: 2,
        size: 12,
        rowGap: 1,
        columnSpacing: 2,
        alignItems: "center",
      }}
    >
      <Grid2 container size={{ lg: "auto", md: 12, sm: 12, xs: 12 }}>
        <Typography fontSize="1.1rem">{header}</Typography>
      </Grid2>
      <NewExtension />
      <Clear />
      <Grid2
        container
        {...{ size: 12, gap: 1, paddingLeft: 2, paddingRight: 2 }}
      >
        {items}
      </Grid2>
    </Grid2>
  );
};

export default CustomExtensions;
