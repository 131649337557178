import { bandwidthLimitUI, throttleActiveUI } from "./Form/Fields/Bandwidth";
import { intervalUI } from "./Form/Fields/Interval";
import { multiUploadActiveUI, multiUploadUI } from "./Form/Fields/Multiupload";
import { deviceNameUI } from "./Form/Fields/NewName";
import { cpasswordActiveUI, cpasswordUI } from "./Form/Fields/SelectionBlock";
import { stealthModeUI } from "./Form/Fields/StealthMode";
import { suspendedUI } from "./Form/Fields/Suspended";
import postDeviceInfo, {
  postDeviceId,
} from "../../../../../store/actions/devices/postDeviceInfo";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../Hooks/useWorker";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const Save = ({ config, devices }) => {
  const oneDevice =
    devices.length > 0
      ? devices[0]
      : { name: "placeholdername", mid: "placeholderMID" };
  const oldDeviceName = oneDevice.name;
  const { auth, dispatch, UI, settings } = useUIBp();
  const { working } = useWorker(postDeviceId);
  const locale = settings.locale;
  const { server, token, cloner_key } = auth;
  // =================================================================
  const isThrottling = UI[throttleActiveUI];
  const bandwidth = UI[bandwidthLimitUI];
  const interval = UI[intervalUI];
  const multiActive = UI[multiUploadActiveUI];
  const multiUpload = UI[multiUploadUI];
  const deviceName = UI[deviceNameUI] ?? { value: "" };
  const activeCpassword = UI[cpasswordActiveUI];
  const newCpassword = UI[cpasswordUI] ?? { value: "" };
  const stealth = UI[stealthModeUI];
  const suspended = UI[suspendedUI];
  // =================================================================

  const {
    scan_interval,
    throttling,
    network_packet_size,
    cpassword,
    service_status,
  } = config;

  const updateData = {
    server,
    cloner_key,
    worldAdmin: auth.admin === 1,
    device: oldDeviceName,
    devicename: { update: false },
    config: { update: false },
    password: { update: false },
    suspended: { update: false },
    locale,
  };

  // checking device name change
  if (oldDeviceName !== deviceName.value && devices.length === 1) {
    const machinePayload = {
      user: oneDevice.user,
      mid: oneDevice.mid,
      name: deviceName.value,
    };
    updateData.devicename.update = true;
    updateData.devicename.payload = machinePayload;
  }

  // checking config change
  if (
    scan_interval !== interval ||
    throttling !== isThrottling ||
    network_packet_size !== bandwidth 
    // stealth_mode !== stealth ||
    // concurrent_uploads > 1
    //   ? concurrent_uploads
    //   : 1 !== (multiActive ? multiUpload : 1) || devices.length > 1
  ) {
    console.log("INSIDE!");
    const config = {
      scan_interval: interval ? interval : 240,
      throttling: !!isThrottling,
      stealth_mode: !!stealth,
      network_packet_size: bandwidth ?? 131072,
      concurrent_uploads: multiActive ? multiUpload : 1,
    };
    const confPayload = {
      user: oneDevice.user,
      machine: oneDevice.mid,
      token,
      value: JSON.stringify(config),
    };
    updateData.config.update = true;
    updateData.config.payload = confPayload;
  }

  // checking cpassword change
  if (newCpassword.value !== "") {
    const passwordPayload = {
      user: oneDevice.user,
      machine: oneDevice.mid,
      newcpasswd: newCpassword.value,
      token,
    };
    updateData.password.update = true;
    updateData.password.payload = passwordPayload;
  } else if ((cpassword && !activeCpassword) || devices.length > 1) {
    const passwordPayload = {
      user: oneDevice.user,
      machine: oneDevice.mid,
      newcpasswd: "",
      token,
    };
    updateData.password.update = true;
    updateData.password.payload = passwordPayload;
  }

  // checking suspension status change
  if (suspended !== !!service_status || devices.length > 1) {
    updateData.suspended.update = true;
    updateData.suspended.payload = {
      user: oneDevice.user,
      machine: oneDevice.mid,
      status: suspended ? "Dispositivo suspendido por soporte" : "",
    };
  }

  const noChanges =
    !updateData.devicename.update &&
    !updateData.config.update &&
    !updateData.password.update &&
    !updateData.suspended.update;

  console.log("updateData", updateData);

  const inputError = !!newCpassword.error || !!deviceName.error;

  const handleSubmitDevice = () => {
    dispatch(postDeviceInfo(updateData));
  };

  return (
    <Button
      {...{
        disabled: noChanges || inputError,
        working,
        UIText,
        onMouseUp: handleSubmitDevice,
      }}
    />
  );
};

export default Save;
