import { Alert, Collapse, Grid2 } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

const timeout = 200;

const SlimAlert = (props) => {
  const {
    errors = [],
    messages = [],
    severity = "error",
    size,
    fuseTop,
    notCollapse,
    paddingTop,
    paddingBottom,
  } = props;
  const [bufferedError, setError] = useState(null);
  const [bufferedSeverity, setSeverity] = useState(undefined);
  const [open, setOpen] = useState(false);

  const msgs = messages.length ? messages : errors;

  const finalMessages = useMemo(() => {
    let resultingErrors = [];
    for (const m of msgs) {
      if (m && !resultingErrors.find((x) => x === m)) {
        resultingErrors.push(m);
      }
    }

    if (resultingErrors.length === 1) {
      return resultingErrors[0];
    } else if (resultingErrors.length > 1) {
      const list = resultingErrors.map((err, idx) => <li key={idx}>{err}</li>);
      return <ul>{list}</ul>;
    } else {
      return null;
    }
  }, [msgs]);

  useEffect(() => {
    if (finalMessages) {
      setError(finalMessages);
      setSeverity(severity);
      setOpen(true);
    }
  }, [setError, setOpen, setSeverity, finalMessages, severity]);

  useEffect(() => {
    if (!finalMessages) {
      setOpen(false);
    }
  }, [setOpen, finalMessages]);

  const handleExit = () => {
    setSeverity(undefined);
    setError(null);
  };

  let color;
  switch (bufferedSeverity) {
    case "info":
      color = "blue";
      break;
    case "error":
      color = "red";
      break;
    case "success":
      color = "green";
      break;
    case "warning":
      color = "orange";
      break;
    case "disabled":
      color = "grey";
      break;
    default:
      color = "grey";
  }

  let baseDOM = (
    <Grid2 container sx={{ width: "100%", paddingTop, paddingBottom }}>
      <Alert
        {...{
          severity: bufferedSeverity,
          sx: {
            padding: 0,
            paddingLeft: 2,
            paddingRight: 2,
            width: "100%",
            border: `1px solid ${color}`,
            borderRadius: fuseTop ? "0px 0px 4px 4px" : undefined,
            borderTop: fuseTop ? "0px" : undefined,
            alignItems: "center",
            "& ul": {
              margin: 0,
            },
          },
        }}
      >
        {bufferedError}
      </Alert>
    </Grid2>
  );

  if (notCollapse) {
    return (
      <Grid2 container {...{ size }}>
        {baseDOM}
      </Grid2>
    );
  }

  return (
    <Grid2 container {...{ size }}>
      <Collapse
        unmountOnExit
        {...{
          in: open || (notCollapse && !!bufferedError),
          sx: { width: "100%" },
          timeout,
          onExited: handleExit,
        }}
      >
        {baseDOM}
      </Collapse>
    </Grid2>
  );
};

export default SlimAlert;
