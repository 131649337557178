import { Download } from "@mui/icons-material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { forceRescanId } from "../../../../../Reports/Actions/forceRescan";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";
import exportFiles from "../../../../Actions/exportFiles";
import { useWorker } from "../../../../../../Hooks/useWorker";

const UIText = {
  "es-CL": { label: "Exportar" },
  "en-US": { label: "Export" },
};

const Export = ({ allFiles }) => {
  const { dispatch, settings } = useUIBp();
  const { working } = useWorker(forceRescanId);

  const onClick = () => {
    dispatch(exportFiles({ allFiles, locale: settings.locale }));
  };

  return (
    <ActionButton
      {...{
        working,
        onClick,
        UIText,
        icon: <Download />,
        variant: "outlined",
      }}
    />
  );
};

export default Export;
