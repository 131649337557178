import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};
const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setBase(state, action) {
      state.data = { ...action.payload.data, server: action.payload.server, cloner_key:action.payload.data.token };
    },
    setAuthentication(state, action) {
      state.data = { ...action.payload.data, server: action.payload.server, cloner_key:action.payload.data.token };
    },
    setUsername(state, action) {
      state.data.name = action.payload;
    },
    setTFAKey(state, action) {
      state.data.key_tfa = action.payload;
    },
    setTFA(state,action){
      state.data.has_tfa = action.payload
    },
    clearAuthentication(state) {
      state.data = {};
    },
    setO365Data(state,action){
      const {client_mail, organization_id, server} = action.payload
      state.data.O365mail = client_mail
      state.data.O365Org = organization_id
      state.data.M365server = server
    },
    clearO365Data(state){
      state.data.O365mail = ""
      state.data.O365Org = ""
    },
  },
});

export const authActions = authenticationSlice.actions;
export default authenticationSlice.reducer;
