import { Grid2, Typography } from "@mui/material";
import BasicContainer from "../UI/BasicLayout/BasicContainer";
import BasicHeader from "../UI/BasicLayout/Components/Header";
import { useEffect } from "react";
import Save from "./Components/Save";
import Back from "./Components/Back";
import TFA, { tfaSwitch } from "./Components/TFA";
import Password from "./Components/Password";
import User, { userUI, usernameUI } from "./Components/User";
import useUIBp from "../../Hooks/useUIBoilerplate";
import { UIactions } from "../../store/slices/UI";
import BasicFooter from "../UI/BasicLayout/Components/BasicFooter";

const UIText = {
  "es-CL": {
    header: "Configuración",
    st1: "Usuario",
    st2: "Cambiar contraseña",
    st3: "Seguridad",
  },
  "en-US": {
    header: "Settings",
    st1: "User",
    st2: "Change password",
    st3: "Security",
  },
};

const Settings = () => {
  const { auth, dispatch, settings } = useUIBp();
  const { header, st1, st2, st3 } = UIText[settings.locale];

  useEffect(() => {
    dispatch(UIactions.setField({ id: userUI, value: auth.user }));
    dispatch(UIactions.setField({ id: usernameUI, value: auth.name }));
    if (auth.has_tfa === 2) {
      dispatch(UIactions.setValue({ id: tfaSwitch, value: true }));
    }
  }, [dispatch, auth]);

  return (
    <BasicContainer {...{ size: { xl: 6, lg: 6, md: 8, sm: 10, xs: 12 } }}>
      <BasicHeader>{header}</BasicHeader>
      <Grid2 container {...{ size:12, padding: 2, rowGap: 2 }}>
        <Grid2 size={12}>
          <Typography fontSize={18}>{st1}</Typography>
        </Grid2>
        <User />
        <Grid2 size={12}>
          <Typography fontSize={18}>{st2}</Typography>
        </Grid2>
        <Password />
        <Grid2 size={12}>
          <Typography fontSize={18}>{st3}</Typography>
        </Grid2>
        <TFA />
      </Grid2>
      <BasicFooter noPagination>
        <Back />
        <Save />
      </BasicFooter>
    </BasicContainer>
  );
};

export default Settings;
