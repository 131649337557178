import { useEffect } from "react";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { deleteSharedLinksId } from "../Actions/deleteSharedLinks";
import { UIactions } from "../../../store/slices/UI";
import { workerActions } from "../../../store/slices/workers";
import { getSharedFilesId } from "../Actions/getSharedFiles";
import { defaultSelected } from "../../UI/GeneralPurpose/genericIDs";
import { useWorker } from "../../../Hooks/useWorker";
import DialogConfirmation from "../../Dialogs/Confirmations/AllPurpose/DialogConfirmation";

const DeleteSharedLinks = () => {
  const { dispatch, navigate, settings, UI } = useUIBp();
  const { done, error } = useWorker(deleteSharedLinksId);
  const locale = settings.locale;
  const { payload } = UI.modal ?? {};
  const { links = [] } = payload;

  const UIText = {
    "es-CL": {
      header:
        links.length > 1
          ? `¿Esta seguro que desea eliminar estos ${links.length} enlaces de descarga?`
          : `¿Esta seguro que desea eliminar el enlace de descarga para el archivo "${links[0].name}"?`,
      message: `Importante: Ten en cuenta que esta operación es definitiva e irreversible. Al confirmar, ${
        links.length > 1
          ? `los enlaces de descarga seran eliminados`
          : `el enlace de descarga será eliminado`
      }`,
    },
    "en-US": {
      header:
        links.length > 1
          ? `Are you sure you want delete these ${links.length} download links?`
          : `Are you sure you want delete the download link for the file "${links[0].name}"?`,
      message: `Important: Beware that this operation is definitive and irreversible. Once confirmed, the link${
        links.length > 1 ? "s" : ""
      } will be deleted`,
    },
  };
  const { header, message } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
      dispatch(UIactions.clear(defaultSelected));
      dispatch(workerActions.clear(getSharedFilesId));
    }
  }, [dispatch, navigate, done, error]);

  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(deleteSharedLinksId));
    };
  }, [dispatch]);

  const confirmation = () => {
    let toBeDeleted = [...links];
    dispatch(
      UIactions.openModal2({
        type: "delete-shared-links-confirmation",
        payload: { ids: toBeDeleted },
      })
    );
  };

  return (
    <DialogConfirmation
      alternativeText
      {...{
        header,
        message,
        severity: "warning",
        confirmation,
        workerId: deleteSharedLinksId,
      }}
    />
  );
};

export default DeleteSharedLinks;
