import ExpandedIcon from "../../../../../../../utility/misc/expandedIcon";
import loadRepositoryNode from "../../../Actions/loadRepositoryNode";
import { tunnelTokenId } from "../../../Actions/getMachineStatus";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { treesActions } from "../../../../../../../store/slices/trees";
import IconButton from "../../../../../../UI/GeneralPurpose/IconButton";

const UIText = {
  universal: {},
};

const ExpandButton = (props) => {
  const { auth, dispatch, UI } = useUIBp();
  const {
    id,
    fullPath,
    treeId,
    idx,
    loaded,
    expanded,
    isDir,
    hasChildren,
    level,
    working,
    status,
  } = props;
  const tunnelToken = UI[tunnelTokenId];

  const expandIcon = ExpandedIcon(
    loaded,
    expanded,
    isDir,
    hasChildren, 
    status,
  );

  const handleExpand = () => {
    if (loaded) {
      dispatch(
        treesActions.toggleExpand({
          id: treeId,
          idx,
        })
      );
    } else {
      dispatch(
        loadRepositoryNode({
          id: level ? id : 'root'+treeId,
          root: treeId,
          idx,
          server: auth.server,
          tunnelToken,
          path: fullPath,
        })
      );
    }
  };


  return (
    <IconButton
      {...{
        UIText,
        disabled: working || (loaded && !hasChildren) || !isDir || !!status,
        onClick: handleExpand,
        icon: expandIcon,
        sx: { padding: 0.3 },
        size: "auto",
      }}
    />
  );
};

export default ExpandButton;
