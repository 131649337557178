import { useMemo } from "react";
import { m365orgsData } from "../../../../../../../store/actions/microsoft365/getOrganizations";
import { mailLocksId } from "../../UsersList/UserRow/M365mail";
import { arrayId } from "../../UsersList/UsersList";
import { postClientsId } from "../../../../../../../store/actions/microsoft365/postClients";
import { deleteClientsId } from "../../../../../../../store/actions/microsoft365/deleteClients";
import suggestM365Mails, { suggestMailId } from "../../../../../../../store/actions/microsoft365/suggestM365Mails";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { validateMailId } from "../../../../../../../store/actions/microsoft365/validateM365Mails";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import Button from "../../../../../../UI/GeneralPurpose/Button";

const UITextSuggest = {
  "es-CL": {
    label: "Sugerir correo",
    fixedTooltip:
      "Sugiere un correo para Windows 365 valido, utilizando el usuario como referencia",
  },
  "en-US": {
    label: "Suggest mail",
    fixedTooltip:
      "Suggest a valid Windows 365 email, using the user as reference",
  },
};

export const newUsersUI = "newUsers";

const SuggestMail = ({ users }) => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBp();
  const { working } = useWorker(suggestMailId);
  const { working: w1 } = useWorker(postClientsId);
  const { working: w2 } = useWorker(validateMailId);
  const { working: w3 } = useWorker(deleteClientsId);
  const { cloner_key, M365server } = auth;
  const { locale } = settings;
  const selectedUsers = useMemo(()=>UI[arrayId] ?? [],[UI]);
  const lockedMails = useMemo(()=>UI[mailLocksId] ?? [],[UI]);

  let payload = useMemo(() => {
    let tryouts = [];

    for (const user of selectedUsers) {
      let skipUser = false;
      for (const locked of lockedMails) {
        if (locked === user) {
          skipUser = true;
          break;
        }
      }
      if (skipUser) continue;

      for (const user2 of users) {
        if (user2.login === user) {
          tryouts.push({
            login: user2.login,
            org: warehouse[m365orgsData][user2.group],
          });
        }
      }
    }
    return tryouts;
  }, [lockedMails, selectedUsers, users, warehouse]);

  const handleSuggest = (e) => {
    dispatch(
      suggestM365Mails({ tryouts: payload, cloner_key, M365server, locale })
    );
  };

  return (
    <Button
      {...{
        disabled: selectedUsers.length === 0 || payload.length === 0 || w1 || w2 || w3,
        UIText: UITextSuggest,
        onClick: handleSuggest,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
      }}
    />
  );
};

export default SuggestMail;
