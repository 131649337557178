import axios from "axios";
import { getTunnelData } from "./getTunnelData";
import { extractUsers } from "./utils";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

export const usersData = "users";

const getUsersRecursive = ({ id, controller, server, cloner_key, group }) => {
  console.log("fetching group data2!");
  return async (dispatch) => {
    dispatch(workerActions.start(id));
    const tunnelsController = new AbortController();
    try {
      const url = server + "api/groups_recursive";
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
        params: { group },
      });

      const users = extractUsers(response.data);
      dispatch(warehouseActions.load({ id: usersData, data: users }));

      for (const user of users) {
        dispatch(
          getTunnelData({
            controller: tunnelsController,
            server,
            cloner_key,
            user: user.login,
          })
        );
      }
      dispatch(workerActions.finish({ id }));
    } catch (error) {
      dispatch(workerActions.finish({ id, error: error.message }));
      tunnelsController.abort();
    }
  };
};

export default getUsersRecursive;
