import { Grid2 } from "@mui/material";
import UserPicker from "./Components/UserPicker";
import DevicePicker from "./Components/DevicePicker";
import GoToGroup from "./Components/GoToGroup";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import Locale from "../Buttons/Locale";
import HelpMode from "../Buttons/HelpMode";
import UserOptions from "../Buttons/UserOptions";
import { viewmodeUI } from "../../../../../App";

const FilesTop = ({ height }) => {
  const { auth, UI } = useUIBp();
  const barHeight = (height * 8) / 100;
  const viewmode = UI[viewmodeUI] ?? "desktop";

  return (
    <Grid2
      container
      {...{
        bgcolor: "white",
        height: barHeight,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 3,
        paddingRight: 3,
        size: 12,
        borderBottom: "1px lightgrey solid",
      }}
    >
      <Grid2 container {...{ columnGap: 2, size: "grow" }}>
        <UserPicker />
        <DevicePicker />
        {auth.admin === 1 ? <GoToGroup /> : null} 
      </Grid2>
      <Grid2 container {...{ alignItems: "center", size: "auto" }}>
        {viewmode === "mobile" ? null : <Locale />}
        {viewmode === "mobile" ? null : <HelpMode />}
        <UserOptions />
      </Grid2>
    </Grid2>
  );
};

export default FilesTop;
