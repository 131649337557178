import { Grid2, Typography } from "@mui/material";
import { Fragment } from "react";
import { BoxStateIcon, ExpandIcon } from "./utils";

const TreeNode = ({ tree, idx, level }) => {

  const { name, childrenIdx, backup } = tree[idx];

  let icon = BoxStateIcon(backup);
  let expandIcon = ExpandIcon(!!childrenIdx.length);

  const children = childrenIdx.map((idx) => (
    <TreeNode key={idx} {...{ tree, idx, level: level + 1 }} />
  ));

  return (
    <Fragment>
      <Grid2
        container
        {...{
          size: 12,
          wrap: "nowrap",
          paddingLeft: level * 3.5,
          sx: { backgroundColor: `rgba(39, 181, 245, ${level * 0.05})` },
          alignItems: "center",
        }}
      >
        {icon}
        {expandIcon}
        <Typography noWrap fontSize={14}>
          {name}
        </Typography>
      </Grid2>
      {children}
    </Fragment>
  );
};

export default TreeNode;
