import { Dialog } from "@mui/material";
import { useEffect } from "react";
import User, { userUI } from "./Components/User";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import Buttons from "./Components/Buttons/Buttons";
import {
  bkpDriveSwitchId,
  bkpMailSwitchId,
  m365MailId,
  m365setup,
  mailLockId,
} from "./Components/M365/M365Userform";
import {
  getPlatformAndServicesId,
  platformServices,
} from "../../../../store/actions/microsoft365/getPlatformsAndServices";
import { bkpDriveSizeId } from "../UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { bkpMailCountId } from "../UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { m365validated } from "./Components/Buttons/Save";
import { postClientId } from "../../../../store/actions/microsoft365/postClient";
import { validateMailId } from "../../../../store/actions/microsoft365/validateM365Mails";
import { suggestMailId } from "../../../../store/actions/microsoft365/suggestM365Mails";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";
import { workerActions } from "../../../../store/slices/workers";
import { postUserId } from "../../../../store/actions/users/postUsers";
import M365, { m365Switch } from "./Components/M365/M365";
import { getM365OrgId, m365orgData } from "../../../../store/actions/microsoft365/getOrganization";
import {
  getM365ClientId,
  m365clientData,
} from "../../../../store/actions/microsoft365/getClient";
import { warehouseActions } from "../../../../store/slices/warehouse";
import M365Userform from "./Components/M365/M365Userform";
import { getM365ClientsSizeId } from "../../../../store/actions/microsoft365/getClientsSize";

const UIText = {
  "es-CL": {
    header: "Integraciones Cloud",
  },
  "en-US": {
    header: "Cloud Integrations",
  },
};

const UserCloudIntegrations = (props) => {
  const { isNew } = props;
  const { dispatch, settings, UI } = useUIBp();
  const locale = settings.locale;
  const { payload } = UI.modal;
  const { users = [] } = payload;

  useEffect(() => {
    if (users.length === 1) {
      const userData = users[0];
      if (userData.login) {
        dispatch(UIactions.setField({ id: userUI, value: userData.login }));
      }
    }
  }, [dispatch, users]);

  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(postUserId));
      dispatch(UIactions.clear(userUI));
      dispatch(UIactions.clear(bkpMailSwitchId));
      dispatch(UIactions.clear(bkpDriveSwitchId));
      dispatch(UIactions.clear(m365Switch));
      dispatch(UIactions.clear(m365MailId));
      dispatch(UIactions.clear(mailLockId));
      dispatch(UIactions.clear(bkpDriveSizeId + users[0].login));
      dispatch(UIactions.clear(bkpMailCountId + users[0].login));
      dispatch(UIactions.clear(m365MailId + "-" + users[0].login));
      dispatch(UIactions.clear(m365validated));
      dispatch(workerActions.clear(m365setup));
      dispatch(workerActions.clear(getM365OrgId));
      dispatch(workerActions.clear(getM365ClientId));
      dispatch(workerActions.clear(getPlatformAndServicesId));
      dispatch(workerActions.clear(postClientId));
      dispatch(workerActions.clear(validateMailId));
      dispatch(workerActions.clear(suggestMailId));
      dispatch(workerActions.clear(getM365ClientsSizeId));
      dispatch(warehouseActions.unload(m365clientData));
      dispatch(warehouseActions.unload(platformServices));
      dispatch(warehouseActions.unload(m365orgData));
    };
  }, [dispatch, users]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  let header = UIText[locale].header;


  return (
    <Dialog {...{ open: true, onClose }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <User {...props} />
        <M365 {...props} user={users[0]} />
        <M365Userform {...props} />
      </BasicDialogContent>
      <Buttons {...{ isNew, users }} />
    </Dialog>
  );
};

export default UserCloudIntegrations;
