import { Dialog } from "@mui/material";
import Content from "./Components/Content/Content";
import { useEffect } from "react";
import { postSupportId } from "../Actions/postSupportTicket";
import Buttons from "./Components/Buttons/Buttons";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../Hooks/useWorker";
import { UIactions } from "../../../store/slices/UI";
import { workerActions } from "../../../store/slices/workers";
import BasicDialogTitle from "../../UI/BasicDialog/BasicDialogTitle";

const UIText = {
  "es-CL": { header: "Contactar a soporte" },
  "en-US": { header: "Contact support" },
};

const ContactSupport = () => {
  const { dispatch, settings } = useUIBp();
  const { done, error } = useWorker(postSupportId);
  const { header } = UIText[settings.locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
    }
  }, [dispatch, done, error]);

  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(postSupportId));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <Content />
      <Buttons />
    </Dialog>
  );
};

export default ContactSupport;
