import { Dialog, Grid2 } from "@mui/material";
import { useCallback, useEffect } from "react";
import SelectDisplay from "./Components/SelectDisplay/SelectDisplay";
import TopBar from "./Components/TopBar/TopBar";
import { rootsDataId, tunnelTokenId } from "./Actions/getMachineStatus";
import Blocked from "./Components/Blocked/Blocked";
import Close from "./Components/Close";
import { passFieldId } from "./Components/Blocked/PassField";
import Submit from "./Components/Submit";
import { postGreylistsId } from "./Actions/postGreylists";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { devicePickerId } from "../../../UI/Specialized/DevicePicker";
import getDeviceConfig, {
  configDataId,
  getConfigId,
} from "../../../../store/actions/devices/getConfig";
import { UIactions } from "../../../../store/slices/UI";
import { treesActions } from "../../../../store/slices/trees";
import { warehouseActions } from "../../../../store/slices/warehouse";
import { workerActions } from "../../../../store/slices/workers";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import { groupsTreeId } from "../../../GroupsTraveler/Actions/loadGroupsTree";
import { useCalendarState } from "@mui/x-date-pickers/internals";

const UIText = {
  "es-CL": { header: "Selección remota" },
  "en-US": { header: "Remote selection" },
};

export const selectionBlockUI = "selectionBlock";

const RemoteSelection = () => {
  const { auth, dispatch, UI, warehouse, settings } = useUIBp();
  const { done } = warehouse[getConfigId] ?? {};
  const { payload } = UI.modal;
  const { user } = payload ?? {};
  const devicePicker = UI[devicePickerId] ?? { mid: "" };
  const config = warehouse[configDataId] ?? { config_hash: "", cpassword: "" };
  const { mid } = devicePicker;
  const { header } = UIText[settings.locale];

  console.log("RemoteSelection.js: user", user);

  useEffect(() => {
    const controller = new AbortController();
    if (mid & !done) {
      dispatch(
        getDeviceConfig({
          ...auth,
          controller,
          payload: { user, machine: mid, token: auth.cloner_key },
        })
      );
    }
    // return () => {
    //   controller.abort();
    // };
  }, [dispatch, auth, user, mid, done]);

  useEffect(() => {
    if (mid && config.config_hash) {
      const value = !!config.cpassword;
      dispatch(UIactions.setValue({ id: selectionBlockUI, value }));
    }
  }, [dispatch, mid, config.config_hash, config.cpassword]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(selectionBlockUI));
      dispatch(UIactions.clear(tunnelTokenId));
      dispatch(UIactions.clear(passFieldId));
      dispatch(treesActions.clearForestWithException(groupsTreeId));
      dispatch(warehouseActions.unload(configDataId));
      dispatch(warehouseActions.unload(rootsDataId));
      dispatch(workerActions.clear(getConfigId));
      dispatch(workerActions.clear(tunnelTokenId));
      dispatch(workerActions.clear(postGreylistsId));
      dispatch(workerActions.clearGroup("root"));
      // controller.abort();
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2 container {...{ paddingTop: 0.5, sx: { width: "100%" } }}>
          <TopBar {...{ user }} />
          <Grid2 container size={12}>
            <Blocked />
            <SelectDisplay {...{ user }} />
          </Grid2>
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        <Submit />
      </BasicDialogActions>
    </Dialog>
  );
};

export default RemoteSelection;
