import bcrypt from "bcryptjs-react";
import { selectionBlockUI } from "../RemoteSelection";
import { enqueueSnackbar } from "notistack";
import { UIactions } from "../../../../../store/slices/UI";

const UIText = {
  "es-CL": { error: "La contraseña de selección es incorrecta" },
  "en-US": { error: "The selection password is wrong" },
};

export const validateSelectionPassword = ({ password, cpassword, locale }) => {
  // console.log("Checking for password equality!");
  // console.log('check:',data)
  const { error } = UIText[locale];
  return async (dispatch) => {
    const isEqual = await bcrypt.compare(password, cpassword);
    // console.log('check?',isEqual)
    if (isEqual) {
      dispatch(UIactions.setValue({ id: selectionBlockUI, value: false }));
    } else {
      enqueueSnackbar(error,{variant:'error'});
    }
  };
};
