import {
  Checkbox,
  FormControl,
  Grid2,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import AutoHelp from "../Wrappers/AutoHelp";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";

const MultiSelect = (props) => {
  const {
    id,
    UIText,
    options = [],
    componentSize = "small",
    required,
    size = 12,
    disabled,
    onChange = () => {},
    fontSize = 14,
  } = props;
  const { dispatch, settings, UI } = useUIBp();
  const locale = settings.locale;
  const filteredProps = filterObjKeys(
    props,
    "UIText",
    "componentSize",
    "size",
    "required",
    "defaultIdx",
    "onChange",
    "fontSize"
  );
  const { label, tooltip, fixedTooltip } = UIText[locale] ?? UIText.universal;
  const value = UI[id] ?? [];

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(UIactions.setValue({ id, value }));
    onChange(e);
  };

  const optionsItems = options.map((data) => {
    return (
      <MenuItem key={data.value} value={data.value}>
        <Checkbox checked={value.indexOf(data.value) > -1} size="small" />
        <ListItemText
          primary={data.name[locale] ?? data.name.universal}
          sx={{ fontSize }}
        />
      </MenuItem>
    );
  });

  const renderValue = () => {
    let out = [];
    for (const val of value) {
      // We don't want to show the "access" permission for now. This should be removed in the future
      if (id === "userPermissions" && val === "access") {
        continue;
      }
      console.log("val", val);
      const x = options.find((opt) => val === opt.value);
      out.push(x.name[locale] ?? x.name.universal);
    }
    return out.join(", ");
  };

  return (
    <Grid2 container size={size}>
      <AutoHelp {...{ tooltip, fixedTooltip, disabled }}>
        <FormControl
          fullWidth
          {...{
            size: componentSize,
            required,
          }}
        >
          <InputLabel sx={{ fontSize }}>{label}</InputLabel>
          <Select
            fullWidth
            multiple
            {...filteredProps}
            {...{
              onChange: handleChange,
              MenuProps: { PaperProps: { sx: { maxHeight: 200 } } },
              label,
              value,
              renderValue,
              sx: { fontSize },
            }}
          >
            {optionsItems}
          </Select>
        </FormControl>
      </AutoHelp>

    </Grid2>
  );
};

export default MultiSelect;
