import { Cloud } from "@mui/icons-material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import { UIactions } from "../../../../../../store/slices/UI";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";
import { usersDataId } from "../../../../../../store/actions/users/getAllUsers";

const UIText = {
  "es-CL": { label: "Cloud" },
  "en-US": { label: "Cloud" },
};

const CloudIntegrations = () => {
  const {  dispatch, UI, warehouse } = useUIBp();
  const { group } = useURLQuery();
  const usersData = warehouse[usersDataId]??[]
  const selected = UI.selected ?? [];

  let selectedUsers = []

  for (const user of selected){
    const userData = usersData.find(x=> x.login === user)
    selectedUsers.push(userData)
  }

  const onClick = () => {
    console.log("selected", selectedUsers)
    dispatch(
      UIactions.openModal({
        type: "multi-cloud-integrations",
        payload: { group, users: selectedUsers },
      })
    );
  };

  return (
    <ActionButton
      {...{ disabled: !selected.length, onClick, UIText, icon: <Cloud /> }}
    />
  );
};

export default CloudIntegrations;
