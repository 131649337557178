import { Laptop } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  Grid2,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { sortObjByKey } from "../../../utility/misc/sortObjByKey";
import AutoHelp from "../Wrappers/AutoHelp";
import { AutocompleteFilter } from "./utils";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../Hooks/useWorker";
import getDevices, {
  devicesDataId,
  getDevicesId,
} from "../../../store/actions/devices/getDevices";
import { UIactions } from "../../../store/slices/UI";
import { warehouseActions } from "../../../store/slices/warehouse";
import { workerActions } from "../../../store/slices/workers";

const UIText = {
  "es-CL": {
    normalTxt: "Dispositivos",
    loadingTxt: "Cargando...",
    errorTxt: "Error al obtener dispositivos",
    emptyTxt: "No se encontró dispositivos",
  },
  "en-US": {
    normalTxt: "Devices",
    loadingTxt: "Loading...",
    errorTxt: "Error loading devices",
    emptyTxt: "No devices found",
  },
};

export const devicePickerId = "devicePicker";

const DevicePicker = (props) => {
  const {
    onChange,
    onLoaded,
    onDismount,
    user = "",
    autoload,
    componentSize = "small",
    size = 12,
    disabled,
    whitelist = [], // must be used in combination with useWhitelist
    useWhitelist,
    clearOnUnmount,
  } = props;
  const { auth, dispatch, UI, warehouse, settings } = useUIBp();
  const { done, working, error } = useWorker(getDevicesId);
  const { normalTxt, loadingTxt, errorTxt, emptyTxt, tooltip } =
    UIText[settings.locale];
  const { devicePicker = { mid: "" } } = UI;
  const { mid } = devicePicker;

  const options = useMemo(() => {
    const devices = warehouse[devicesDataId] ?? [];
    if (devices.length <= 0) {
      return [];
    }
    let out = [...devices]
      .filter((a) => !useWhitelist || !!whitelist.find((b) => a.mid === b))
      .sort((a, b) => sortObjByKey(a, b, "name"))
      .map((data) => {
        return { label: data.name, mid: data.mid };
      });
    return out;
  }, [warehouse, useWhitelist, whitelist]);

  let label = normalTxt;
  if (done && !error && !options.length) {
    label = emptyTxt;
  } else if (done && error) {
    label = errorTxt;
  } else if (working) {
    label = loadingTxt;
  }

  // Load data
  useEffect(() => {
    // console.log("getDevice useEffect triggered", auth,user,done,autoload)
    const controller = new AbortController();
    if (user && !done && autoload) {
      dispatch(getDevices({ controller, ...auth, user }));
    }
    // return () => {
    //   if (!done) {
    //     controller.abort();
    //   }
    // };
  }, [dispatch, auth, user, done, autoload]);

  // Fill blank
  useEffect(() => {
    if (!!options.length && done && !mid) {
      if (onLoaded) {
        onLoaded(options);
      } else {
        dispatch(UIactions.setValue({ id: devicePickerId, value: options[0] }));
      }
    }
  }, [dispatch, mid, options, done, onLoaded]);

  // Cleanup
  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        dispatch(warehouseActions.unload(devicesDataId));
        dispatch(UIactions.clear(devicePickerId));
        dispatch(workerActions.clear(getDevicesId));
        if (onDismount) {
          onDismount();
        }
      }
    };
  }, [dispatch, onDismount, clearOnUnmount]);

  const handleChange = (e, value) => {
    dispatch(UIactions.setValue({ id: devicePickerId, value }));
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Grid2 {...{ size }}>
      <AutoHelp {...{ tooltip, disabled: disabled || working }}>
        <Autocomplete
          blurOnSelect
          disableClearable
          fullWidth
          {...{
            disabled: options.length > 1 ? false : true,
            value: mid ? devicePicker : null,
            filterOptions: AutocompleteFilter,
            onChange: handleChange,
            options,
            size: componentSize,
            loading: working,
            renderOption: (props, option) => {
              return (
                <li {...props} key={option.mid}>
                  {option.label}
                </li>
              );
            },
            renderInput: (params) => {
              params.InputProps.startAdornment = (
                <InputAdornment position="start">
                  <Laptop />
                </InputAdornment>
              );
              if (working) {
                params.InputProps.endAdornment = (
                  <InputAdornment position="end">
                    <CircularProgress size={14} />
                  </InputAdornment>
                );
              }
              return <TextField {...params} label={label} />;
            },
            isOptionEqualToValue: (option, value) => option.mid === value.mid,
          }}
        />
      </AutoHelp>
    </Grid2>
  );
};

export default DevicePicker;
