import { Grid2 } from "@mui/material";
import TTChip from "./TTChip";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": { from: "De: ", to: "Para: " },
  "en-US": { from: "From: ", to: "To: " },
};

const FromTo = ({ fromM, toRecipients }) => {
  const { settings } = useUIBp();
  const { from, to } = UIText[settings.locale];

  const fromData = JSON.parse(fromM);
  const toData = JSON.parse(toRecipients);
  const toArray = toData.map((data, idx) => (
    <TTChip {...{ key: idx, data: data.emailAddress }} />
  ));

  return (
    <Grid2
      container
      {...{
        rowGap: 1,
        padding: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderTop: "1px solid lightgrey",
        borderBottom: "1px solid lightgrey",
      }}
    >
      <Grid2
        container
        {...{
          size: 12,
          columnGap: 1,
          rowGap: 0.5,
          alignItems: "center",
        }}
      >
        {from}
        <TTChip {...{ data: fromData.emailAddress }} />
      </Grid2>
      <Grid2
        container
        {...{
          size: 12,
          columnGap: 1,
          rowGap: 0.5,
          alignItems: "center",
        }}
      >
        {to}
        {toArray}
      </Grid2>
    </Grid2>
  );
};

export default FromTo;
