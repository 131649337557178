import { Grid2, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  AdminPanelSettings,
  Delete,
  Email,
  Error,
} from "@mui/icons-material";
import { newUsersUI } from "../TopBar/UploadSheet";
import notEmptyUser from "../../../../../../utility/validations/notEmptyUser";
import { notEmptyName } from "../../../../../../utility/validations/notEmptyName";
import notEmptyPassword from "../../../../../../utility/validations/notEmptyPassword";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect } from "react";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../store/slices/UI";
import AutoHelp from "../../../../../UI/Wrappers/AutoHelp";
import IconButton from "../../../../../UI/GeneralPurpose/IconButton";
import BasicRow from "../../../../../UI/BasicLayout/Components/BasicRow";
import compliantEmail from "../../../../../../utility/validations/compliantEmail";

const UIText = {
  "es-CL": { fixedTooltip: "Remover usuario" },
  "en-US": { fixedTooltip: "Remove user" },
};

const UITextAdmin = {
  "es-CL": { fixedTooltip: "Administrador" },
  "en-US": { fixedTooltip: "Administrator" },
};
const UITextMails = {
  "es-CL": { fixedTooltip: "Recibe correos" },
  "en-US": { fixedTooltip: "Receives mails" },
};
const UITextDuplicate = {
  "es-CL": "El usuario debe ser unico y se encuentra duplicado",
  "en-US": "The user must be unique and has a duplicate",
};

export const rowErrorsUI = "rowErrors";

const UserRow = ({ id, mail, name, pass, admin, mails }) => {
  const { dispatch, settings, UI } = useUIBp();
  const users = UI[newUsersUI] ?? [];
  const locale = settings.locale;

  const onClick = () => {
    dispatch(UIactions.removeObj({ id: newUsersUI, key: id }));
    dispatch(UIactions.remove({ id: rowErrorsUI, key: id }));
  };

  let errors = [];
  const duplicate = users.find((x) => x.mail === mail && x.id !== id);
  if (duplicate) {
    errors.push(UITextDuplicate[locale]);
  }

  const mailError2 = compliantEmail(mail, locale);
  if (mailError2) {
    errors.push(mailError2);
  }


  const mailError = notEmptyUser(mail, locale);
  if (mailError) {
    errors.push(mailError);
  }
  const nameError = notEmptyName(name, locale);
  if (nameError) {
    errors.push(nameError);
  }
  const passError = notEmptyPassword(pass, locale);
  if (passError) {
    errors.push(passError);
  }

  const error = !!errors.length;

  useEffect(() => {
    if (error) {
      dispatch(UIactions.add({ id: rowErrorsUI, key: id }));
    } else {
      dispatch(UIactions.remove({ id: rowErrorsUI, key: id }));
    }
  }, [dispatch, error, id]);

  const selectedColor = error ? "error" : "primary";
  const errorTTP = errors.map((x, idx) => <li key={idx}>{x}</li>);

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({ [`& .${tooltipClasses.tooltip}`]: { maxWidth: "none" } });

  const rowData = {
    error,
    elements: [
      {
        content: (
          <Grid2 container {...{size:12, alignItems:"center"}}>
            {error ? (
              <Grid2 container {...{size:1}}>
                <CustomWidthTooltip
                  placement="bottom-end"
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      <ul style={{ paddingLeft: 15 }}>{errorTTP}</ul>
                    </div>
                  }
                >
                  <Error color="error" sx={{fontSize:18}} />
                </CustomWidthTooltip>
              </Grid2>
            ) : null}
            <Grid2 container {...{ size:error ? 11 : 12}}>
              <Typography noWrap {...{ fontSize: 14 }}>
                {mail}
              </Typography>
            </Grid2>
          </Grid2>
        ),
        size: 3,
      },
      {
        content: (
          <Typography noWrap {...{ fontSize: 14 }}>
            {name}
          </Typography>
        ),
        size: 3,
      },
      {
        content: (
          <Grid2 container columnGap={2}>
            <AutoHelp {...{ fixedTooltip: UITextAdmin[locale].fixedTooltip }}>
              <AdminPanelSettings color={admin ? selectedColor : "disabled"} />
            </AutoHelp>
            <AutoHelp {...{ fixedTooltip: UITextMails[locale].fixedTooltip }}>
              <Email color={mails ? selectedColor : "disabled"} />
            </AutoHelp>
          </Grid2>
        ),
        size: 3,
      },
      {
        content: (
          <Typography noWrap {...{ fontSize: 14 }}>
            {pass}
          </Typography>
        ),
        size: "grow",
      },
      {
        content: (
          <IconButton
            {...{
              onClick,
              UIText,
              icon: <Delete {...{ sx: { fontSize: 18 } }} />,
            }}
          />
        ),
        justify: "end",
        size: 0.5,
      },
    ],
  };
  return <BasicRow {...rowData} />;
};

export default UserRow;
