import { Grid2 } from "@mui/material";
// import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import Switch from "../../../../../UI/GeneralPurpose/Switch";

const UIText = {
  "es-CL": { label: "Mostrar archivos eliminados" },
  "en-US": { label: "Show deleted files" },
};

export const showDeletedUI = "showDeleted";

const ShowDeleted = () => {
  // const { settings } = useUIBp();

  // const { label } = UIText[settings.locale] ?? {};

  return (
    <Grid2
      container
      {...{
        size: "auto",
        alignItems: "center",
        justifySelf: "start",
      }}
    >
      <Switch {...{ id: showDeletedUI, UIText, size: "auto" }} />
      {/* <Grid2 container {...{ size: "auto" }}>
        <Typography>{label}</Typography>
      </Grid2> */}
    </Grid2>
  );
};

export default ShowDeleted;
