import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import ToggleIcon from "../GeneralPurpose/ToggleIcon";

const options = [
  {
    icon: <VisibilityOff sx={{ fontSize: "1rem" }} />,
    UIText: {
      "es-CL": { fixedTooltip: "Mostrar contraseña", },
      "en-US": { fixedTooltip: "Show password", },
    },
  },
  {
    icon: <Visibility sx={{ fontSize: "1rem" }} />,
    UIText: {
      "es-CL": { fixedTooltip: "Ocultar contraseña", },
      "en-US": { fixedTooltip: "Hide password", },
    },
  },
];

const VisibilityInsert = ({ id, disabled, position = "end" }) => {
  return (
    <InputAdornment {...{ position }}>
      <ToggleIcon {...{ id: id + "-show", options, disabled }} />
    </InputAdornment>
  );
};

export default VisibilityInsert;
