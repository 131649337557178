import useUIBp from "../../../Hooks/useUIBoilerplate";
import deleteUsers from "../../../store/actions/users/deleteUsers";
import DeleteConfirmation from "./Deletions/DeleteConfirmation";

const DeleteUsersConfirmation = () => {
  const { auth, dispatch, settings, UI } = useUIBp();
  const locale = settings.locale;
  const { payload } = UI.modal2;
  const { users } = payload;

  const confirmation = () => {
    dispatch(deleteUsers({ ...auth, users, locale }));
  };

  return <DeleteConfirmation {...{ confirmation }} />;
};

export default DeleteUsersConfirmation;
