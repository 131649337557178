import { useDispatch } from "react-redux";
import Button from "../../../UI/GeneralPurpose/Button";
import { UIactions } from "../../../../store/slices/UI";

const UIText = {
  "es-CL": { label: "Volver a version anterior" },
  "en-US": { label: "Return to previous version" },
};

const LegacyBtn = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.openModal({ type: "" }));
  };

  return <Button {...{ UIText, onClick }} />;
};

export default LegacyBtn;
