import { Delete } from "@mui/icons-material";
import { devicesData } from "../../Devices";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../store/slices/UI";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";

const UIText = {
  "es-CL": { label: "Eliminar" },
  "en-US": { label: "Delete" },
};

const DeleteBtn = () => {
  const { dispatch, UI, warehouse } = useUIBp();
  const selected = UI.selected ?? [];
  const devices = warehouse[devicesData] ?? {};

  const onClick = () => {
    let selectedDevices = [];
    for (const mid of selected) {
      const device = devices[mid];
      selectedDevices.push(device);
    }

    dispatch(
      UIactions.openModal({
        type: "delete-devices",
        payload: { devices: selectedDevices },
      })
    );
  };

  return (
    <ActionButton
      {...{
        disabled: !selected.length,
        onClick,
        UIText,
        icon: <Delete />,
      }}
    />
  );
};

export default DeleteBtn;
