import { Grid2, Typography } from "@mui/material";
import { Fragment } from "react";

const UIText = {
  "es-CL": { label: "Bloqueo de cambio de selección" },
  "en-US": { label: "Selection change block" },
};

const SelectionBlock = ({ cpassword, locale }) => {
  const { label } = UIText[locale];

  return (
    <Fragment>
      <Grid2 container size={6}>
        <Typography>{label}</Typography>
      </Grid2>
      <Grid2 container size={6}>
        <Typography>{cpassword ? "Activado" : "Desactivado"}</Typography>
      </Grid2>
    </Fragment>
  );
};

export default SelectionBlock;
