import { Replay } from "@mui/icons-material";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Iniciar una nueva solicitud" },
  "en-US": { label: "Start a new request"},
};

const NewRecovery = () => {
  return (
      <Button
        {...{
          UIText,
          navTo: "/dashboard/recovery/step1",
          endIcon: <Replay />,
        }}
      />
  );
};

export default NewRecovery;
