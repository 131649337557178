import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { fixChildrenIdx, parseGroupsListRecursive } from "./utils";
import { reloadUI } from "../GroupsTraveler";
import { workerActions } from "../../../store/slices/workers";
import { treesActions } from "../../../store/slices/trees";
import { UIactions } from "../../../store/slices/UI";

export const getGroupsListId = "groupsList";
export const groupsTreeId = "groups";
export const selectedGroupIdx = "groupIdx";
export const selectedGroupId = "groupId";
export const selectedGroupIdx2 = "groupIdx2";
export const selectedGroupId2 = "groupId2";
export const currentGroupnameId = "currentGroupname";
export const currentGroupId = "currentGroup";

const loadGroupsTree = (data) => {
  console.log("Getting groups list!");
  const { server, group, cloner_key, startGroup, mode } = data;
  const id = getGroupsListId;

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      const response = await axios.get(server + "api/groups_list", {
        params: { group },
        headers: { cloner_key },
      });
      const groupsListData = [{ ...response.data }];

      const tree = parseGroupsListRecursive(groupsListData[0], null, null, 0);
      const fixedTree = fixChildrenIdx(tree);
      dispatch(treesActions.loadTree({ id: groupsTreeId, tree: fixedTree }));

      const endGroup = fixedTree.find(
        (node) => node.id === parseInt(startGroup)
      );

      if (mode === "topbar") {
        dispatch(UIactions.setValue({ id: selectedGroupIdx, value: endGroup.idx }));
        dispatch(UIactions.setValue({ id: selectedGroupId, value: endGroup.id }));
        dispatch(UIactions.setValue({ id: currentGroupnameId, value: endGroup.name }));
        dispatch(UIactions.setValue({ id: currentGroupId, value: endGroup.id }));
      } else if (mode === "modal") {
        dispatch(UIactions.setValue({ id: selectedGroupIdx2, value: endGroup.idx }));
        dispatch(UIactions.setValue({ id: selectedGroupId2, value: endGroup.id }));
      }
      dispatch(UIactions.clear(reloadUI))
      dispatch(workerActions.finish({ id }));
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
};

export default loadGroupsTree;
