import { sortObjByKey } from "../../../../../../../../utility/misc/sortObjByKey";
import { addressData, removeArrayDuplicates } from "./addressUtils";
import { districtSelectUI } from "./District";
import { citySelectUI } from "./City";
import { emptyCityUI, emptyDistrictUI, emptyRegionUI, miscErrorsUI } from "../../../Next";
import useUIBp from "../../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../../store/slices/UI";
import Select from "../../../../../../../UI/GeneralPurpose/Select";

const UIText = {
  "es-CL": { label: "Región" },
  "en-US": { label: "Region" },
};

export const regionSelectUI = "regionSelect";

const Region = () => {
  const { dispatch, UI } = useUIBp();
  const miscErrors = UI[miscErrorsUI] ?? [];
  const rawRegions = addressData.map((x) => ({
    label: x.region,
    value: x.region,
  }));

  const deduplicatedRegions = removeArrayDuplicates(rawRegions);

  const sortedRegions = deduplicatedRegions.sort((a, b) =>
    sortObjByKey(a, b, "label")
  );

  const regions = sortedRegions.map((x) => ({ universal: x, value: x.value }));

  const handleChange = () => {
    dispatch(UIactions.clear(districtSelectUI));
    dispatch(UIactions.clear(citySelectUI));
    dispatch(UIactions.remove({ id: miscErrorsUI, key: emptyRegionUI }));
    dispatch(UIactions.remove({ id: miscErrorsUI, key: emptyDistrictUI }));
    dispatch(UIactions.remove({ id: miscErrorsUI, key: emptyCityUI }));
  };

  const error = !!miscErrors.find((x) => x === emptyRegionUI);

  return (
    <Select
      {...{
        error,
        id: regionSelectUI,
        options: regions,
        UIText,
        onChange: handleChange,
        size:{
        xl: 4,
        lg: 4,
        md: 4,
        sm: 12,
        xs: 12,

        }
      }}
    />
  );
};

export default Region;
