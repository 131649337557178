import { Box, Grid2, Typography } from "@mui/material";
import AutoHelp from "../../../../UI/Wrappers/AutoHelp";
import { AttachFile } from "@mui/icons-material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": { tt: "tiene archivos adjuntos" },
  "en-US": { tt: "has attached files" },
};

const Subject = ({ subject, bodyPreview, hasAttachments }) => {
  const { settings } = useUIBp();
  const fixedTooltip = (subject ? `${subject} - ` : "") + bodyPreview;
  const { tt } = UIText[settings.locale];

  return (
    <Grid2 container {...{ columnSpacing: 1, size: 12, alignItems: "center" }}>
      <AutoHelp {...{ fixedTooltip }}>
        <Grid2 {...{ size: hasAttachments ? 11 : 12 }}>
          <Typography noWrap component="div" fontSize={13}>
            {subject ? (
              <Box display="inline" sx={{ fontWeight: "bold" }}>
                {subject}
                {" - "}
              </Box>
            ) : null}
            {bodyPreview}
          </Typography>
        </Grid2>
      </AutoHelp>
      {hasAttachments ? (
        <Grid2
          container
          {...{ size: 0.5, alignItems: "center", justifyContent: "end" }}
        >
          <AutoHelp {...{ fixedTooltip: tt }}>
            <AttachFile sx={{ fontSize: 20 }} color="primary" />
          </AutoHelp>
        </Grid2>
      ) : null}
    </Grid2>
  );
};

export default Subject;
