import { Dialog } from "@mui/material";
import Cancel from "./Components/Cancel";
import Confirm from "./Components/Confirm";
import { useDispatch } from "react-redux";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import SlimAlert from "../../../UI/GeneralPurpose/SlimAlert";
import { UIactions } from "../../../../store/slices/UI";

const DialogConfirmation = (props) => {
  const {
    header,
    message,
    severity,
    confirmation = () => {
      console.log("no confirmation fn()");
    },
  } = props;
  const dispatch = useDispatch();

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal2());
    }
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <SlimAlert {...{ errors: [message], severity }} />
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel />
        <Confirm {...{ confirmation }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
