import { Divider, Grid2 } from "@mui/material";
import Locale from "../Buttons/Locale";
import HelpMode from "../Buttons/HelpMode";
import UserOptions from "../Buttons/UserOptions";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import EditGroup from "./Buttons/EditGroup";
import GroupsTraveler from "../../../../GroupsTraveler/GroupsTraveler";
import DeleteGroup from "./Buttons/DeleteGroup";
import { viewmodeUI } from "../../../../../App";
import { useLocation } from "react-router-dom";
import CloudBackup from "./Buttons/CloudBackup";

const UsersTop = ({ height }) => {
  const { auth, UI } = useUIBp();
  const viewmode = UI[viewmodeUI] ?? "desktop";
  const location = useLocation() ?? {};
  let isUsers = location.pathname === "/dashboard/users";

  const { currentGroup } = UI;
  const barHeight = (height * 8) / 100;

  return (
    <Grid2
      container
      {...{
        bgcolor: "white",
        height: barHeight,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 3,
        paddingRight: 3,
        borderBottom: "1px lightgrey solid",
        size: 12,
      }}
    >
      <Grid2 container {...{ columnGap: 2, size: "auto" }}>
        <GroupsTraveler {...{ size: "auto" }} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <Grid2 container {...{ columnGap: 1, size: "auto" }}>
          {auth.admin === currentGroup ? null : <EditGroup />}
          {isUsers ? <CloudBackup /> : null}
          <DeleteGroup />
        </Grid2>
      </Grid2>
      <Grid2 container {...{ alignItems: "center", size: "auto" }}>
        {viewmode === "mobile" ? null : <Locale />}
        {viewmode === "mobile" ? null : <HelpMode />}
        <UserOptions />
      </Grid2>
    </Grid2>
  );
};

export default UsersTop;
