import { Grid2 } from "@mui/material";
import clonerWhite from "../../Resources/CLONER.png";

const Logo = () => {
  return (
    <Grid2 container size={12} justifyContent="center">
      <Grid2 size={{ lg: 4, md: 6, sm: 8, xs: 10 }}>
        <img src={`${clonerWhite}`} alt="cloner-logo" width="100%" />
      </Grid2>
    </Grid2>
  );
};

export default Logo;
