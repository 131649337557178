import React, { Fragment, useRef, useState } from "react";
import { Grid2, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import {
  ContactSupport,
  Equalizer,
  HelpOutline,
  Logout,
  Settings,
} from "@mui/icons-material";
import getAvatar from "./getAvatar";
import { viewmodeUI } from "../../../../../App";
import { useLocation } from "react-router-dom";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { authActions } from "../../../../../store/slices/authentication";
import { UIactions } from "../../../../../store/slices/UI";

const UIText = {
  "es-CL": {
    setting: "Preferencias",
    faq: "Preguntas frecuentes",
    support: "Soporte",
    downloads: "Actividades",
    exit: "Salir",
  },
  "en-US": {
    setting: "Settings",
    faq: "FAQ",
    support: "Support",
    downloads: "Activities",
    exit: "Exit",
  },
};

const UserOptions = () => {
  const { auth, dispatch, navigate, settings, UI } = useUIBp();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const location = useLocation() ?? {};
  const viewmode = UI[viewmodeUI];
  const { name = "", user } = auth;
  const { setting, faq, support, downloads, exit } = UIText[settings.locale];
  const isAdmin = auth.group === 1;

  const avatar = getAvatar(name);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    setOpen(false);
    localStorage.clear("remember-session");
    localStorage.clear("persist:auth");
    sessionStorage.clear("persist:auth");
    dispatch(authActions.clearAuthentication());
    navigate("/login");
  };

  const handleFAQ = () => {
    setOpen(false);
    navigate("/dashboard/support");
  };

  const handleSupport = () => {
    dispatch(UIactions.openModal({ type: "contact-support" }));
  };

  const handleSettings = () => {
    setOpen(false);
    navigate("/dashboard/settings");
  };

  const handleUsersDownloads = () => {
    dispatch(UIactions.openModal({ type: "users-downloads" }));
  };

  let show = true;
  switch (location.pathname) {
    case "/dashboard/users":
      show = false;
      break;
    case "/dashboard/devices":
      show = false;
      break;
    case "/dashboard/reports":
      show = false;
      break;
    case "/dashboard/files":
      show = false;
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <Grid2 container size="auto">
        <Grid2 container size="auto">
          <IconButton {...{ ref: anchorRef, onClick: handleToggle }}>
            {avatar}
          </IconButton>
        </Grid2>
        {viewmode === "desktop" || show ? (
          <Grid2
            container
            {...{
              size: "grow",
              padding: 0.8,
              direction: "column",
            }}
          >
            <Typography>{name}</Typography>
            <Typography fontSize={13} color="secondary.light">
              {user}
            </Typography>
          </Grid2>
        ) : null}
      </Grid2>
      <Menu
        {...{
          anchorEl: anchorRef.current,
          id: "account-menu",
          open: open,
          onClose: handleToggle,
          onClick: handleToggle,
          transformOrigin: { horizontal: "right", vertical: "top" },
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
          PaperProps: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleSettings} sx={{ color: "DimGray" }}>
          <Settings sx={{ marginRight: 1 }} /> {setting}
        </MenuItem>
        <MenuItem onClick={handleSupport} sx={{ color: "DimGray" }}>
          <ContactSupport sx={{ marginRight: 1 }} /> {support}
        </MenuItem>
        {false ? ( // TODO: ENABLE WHEN FAQ IS READY
          <MenuItem onClick={handleFAQ} sx={{ color: "DimGray" }}>
            <HelpOutline sx={{ marginRight: 1 }} /> {faq}
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem onClick={handleUsersDownloads} sx={{ color: "DimGray" }}>
            <Equalizer sx={{ marginRight: 1 }} /> {downloads}
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleLogout} sx={{ color: "DimGray" }}>
          <Logout sx={{ marginRight: 1 }} /> {exit}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserOptions;
