import { Grid2 } from "@mui/material";
import Logs from "./Logs";
import Rescan from "./Rescan";
import Export from "./Export";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { viewmodeUI } from "../../../../App";

export const plusActionsGrid = {
  xl: 5,
  lg: 6,
  md: 12,
  sm: 12,
  xs: 12,
};

const PlusActions = () => {
  const { auth, UI } = useUIBp();
  const viewmode = UI[viewmodeUI ] ?? "desktop";

  return (
    <Grid2
      container
      {...{
        columnSpacing: 2,
        justifyContent: viewmode === "desktop" ? "end" : "space-between",
        size: { xl: 7, lg: 6, md: 12, sm: 12, xs: 12 },
      }}
    >
      <Export />
      {auth.admin === 1 ? <Rescan /> : null}
      {auth.admin === 1 ? <Logs /> : null}
    </Grid2>
  );
};

export default PlusActions;
