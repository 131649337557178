import { Grid2, Typography } from "@mui/material";
import { Fragment } from "react";
import { postDeviceId } from "../../../../../../../store/actions/devices/postDeviceInfo";
import HelpTooltip from "../../../../../../UI/GeneralPurpose/HelpTooltip";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import Switch from "../../../../../../UI/GeneralPurpose/Switch";

const UIText = {
  "es-CL": { label: "Estado del dispositivo" },
  "en-US": { label: "Device status" },
};

const UITextA = {
  "es-CL": { label: "Suspendido" },
  "en-US": { label: "Suspended" },
};

const UIText1 = {
  "es-CL": {
    tooltip:
      "Suspende el dispositivo para que no pueda continuar respaldando. El dispositivo aun será visible y su información accesible",
  },
  "en-US": {
    tooltip:
      "Suspend the device so it can not continue backing up. The device will still be visible and its information accessible",
  },
};

export const suspendedUI = "suspended";

const Suspended = ({ locale }) => {
  const { working } = useWorker(postDeviceId);
  const { label } = UIText[locale];

  return (
    <Fragment>
      <Grid2 container {...{ size: 7, alignItems: "center", columnGap: 1 }}>
        <Typography>{label}</Typography>
        <HelpTooltip {...{ UIText: UIText1 }} />
      </Grid2>
      <Switch
        clearOnUnmount
        labelRight
        {...{
          id: suspendedUI,
          UIText: UITextA,
          disabled: working,
          size: 5,
          labelProps: { sx: { fontSize: undefined, fontWeight: undefined } },
        }}
      />
    </Fragment>
  );
};

export default Suspended;
