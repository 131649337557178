import { Fragment } from "react";
import Region, { regionSelectUI } from "./Region";
import District, { districtSelectUI } from "./District";
import City from "./City";
import DeliveryStreet, { deliveryStreetUI } from "./DeliveryStreet";
import DeliveryStreetNumber, {
  deliveryStreetNumberUI,
} from "./DeliveryStreetNumber";
import DeliveryOffice from "./DeliveryOffice";
import { Grid2, Typography } from "@mui/material";
import {
  emptyCityUI,
  emptyDistrictUI,
  emptyRegionUI,
  miscErrorsUI,
} from "../../../Next";
import useUIBp from "../../../../../../../../Hooks/useUIBoilerplate";
import SlimAlert from "../../../../../../../UI/GeneralPurpose/SlimAlert";

const UIText = {
  "es-CL": {
    subtitle: "Dirección de envío",
    emptyRegion: "La region no puede estar vacía",
    emptyDistrict: "La comuna no puede estar vacía",
    emptyCity: "La ciudad no puede estar vacía",
  },
  "en-US": {
    subtitle: "Shipping address",
    emptyRegion: "The region cannot be empty",
    emptyDistrict: "The district cannot be empty",
    emptyCity: "The city cannot be empty",
  },
};

const AddressInfo = () => {
  const { settings, UI } = useUIBp();
  const locale = settings.locale;
  const { subtitle, emptyRegion, emptyDistrict, emptyCity } = UIText[locale];
  const region = UI[regionSelectUI] ?? "";
  const district = UI[districtSelectUI] ?? "";
  const miscErrors = UI[miscErrorsUI] ?? [];
  const emptyRegionError = miscErrors.includes(emptyRegionUI);
  const emptyDistrictError = miscErrors.includes(emptyDistrictUI);
  const emptyCityError = miscErrors.includes(emptyCityUI);
  const { error: e1 } = UI[deliveryStreetUI] ?? {};
  const { error: e2 } = UI[deliveryStreetNumberUI] ?? {};

  const errors = [
    emptyRegionError ? emptyRegion : "",
    emptyDistrictError ? emptyDistrict : "",
    emptyCityError ? emptyCity : "",
    e1,
    e2,
  ];

  return (
    <Fragment>
      <Grid2
        container
        {...{
          size:12,
          columnSpacing: 2,
          rowSpacing: 2,
          paddingTop: 2,
        }}
      >
        <Grid2 container size={12}>
          <Typography fontWeight="bold">{subtitle}</Typography>
        </Grid2>
        <Region />
        {region ? <District /> : null}
        {district ? <City /> : null}
        {district ? <DeliveryStreet /> : null}
        {district ? <DeliveryStreetNumber /> : null}
        {district ? <DeliveryOffice /> : null}
      </Grid2>
      <SlimAlert {...{ errors, size: 12, paddingTop: 2 }} />
    </Fragment>
  );
};

export default AddressInfo;
