import { Grid2, Typography } from "@mui/material";
import { Fragment } from "react";

const UIText = {
  "es-CL": { label: "Modo oculto" },
  "en-US": { label: "Stealth mode" },
};

const StealthMode = ({ stealth_mode, locale }) => {
  const { label } = UIText[locale];

  return (
    <Fragment>
      <Grid2 container size={6}>
        <Typography>{label}</Typography>
      </Grid2>
      <Grid2 container size={6}>
        <Typography>{stealth_mode ? "Desactivado" : "Activo"}</Typography>
      </Grid2>
    </Fragment>
  );
};

export default StealthMode;
