import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";
import { recoveriesActions } from "../../slices/recoveries";

const UIText = {
  "es-CL": {
    success: "Recuperación creada exitosamente",
    error: "Error al obtener los repositorios",
  },
  "en-US": {
    success: "Recovery successfully created",
    error: "Error fetching repositories",
  },
};

export const addRecoveryUI = "addRecovery";

const addRecovery = ({
  server,
  cloner_key,
  user,
  mid,
  device,
  option,
  bkpDate,
  fullSize,
  lastBkp,
  locale,
}) => {
  const id = addRecoveryUI;
  const { success, error } = UIText[locale];
  console.log("Creating recovery request");

  return async (dispatch) => {
    dispatch(workerActions.start({ id }));
    let repositories = [];

    try {
      // Getting the repositories for the given user and device
      const url = server + "api/repositories";
      const response = await axios.get(url, {
        headers: { cloner_key },
        params: { id: mid, user },
      });
      // Adding the repositories to the recovery
      for (const repo of response.data.repositories) {
        repositories.push({
          id: repo.id,
          metafile: repo.root,
          name: repo.name,
          option,
          bkpDate,
          last_backup:repo.last_backup,
          size: repo.active_space,
          recover:true,
        });
      }
    } catch (e) {
      console.log(e)
      enqueueSnackbar(`${error}: ${e.message}`, { variant: "error" });
      dispatch(workerActions.finish({ id, error: e.message }));
    }
    // Creating the recovery
    dispatch(
      recoveriesActions.newRecovery({
        user,
        mid,
        device,
        repositories,
        fullSize,
        lastBkp,
        mode: "simple",
      })
    );

    dispatch(recoveriesActions.dontAskAgain())
    enqueueSnackbar(success, { variant: "success" });
    dispatch(workerActions.finish({ id }));
  };
};

export default addRecovery;
