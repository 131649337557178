import {
  Alert,
  Grid2,
  Typography,
} from "@mui/material";
import NewRecovery from "./Components/NewRecovery";
import Return from "./Components/Return";
import { useEffect } from "react";
import {
  adminNameUI,
  adminUserUI,
  organizationUI,
} from "../RecoveryStep3/Components/DeliverySumary";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { recoveriesActions } from "../../../../store/slices/recoveries";
import { UIactions } from "../../../../store/slices/UI";
import StepsBar from "../RecoveryStep1/Components/StepsBar";

const UIText = {
  "es-CL": {
    pt1: "Su solicitud de recuperación se ha enviado al equipo de soporte de",
    pt2: ", por lo que pronto recibirá un correo de confirmación indicando el número de ticket asociado a la misma.",
    pt3: "Las recuperaciónes de información tienen nuestra",
    pt4: "maxima prioridad",
    pt5: "por lo que ya nos encontramos trabajando para tenerla a su disposición a la brevedad posible.",
    pt6: "Esperamos poder responder a su requerimiento con la mayor rapidez.",
  },
  "en-US": {
    pt1: "Your recovery request has been sent to the",
    pt2: "support team, so you will soon receive a confirmation email indicating the ticket number associated with it.",
    pt3: "Information recoveries have our",
    pt4: "highest priority",
    pt5: ", so we are already working to have it available to you as soon as possible.",
    pt6: "We hope to be able to respond to your request as quickly as possible.",
  },
};

const Finished = () => {
  const { dispatch, settings } = useUIBp();
  const { pt1, pt2, pt3, pt4, pt5, pt6 } = UIText[settings.locale];

  useEffect(() => {
    return () => {
      dispatch(recoveriesActions.reset());
      dispatch(UIactions.clear("selected"));
      dispatch(UIactions.clear(adminNameUI));
      dispatch(UIactions.clear(adminUserUI));
      dispatch(UIactions.clear(organizationUI));
    };
  }, [dispatch]);

  return (
    <Grid2
      container
      {...{
        sx: {
          padding: "2vh",
          paddingTop: 0,
          paddingBottom: 0,
          height: "92vh",
        },
        alignContent: "start",
      }}
    >
      <StepsBar idx={99} />
      <Grid2
        container
        {...{
          size: 12,
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Grid2
          container
          {...{
            size: 12,
            alignItems: "center",
            justifyContent: "center",
            sx: { height: "80vh" },
          }}
        >
          <Grid2 container {...{ size: 12, rowGap: 2 }}>
            <Grid2 container {...{ justifyContent: "center", size: 12 }}>
              <Grid2 container {...{ size: { lg: 6, md: 8, sm: 9, xs: 12 } }}>
                <Alert severity="info" sx={{ border: "1px solid lightblue" }}>
                  <Grid2 container rowGap={2}>
                    <Typography textAlign="justify">
                      {pt1} <b>Cloner</b>
                      {pt2}
                    </Typography>
                    <Typography textAlign="justify">
                      {pt3} <b>{pt4}</b> {pt5}
                    </Typography>
                    <Typography textAlign="justify">{pt6}</Typography>
                  </Grid2>
                </Alert>
              </Grid2>
            </Grid2>
            <Grid2 container {...{ justifyContent: "center", size: 12 }}>
              <Grid2
                container
                {...{
                  size: {
                    lg: 6,
                    md: 8,
                    sm: 9,
                    xs: 12,
                  },
                  justifyContent: "space-between",
                }}
              >
                <Return />
                <NewRecovery />
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Finished;
