import { Grid2 } from "@mui/material";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar/TopBar";

const DashBody = ({ height }) => {
  return (
    <Grid2
      container
      {...{
        size: 12,
        direction: "column",
        sx: {
          flexGrow: 1,
          backgroundColor: "ivory.main",
          height,
          overflowX: "visible",
        },
      }}
    >
      <TopBar height={height} />
      <Grid2 container {...{ size: 12, sx: { overflowX: "auto" } }}>
        <Outlet />
      </Grid2>
    </Grid2>
  );
};

export default DashBody;
