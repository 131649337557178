import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workers: {},
};
const workersSlice = createSlice({
  name: "workers",
  initialState,
  reducers: {
    init(state, action) {
      const id = action.payload;
      state.workers[id] = {
        working: false,
        done: false,
        error: "",
      };
    },
    // ================================================================
    start(state, action) {
      const id = action.payload;

      state.workers[id] = {
        working: true,
        done: false,
        error: "",
      };
    },
    finish(state, action) {
      const { id, error, msg } = action.payload;
      state.workers[id] = {
        working: false,
        done: true,
        error: error ?? msg,
      };
    },

    // ================================================================
    startStackWorker(state, action) {
      const id = action.payload;
      const worker = state.workers[id];
      if (worker) {
        state.workers[id].workingCt++;
      } else {
        state.workers[id] = {
          workingCt: 1,
        };
      }
    },
    finishTask(state, action) {
      const id = action.payload;
      const worker = state.workers[id];
      if (worker) {
        state.workers[id].workingCt--;
      } else {
        console.error("this should not be happening");
      }
    },
    // ================================================================
    clear(state, action) {
      const id = action.payload;
      delete state.workers[id];
    },
    clearGroup(state, action) {
      const group = action.payload;
      for (const key in state.workers) {
        if (!!key.match(group)) {
          delete state.workers[key];
        }
      }
    },
    // ================================================================
    fullClear(state) {
      state.workers = {};
    },
    startPost(state, action) {
      const id = action.payload;
      state.workers[id] = {
        submitting: true,
        done: false,
        error: "",
      };
    },
    finishPost(state, action) {
      const { id, error } = action.payload;
      state.workers[id] = {
        submitting: false,
        done: true,
        error,
      };
    },
  },
});

export const workerActions = workersSlice.actions;
export const worker = workersSlice.actions;
export default workersSlice.reducer;
