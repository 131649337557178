import {
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import { useMemo } from "react";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import AutoHelp from "../Wrappers/AutoHelp";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";

const DynamicSelect = (props) => {
  const {
    id,
    UIText,
    options = [],
    size = 12,
    componentSize = "small",
    required,
    disabled,
    defaultIdx = 0,
    onChange = () => {},
    hide,
    done,
    working,
    error,
  } = props;
  const { dispatch, settings, UI } = useUIBp();
  const locale = settings.locale;
  const filteredProps = filterObjKeys(
    props,
    "UIText",
    "xs",
    "size",
    "componentSize",
    "required",
    "defaultIdx",
    "onChange",
    "hide",
    "done",
    "working",
    "error"
  );
  const { normalTxt, loadingTxt, errorTxt, emptyTxt, tooltip } = UIText[locale];

  const items = useMemo(() => {
    return options.map((x, idx) => {
      const { label, value } = x[locale] ?? x.universal;
      return <MenuItem key={idx} {...{ value }}>{label}</MenuItem>;
    });
  }, [options, locale]);

  if (!options.length) {
    return;
  }

  const value = UI[id] ?? options[defaultIdx].universal.value;

  let label = normalTxt;
  if (done && !error && !options.length) {
    label = emptyTxt;
  } else if (done && error) {
    label = errorTxt;
  } else if (working) {
    label = loadingTxt;
  }

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(UIactions.setValue({ id, value }));
    onChange(e);
  };

  return (
    <Grid2 container size={size}>
      <AutoHelp {...{ tooltip, disabled }}>
        <FormControl
          fullWidth
          {...{
            size: componentSize,
            required,
            sx: { visibility: hide ? "hidden" : undefined },
          }}
        >
          <InputLabel>{label}</InputLabel>
          <MUISelect
            fullWidth
            {...filteredProps}
            {...{
              onChange: handleChange,
              MenuProps: { PaperProps: { sx: { maxHeight: 200 } } },
              label,
              value,
            }}
          >
            {items}
          </MUISelect>
        </FormControl>
      </AutoHelp>
    </Grid2>
  );
};

export default DynamicSelect;
