import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";
import { recoveriesActions } from "../../slices/recoveries";

const UIText = {
  "es-CL": {
    info: "Creando solicitud de recuperación",
    success: "Solicitud de recuperación creada",
    error: "Error al obtener los repositorios",
  },
  "en-US": {
    info: "Creating recovery request",
    success: "Recovery request created",
    error: "Error fetching repositories",
  },
};

export const addRecoveriesUI = "addRecoveries";

const addRecoveries = ({ server, cloner_key, locale, devices }) => {
  const { success, error, info } = UIText[locale];
  const id = addRecoveriesUI;
  console.log("Adding recoveries");

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    enqueueSnackbar(info, { variant: "info" });

    // Fetching repositories for each device
    for (const device of devices) {
      const { user, mid, space_active, lastBkp } = device;
      if (!mid) {
        continue;
      }
      let repositories = [];
      try {
        // Fetching repositories for the device
        const url = server + "api/repositories";
        const response = await axios.get(url, {
          headers: { cloner_key },
          params: { id: mid, user },
        });

        // Adding repositories to the list
        for (const repo of response.data.repositories) {
          repositories.push({
            id: repo.id,
            metafile: repo.root,
            name: repo.name,
            last_backup: repo.last_backup,
            size: repo.active_space,
            recover: true,
          });
        }
      } catch (e) {
        enqueueSnackbar(`${error}: ${e.message}`, { variant: "error" });
        dispatch(workerActions.finish({ id, error: e.message }));
      }

      // Creating the recovery request
      dispatch(
        recoveriesActions.newRecovery({
          user,
          mid,
          device: device.name,
          repositories,
          fullSize: space_active,
          lastBkp,
          mode: "simple",
        })
      );
    }

    enqueueSnackbar(success, { variant: "success" });
    dispatch(recoveriesActions.initialize());
    dispatch(workerActions.finish({ id }));
  };
};

export default addRecoveries;
