import useTrees from "../../../../../Hooks/useTrees";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../Hooks/useWorker";
import { useWorkerGroup } from "../../../../../Hooks/useWorkerGroup";
import Button from "../../../../UI/GeneralPurpose/Button";
import { rootsDataId, tunnelTokenId } from "../Actions/getMachineStatus";
import postGreylists, { postGreylistsId } from "../Actions/postGreylists";
import { passFieldId } from "./Blocked/PassField";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const Submit = () => {
  const { auth,dispatch,UI,warehouse,settings } = useUIBp();
  const {working:w2, done,errors} = useWorkerGroup('root')
  const {working} = useWorker(postGreylistsId)
  const trees = useTrees()
  const roots = warehouse[rootsDataId]
  const tunnelToken = UI[tunnelTokenId]
  const {value:password} = UI[passFieldId]??{}

  const onClick = () => {
    dispatch(postGreylists({
      server:auth.server,
      tunnelToken,
      password,
      roots,
      trees,
      locale:settings.locale,
    }))
  };

  const disabled = w2 || !done || errors.length > 0

  return <Button {...{ disabled, working, UIText, onClick, variant:'contained' }} />;
};

export default Submit;
