import { Alert, Grid2 } from "@mui/material";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { passRecoveryUserID } from "../User";

const UIText = {
  "es-CL": {
    content1: "Se envió un correo al usuario",
    content2: "con un enlace para realizar el cambio de la contraseña.",
  },
  "en-US": {
    content1: "An email was sent to the user",
    content2: "that contains a link to execute the password change.",
  },
};

const Done = () => {
  const { UI, settings } = useUIBp();
  const user = UI[passRecoveryUserID] ?? { value: "" };

  const { content1, content2 } = UIText[settings.locale];

  return (
    <Grid2 container size={12} justifyContent="center">
      <Grid2 container size={{ xl: 3, lg: 4, md: 6, sm: 8, xs: 10 }}>
        <Alert severity="info">
          {content1} {user.value} {content2}
        </Alert>
      </Grid2>
    </Grid2>
  );
};

export default Done;
