import { CloudOff } from "@mui/icons-material";
import { Grid2, Tooltip, Typography } from "@mui/material";
import AutoHelp from "../../../../../../UI/Wrappers/AutoHelp";

const Username = ({ fontSize, user, suspended, settings }) => {
  const UIText = {
    "es-CL": { suspendedTTP: "Equipo suspendido" },
    "en-US": { suspendedTTP: "Suspended device" },
  };

  const { suspendedTTP } = UIText[settings.locale];

  return (
    <Grid2
      container
      {...{ alignItems: "center", columnGap: 2, wrap: "nowrap" }}
    >
      <Tooltip title={user}>
        <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
          {user}
        </Typography>
      </Tooltip>
      {suspended ? (
        <AutoHelp {...{ fixedTooltip: suspendedTTP }}>
          <CloudOff color="warning" />
        </AutoHelp>
      ) : null}
    </Grid2>
  );
};

export default Username;
