import DateTime from "../../../../../UI/GeneralPurpose/DateTime";

const UIText = {
  "es-CL": { label: "Desde" },
  "en-US": { label: "From" },
};

export const filterFromUI = "filterFrom";

const FilterFrom = () => {
  return (
    <DateTime
      noDefault
      clearOnUnmount
      {...{
        id: filterFromUI,
        UIText,
        size: 6,
      }}
    />
  );
};

export default FilterFrom;
