import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../../store/slices/workers";
import { authActions } from "../../../store/slices/authentication";

const UIText = {
  "es-CL": {
    success: "Exito al modificar el usuario",
    error: "Fallo al crear/modificar usuario: ",
  },
  "en-US": {
    success: "Success modifying the user",
    error: "Failure while creating/modifying user: ",
  },
};

export const postSelfId = "postSelf";

// POST user data
export const postSelf = ({ server, cloner_key, payload, locale }) => {
  const { error, success } = UIText[locale];
  const id = postSelfId;

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      const bodyData = JSON.stringify(payload);
      await axios.post(server + "api/users", bodyData, {
        headers: { "Content-Type": "application/json", cloner_key },
      });
      const msg = success + payload.user;
      dispatch(workerActions.finish({ id }));
      dispatch(authActions.setUsername(payload.name))
      enqueueSnackbar(msg, { variant: "success" });
    } catch (e) {
      console.error(e)
      enqueueSnackbar(error+e.message, { variant: "error" });
      dispatch(workerActions.finish({ id, error: e }));
    }
  };
};
