import { groupNameId } from "./GroupName";
import {
  postGroup,
  postGroupId,
} from "../../../../../store/actions/users/postGroup";
import { useEffect } from "react";
import notEmptyField from "../../../../../utility/validations/notEmpty";
import { groupsTreeId, selectedGroupId2 } from "../../../../GroupsTraveler/Actions/loadGroupsTree";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../Hooks/useWorker";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Crear" },
  "en-US": { label: "Create" },
};
const UIText2 = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const Send = ({ isNew, groupId }) => {
  const { auth, dispatch, UI, settings } = useUIBp();
  const { done, working, error: e1 } = useWorker(postGroupId);
  const { error } = useWorker(groupsTreeId);
  const open = UI["group-traveler2-open"] ?? false;
  const { value, error: e2 } = UI[groupNameId] ?? {};
  const group = UI[selectedGroupId2];
  const locale = settings.locale;

  const onClick = () => {
    const payload = { parent: parseInt(group), name: value,  group: isNew?undefined:groupId };
    dispatch(postGroup({ ...auth, payload, locale }));
  };

  const onMouseDown = () => {
    dispatch(
      UIactions.validateField({
        id: groupNameId,
        force: true,
        fn: notEmptyField,
        locale,
      })
    );
  };

  useEffect(() => {
    if (done && !e1) {
      dispatch(UIactions.closeModal());
    }
  }, [dispatch, done, e1]);

  return (
    <Button
      {...{
        disabled: !!e2 || error || open,
        UIText: isNew ? UIText : UIText2,
        onMouseDown,
        onClick,
        working,
      }}
    />
  );
};

export default Send;
