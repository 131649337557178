import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { bkpMailCountId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { bkpDriveSizeId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { m365validated } from "../../../components/Users/Dialogs/UserCloudIntegrations/Components/Buttons/Save";
import { workerActions } from "../../slices/workers";
import { UIactions } from "../../slices/UI";

export const validateMailId = "windows365validations";

const UIError = {
  "es-CL": {
    error: "Error al validar correo",
    empty: "El campo del correo se encuentra vacío",
    notFound: "El correo no existe en la organización",
    similar: "El correo no existe en la organización, pero se sugiere",
    found: "Correo encontrado en la organización",
    gettingData: "Obteniendo tamaño de la cuenta y numero de correos",
  },
  "en-US": {
    error: "Error validating mail",
    empty: "Mail field is empty",
    notFound: "Mail not found in the organization",
    similar: "Mail not found in the organization, but suggested",
    found: "Mail found in the organization",
    gettingData: "Getting account size and mail count",
  },
};

const validateM365Mails = ({ M365server, cloner_key, tryouts, locale }) => {
  const id = validateMailId;

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    
    for (let attempt of tryouts) {
      const newMail = attempt.mail;
      const url = `${M365server}mailapi/organization/${attempt.org.id}/client/${newMail}`;
      let clientMail = "";
      try {
        if (attempt.mail === "") {
          dispatch(
            UIactions.setFieldError({
              id: "365mail-" + attempt.login,
              value: "campo vacío",
            })
          );
          enqueueSnackbar(UIError[locale].empty + ": " + attempt.login, {
            variant: "error",
          });
          dispatch(UIactions.clear(bkpMailCountId + attempt.login));
          dispatch(UIactions.clear(bkpDriveSizeId + attempt.login));
        } else {
          const response = await axios.get(url, { headers: { cloner_key } });

          const client = response.data.data;
          clientMail = client.mail.toLowerCase();

          if (clientMail === attempt.mail.toLowerCase()) {

            let url2 = `${M365server}mailapi/organization/${attempt.org.id}/client/${newMail}/space`;
            const response2 = await axios.get(url2, {
              headers: { cloner_key },
            });
            const data = response2.data.data;
            console.log("response2", response2.data.data);

            dispatch(
              UIactions.setValue({
                id: bkpMailCountId + attempt.login,
                value: data.count_mails,
              })
            );
            dispatch(
              UIactions.setValue({
                id: bkpDriveSizeId + attempt.login,
                value: data.drive,
              })
            );
            dispatch(
              UIactions.setValue({
                id: m365validated,
                value: true,
              })
            );
            enqueueSnackbar(UIError[locale].found + ": " + newMail, {
              variant: "success",
            });
          } else {
            dispatch(
              UIactions.setFieldError({
                id: "365mail-" + attempt.login,
                value: UIError[locale].similar + ": " + clientMail,
              })
            );
            let warning = `${UIError[locale].similar}: ${clientMail}@${attempt.org.domain}`;
            enqueueSnackbar(warning, { variant: "warning" });
            dispatch(UIactions.clear(bkpMailCountId + attempt.login));
            dispatch(UIactions.clear(bkpDriveSizeId + attempt.login));
          }
        }
        dispatch(workerActions.finish({ id }));
      } catch (e) {
        console.error("caught error", e);
        dispatch(UIactions.clear(bkpMailCountId + attempt.login));
        dispatch(UIactions.clear(bkpDriveSizeId + attempt.login));
        if (e.response.status === 404) {
          enqueueSnackbar(UIError[locale].notFound + ": " + newMail, {
            variant: "error",
          });
          dispatch(
            UIactions.setField({
              id: "365mail-" + attempt.login,
              value: "",
            })
          );
          dispatch(
            UIactions.setFieldError({
              id: "365mail-" + attempt.login,
              value: UIError[locale].notFound,
            })
          );
        } else {
          enqueueSnackbar(UIError[locale].error + ": " + newMail, {
            variant: "error",
          });
        }
        // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
        dispatch(workerActions.finish({ id, error: e.message }));
      }
    }

    dispatch(workerActions.finish({ id }));
  };
};

export default validateM365Mails;
