import { Grid2 } from "@mui/material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import SlimAlert from "../../../../UI/GeneralPurpose/SlimAlert";

const UIText = {
  "es-CL":
    "Cargue la información de los usuarios utilizando el templado .xlsx disponible",
  "en-US": "Upload users data through the .xlsx sheet template available",
};
const Empty = () => {
  const { settings } = useUIBp();
  return (
    <Grid2
      container
      {...{
        padding: 4,
        size: 12,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SlimAlert
        notCollapse
        {...{
          severity: "info",
          errors: [UIText[settings.locale]],
          size: 6,
        }}
      />
    </Grid2>
  );
};

export default Empty;
