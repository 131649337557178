import { Grid2 } from "@mui/material";
import Locale from "../Buttons/Locale";
import HelpMode from "../Buttons/HelpMode";
import UserOptions from "../Buttons/UserOptions";

const Blank = ({ height }) => {
  const barHeight = (height * 8) / 100;

  return (
    <Grid2
      container
      {...{
        bgcolor: "white",
        height: barHeight,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 3,
        paddingRight: 3,
        size: {
          xs: 12,
        },
        borderBottom: "1px lightgrey solid",
      }}
    >
      <Grid2 container {...{ columnGap: 2, size: { xs: "auto" } }}></Grid2>
      <Grid2 container {...{ alignItems: "center", size: { xs: "auto" } }}>
        <Locale />
        <HelpMode />
        <UserOptions />
      </Grid2>
    </Grid2>
  );
};

export default Blank;
