import { Grid2, Typography } from "@mui/material";

const UIText = {
  "es-CL": { label: "Seguridad" },
  "en-US": { label: "Security" },
};

const Security = ({locale}) => {
  const { label } = UIText[locale];

  return (
    <Grid2 container size={12}>
      <Typography fontWeight="bold">{label}</Typography>
    </Grid2>
  );
};

export default Security;
