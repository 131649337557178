import Button from "../../../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};

const Back = () => {
  return (
    <Button
      {...{ UIText, navTo: -1, size: {md:"auto",sm:6,xs:6}, variant: "outlined" }}
    />
  );
};

export default Back;
