import { useEffect, useMemo } from "react";
import Core from "../Components/Core";
import CalculatePagination from "../../UI/Utilities/CalculatePagination";
import { sortObjByKey } from "../../../utility/misc/sortObjByKey";
import { currentCatsUI } from "../../Dashboard/Components/Drawer/Components/MailCategories/MailCategories";
import {
  defaultFilter,
  defaultPagination,
  defaultSelected,
  defaultSort,
} from "../../UI/GeneralPurpose/genericIDs";
import { lowerMatch } from "../../../utility/misc/filter";
import SlimAlert from "../../UI/GeneralPurpose/SlimAlert";
import { Grid2 } from "@mui/material";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import useTrees from "../../../Hooks/useTrees";
import useURLQuery from "../../../Hooks/useURLQuery";
import { useWorker } from "../../../Hooks/useWorker";
import getMailCategories, {
  getMailCategoriesId,
} from "../Actions/getMailCategories";
import getMails, {
  getMailsId,
  mailsData,
} from "../Actions/getMails";
import { UIactions } from "../../../store/slices/UI";
import { treesActions } from "../../../store/slices/trees";
import { warehouseActions } from "../../../store/slices/warehouse";
import { workerActions } from "../../../store/slices/workers";

const UIText = {
  "es-CL":
    "El usuario nunca ha habilitado y respaldado correos utilizando Windows365",
  "en-US": "The user has never enabled and backed up emails using Windows365",
};

const InboxLocaleArray = ["bandeja de entrada"];

const Mails = () => {
  const { auth, dispatch, navigate, warehouse, UI, settings } = useUIBp();
  const trees = useTrees();
  const { folderId } = useURLQuery();
  const workerId1 = getMailsId;
  const workerId2 = getMailCategoriesId;
  const { done: d1, working: w1, error: e1 } = useWorker(workerId1);
  const { done: d2, working: w2, error: e2 } = useWorker(workerId2);
  const working = w1 || w2;
  const done = d1 && d2;
  const error = !!e1 ? e1 : e2;
  const workerId = d2 && !e2 ? workerId1 : workerId2;

  const info = UIText[settings.locale];

  const filter = UI.filter ?? "";
  const sort = UI.sort ?? {};
  const pagination = UI.pagination ?? { page: 0, count: 0, rowsPerPage: 0 };

  const query = "";

  useEffect(() => {
    let found = false;
    let inboxId = "";
    for (const key in trees) {
      if (!key.match("mails-")) {
        continue;
      }
      const treeRoot = trees[key][0];
      if (treeRoot.id === folderId) {
        found = true;
      }
      if (
        !!InboxLocaleArray.find(
          (x) => x.toLowerCase() === treeRoot.displayName.toLowerCase()
        )
      ) {
        inboxId = treeRoot.id;
      }
    }
    if (!found) {
      navigate(`/dashboard/mails?folderId=${inboxId}&idx=0`);
    }
  }, [navigate, trees, folderId]);

  useEffect(() => {
    if (!d2) {
      dispatch(getMailCategories({ ...auth }));
    }
  }, [dispatch, auth, d2]);

  useEffect(() => {
    if (folderId && !d1) {
      const controller = new AbortController();
      dispatch(getMails({ ...auth, query, folderId, controller }));
      return () => {
        controller.abort();
      };
    }
  }, [dispatch, query, folderId, auth, d1]);

  // filtering devices
  const filteredMails = useMemo(() => {
    const mails = warehouse[mailsData] ?? [];
    return [...mails]
      .filter(
        (m) =>
          lowerMatch(m.fromM, filter) ||
          lowerMatch(m.toRecipients, filter) ||
          lowerMatch(m.subject, filter) ||
          lowerMatch(m.bodyPreview, filter)
      )
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending));
  }, [sort.value, sort.descending, filter, warehouse]);

  // Array to select all
  const allMails = useMemo(() => {
    return filteredMails.map((x) => x.id);
  }, [filteredMails]);

  // Initializing pagination
  useEffect(() => {
    dispatch(UIactions.setPagination({ count: filteredMails.length }));
  }, [dispatch, filteredMails.length]);

  // Trimming devices to only current page ones
  const { from, to } = CalculatePagination(pagination);
  const paginatedMails = useMemo(() => {
    return filteredMails.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredMails]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(defaultPagination));
      dispatch(UIactions.clear(defaultSort));
      dispatch(UIactions.clear(defaultFilter));
      dispatch(UIactions.clear(defaultSelected));
      dispatch(UIactions.clear(currentCatsUI));
      dispatch(treesActions.removeTreeGroup("mails-"));
      dispatch(warehouseActions.unload(mailsData));
      dispatch(workerActions.clear(getMailCategoriesId));
      dispatch(workerActions.clear(getMailsId));
      dispatch(workerActions.clearGroup("restore"));
    };
  }, [dispatch]);

  let foundCategories = false;
  for (const key in trees) {
    if (!key.match("mails-")) {
      continue;
    }
    foundCategories = true;
    break;
  }

  if (d2 && !e2 && !foundCategories) {
    return (
      <Grid2 container {...{ size: 12, justifyContent: "center", padding: 10 }}>
        <SlimAlert notCollapse {...{ severity: "info", errors: [info] }} />
      </Grid2>
    );
  }
  return (
    <Core
      {...{ mails: paginatedMails, allMails, done, working, error, workerId }}
    />
  );
};
export default Mails;
