import axios from "axios";
import { warehouseActions } from "../../slices/warehouse";

export const tunnelsDataId = 'tunnels'

export const getTunnelData = (data) => {
  return async (dispatch) => {
    const { server, controller, user, cloner_key } = data;
    try {
      const newURL = server + "api/tunnels";
      const response = await axios.get(newURL, {
        signal: controller.signal,
        params: { user },
        headers: { cloner_key },
      });
      if (response.data.length) {
        dispatch(
          warehouseActions.add({ id: tunnelsDataId, key: user, data: response.data })
        );
      }
    } catch (error) {
      console.log("Failed to load tunnels: ", error.message);
    }
  };
};
