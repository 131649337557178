import { Collapse, Grid2 } from "@mui/material";
import Row from "./Row/Row";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { activitiesDataID } from "../Actions/fetchActivities";
import { sortObjByKey } from "../../../utility/misc/sortObjByKey";
import { activitiesFilterID } from "./Header/Header";
import { containsEscaped } from "../../../utility/misc/filter";

const Listing = () => {
  const { warehouse, UI } = useUIBp();
  const filter = UI[activitiesFilterID] ?? "";

  let activities = [...(warehouse[activitiesDataID] ?? [])];

  var finalActivities = activities
    .filter((activity) => {
      return containsEscaped(activity.login, filter);
    })
    .sort((a, b) => sortObjByKey(a, b, "login"));

  let rows = finalActivities.map((activity, index) => {
    return <Row key={index} {...activity} />;
  });

  return (
    <Grid2 container {...{ size: 12 }}>
      {rows}
    </Grid2>
  );
};

export default Listing;
