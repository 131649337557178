import { useDispatch } from "react-redux";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Confirmar" },
  "en-US": { label: "Confirm" },
};

const Confirm = ({ disabled, confirmation }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.closeModal2());
    confirmation();
  };

  return (
    <Button
      {...{
        disabled,
        UIText,
        onClick,
        labelSx: { fontSize: 13, fontWeight: "bold" },
      }}
    />
  );
};

export default Confirm;
