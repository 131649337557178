import { Grid2, Typography } from "@mui/material";

const UIText = {
  "es-CL": { text: "General" },
  "us-CL": { text: "General" },
};


const General = (props) => {
  const { locale } = props;
  const { text } = UIText[locale];

  return (
    <Grid2 container size={12}>
      <Typography fontWeight="bold">{text}</Typography>
    </Grid2>
  );
};

export default General;
