import { Tooltip } from "@mui/material";
import { Error } from "@mui/icons-material";
import { useEffect } from "react";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { suggestMailId } from "../../../../../../../store/actions/microsoft365/suggestM365Mails";
import { validateMailId } from "../../../../../../../store/actions/microsoft365/validateM365Mails";
import { UIactions } from "../../../../../../../store/slices/UI";
import TextField from "../../../../../../UI/GeneralPurpose/SimpleTextField";
import { useWorker } from "../../../../../../../Hooks/useWorker";

const UIText365Mail = {
  universal: {},
};

export const mailLocksId = "365maillocks";

const M365mail = ({ login, fontSize }) => {
  const { dispatch,UI } = useUIBp();
  const { working: w1 } = useWorker(suggestMailId);
  const { working: w2 } = useWorker(validateMailId);
  const mailLocks = UI[mailLocksId] ?? []
  let id = "365mail-" + login;
  let field = UI[id] ?? { value: "" };
  let fieldErr = field.error;


  let mailLocked = mailLocks.includes(login);

  useEffect(() => {
    return ()=>{
      dispatch(UIactions.clear(id))
    }
  },[dispatch, id])

  return (
    <TextField
      {...{
        id: id,
        disabled: w1 || w2 || mailLocked,
        UIText: UIText365Mail,
        fontSize,
        size: 12,
        InputProps: {
          endAdornment: fieldErr ? (
            <Tooltip title={fieldErr}>
              <Error sx={{marginRight:-1, color: "red"}}/>
            </Tooltip>
          ) : null,
        },
      }}
    />
  );
};

export default M365mail;
