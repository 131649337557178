import { Grid2, ListItem, ListItemButton } from "@mui/material";
import Actions from "./Components/Actions";
import Name from "./Components/Name";
import FileDate from "./Components/FileDate";
import Size from "./Components/Size";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import downloadFile from "../../../../../../store/actions/devices/downloadFile";
import { warehouseActions } from "../../../../../../store/slices/warehouse";
import { workerActions } from "../../../../../../store/slices/workers";
import { UIactions } from "../../../../../../store/slices/UI";
import { breadcrumbsId } from "../../../../../../store/actions/devices/getFiles";

const fontSize = 13;

const FilesRow = (props) => {
  const { id, created, size, type, repo, deleted  } = props;
  const { dispatch, navigate, auth,settings } = useUIBp();
  const { user, mid } = useURLQuery();
  const locale = settings.locale;

  const gridProps = {
    alignItems: "center",
    columnGap: 0.5,
    sx: {
      fontSize: 20,
      height: "6vh",
      paddingLeft: "2vh",
      paddingRight: "2vh",
      borderBottom: "1px solid lightGrey",
      width: "100%",
    },
  };

  const handleDownload = () => {
    console.log("handling donwload!");
    dispatch(
      downloadFile({
        ...auth,
        fileId: id,
        repoId: repo,
        type,
        deleted,
        locale,
      })
    );
  };

  const onClick = () => {
    if (type === 0 || type === 99) {
      if (type === 99) {
        dispatch(warehouseActions.unload(breadcrumbsId));
      }
      dispatch(workerActions.clearGroup("file"));
      dispatch(warehouseActions.unload("files"));
      dispatch(UIactions.clear("filter"));
      navigate(
        "?user=" +
          encodeURIComponent(user) +
          "&mid=" +
          mid +
          "&repo=" +
          repo +
          "&file=" +
          id
      );
    } else {
      handleDownload();
    }
  };

  return (
    <ListItem disablePadding secondaryAction={<Actions {...props} {...{handleDownload,auth, locale}}/>}>
      <ListItemButton
        {...{
          onClick,
          sx: { padding: 0, "&.MuiListItemButton-root": { paddingRight: 0 } },
        }}
      >
        <Grid2 container {...gridProps}>
          <Grid2
            container
            {...{ columnSpacing: 1, size: 6, alignItems: "center" }}
          >
            <Name {...props} {...{ fontSize }} />
          </Grid2>
          <Grid2 container {...{ size: 3 }}>
            <FileDate {...{ created, fontSize, locale }} />
          </Grid2>
          <Grid2 container {...{ size: 2 }}>
            <Size {...{ size, fontSize }} />
          </Grid2>
          <Grid2 size="grow"></Grid2>
        </Grid2>
      </ListItemButton>
    </ListItem>
  );
};

export default FilesRow;
