import { useEffect } from "react";
import SelectionNode from "./SelectionNode/SelectionNode";
import { passFieldId } from "../Blocked/PassField";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { tunnelTokenId } from "../../Actions/getMachineStatus";
import useTrees from "../../../../../../Hooks/useTrees";
import loadRepositoryRoot from "../../Actions/loadRepositoryRoot";

const Repository = ({ id, root }) => {
  const { auth, dispatch, UI } = useUIBp();
  const { server } = auth;
  const tunnelToken = UI[tunnelTokenId] ?? "";
  const { value: password } = UI[passFieldId] ?? "";
  const trees = useTrees();
  const tree = trees[root];

  useEffect(() => {
    if (tunnelToken) {
      dispatch(
        loadRepositoryRoot({
          server,
          tunnelToken,
          cpassword: password,
          repository: id,
          path: root,
        })
      );
    }
  }, [dispatch, server, tunnelToken, password, id, root]);

  let rootNodeData = tree
    ? tree[0]
    : {
        backup: 0,
        childrenIdx: [],
        idx: 0,
        isDir: true,
        name: root,
        parentIdx: null,
        status: 0,
      };

  return (
    <SelectionNode
      {...rootNodeData}
      {...{ level: 0, root, repo: id, path: "" }}
    />
  );
};

export default Repository;
