import BasicRow from "../../../UI/BasicLayout/Components/BasicRow";
import Username from "./Components/Username";
import Device from "./Components/Device";
import Size from "./Components/Size";
import LastBackup from "./Components/LastBackup";
import LastChanges from "./Components/LastChanges";
import Bandwidth from "./Components/Bandwidth";
import Version from "./Components/Version";
import Online from "./Components/Online";
import Status from "./Components/Status";
import useUIBp from "../../../../Hooks/useUIBoilerplate";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };
const fontSize = 13;

const ReportRow = (props) => {
  const { auth, settings } = useUIBp();
  const { mid } = props;

  const data = {
    id: auth.admin === 1 ? mid : undefined,
    disabled: auth.admin === 1 ? !mid : undefined,
    elements: [
      { content: <Username {...props} {...{ fontSize }} />, size: 1.6 },
      { content: <Device {...props} {...{ fontSize }} />, size: 1.6 },
      { content: <Size {...props} {...{ fontSize }} />, size: 1 },
      {
        content: <LastBackup {...props} {...{ fontSize, settings }} />,
        size: 1.5,
      },
      {
        content: <LastChanges {...props} {...{ fontSize, settings }} />,
        size: 1.5,
      },
      {
        content: <Bandwidth {...props} {...{ fontSize }} />,
        justify: "center",
        size: "grow",
      },
      {
        content: <Version {...props} {...{ fontSize }} />,
        justify: "center",
        size: 1,
      },
      {
        content: <Online {...props} {...{ fontSize, settings }} />,
        justify: "center",
        size: 0.9,
      },
      {
        content: <Status {...props} {...{ fontSize, settings }} />,
        justify: "center",
        size: 0.9,
      },
    ],
  };

  return <BasicRow {...data} />;
};

export default ReportRow;
