import { Chip } from "@mui/material";
import AutoHelp from "../../../../../../UI/Wrappers/AutoHelp";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": {
    text: "dispositivos",
    active: "Activos",
    suspended: "Suspendidos",
  },
  "en-US": { text: "devices", active: "Active", suspended: "Suspended" },
};

const Devices = ({ devices, suspensions, fontSize }) => {
  const { settings } = useUIBp();
  const { text, active, suspended } = UIText[settings.locale];

  let color;
  if (suspensions) {
    color = "warning";
  } else if (devices) {
    color = "primary";
  }

  return (
    <AutoHelp
      {...{
        fixedTooltip: (
          <ul style={{ marginTop: 5, marginBottom: 5, paddingLeft: 15 }}>
            <li>
              {active}: {devices - suspensions}
            </li>
            <li>
              {suspended}: {suspensions}
            </li>
          </ul>
        ),
      }}
    >
      <Chip
        {...{
          size: "small",
          label: (
            <span style={{ cursor: "default" }}>{`${devices} ${text}`}</span>
          ),
          sx: { fontSize: fontSize - 1 },
          color,
        }}
      />
    </AutoHelp>
  );
};

export default Devices;
