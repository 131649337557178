import { Grid2 } from "@mui/material";
import { aditionalOptionUI, recoveryDateUI } from "./AditionalOptions";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import SlimAlert from "../../../../../../UI/GeneralPurpose/SlimAlert";
import MultilineTextField from "../../../../../../UI/GeneralPurpose/MultilineTextField";

const UITextAditional = {
  "es-CL": { placeholder: "Detalles adicionales" },
  "en-US": { placeholder: "Additional details" },
};

const UIText = {
  "es-CL": {
    emptyDate: "Fecha vacía",
    badDate: "Fecha inválida. Debe ser anterior a la fecha actual",
  },
  "en-US": {
    emptyDate: "Empty date",
    badDate: "Invalid date. Must be before current date",
  },
};

export const aditionalInfoUI = "aditionalInfo";

const AditionalDetails = ({ id, readOnly }) => {
  const { UI, settings } = useUIBp();
  const { emptyDate, badDate } = UIText[settings.locale];
  const selected = UI[`${aditionalOptionUI}-${id}`];
  const { value: selectedDate } = UI[`${recoveryDateUI}-${id}`] ?? {};

  let dateError = "";
  if (selected === "dated") {
    if (!selectedDate) {
      dateError = emptyDate;
    } else if (new Date(selectedDate) > Date.now()) {
      dateError = badDate;
    }
  }

  return (
    <Grid2 container {...{ alignItems: "center", size: 12, rowGap: 2 }}>
      <SlimAlert {...{ errors: [dateError] }} />
      <MultilineTextField
        {...{
          id: `${aditionalInfoUI}-${id}`,
          disabled: readOnly,
          UIText: UITextAditional,
          size:12,
        }}
      />
    </Grid2>
  );
};

export default AditionalDetails;
