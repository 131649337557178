import BasicDialogActions from "../../../../../UI/BasicDialog/BasicDialogActions";
import Cancel from "./Cancel";
import Save from "./Save";

const Buttons = ({ users }) => {
  return (
    <BasicDialogActions>
      <Cancel />
      <Save {...{ users }} />
    </BasicDialogActions>
  );
};

export default Buttons;
