import { Grid2 } from "@mui/material";
import { debounce } from "lodash";
import notEmptyUser from "../../../utility/validations/notEmptyUser";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import resetPassword, { passRecoveryId } from "../Actions/resetPassword";
import TextField from "../../UI/GeneralPurpose/SimpleTextField";
import { useWorker } from "../../../Hooks/useWorker";

const UIText = {
  "es-CL": {
    label: "Usuario",
    placeholder: "correousuario@cloner.cl",
  },
  "en-US": {
    label: "User",
    placeholder: "usermail@cloner.cl",
  },
};

export const passRecoveryUserID = "user";

const User = () => {
  const { dispatch, UI } = useUIBp();
  const user = UI[passRecoveryUserID] ?? {};
  const { done, working, error } = useWorker(passRecoveryId);

  if (done && !error) {
    return;
  }

  const debouncedReset = debounce(() => {
    if (!user.error) {
      dispatch(resetPassword({ login: user.value }));
    }
  }, 200);
  const handlePasswordReset = () => {
    debouncedReset();
  };

  return (
    <Grid2 container size={12} justifyContent="center">
      <TextField
        autoFocus
        tagError
        required
        hardValidate
        {...{
          id: passRecoveryUserID,
          UIText,
          validation: notEmptyUser,
          disabled: working,
          submit: handlePasswordReset,
          lg: 3,
          md: 4,
          sm: 6,
          xs: 8,
        }}
      />
    </Grid2>
  );
};

export default User;
