import { Grid2, Typography } from "@mui/material";
import { useMemo } from "react";
import DeviceRow from "./Components/DeviceRow/DeviceRow";
import Headers, { exclusionDevicesUI } from "./Components/Headers";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": {
    many: "Dispositivos a modificar ",
    one: "Dispositivo a modificar: ",
    none: "No hay dispositivos seleccionados para modificar",
  },
  "en-US": {
    many: "Devices to be modified ",
    one: "Device to be modified: ",
    none: "No devices selected for modification",
  },
};

export const exclusionDevicesData = "exclusionDevData";

const DevicesList = () => {
  const { settings, warehouse, UI } = useUIBp();
  const exclusionDevicesSelected = UI[exclusionDevicesUI]??[]
  const allDevices = warehouse[exclusionDevicesData];
  const { many, one, none } = UIText[settings.locale];

  let finalHeader = "";

  if (exclusionDevicesSelected.length > 1) {
    finalHeader = many + `( ${exclusionDevicesSelected.length} )`;
  } else if (exclusionDevicesSelected.length === 1) {
    let deviceName = "";
    for (const key in allDevices) {
      deviceName = allDevices[key].name;
    }
    finalHeader = one + deviceName;
  } else {
    finalHeader = none;
  }

  const devicesList = useMemo(() => {
    let all = [];
    for (const key in allDevices) {
      const data = allDevices[key];
      all.push(<DeviceRow key={data.mid} {...data} />);
    }
    return all;
  }, [allDevices]);

  return (
    <Grid2
      container
      {...{ padding: 1, paddingLeft: 2, paddingRight: 2, size:{xs: 12}, alignItems: "center" }}
    >
      <Typography fontSize={18} padding={2} paddingLeft={0}>
        {finalHeader}
      </Typography>
      {devicesList.length > 1 ? (
        <Grid2 container size={{xs:12}}>
          <Headers />
          <Grid2
            container
            {...{
              paddingLeft: "2vh",
              paddingRight: "2vh",
              size:{
              xs: 12,
              },
              sx: { maxHeight: 200, overflow: "scroll" },
            }}
          >
            {devicesList}
          </Grid2>
        </Grid2>
      ) : null}
    </Grid2>
  );
};

export default DevicesList;
