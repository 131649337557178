import {
  Block,
  Delete,
  Description,
  List,
  Settings,
} from "@mui/icons-material";
import { devicesDataId } from "../../../../../../../store/actions/devices/getAllDevices";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../store/slices/UI";
import { warehouseActions } from "../../../../../../../store/slices/warehouse";
import MoreActions from "../../../../../../UI/BasicLayout/Components/MoreActions/MoreActions";
import { currentExclusionDevicesUI, exclusionDevicesUI } from "../../../../Exclusions/Components/DevicesList/Components/Headers";
import { exclusionDevicesData } from "../../../../Exclusions/Components/DevicesList/DevicesList";

const UITextExclusions = {
  "es-CL": { label: "Exclusiones" },
  "en-US": { label: "Exclusions" },
};

const UITextConfigure = {
  "es-CL": { label: "Configurar" },
  "en-US": { label: "Configure" },
};

const UITextViewDetails = {
  "es-CL": { label: "Ver detalles" },
  "en-US": { label: "View details" },
};

const UITextDelete = {
  "es-CL": { label: "Eliminar" },
  "en-US": { label: "Delete" },
};

const UITextData = {
  "es-CL": { label: "Data" },
  "en-US": { label: "Data" },
};

const Actions = (props) => {
  const { mid, user } = props
  const {
    auth,
    dispatch,
    warehouse: Warehouse,
  } = useUIBp();
  const devices = Warehouse[devicesDataId];

  let data = {
    id: mid,
    buttonsData: [
      {
        icon: <Block sx={{ color: "grey" }} />,
        UIText: UITextExclusions,
        navTo: "/dashboard/devices/exclusions",
        onClick: () => {
          let newObj = {};
          newObj[mid] = devices[mid];
          dispatch(
            UIactions.setValue({ id: exclusionDevicesUI, value: [mid] })
          );
          dispatch(
            UIactions.setValue({
              id: currentExclusionDevicesUI,
              value: [mid],
            })
          );
          dispatch(
            warehouseActions.load({ id: exclusionDevicesData, data: newObj })
          );
        },
      },
      {
        icon: <Settings sx={{ color: "grey" }} />,
        UIText: UITextConfigure,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "device-edit",
              payload: { devices: [props] },
            })
          );
        },
      },
      {
        icon: <List sx={{ color: "grey" }} />,
        UIText: UITextViewDetails,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "device-details",
              payload: { user, mid },
            })
          );
        },
      },
      {
        icon: <Delete sx={{ color: "grey" }} />,
        UIText: UITextDelete,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "delete-devices",
              payload: { devices: [props] },
            })
          );
        },
      },
    ],
  };

  if (auth.group === 1) {
    data.buttonsData = [
      {
        icon: <Description sx={{ color: "grey" }} />,
        UIText: UITextData,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "device-details-support",
              payload: { data: props },
            })
          );
        },
      },
      {
        divider: true,
      },
      ...data.buttonsData,
    ];
  }

  return <MoreActions {...data} />;
};

export default Actions;
