import { FactCheckOutlined } from "@mui/icons-material";
import IconButton from "../../../../../../UI/GeneralPurpose/IconButton";
import { useNavigate } from "react-router-dom";

const UIText = {
  'es-CL': { fixedTooltip: 'Ver detalles' },
  'en-US': { fixedTooltip: 'View details' },
}

const Actions = ({id}) => {
  const navigate  = useNavigate();

  const handleClick = () => {
          navigate(`/dashboard/recovery/review?id=${id}`);
  };

  return <IconButton {...{ UIText, onClick:handleClick, icon: <FactCheckOutlined /> }} />;
};

export default Actions;
