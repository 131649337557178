import { CircularProgress, Grid2 } from "@mui/material";
import { useWorker } from "../../../../../Hooks/useWorker";
import { getMailId } from "../../../Actions/getMail";

const Loading = () => {
  const { working } = useWorker(getMailId);

  if (working) {
    return (
      <Grid2
        container
        {...{ justifyContent: "center", alignContent: "center", padding: 8 }}
      >
        <CircularProgress size={30} />
      </Grid2>
    );
  }

  return null;
};

export default Loading;
