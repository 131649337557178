import axios from "axios";
import { sortObjByKey } from "../../../utility/misc/sortObjByKey";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";
import { createMachineData } from "./utils";

export const getDevicesId = "getDevices";
export const devicesDataId = "devicesInPicker";
export const devicesListId = "devicesList";

const getDevices = ({ controller, cloner_key, server, user }) => {
  // console.log("Getting devices", server, user);

  return async (dispatch) => {
    dispatch(workerActions.start(getDevicesId));

    try {
      // Getting devices through the /machines handler
      const response = await axios.get(server + "api/machines", {
        signal: controller.signal,
        headers: { cloner_key },
        params: { user },
      });
      let newDevices = [];

      // Parsing the machines data into a more usable form
      for (const machine of response.data.machines_info) {
        newDevices.push(createMachineData(user, 0, machine));
      }
      newDevices.sort((a, b) => sortObjByKey(a, b, "name"));

      // Filling the warehouse with the new devices
      dispatch(warehouseActions.load({ id: devicesDataId, data: newDevices }));
      const newDevices2 = newDevices.filter((x) => !!x.mid).map((x) => x.mid);
      dispatch(warehouseActions.load({ id: devicesListId, data: newDevices2 }));

      dispatch(workerActions.finish({ id: getDevicesId }));
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id: getDevicesId, error: e.message }));
    }
  };
};

export default getDevices;
