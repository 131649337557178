import { Grid2 } from "@mui/material";
import useVH from "../../../../Hooks/useVH";

const BasicHeader = ({ children }) => {
  const vh1 = useVH(8)
  return (
    <Grid2
      container
      {...{
        paddingLeft: "2vh",
        paddingRight: "2vh",
        height: vh1,
        alignItems: "center",
        size: 12,
        sx: { borderBottom: "1px solid lightgrey" },
        fontSize: 20,
      }}
    >
      {children}
    </Grid2>
  );
};


export default BasicHeader