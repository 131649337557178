import { Typography } from "@mui/material";
import { timeDiff } from "../../../../../utility/misc/humanizers";

const UIText = {
  "es-CL": { text: "Nunca completado" },
  "en-US": { text: "Never completed" },
};

const LastBackup = ({ fontSize, lastBkp, settings }) => {
  const { text } = UIText[settings.locale];
  const now = Date.now();

  let txtBkp = lastBkp <= 0 ? text : timeDiff(now - lastBkp,settings.locale);

  return (
    <Typography {...{ fontSize, sx: { cursor: "default" } }}>
      {txtBkp}
    </Typography>
  );
};

export default LastBackup;
