import { Dialog, Grid2 } from "@mui/material";
import Close from "./Components/Close";
import Device from "./Components/Pickers/DevicePicker";
import RepoPicker from "./Components/Pickers/RepoPicker";
import Greylist, {
  greylistTreeReady,
} from "./Components/Pickers/GreylstPicker";
import Body from "./Components/Body";
import { useEffect } from "react";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";
import { warehouseActions } from "../../../../store/slices/warehouse";
import { treesActions } from "../../../../store/slices/trees";
import { workerActions } from "../../../../store/slices/workers";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import { greylistDataId } from "../../../UI/Specialized/Actions/getGreylist";

const UIText = {
  "es-CL": { header: "Historial de selección" },
  "en-US": { header: "Selection history" },
};

const SelectionHistory = () => {
  const { dispatch, settings, UI } = useUIBp();
  const { header } = UIText[settings.locale];
  const { payload } = UI.modal;
  const { user } = payload ?? {};

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  useEffect(() => {
    return () => {
      dispatch(warehouseActions.unload(greylistDataId));
      dispatch(treesActions.removeTreeGroup("greylist"));
      dispatch(workerActions.clear(greylistTreeReady));
    };
  }, [dispatch]);

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2 container {...{ gap: 2, paddingTop: 0.5, sx: { width: "100%" } }}>
          <Device {...{ user }} />
          <RepoPicker {...{ user }} />
          <Greylist />
          <Body />
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close {...{ onClose }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default SelectionHistory;
