import xlsx from "json-as-xlsx";
import {
  humanBytes,
} from "../../../utility/misc/humanizers";

const UIText = {
  "es-CL": {
    sheet1: "Recuperaciones",
    userCol: "Usuarios",
    deviceCol: "Dispositivos",
    drive: "Disco",
    sizeCol: "Tamaño",
    root: "Raíz",
    metafile: "Metadatos",
    filename: "Recuperaciones",
  },
  "en-US": {
    sheet1: "Full report",
    userCol: "Users",
    deviceCol: "Devices",
    drive: "Drive",
    sizeCol: "Size",
    root: "Root",
    metafile: "Metafile",
    filename: "Recoveries",
  },
};

const exportRecovery = (data) => {
  const { devices, locale } = data;
  return async (dispatch) => {

    const text = UIText[locale];

    const columns = [
      { label: text.userCol, value: "user" }, // Top level data
      { label: text.deviceCol, value: "device"},
      { label: text.drive, value: "drive" },
      { label: text.sizeCol, value: (row) => humanBytes(row.size) }, // Custom format
      { label: text.root, value: "root" }, // Top level data
      { label: text.metafile, value: "metafile" }, // Top level data
    ];

    let data = [{ sheet: text.sheet1, columns, content: devices }];

    let settings = {
      fileName: text.filename, // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      // writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      // writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      // RTL: true, // Display the columns from right-to-left (the default value is false)
    };

    xlsx(data, settings); // Will download the excel file
  };
};

export default exportRecovery;
