import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";
import Region from "../../../components/RecoveryRequest/Views/RecoveryStep2/Components/Form/Components/AddressInfo/Region";
import { generateHexString } from "../../../utility/misc/generateString";
import dayjs from "dayjs";

export const submitRecoveryId = "submitRecovery";

const sendRecoveryRequest = ({
  server,
  token,
  requestData,
  senderData,
  organization: org,
}) => {
  const id = submitRecoveryId;
  const { recoveries, deliveryData } = requestData;
  const { adminUser, adminName } = senderData;
  const {
    contactName,
    contactEmail,
    contactPhone,
    reason,
    otherReason,
    deliveryMethod,
  } = deliveryData;

  // Parse and format the delivery window times
  const deliveryWindowFrom = deliveryData.deliveryWindowFrom.format("HH:mm");
  const deliveryWindowTo = deliveryData.deliveryWindowTo.format("HH:mm");

  return async (dispatch) => {
    dispatch(workerActions.start(id));

    let newRecoveries = [];
    // I'll keep this for legacy purposes... I'll remove it later
    for (const recovery of recoveries) {
      for (const repository of recovery.repositories) {
        let basePath =
          repository.name[0] === "/" ? "/" : `/${repository.name[0]}:`;

        let option;
        if (repository.option === "dated") {
          option = `dated: ${repository.bkpDate}`;
        } else {
          option = repository.option;
        }

        let newRecovery = {
          userlogin: recovery.user,
          machine: recovery.device,
          repository: repository.id,
          size: 0,
          root: repository.metafile,
          path: basePath,
          option,
          details: repository.details,
          cloner_key: token,
        };

        if (repository.recover) {
          newRecovery.size = repository.size;
          newRecoveries.push({ ...newRecovery });
        }
      }
    }

    let newRecoveries2 = [];
    let newDevices = [];
    for (const recovery of recoveries) {
      let newDevice = {
        User: recovery.user,
        MachineName: recovery.device,
        MachineId: recovery.mid,
        Recoveries: [],
      };

      for (const repository of recovery.repositories) {
        if (repository.recover) {
          let newID = generateHexString(32);
          let newDate = repository.bkpDate
            ? dayjs(repository.bkpDate).toISOString()
            : null;

          let newRecovery = {
            id: newID,
            repoId: repository.id,
            metafileId: repository.metafile,
            size: repository.size,
            drive: repository.name,
            extraOption: repository.option,
            versionDate: newDate,
          };

          newRecoveries2.push({ ...newRecovery });
          newDevice.Recoveries.push(newID);
        }
      }

      newDevices.push({ ...newDevice });
    }

    const fullAddress = `${deliveryData.deliveryStreet} ${
      deliveryData.deliveryStreetNumber
    } ${deliveryData.deliveryOffice ? `( ${deliveryData.deliveryOffice} )` : ""}
          , ${deliveryData.district}, ${deliveryData.city}, ${
      deliveryData.region
    }.`;
    const timeRange = `${deliveryWindowFrom} - ${deliveryWindowTo}`;

    const payload = {
      request: {
        server,
        issuer_email: adminUser,
        issuer_name: adminName,
        group_name: org.name,
        contact_name: contactName,
        contact_email: contactEmail,
        contact_phone: contactPhone,
        delivery_method: deliveryMethod,
        address: fullAddress,
        delivery_hours: timeRange,
        reason: reason === "Otro" ? `Otro: ${otherReason}` : reason,
      },
      recoveries: newRecoveries,
    };

    console.log("delivery data", deliveryData);

    const newServerPayload = {
      request: {
        // adminUser: adminUser,
        DeliveryMode: deliveryMethod,
        Motive: reason === "Otro" ? `Otro: ${otherReason}` : reason,
        // AditionalNotes: "",
      },
      devices: newDevices,
      recoveries: newRecoveries2,
      DeliveryData: {
        Requester: adminUser,
        Country: "Chile",
        Region: deliveryData.region,
        District: deliveryData.district,
        City: deliveryData.city,
        Address: deliveryData.deliveryStreet,
        Number: deliveryData.deliveryStreetNumber,
        Office: deliveryData.deliveryOffice ?? "",
        DeliverFrom: deliveryWindowFrom,
        DeliverUntil: deliveryWindowTo,
        ContactName: contactName,
        ContactPhone: contactPhone,
        ContactEmail: contactEmail,
      },
      Organization: {
        Id: org.id,
        Name: org.name,
      },
    };

    // console.log("newServerPayload", newServerPayload);

    const bodyData = JSON.stringify(payload);

    const bodyData2 = JSON.stringify(newServerPayload);

    try {
      // POST new recovery request
      const url = "https://director.cloner.cl/api/recoveries2";
      await axios.post(url, bodyData, {
        headers: { "Content-Type": "application/json", cloner_key: token },
      });

      // // POST new recovery request to recovery manager
      // const url = "http://localhost:8081/request";
      // const response = await axios.post(url, bodyData2, {
      //   headers: { "Content-Type": "application/json", token: "placeholdertoken"},
      // });

      // console.log("Response:",response)

      dispatch(workerActions.finish({ id }));
      enqueueSnackbar("Recuperación solicitada exitosamente", {
        variant: "success",
      });
    } catch (e) {
      console.error(e);
      // dispatch(workerActions.finish({ id, error: e.message }));
      enqueueSnackbar("Error al intentar solicitar la recuperación", {
        variant: "error",
      });
    }
  };
};

export default sendRecoveryRequest;
