import useUIBp from "../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../Hooks/useURLQuery";
import { postUsers } from "../../../store/actions/users/postUsers";
import { newUsersUI } from "../../Users/Dialogs/UsersUpload/Components/TopBar/UploadSheet";
import GenericConfirmation2 from "./AllPurpose2/DialogConfirmation";

const CreateUsersConfirmation = () => {
  const { auth, dispatch, settings, UI } = useUIBp();
  const { group } = useURLQuery();
  const newUsers = UI[newUsersUI] ?? [];
  const locale = settings.locale;

  const UIText = {
    "es-CL": {
      header: "Esta seguro que desea crear estos usuarios?",
      message: `Se creará un total de ${newUsers.length} usuarios. Una vez finalizado el proceso será posible descargar un archivo PDF con el detalle de la operación. Esto ultimo es MUY importante si algun usuario fue creado con una contraseña aleatoria y esta no ha sido guardada aun`,
    },
    "en-US": {
      header: "Are you sure you want to create these users?",
      message: `A total of ${newUsers.length} users will be created. Once finished a PDF file detailing the operation will be available to download.  This is VERY important if users were created with a random password and it hasn't been stored safely`,
    },
  };
  const { header, message } = UIText[locale];

  const confirmation = () => {
    let users = [];
    for (const user of newUsers) {
      const payload = {
        token: auth.token,
        server: auth.server,
        login: user.mail,
        user: user.mail,
        name: user.name,
        admin: user.admin ? "true" : "false",
        has_access: user.access ? 1 : 0,
        receives_mail: user.mails ? 1 : 0,
        pass: user.pass,
        pass2: user.pass,
        group: parseInt(group),
      };
      users.push(payload);
    }

    dispatch(
      postUsers({
        ...auth,
        locale,
        reloadId: group,
        isNew: true,
        users,
      })
    );
  };

  return (
    <GenericConfirmation2
      {...{ header, message, severity: "warning", confirmation }}
    />
  );
};

export default CreateUsersConfirmation;
