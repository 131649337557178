import { useEffect, useMemo } from "react";
import getAllDevices from "../../../../store/actions/devices/getAllDevices";
import getRootDevices, { devicesLoadedId } from "../../../../store/actions/devices/getRootDevices";
import Core from "./Components/Core";
import { sortObjByKey } from "../../../../utility/misc/sortObjByKey";
import { containsEscaped } from "../../../../utility/misc/filter";
import {
  defaultFilter,
  defaultPagination,
  defaultPreFilter,
  defaultSelected,
  defaultSort,
} from "../../../UI/GeneralPurpose/genericIDs";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../Hooks/useURLQuery";
import { useWorker } from "../../../../Hooks/useWorker";
import { UIactions } from "../../../../store/slices/UI";
import { warehouseActions } from "../../../../store/slices/warehouse";
import { workerActions } from "../../../../store/slices/workers";
import { addRecoveriesUI } from "../../../../store/actions/recoveries/addRecoveries";
import CalculatePagination from "../../../UI/Utilities/CalculatePagination";
import { recursiveRequestId } from "../../../UI/BasicLayout/Components/FilterPlusActions/Recursive";

export const devicesData = "devices";
export const currentDevicesId = "currentDevices";

const Devices = () => {
  const { auth, dispatch, navigate, UI, warehouse } = useUIBp();
  const { group } = useURLQuery();
  const workerId = devicesLoadedId;
  const { done, working, error } = useWorker(workerId);
  const prefilter = UI[defaultPreFilter];
  const recursive = UI[recursiveRequestId];
  const filter = UI.filter ?? "";
  const sort = UI.sort ?? { value: "user" };
  const pagination = UI.pagination ?? {};

  // If user is not an admin move him to his files
  useEffect(() => {
    if (auth.admin !== auth.group) {
      const url = "/dashboard/files?user=" + encodeURIComponent(auth.user);
      navigate(url, { replace: true });
    } else if (!group && auth.admin) {
      navigate("/dashboard/devices?group=" + auth.group, { replace: true });
    }
  }, [navigate, auth, group]);

  useEffect(() => {
    if (prefilter) {
      dispatch(UIactions.setValue({ id: defaultFilter, value: prefilter }));
      dispatch(UIactions.clear(defaultPreFilter));
    }
  }, [dispatch, prefilter]);

  // Getting devices data
  useEffect(() => {
    if (!done && !prefilter) {
      const controller = new AbortController();
      dispatch(warehouseActions.unload(devicesData));
      if (!recursive) {
        dispatch(getRootDevices({ ...auth, group, controller }));
      } else {
        dispatch(getAllDevices({ ...auth, group, controller }));
      }
      return () => {
        controller.abort();
      };
    }
  }, [dispatch, auth, group, recursive, done, prefilter]);

  // Raw devices data
  const devices = useMemo(() => {
    const rawData = warehouse[devicesData];
    let devices = [];
    for (const key in rawData) {
      devices.push(rawData[key]);
    }
    return devices;
  }, [warehouse]);

  // filtering devices
  const filteredDevices = useMemo(() => {
    return devices
      .filter(
        (dev) =>
          containsEscaped(dev.user, filter) || containsEscaped(dev.name, filter)
      )
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending));
  }, [devices, filter, sort.value, sort.descending]);

  // Array to select all
  const allDevices = useMemo(() => {
    return filteredDevices.map((x) => x.mid);
  }, [filteredDevices]);

  // Initializing pagination
  useEffect(() => {
    dispatch(UIactions.setPagination({ count: filteredDevices.length }));
  }, [dispatch, filteredDevices.length]);

  // Trimming devices to only current page ones
  const { from, to } = CalculatePagination(pagination);
  const paginatedDevices = useMemo(() => {
    return filteredDevices.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredDevices]);

  // Garbage collection
  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(currentDevicesId));
      dispatch(UIactions.clear(defaultFilter));
      dispatch(UIactions.clear(defaultSelected));
      dispatch(UIactions.clear(defaultSort));
      dispatch(UIactions.clear(defaultPagination));
      dispatch(warehouseActions.unload(devicesData));
      dispatch(workerActions.clear(devicesLoadedId));
      dispatch(workerActions.clear(addRecoveriesUI));
    };
  }, [dispatch]);

  return (
    <Core
      {...{ devices: paginatedDevices, allDevices, done, working, error, workerId }}
    />
  );
};

export default Devices;
