import PassField, { passFieldId } from "./PassField";
import Submit from "./Submit";
import { selectionBlockUI } from "../../RemoteSelection";
import { validateSelectionPassword } from "../../Actions/validateSelectionPassword";
import { getConfigId } from "../../../../../../store/actions/devices/getConfig";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import CollapseGrid from "../../../../../UI/GeneralPurpose/CollapseGrid";

const Blocked = () => {
  const { dispatch, settings, UI, warehouse } = useUIBp();
  const { value: password } = UI[passFieldId] ?? {};
  const blocked = UI[selectionBlockUI] ?? false;
  const { cpassword = "" } = warehouse[getConfigId] ?? {};
  const locale = settings.locale;

  const onSubmit = () => {
    dispatch(validateSelectionPassword({ password, cpassword, locale }));
  };

  console.log("Blocked.js: blocked", blocked);

  return (
    <CollapseGrid open={blocked} outerProps={{ size: 12 }}>
      <PassField {...{ onSubmit }} />
      <Submit {...{ onSubmit }} />
    </CollapseGrid>
  );
};

export default Blocked;
