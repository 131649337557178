import { Dialog } from "@mui/material";
import Close from "./Components/Close";
import Data from "./Components/Data";
import { useEffect } from "react";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import getRepositories, { getRepoId, repoDataId } from "../../../../store/actions/repositories/getRepositories";
import { workerActions } from "../../../../store/slices/workers";
import { warehouseActions } from "../../../../store/slices/warehouse";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";

const UIText = {
  "es-CL": "Dispositivo: ",
  "en-US": "Device: ",
};

const DeviceDataPop = () => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBp();
  const locale = settings.locale;
  const text = UIText[locale];
  const { payload } = UI.modal ?? {};
  const { data } = payload ?? {};
  const { name, user, mid } = data ?? {};

  const repositories = warehouse[repoDataId] ?? [];

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getRepositories({ ...auth, mid, user, controller }));
    return ()=>{
      controller.abort()
    }
  }, [dispatch, auth, mid, user]);

  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(getRepoId));
      dispatch(warehouseActions.unload(repoDataId));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "lg" }}>
      <BasicDialogTitle {...{ header: text + name }} />
      <BasicDialogContent>
        <Data {...{ data, repositories, locale }} />
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
      </BasicDialogActions>
    </Dialog>
  );
};

export default DeviceDataPop;
