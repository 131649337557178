import notEmptyUser from "../../../../../../../../utility/validations/notEmptyUser";
import TextField from "../../../../../../../UI/GeneralPurpose/SimpleTextField";

const UIText = {
  "es-CL": { label: "Correo de contacto", placeholder: "usuario@cloner.cl" },
  "en-US": { label: "Contact email", placeholder: "user@cloner.cl" },
};

export const contactEmailUI = "contactEmail";

const ContactEmail = () => {
  return (
    <TextField
      required
      {...{
        id: contactEmailUI,
        validation: notEmptyUser,
        UIText,
        size: {
          xl: 4,
          lg: 4,
          md: 4,
          sm: 12,
          xs: 12,
        },
      }}
    />
  );
};

export default ContactEmail;
