import { PersonAdd, PersonAddAlt1, UploadFile } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import { UIactions } from "../../../../../../store/slices/UI";
import MoreActions2 from "../../../../../UI/BasicLayout/Components/MoreActions/MoreActions2";

const UIText = {
  "es-CL": { label: "Crear" },
  "en-US": { label: "Create" },
};

const Create = () => {
  const dispatch = useDispatch();
  const { group } = useURLQuery();

  const buttonsData = [
    {
      icon: <PersonAddAlt1 sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": { label: "Crear usuario" },
        "en-US": { label: "Create user" },
      },
      onClick: () => {
        dispatch(
          UIactions.openModal({
            type: "create-new-user",
            payload: { group },
          })
        );
      },
    },
    {
      icon: <UploadFile sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": { label: "Cargar usuarios" },
        "en-US": { label: "Upload users" },
      },
      // disabled: true,
      onClick: () => {
        dispatch(
          UIactions.openModal({
            type: "upload-users",
            payload: { group },
          })
        );
      },
    },
  ];

  return (
    <MoreActions2
      {...{
        id: "userCreation",
        buttonsData,
        UIText,
        icon: <PersonAdd sx={{ fontSize: 18 }} />,
        placement:"bottom-start",
      }}
    />
  );
};

export default Create;
