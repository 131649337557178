import { Grid2, Typography } from "@mui/material";
import { humanBytes } from "../../../../../utility/misc/humanizers";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import useRecoveries from "../../../../../Hooks/useRecoveries";

const UIText = {
  "es-CL": {
    subt1: "Resumen de la solicitud de recuperación actual",
    users: "Usuarios",
    devices: "Dispositivos",
    repos: "Repositorios",
    size: "Tamaño total",
  },
  "en-US": {
    subt1: "Current recovery request summary",
    users: "Users",
    devices: "Devices",
    repos: "Repositories",
    size: "Total size",
  },
};

const RequestSummary = () => {
  const { settings } = useUIBp();
  const { subt1, users, devices, size } = UIText[settings.locale];
  const requestData = useRecoveries() ?? {};
  const { recoveries } = requestData;

  let usersList = [];
  let devicesCt = recoveries.length;
  let sizeSum = 0;
  for (const recovery of recoveries) {
    if (!usersList.includes(recovery.user)) {
      usersList.push(recovery.user);
    }
    for (const repo of recovery.repositories) {
      sizeSum += repo.size;
    }
  }

  return (
    <Grid2 container {...{ rowGap: 2, sx: { width: "100%" } }}>
      <Grid2 container size={12}>
        <Typography fontWeight="bold" fontSize="1.1rem">
          {subt1}:
        </Typography>
      </Grid2>
      <Grid2 container size={4} columnGap={2}>
        <Typography fontWeight="bold">{users}:</Typography>
        {usersList.length}
      </Grid2>
      <Grid2 container size={4} columnGap={2}>
        <Typography fontWeight="bold">{devices}:</Typography>
        {devicesCt}
      </Grid2>
      <Grid2 container size={4} columnGap={2}>
        <Typography fontWeight="bold">{size}:</Typography>
        {humanBytes(sizeSum)}
      </Grid2>
    </Grid2>
  );
};

export default RequestSummary;
