import { Dialog, DialogActions, DialogContent, Grid2 } from "@mui/material";
import Close from "./Components/Close";
import Footer, { versionsPaginationUI } from "./Components/Footer";
import { useEffect, useMemo } from "react";
import Header from "./Components/Header/Header";
import { filterFromUI } from "./Components/Header/FilterFrom";
import { filterToUI } from "./Components/Header/FilterTo";
import Headers from "./Components/Headers";
import FilesRow from "./Components/FilesRow/FilesRow";
import Core2 from "./Components/Core";
import CalculatePagination from "../../../UI/Utilities/CalculatePagination";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../Hooks/useWorker";
import {
  getVersionsId,
  versionsDataId,
} from "../../../../store/actions/devices/loadVersions";
import { UIactions } from "../../../../store/slices/UI";
import { workerActions } from "../../../../store/slices/workers";

export const versionsFilterUI = "versionsFilter";

const FileVersions = () => {
  const { dispatch, warehouse, UI } = useUIBp();
  const { done, working, error } = useWorker(getVersionsId);
  const fromField = UI[filterFromUI] ?? {};
  const dateFrom = fromField.value;
  const toField = UI[filterToUI] ?? {};
  const dateTo = toField.value;
  const pagination = UI[versionsPaginationUI] ?? {
    page: 0,
    count: 0,
    rowsPerPage: 0,
  };

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  const filteredVersions = useMemo(() => {
    const fromMS = Date.parse(dateFrom);
    const toMS = Date.parse(dateTo);

    const versions = warehouse[versionsDataId] ?? [];

    const versions2 = versions
      .filter((x) => {
        return fromMS ? fromMS <= Date.parse(x.created) : true;
      })
      .filter((x) => {
        return toMS ? toMS >= Date.parse(x.created) : true;
      });
    return versions2.map((data) => <FilesRow key={data.id} {...data} />);
  }, [warehouse, dateFrom, dateTo]);

  useEffect(() => {
    dispatch(
      UIactions.setPagination({
        id: versionsPaginationUI,
        count: filteredVersions.length,
      })
    );
  }, [dispatch, filteredVersions.length]);

  const { from, to } = CalculatePagination(pagination);
  const paginatedVersions = useMemo(() => {
    return filteredVersions.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredVersions]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(filterFromUI));
      dispatch(UIactions.clear(filterToUI));
      dispatch(UIactions.clear(versionsPaginationUI));
      dispatch(workerActions.clear(getVersionsId));
    };
  }, [dispatch]);

  return (
    <Dialog
      fullWidth
      {...{
        open: true,
        onClose,
        maxWidth: "md",
      }}
    >
      <Header />
      <DialogContent sx={{ padding: 2, paddingTop: 0 }}>
        <Grid2 container size={true}>
          <Headers />
          <Core2
            {...{
              elements: paginatedVersions,
              workerId: getVersionsId,
              done,
              working,
              error,
            }}
          />
          <Footer />
        </Grid2>
      </DialogContent>
      <DialogActions sx={{ paddingLeft: 3, paddingRight: 3 }}>
        <Close />
      </DialogActions>
    </Dialog>
  );
};

export default FileVersions;
