import { useDispatch } from "react-redux";
import BoxStateIcon from "../../../../../../../utility/misc/boxStateIcon";
import { treesActions } from "../../../../../../../store/slices/trees";
import IconButton from "../../../../../../UI/GeneralPurpose/IconButton";

const UIText = {
  universal: {},
};

const SelectButton = ({ treeId:id, idx, backup, working }) => {
  const dispatch = useDispatch();

  const checkIcon = BoxStateIcon(backup, working);

  const handleSelect = () => {
    dispatch(treesActions.RSnodeSelect({ id, idx }));
  };

  return (
    <IconButton
      {...{
        UIText,
        disabled: working,
        onClick: handleSelect,
        icon: checkIcon,
        sx: { padding: 0.3 },
        size: "auto",
      }}
    />
  );
};

export default SelectButton;
