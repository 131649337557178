import { ChevronLeft } from "@mui/icons-material";
import Button from "../../../../UI/GeneralPurpose/Button";
import { Grid2 } from "@mui/material";

const UIText = {
  "es-CL": { label: "Atras" },
  "en-US": { label: "Back" },
};

const Back = () => {
  return (
    <Grid2 container size={4}>
      <Button
        {...{
          UIText,
          navTo: "/dashboard/recovery/step1",
          startIcon: <ChevronLeft />,
        }}
      />
    </Grid2>
  );
};

export default Back;
