import { Grid2 } from "@mui/material";
import ChangeGroup, { changeGroupUI } from "./ChangeGroup";
import GroupButton from "./GroupButton";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import CollapseGrid from "../../../../../UI/GeneralPurpose/CollapseGrid";

const Group = ({ isNew, users, group }) => {
  const { UI, settings } = useUIBp();
  const changeGroup = UI[changeGroupUI];

  return (
    <Grid2 container {...{ size: 12 }}>
      {users.length > 1 ? (
        <ChangeGroup {...{ locale: settings.locale }} />
      ) : null}
      <CollapseGrid
        open={changeGroup || users.length === 1 || isNew}
        outerProps={{ size: 12 }}
      >
        <GroupButton
          init
          disabled={isNew}
          startGroup={users.length === 1 ? users[0].group : group}
        />
      </CollapseGrid>
    </Grid2>
  );
};

export default Group;
