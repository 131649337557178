import { useEffect } from "react";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { addRecoveriesUI } from "../../../../../store/actions/recoveries/addRecoveries";
import { workerActions } from "../../../../../store/slices/workers";
import { recoveriesActions } from "../../../../../store/slices/recoveries";
import createUsersRequest from "../../../../../store/actions/recoveries/createUsersRequest";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Iniciar nueva solicitud" },
  "en-US": { label: "Start new request" },
};

const Continue = ({ users = [] }) => {
  const { auth, dispatch, settings } = useUIBp();
  const locale = settings.locale;


  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(addRecoveriesUI));
    };
  }, [dispatch]);

  const onClick = () => {
    dispatch(recoveriesActions.reset());
    dispatch(
      createUsersRequest({
        ...auth,
        users,
        locale,
      })
    );
    dispatch(UIactions.closeModal());
  };

  return <Button {...{ UIText, onClick }} />;
};

export default Continue;
