import { Dialog, Grid2 } from "@mui/material";
import BasicDialogContent from "../../UI/BasicDialog/BasicDialogContent";
import BasicDialogTitle from "../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogActions from "../../UI/BasicDialog/BasicDialogActions";
import { Download } from "@mui/icons-material";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { platformServices } from "../../../store/actions/microsoft365/getPlatformsAndServices";
import { UIactions } from "../../../store/slices/UI";
import { createClientsPDF } from "../../../store/actions/microsoft365/createClientsPDF";
import SlimAlert from "../../UI/GeneralPurpose/SlimAlert";
import Button from "../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Cerrar" },
  "en-US": { label: "Close" },
};
const UIText2 = {
  "es-CL": { label: "Descargar PDF" },
  "en-US": { label: "Download PDF" },
};

const UITextMessages = {
  "es-CL": {
    header: "Guardado de clientes finalizado",
    message: `El proceso de guardado de clientes finalizó. Le sugerimos descargar el archivo PDF con el resumen del resultado de la operación.`,
  },
  "en-US": {
    header: "Users creation finished",
    message: `The users creation process ended. We suggest that you download the PDF file summarizing the operation.`,
  },
};

const CreateClientsAfterM365Post = (props) => {
  const { dispatch, settings, UI, warehouse } = useUIBp();
  const { payload } = UI.modal2;
  const services = warehouse[platformServices] ?? [];
  const locale = settings.locale;

  const { header, message } = UITextMessages[locale];

  const onClose = (e, reason) => {
    dispatch(UIactions.closeModal2());
  };

  const onClick = () => {
    dispatch(createClientsPDF({ pdfData: payload.results, services, locale }));
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2 container {...{ sx: { width: "100%" } }}>
          <SlimAlert
            notCollapse
            {...{ errors: [message], severity: "info", size: 12 }}
          />
          <Grid2
            container
            {...{ padding: 3, size: 12, justifyContent: "center" }}
          >
            <Button
              {...{
                UIText: UIText2,
                onClick,
                variant: "outlined",
                startIcon: <Download />,
                size: "medium",
              }}
            />
          </Grid2>
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Button {...{ UIText, onClick: onClose }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default CreateClientsAfterM365Post;
