import { Grid2 } from "@mui/material";
import ShowDeleted from "./Components/Deleted";
import Export from "./Components/Export";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

export const plusActionsGrid = {
  xl: 4,
  lg: 4,
  md: 4,
  sm: 6,
  xs: 5,
};

const PlusActions = ({ allFiles }) => {
  const { auth } = useUIBp();

  return (
    <Grid2
      container
      {...{
        columnSpacing: 2,
        justifyContent: "end",
        size: { xl: 8, lg: 8, md: 8, sm: 6, xs: 7 },
      }}
    >
      <ShowDeleted />
      {auth.group === 1 ? <Export {...{ allFiles }} /> : null}
    </Grid2>
  );
};

export default PlusActions;
