import axios from "axios";
import { workerActions } from "../../../store/slices/workers";
import { treesActions } from "../../../store/slices/trees";
import { parseMailFolders } from "./utils";

export const getMailCategoriesId = "mailCategories";

const getMailCategories = ({ M365server, cloner_key }) => {
  const id = getMailCategoriesId;
  console.log("Getting mail categories")

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {

      // Getting the mail through the /api/folder handler
      const url = M365server + "mailapi/folder";
      const response = await axios.get(url, { headers: { cloner_key } });

      // Parsing the mail folders and loading them in the mail folders tree
      for (const cat of response.data) {
        let nodes = [];
        parseMailFolders(cat, nodes, null);
        dispatch(treesActions.loadTree({ id: `mails-${nodes[0].id}`, tree: nodes }));
      }

      dispatch(workerActions.finish({ id }));
    } catch (e) {
      console.error(e)
      dispatch(workerActions.finish({ id, error:e.message }));
    }
  };
};

export default getMailCategories;