import { Fragment } from "react";
import Divider from "../../UI/GeneralPurpose/Divider";
import { Grid2 } from "@mui/material";
import Button from "../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Seleccionar" },
  "en-US": { label: "Select" },
};
const Bottom = ({ close }) => {
  return (
    <Fragment>
      <Divider />
      <Grid2 container padding={1} justifyContent="end">
        <Button {...{ UIText, onClick: close }} />
      </Grid2>
    </Fragment>
  );
};

export default Bottom;
