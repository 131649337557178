import { Dialog, Grid2 } from "@mui/material";
import BasicDialogContent from "../../UI/BasicDialog/BasicDialogContent";
import BasicDialogTitle from "../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogActions from "../../UI/BasicDialog/BasicDialogActions";
import { Download } from "@mui/icons-material";
import useTrees from "../../../Hooks/useTrees";
import {
  groupsTreeId,
  selectedGroupIdx,
} from "../../GroupsTraveler/Actions/loadGroupsTree";
import { useEffect } from "react";
import { warehouseActions } from "../../../store/slices/warehouse";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { postedUsersData } from "../../../store/actions/users/postUsers";
import { UIactions } from "../../../store/slices/UI";
import Button from "../../UI/GeneralPurpose/Button";
import SlimAlert from "../../UI/GeneralPurpose/SlimAlert";
import { createUsersPDF } from "./UsersUpload/Actions/createPDF";

const UIText = {
  "es-CL": { label: "Cerrar" },
  "en-US": { label: "Close" },
};
const UIText2 = {
  "es-CL": { label: "Descargar PDF" },
  "en-US": { label: "Download PDF" },
};

const UITextMessages = {
  "es-CL": {
    header: "Creación de usuarios finalizada",
    message: `El proceso de creación de usuarios finalizó. Le sugerimos descargar el archivo PDF con el resumen del resultado de la operación, en particular si fueron creador usuarios con contraseña aleatoria o si se gatilló algun mensaje de error.`,
  },
  "en-US": {
    header: "Users creation finished",
    message: `The users creation process ended. We suggest that you download the PDF file summarizing the operation, particularily if random password users were created or if any error message was triggered.`,
  },
};

const CreateUsersAfterPost = () => {
  const { dispatch, settings, UI, warehouse } = useUIBp();
  const trees = useTrees() ?? {};
  const groups = trees[groupsTreeId] ?? [];
  const currentGroupIdx = UI[selectedGroupIdx];
  const postedUsers = warehouse[postedUsersData] ?? [];
  const locale = settings.locale;
  const { header, message } = UITextMessages[locale];

  let groupPath = "";
  const buildTrace = (idx) => {
    const group = groups.find((x) => x.idx === idx);
    groupPath = "/" + group.name + groupPath;
    if (group.parentIdx !== null) {
      buildTrace(group.parentIdx);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(warehouseActions.unload(postedUsersData));
    };
  }, [dispatch]);

  const onClose = () => {
    dispatch(UIactions.closeModal());
  };

  const onClick = () => {
    buildTrace(currentGroupIdx);
    let pdfData = [];
    for (const user of postedUsers) {
      const data = { ...user, groupPath };
      pdfData.push(data);
    }
    dispatch(createUsersPDF({ pdfData, locale }));
  };

  return (
    <Dialog {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2 container {...{ sx: { width: "100%" } }}>
          <SlimAlert
            notCollapse
            {...{ errors: [message], severity: "info", size: 12 }}
          />
          <Grid2
            container
            {...{ padding: 3, size: 12, justifyContent: "center" }}
          >
            <Button
              {...{
                UIText: UIText2,
                onClick,
                variant: "outlined",
                startIcon: <Download />,
                size: "medium",
              }}
            />
          </Grid2>
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Button {...{ UIText, onClick: onClose }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default CreateUsersAfterPost;
