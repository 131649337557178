import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

const UIText = {
  "es-CL": { error: "Error al descargar informacion de archivos" },
  "en-US": { error: "Error while loading files information" },
};

export const filesDataId = "files";
export const breadcrumbsId = "breadcrumbs";

export const getFiles = ({ server, cloner_key, file, repo, locale }) => {
  const { error } = UIText[locale];

  return async (dispatch) => {
    dispatch(workerActions.start("file-" + file));

    try {
      // Getting the files through the /api/files endpoint
      const url = server + "api/files";
      const response = await axios.get(url, {
        headers: { cloner_key },
        params: { id: file, repoid: repo },
      });

      // Filling the warehouse with the files and breadcrumbs (this last one reversed)
      dispatch(
        warehouseActions.load({ id: filesDataId, data: response.data.files })
      );
      dispatch(
        warehouseActions.load({
          id: breadcrumbsId,
          data: [...response.data.breadcrumb].reverse(),
        })
      );
      dispatch(workerActions.finish({ id: "file-" + file }));
    } catch (e) {
      console.log(e);
      dispatch(workerActions.finish({ id: "file-" + file, error: e.message }));
      enqueueSnackbar(error, { variant: "error" });
    }
  };
};
