import Switch from "../../../GeneralPurpose/Switch";
import { useDispatch } from "react-redux";
import { workerActions } from "../../../../../store/slices/workers";
import { devicesLoadedId } from "../../../../../store/actions/devices/getRootDevices";
import { UIactions } from "../../../../../store/slices/UI";
import { resetScrollId } from "../Core/Core";
import useURLQuery from "../../../../../Hooks/useURLQuery";

const UIText = {
  "es-CL": {
    label: "Incluir subgrupos",
    fixedTooltip: "Incluir dispositivos de subgrupos",
  },
  "en-US": {
    label: "Include subgroups",
    fixedTooltip: "Include devices from subgroups",
  },
};

export const recursiveRequestId = "recursiveRequest";

const RecursiveSwitch = ({ size, switchSize, working: disabled }) => {
  const dispatch = useDispatch();
  const { group } = useURLQuery();
  const workerId = `groupData${group}`;

  const onChange = () => {
    dispatch(workerActions.clear(workerId));
    dispatch(workerActions.clear(devicesLoadedId));
    dispatch(UIactions.setValue({ id: resetScrollId, value: true }));
  };

  return (
    <Switch
      labelRight
      {...{
        id: recursiveRequestId,
        UIText,
        disabled,
        onChange,
        switchSize,
        size,
      }}
    />
  );
};

export default RecursiveSwitch;
