import { Typography } from "@mui/material";
import AutoHelp from "../../../../UI/Wrappers/AutoHelp";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": { from: "De: ", to: "Para: " },
  "en-US": { from: "From: ", to: "To: " },
};

const Sender = ({ fontSize, fromM, toRecipients }) => {
  const { settings } = useUIBp();
  const { from, to } = UIText[settings.locale];
  const emailFrom = JSON.parse(fromM);
  const emailTo = JSON.parse(toRecipients);

  let text = "";
  let fixedTooltip = "";
  if (emailFrom) {
    text = `${from} ${emailFrom.emailAddress.name}`;
    fixedTooltip = `(${emailFrom.emailAddress.name}) ${emailFrom.emailAddress.address}`;
  } else if (emailTo) {

    text = `${to}`;
    let first = true
    for (const m of emailTo){
      if (first){
        first = false
      } else {
        text = `${text}, `
        fixedTooltip = `${fixedTooltip}, `
      }
      text = `${text}${m.emailAddress.name}`
      fixedTooltip = `${fixedTooltip}(${m.emailAddress.name}) ${m.emailAddress.address}`
    }
  }

  return (
    <AutoHelp {...{ fixedTooltip }}>
      <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
        {text}
      </Typography>
    </AutoHelp>
  );
};

export default Sender;
