import { Dialog, DialogContent } from "@mui/material";
import { useEffect } from "react";
import Header from "./Components/Header/Header";
import FromTo from "./Components/FromTo/FromTo";
import Loading from "./Components/Loading";
import Mail, { iframeHeightUI } from "./Components/Mail";
import Attachs from "./Components/Attachs/Attachs";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../Hooks/useWorker";
import getMail, { getMailId, mailData } from "../../Actions/getMail";
import { UIactions } from "../../../../store/slices/UI";
import { warehouseActions } from "../../../../store/slices/warehouse";
import { workerActions } from "../../../../store/slices/workers";

const MailView = () => {
  const { auth, dispatch, UI } = useUIBp();
  const { done } = useWorker(getMailId);
  const { payload = {} } = UI.modal ?? {};
  const { id: mailId } = payload;

  useEffect(() => {
    if (!done) {
      const controller = new AbortController();
      dispatch(getMail({ ...auth, mailId, controller }));
      return () => {
        controller.abort();
      };
    }
  }, [dispatch, auth, mailId, done]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear("modal"));
      dispatch(UIactions.clear(iframeHeightUI));
      dispatch(warehouseActions.unload(mailData));
      dispatch(workerActions.clear(getMailId));
    };
  }, [dispatch]);

  const onClose = () => {
    dispatch(UIactions.closeModal());
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "md" }}>
      <Header {...payload} />
      <DialogContent sx={{ padding: 0, overflow: "hidden" }}>
        <FromTo {...payload} />
        <Mail />
        <Loading />
        <Attachs {...payload} />
      </DialogContent>
    </Dialog>
  );
};

export default MailView;
