import { Grid2, Typography } from "@mui/material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import useRecoveries from "../../../../../Hooks/useRecoveries";
import Divider from "../../../../UI/GeneralPurpose/Divider";
import TextField from "../../../../UI/GeneralPurpose/SimpleTextField";
import Group from "./Group/Group";

const UITextG = {
  "es-CL": {
    subtitle1: "Administrador solicitante",
    subtitle2: "Contacto de recepción",
    subtitle3: "Dirección de envío",
    subtitle4: "Horario de recepción",
    subtitle5: "Metodo de envío:",
    contactName: "Nombre",
    contactPhone: "Teléfono",
    contactEmail: "Correo electrónico",
    mode1: "Vínculo de descarga",
    mode2: "Disco duro",
  },
  "en-US": {
    subtitle1: "Requesting administrator",
    subtitle2: "Reception contact",
    subtitle3: "Shipping address",
    subtitle4: "Reception schedule",
    subtitle5: "Delivery method:",
    contactName: "Name",
    contactPhone: "Phone",
    contactEmail: "Email",
    mode1: "Download link",
    mode2: "Hard drive",
  },
};

export const adminNameUI = "adminName";
export const adminUserUI = "adminUser";
export const organizationUI = "organization";

const DeliverySummary = () => {
  const { auth, settings } = useUIBp();
  const requestData = useRecoveries() ?? {};
  const { deliveryData } = requestData ?? {};
  const locale = settings.locale;
  const {
    subtitle1,
    subtitle2,
    subtitle3,
    subtitle4,
    subtitle5,
    contactName,
    contactPhone,
    contactEmail,
    mode1,
    mode2,
  } = UITextG[locale];

  const UIText1 = {
    "es-CL": { label: "Nombre", placeholder: auth.name },
    "en-US": { label: "Name", placeholder: auth.name },
  };

  const UIText2 = {
    "es-CL": { label: "Usuario", placeholder: auth.user },
    "en-US": { label: "User", placeholder: auth.user },
  };

  const UIText3 = {
    "es-CL": { label: "Organización", placeholder: auth.groupName },
    "en-US": { label: "Organization", placeholder: auth.groupName },
  };

  return (
    <Grid2 container {...{ padding: 2, rowGap: 2, columnSpacing: 2, size: 12 }}>
      <Grid2
        container
        {...{
          alignItems: "center",
          size: { xl: 2, lg: 3, md: 12, sm: 12, xs: 12 },
        }}
      >
        <Typography fontWeight="bold">{subtitle1}</Typography>
      </Grid2>
      <TextField
        fullWidth
        forceShrink
        {...{
          id: adminNameUI,
          disabled: auth.admin !== 1,
          UIText: UIText2,
          size: {
            xl: 2,
            lg: 3,
            md: 4,
            sm: 12,
            xs: 12,
          },
        }}
      />
      <TextField
        fullWidth
        forceShrink
        {...{
          id: adminUserUI,
          disabled: auth.admin !== 1,
          UIText: UIText1,
          size: {
            xl: 2,
            lg: 3,
            md: 4,
            sm: 12,
            xs: 12,
          },
        }}
      />
      <Group />
      {/* <TextField
        fullWidth
        forceShrink
        {...{
          id: organizationUI,
          disabled: auth.admin !== 1,
          UIText: UIText3,
          size: {
            xl: 2,
            lg: 3,
            md: 4,
            sm: 12,
            xs: 12,
          },
        }}
      /> */}
      <Divider {...{ size: 12 }} />
      <Grid2 container {...{ size: 12 }}>
        <Typography fontWeight="bold">{subtitle2}</Typography>
      </Grid2>
      <Grid2
        container
        {...{
          wrap: "nowrap",
          size: { xl: 2, lg: 3, md: 4, sm: 12, xs: 12 },
          columnGap: 2,
        }}
      >
        <Typography fontWeight="bold" fontSize="0.95rem">
          {contactName}:
        </Typography>
        <Typography noWrap>{deliveryData.contactName}</Typography>
      </Grid2>
      <Grid2
        container
        {...{
          wrap: "nowrap",
          size: { xl: 2, lg: 3, md: 4, sm: 12, xs: 12 },
          columnGap: 2,
        }}
      >
        <Typography fontWeight="bold" fontSize="0.95rem">
          {contactPhone}:
        </Typography>
        <Typography noWrap>{deliveryData.contactPhone}</Typography>
      </Grid2>
      <Grid2
        container
        {...{
          wrap: "nowrap",
          size: { xl: 8, lg: 6, md: 4, sm: 12, xs: 12 },
          columnGap: 2,
        }}
      >
        <Typography fontWeight="bold" fontSize="0.95rem">
          {contactEmail}:
        </Typography>
        <Typography noWrap>{deliveryData.contactEmail}</Typography>
      </Grid2>
      <Grid2 container {...{ size: 12, columnGap: 2 }}>
        <Typography fontWeight="bold">{subtitle5}</Typography>
        <Typography>
          {deliveryData.recoveryMode === "hdd" ? mode2 : mode1}
        </Typography>
      </Grid2>
      {deliveryData.recoveryMode === "hdd" ? (
        <Grid2 container {...{ size: 12, gap: 2 }}>
          <Typography fontWeight="bold">{subtitle3}:</Typography>
          <Typography>
            {deliveryData.deliveryStreet} {deliveryData.deliveryStreetNumber}{" "}
            {deliveryData.deliveryOffice
              ? `( ${deliveryData.deliveryOffice} )`
              : ""}
            , {deliveryData.district}, {deliveryData.city},{" "}
            {deliveryData.region}.
          </Typography>
        </Grid2>
      ) : null}
      {deliveryData.recoveryMode === "hdd" ? (
        <Grid2 container {...{ size: 12, columnGap: 2 }}>
          <Typography fontWeight="bold">{subtitle4}:</Typography>
          <Typography>
            {deliveryData.deliveryWindowFrom} - {deliveryData.deliveryWindowTo}
          </Typography>
        </Grid2>
      ) : null}
      {/* <Divider {...{ xs: 12 }} /> */}
    </Grid2>
  );
};

export default DeliverySummary;
