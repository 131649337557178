import { Dialog, Grid2 } from "@mui/material";
import TopBar from "./Components/TopBar/TopBar";
import Create from "./Components/Create";
import Cancel from "./Components/Cancel";
import UsersList, { sortId } from "./Components/UsersList/UsersList";
import { newUsersUI } from "./Components/TopBar/UploadSheet";
import Empty from "./Components/Empty";
import { useEffect } from "react";
import { rowErrorsUI } from "./Components/UsersList/UserRow";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { postUserId } from "../../../../store/actions/users/postUsers";
import { UIactions } from "../../../../store/slices/UI";
import { useWorker } from "../../../../Hooks/useWorker";
import { workerActions } from "../../../../store/slices/workers";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";

const UITextHeader = {
  "es-CL": "Crear usuarios",
  "en-US": "Create users",
};

const UsersUpload = () => {
  const { dispatch, settings, UI } = useUIBp();
  const { done } = useWorker(postUserId);
  const locale = settings.locale;
  const header = UITextHeader[locale];
  const users = UI[newUsersUI] ?? [];

  useEffect(() => {
    if (done) {
      dispatch(UIactions.openModal({ type: "download-creation-report" }));
    }
  }, [dispatch, done]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(sortId));
      dispatch(UIactions.clear(rowErrorsUI));
      dispatch(UIactions.clear(newUsersUI));
      dispatch(workerActions.clear(postUserId));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    console.log("reason", reason);
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "lg" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2 container {...{ sx: { width: "100%" } }}>
          <TopBar />
          {!!users.length ? <UsersList /> : <Empty />}
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel />
        <Create />
      </BasicDialogActions>
    </Dialog>
  );
};

export default UsersUpload;
