import { SystemUpdateAlt } from "@mui/icons-material";
import { currentGroupnameId } from "../../../../../GroupsTraveler/Actions/loadGroupsTree";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import { UIactions } from "../../../../../../store/slices/UI";
import ToggleButton from "../../../../../UI/GeneralPurpose/ToggleButton";

const UIText = {
  "es-CL": {
    label: "Obtener",
    fixedTooltip: "Obtener las credenciales desde el grupo padre",
  },
  "en-US": { label: "Fetch", fixedTooltip: "Fetch credentials shared by parent group" },
};

export const w365fetchUI = "windows365fetch";

const GroupLock = ({ domain = null, own,working }) => {
  const { dispatch, UI } = useUIBp();
  const { group } = useURLQuery();
  const groupname = UI[currentGroupnameId];

  const onClick = () => {
    dispatch(
      UIactions.openModal2({
        type: "m365-fetch",
        payload: {
          groupname,
          payload: {
            domain,
            id: own.id,
            group_id: group,
            prevent_propagation: domain ? own.prevent_propagation: true,
            group_locked: domain ? !own.group_locked: false,
          },
        },
      })
    );
  };

  return (
    <ToggleButton
      {...{
        id: w365fetchUI,
        UIText,
        onClick,
        startIcon: <SystemUpdateAlt sx={{ marginRight: 1, fontSize: 20 }} />,
        color: "primary",
        variant: "outlined",
      }}
    />
  );
};

export default GroupLock;
