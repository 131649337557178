import { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
} from "@mui/material";
import notEmptyPassword from "../../../utility/validations/notEmptyPassword";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../Hooks/useWorker";
import { UIactions } from "../../../store/slices/UI";
import { workerActions } from "../../../store/slices/workers";
import { getSharedFilesId, sharedFilesData } from "../Actions/getSharedFiles";
import SlimAlert from "../../UI/GeneralPurpose/SlimAlert";
// import TextField from "../../UI/SimpleTextField";
import PassField from "../../UI/GeneralPurpose/PassField";
import Button from "../../UI/GeneralPurpose/Button";
import updateSharedLink, { updateSharedLinkId } from "../Actions/updateSharedLink";

const UIText = {
  "es-CL": {
    header: "Proteger enlace de descarga",
    instructions:
      "La contraseña será requerida por cualquiera que intente descargar el archivo mediante el enlace de descarga",
  },
  "en-US": {
    header: "Protect download link",
    instructions:
      "The password will be required of anyone that attempts to download the file through the download link",
  },
};
const UIText1 = {
  "es-CL": { label: "Contraseña de descarga", helper: "" },
  "en-US": { label: "Download password", helper: "" },
};
const UIText2 = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};
const UIText3 = {
  "es-CL": { label: "Proteger" },
  "en-US": { label: "Protect" },
};

export const passwordFieldId = "passwordField";

const ProtectSharedLink = (props) => {
  const { auth, dispatch, navigate, settings, UI, warehouse } =
    useUIBp();
  const { done, working, error } = useWorker(updateSharedLinkId);
  const locale = settings.locale;
  const { helper } = UIText[locale];
  const { payload } = UI.modal;
  const { id: Id } = payload;
  const sharedFiles = warehouse[sharedFilesData];

  const { header, instructions } = UIText[locale];
  const passField = UI[passwordFieldId] ?? {};
  const { value: password, error: error2 } = passField;

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
      dispatch(UIactions.clear("selected"));
      dispatch(workerActions.clear(getSharedFilesId));
    }
  }, [dispatch, navigate, done, error]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(passwordFieldId));
      dispatch(workerActions.clear(updateSharedLinkId));
    };
  }, [dispatch]);

  const close = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  const validation = () => {
    dispatch(
      UIactions.validateField({
        id: passwordFieldId,
        force: true,
        fn: notEmptyPassword,
        locale,
      })
    );
  };

  const submit = () => {
    const { id, file, repo_id } = sharedFiles.find((x) => x.id === Id);
    dispatch(
      updateSharedLink({
        ...auth,
        locale,
        payload: {
          id,
          file,
          repo_id,
          password,
          password_set: true,
          public: true,
        },
      })
    );
  };

  return (
    <Dialog
      fullWidth
      {...{
        open: true,
        onClose: close,
        maxWidth: "xs",
      }}
    >
      <DialogTitle>{header}</DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Grid2 container {...{rowGap:2, paddingLeft:2, paddingRight:2}}>
          <SlimAlert
            notCollapse
            {...{ errors: [instructions], severity: "info", size: 12 }}
          />
          <PassField
            autoFocus
            tagError
            {...{
              id: passwordFieldId,
              validation: notEmptyPassword,
              type: "password",
              UIText: UIText1,
              helperText: helper,
              submit,
              onKeyDown: validation,
              componentSize: "medium",
              size: 12,
            }}
          />
        </Grid2>
      </DialogContent>
      <DialogActions sx={{ paddingLeft: 3, paddingRight: 3 }}>
        <Button
          {...{
            UIText: UIText2,
            onClick: close,
            variant: "text",
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
        <Button
          {...{
            disabled: !!error2,
            UIText: UIText3,
            onClick: submit,
            onMouseDown: validation,
            working,
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ProtectSharedLink;
