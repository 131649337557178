import { enqueueSnackbar } from "notistack";
import axios from "axios";
import { workerActions } from "../../../store/slices/workers";

export const forceRescanId = "forceRescan";

const UIText = {
  "es-CL": {
    start: "Forzando el reescaneo de equipos",
    success: "Reescaneo solicitado exitosamente",
    serverError: "Servidor retornó error",
    error: "Error al intentar solicitar el rescaneo de un equipo",
  },
  "en-US": {
    start: "Forcing devices to rescan data",
    success: "Rescan requested successfully",
    serverError: "Server returned error",
    error: "Error while trying to request a rescan",
  },
};

const forceRescan = (data) => {
  const { server, cloner_key, payload, locale } = data;
  return async (dispatch) => {
    const { start, success, serverError, error } = UIText[locale];
    dispatch(workerActions.start(forceRescanId));
    enqueueSnackbar(start, { variant: "info" });
    try {
      for (const item of payload) {
        const bodyData = JSON.stringify(item);
        const url = server + "api/rescan";
        const response = await axios.post(url, bodyData, {
          headers: { cloner_key },
        });
        if (!response.data) {
          throw new Error(serverError);
        }
      }
      dispatch(workerActions.finish({ id: forceRescanId }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id: forceRescanId, error: e.msg }));
      enqueueSnackbar(error, { variant: "error" });
    }
  };
};

export default forceRescan;
