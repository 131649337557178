import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";
import { getM365ClientId } from "./getClient";

const UIText = {
  "es-CL": {
    success: "Activacion del usuario de Windows365 exitosa",
    error: "Error al activar usuario de Windows365: ",
  },
  "en-US": {
    success: "Windows365 user activation successful",
    error: "Error while activating Windows365 user: ",
  },
};

export const postClientId = "post365client";

const postClient = ({ M365server, cloner_key, payload, locale }) => {
  const id = postClientId;
  const { success, error } = UIText[locale];

  return async (dispatch) => {
    const bodyData = JSON.stringify(payload);
    dispatch(workerActions.start(id));
    try {
      const url = `${M365server}mailapi/client`;
      await axios.post(url, bodyData, {
        headers: { cloner_key },
      });
      enqueueSnackbar(success, { variant: "success" });
      dispatch(workerActions.finish({ id }));
      dispatch(workerActions.clear(getM365ClientId));
    } catch (e) {
      console.error(e);
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};

export default postClient;
