import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { CustomError } from "../../../utility/misc/response";
import { worker } from "../../../store/slices/workers";
import { UIactions } from "../../../store/slices/UI";
import { authActions } from "../../../store/slices/authentication";

const UIText = {
  "es-CL": {
    badPassword: "Contraseña equivocada",
    badUser: "Usuario no existe",
  },
  "en-US": { badPassword: "Bad password", badUser: "User doesn't exist" },
};

export const loginWrkID = "login";
export const TFA_ID = "twofactor";

const director = "https://login.cloner.cl/api/server";
export const M365server = "https://c365.cloner.cl:4022/";
export const Dev365server = "https://devel.cloner.cl:4022/";

const loginToCloner = ({ locale, user, pass }) => {
  const id = loginWrkID;

  return async (dispatch) => {
    console.log("Signing in to Cloner!");
    const { badPassword, badUser } = UIText[locale];
    dispatch(worker.start(id));
    try {
      // GET login server
      const response = await axios.get(director, { params: { login:user } });
      const loginServer = response.data;

      // POST login data
      const bodyData = JSON.stringify({ user, pass });
      const url = loginServer + "api/user/login";
      const response2 = await axios.post(url, bodyData);

      const rData = response2.data;
      if (rData.resp === 2) { 
        // User doesn't exist
        throw CustomError(badPassword, "ERR_BAD_LOGIN");
      } else if (rData.resp === 7 && rData.has_tfa === 2) { 
        // User has 2FA
        dispatch(UIactions.setValue({ id: TFA_ID, value: true }));
      } else if (response2.data.resp === 0) { 
        // Direct login
        const url2 = loginServer + "api/groups";
        const response3 = await axios.get(url2, {
          params: { group: response2.data.group },
          headers: { cloner_key: response2.data.token },
        });
        const groupName = response3.data.name;

        let server;
        if (loginServer === "https://zifre.cloner.cl/") {
          server = M365server;
        } else {
          server = Dev365server;
        }
        const url = `${server}mailapi/login`;

        const response4 = await axios.post(url, bodyData, {
          headers: { cloner_key: response2.data.token },
        });

        dispatch(
          authActions.setAuthentication({
            data: { ...response2.data, groupName },
            server: loginServer,
          })
        );
        dispatch(authActions.setO365Data({ ...response4.data, server }));
      }
      dispatch(worker.finish({ id }));
    } catch (e) {
      console.error(e);
      if (e.code === "ERR_BAD_REQUEST") {
        enqueueSnackbar(badUser, { variant: "error" });
      } else if (e.code === "ERR_BAD_LOGIN") {
        enqueueSnackbar(badPassword, { variant: "error" });
      } else {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};

export default loginToCloner;
