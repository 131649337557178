import { Divider, Grid2 } from "@mui/material";
import DeleteUser from "./Delete";
import Recover from "./Recover";
import EditUsers from "./Edit";
import Create from "./Create";
import CloudIntegrations from "./CloudIntegrations";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";

export const plusActionsGrid = {
  xl: 5,
  lg: 6,
  md: 12,
  sm: 12,
  xs: 12,
};

const PlusActions = () => {
  const { UI } = useUIBp();
  const viewmode = UI["viewmode"] ?? "desktop";

  return (
    <Grid2
      container
      {...{
        columnGap: 2,
        justifyContent: viewmode === "desktop" ? "end" : "space-between",
        size: { xl: 7, lg: 6, md: 12, sm: 12, xs: 12 },
      }}
    >
      <Create />
      <Divider orientation="vertical" variant="middle" flexItem />
      <EditUsers />
      <CloudIntegrations />
      <DeleteUser />
      <Recover />
    </Grid2>
  );
};

export default PlusActions;
