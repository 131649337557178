import notEmptyField from "../../../../../../../../utility/validations/notEmpty";
import TextField from "../../../../../../../UI/GeneralPurpose/SimpleTextField";

const UIText = {
  "es-CL": { label: "Calle", placeholder: "Av. Vitacura" },
  "en-US": { label: "Street", placeholder: "Vitacura Avenue" },
};
export const deliveryStreetUI = "deliveryStreet";

const DeliveryStreet = () => {
  return (
    <TextField
      required
      {...{
        id: deliveryStreetUI,
        validation: notEmptyField,
        UIText,
        size: {
          xl: 6,
          lg: 6,
          md: 12,
          sm: 12,
          xs: 12,
        },
      }}
    />
  );
};

export default DeliveryStreet;
