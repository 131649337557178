import useUIBp from "../../../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../../../Hooks/useWorker";
import {
  deleteOrgId,
  deleteOrganization,
} from "../../../../../../../../store/actions/microsoft365/deleteOrganization";
import Button from "../../../../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Confirmar" },
  "en-US": { label: "Confirm" },
};

const Confirm = () => {
  const { auth, dispatch, settings, UI } = useUIBp();
  const { working } = useWorker(deleteOrgId);
  const { payload } = UI.modal2 ?? {};
  const { orgId } = payload ?? {};
  const locale = settings.locale;

  const onClick = () => {
    dispatch(deleteOrganization({ ...auth, orgId, locale }));
  };

  return <Button {...{ UIText, onClick, working }} />;
};

export default Confirm;
