import MultiSelect from "../../../../../UI/GeneralPurpose/MultiSelect";

const UIText = {
  "es-CL": { label: "Permisos" },
  "en-US": { label: "Permissions" },
};

const options = [
  { 
    name: { "es-CL": "Administrador", "en-US": "Admin" }, 
    value: "admin" 
  },
  {
    name: { "es-CL": "Recibir correos", "en-US": "Receive mails" },
    value: "mails",
  },
];

export const permissionsUI = "userPermissions";

const PermissionsSelect = () => {
  return <MultiSelect {...{ id: permissionsUI, UIText, options, size:12 }} />;
};

export default PermissionsSelect;
