import { Typography } from "@mui/material";
import { humanBytes } from "../../../../../../../utility/misc/humanizers";

const Size = ({ fontSize, repositories }) => {
  let totalSize = 0
  for (const repo of repositories) {
    if (repo.recover) {
      totalSize += repo.size
    }
  }

  return (
      <Typography {...{ fontSize, sx: { cursor: "default" } }}>
        {humanBytes(totalSize)}
      </Typography>
  );
};

export default Size;
