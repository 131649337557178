import { extensions } from "../../Category/extensions";
import { enqueueSnackbar } from "notistack";
import { Fragment } from "react";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../store/slices/UI";
import TextField from "../../../../../../UI/GeneralPurpose/SimpleTextField";
import Button from "../../../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Nueva extensión" },
  "en-US": { label: "New extension" },
};
const UIText2 = {
  "es-CL": { label: "Agregar" },
  "en-US": { label: "Add" },
};

const UITextWarnings = {
  "es-CL": {
    customExist: "La extension personalizada ya existe",
    commonExist: "La extension ingresada es una extension en comun",
  },
  "en-US": {},
};

const newExtensionUI = "newExtension";
export const customExtensionsUI = "customExtensions";

const NewExtension = () => {
  const { dispatch, UI, settings } = useUIBp();
  const { value } = UI[newExtensionUI] ?? {};
  const customExts = UI[customExtensionsUI] ?? [];
  const commonExts = extensions;
  const { customExist, commonExist } = UITextWarnings[settings.locale];

  const handleSubmit = () => {
    if (!!value) {
      if (customExts.find((x) => value.toUpperCase() === x.toUpperCase())) {
        enqueueSnackbar(customExist, { variant: "error" });
      } else if (
        commonExts.find((x) => value.toUpperCase() === x.name.toUpperCase())
      ) {
        enqueueSnackbar(commonExist, { variant: "error" });
      } else {
        dispatch(UIactions.add({ id: customExtensionsUI, key: value }));
        dispatch(UIactions.clear(newExtensionUI));
      }
    }
  };

  return (
    <Fragment>
      <TextField
        {...{ id: newExtensionUI, onKeyDown: handleSubmit, UIText, size: 3 }}
      />
      <Button
        {...{
          UIText: UIText2,
          onClick: handleSubmit,
          disabled: !value,
          size: "auto",
          variant: "outlined",
          btnSize: "medium",
        }}
      />
    </Fragment>
  );
};

export default NewExtension;
