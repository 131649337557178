import { arrayId } from "../../UsersList/UsersList";
import { deleteClientsId } from "../../../../../../../store/actions/microsoft365/deleteClients";
import { postClientsId } from "../../../../../../../store/actions/microsoft365/postClients";
import { getM365ClientsId } from "../../../../../../../store/actions/microsoft365/getClients";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { validateMailId } from "../../../../../../../store/actions/microsoft365/validateM365Mails";
import { suggestMailId } from "../../../../../../../store/actions/microsoft365/suggestM365Mails";
import { getPlatformAndServicesId } from "../../../../../../../store/actions/microsoft365/getPlatformsAndServices";
import { UIactions } from "../../../../../../../store/slices/UI";
import Button from "../../../../../../UI/GeneralPurpose/Button";
import { useWorker } from "../../../../../../../Hooks/useWorker";

const UITextUpload = {
  "es-CL": {
    label: "Seleccionar vacíos",
    fixedTooltip:
      "Selecciona todos los usuarios sin correo windows 365 asignado",
  },
  "en-US": {
    label: "Select empty",
    fixedTooltip: "Select all users without a windows 365 email assigned",
  },
};

export const newUsersUI = "newUsers";

const SelectEmpty = ({ users }) => {
  const { dispatch, UI } = useUIBp();
  const { working: w1 } = useWorker(postClientsId);
  const { working: w2 } = useWorker(validateMailId);
  const { working: w3 } = useWorker(deleteClientsId);
  const { working: w4 } = useWorker(suggestMailId);
  const { done: d2 } = useWorker(getM365ClientsId);
  const { done: d3 } = useWorker(getPlatformAndServicesId);

  const handleSelect = (e) => {
    let justUsers = users.map((user) => user.login);

    let newSelection = []

    for (const user of justUsers) {
      let mailField = UI["365mail-" + user] ?? {};
      if (!mailField.value) {
        newSelection.push(user)
      }
    }

    dispatch(UIactions.setValue({ id: arrayId, value: newSelection }));
  };

  return (
    <Button
      {...{
        disabled: w1 || w2 || w3 || w4 || !d2 || !d3,
        UIText: UITextUpload,
        onClick: handleSelect,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
      }}
    />
  );
};

export default SelectEmpty;
