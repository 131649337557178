import useUIBp from "../../../Hooks/useUIBoilerplate";
import DeleteGroup from "../../Dashboard/Components/TopBar/Dialogs/DeleteGroup";
import ContactSupport from "../../ContactSupport/View/ContactSupport";
import TwoFactorAuthentication from "../../Users/Dialogs/TwoFactorAuthentication/TwoFactorAuthentication";
import DeprotectSharedLink from "../../SharedFiles/Dialogs/DeprotectSharedLink";
import ProtectSharedLink from "../../SharedFiles/Dialogs/ProtectSharedLink";
import DeleteSharedLinks from "../../SharedFiles/Dialogs/DeleteSharedLink";
import UserForm from "../../Users/Dialogs/UserCUForm/UserForm";
import SelectionHistory from "../../Users/Dialogs/SelectionHistory/SelectionHistory";
import RemoteSelection from "../../Users/Dialogs/RemoteSelection/RemoteSelection";
import UsersUpload from "../../Users/Dialogs/UsersUpload/UsersUpload";
import CUrecoveries from "../../RecoveryRequest/Dialogs/CUrecovery/CUrecoveries";
import NewUsersRecoveryRequest from "../../RecoveryRequest/Dialogs/NewUsersRecoveryRequest/NewUsersRecoveryRequest";
import NewDevicesRecoveryRequest from "../../RecoveryRequest/Dialogs/NewDevicesRecoveriesRequest/NewDevicesRecoveriesRequest";
import FileDataPop from "../../Files/Dialogs/FilesDataPop/FilesDataPop";
import FileVersions from "../../Files/Dialogs/FileVersions/FileVersions";
import DeleteUsers from "../../Users/Dialogs/DeleteUsers";
import OrgCloudBackup from "../../Users/Dialogs/OrgCloudBackup/OrgCloudBackup";
import UserCloudIntegrations from "../../Users/Dialogs/UserCloudIntegrations/UserCloudIntegrations";
import UsersCloudIntegration from "../../Users/Dialogs/UsersCloudIntegration/UsersCloudIntegration";
import NewRepositoryNewRequest from "../../RecoveryRequest/Dialogs/NewRepositoryNewRequest/NewRepositoryNewRequest";
import ResetRequest from "../../RecoveryRequest/Dialogs/ResetRequest/ResetRequest";
import CreateUsersAfterPost from "../../Users/Dialogs/CreateUsersAfterPost";
import GroupForm from "../../Users/Dialogs/GroupCUForm/GroupForm";
import DeleteDevices from "../../Devices/Dialogs/DeleteDevices";
import DeviceEdit from "../../Devices/Dialogs/DeviceEdit/DeviceEdit";
import DeviceDataPop from "../../Devices/Dialogs/DeviceDataPop/DeviceDataPop";
import DeviceConfig from "../../Devices/Dialogs/DeviceConfig/DeviceConfig";
import MailView from "../../Mails/Dialogs/MailView/MailView";
import Activities from "../../Activities/Activities";

const DialogsPrimaryOutlet = () => {
  const { UI } = useUIBp();
  const modal = UI.modal ?? { type: "" };
  const { type: mType, open } = modal;

  if (open) {
    switch (mType) {
      // ================================== RECOVERY REQUEST ==================================
      case "new-repository-new-request":
        return <NewRepositoryNewRequest />;
      case "new-users-recovery-request":
        return <NewUsersRecoveryRequest />;
      case "new-devices-recovery-request":
        return <NewDevicesRecoveryRequest />;
      case "reset-recovery-request":
        return <ResetRequest />;
      case "create-update-recoveries":
        return <CUrecoveries />;
      // =================================== CLOUD INTEGRATIONS ===================================
      case "cloud-backup":
        return <OrgCloudBackup />;
      case "enable-tfa":
        return <TwoFactorAuthentication />;
      case "contact-support":
        return <ContactSupport />;
      case "view-mail-content":
        return <MailView />;
      // ===================================== SHARED FILES =====================================
      case "deprotect-shared-link":
        return <DeprotectSharedLink />;
      case "protect-shared-link":
        return <ProtectSharedLink />;
      case "delete-shared-links":
        return <DeleteSharedLinks />;
      case "file-versions":
        return <FileVersions />;
      case "file-details":
        return <FileDataPop {...{ modal: "1" }} />;
      // ============================ DEVICE MODALS ============================
      case "delete-devices":
        return <DeleteDevices />;
      case "device-edit":
        return <DeviceEdit />;
      case "device-details-support":
        return <DeviceDataPop />;
      case "device-details":
        return <DeviceConfig />;
      // ============================ USERS MODALS ============================
      case "download-creation-report":
        return <CreateUsersAfterPost />;
      // case "download-clients-report":
      //   return <CreateClientsAfterM365Post />;
      case "create-new-user":
        return <UserForm isNew />;
      case "upload-users":
        return <UsersUpload />;
      case "edit-user":
        return <UserForm />;
      case "delete-users":
        return <DeleteUsers />;
      case "selection-history":
        return <SelectionHistory />;
      case "remote-selection":
        return <RemoteSelection />;
      // ============================= CLOUD INTEGRATIONS =============================
      case "edit-user-cloud":
        return <UserCloudIntegrations />;
      case "multi-cloud-integrations":
        return <UsersCloudIntegration />;
      // ============================ Group Modals ============================
      case "create-new-group":
        return <GroupForm isNew />;
      case "edit-group":
        return <GroupForm />;
      case "delete-group":
        return <DeleteGroup />;
      // ============================ Other ============================
      case "users-downloads":
        return <Activities />
      default:
        console.error("modal type does not match with any known modal");
        return;
    }
  }
};
export default DialogsPrimaryOutlet;
