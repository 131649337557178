import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../../store/slices/workers";
import { treesActions } from "../../../store/slices/trees";

const UIText = {
  "es-CL": { error: "Error al descargar informacion de archivos" },
  "en-US": { error: "Error while loading files information" },
};

export const filesDataId = "files";
export const breadcrumbsId = "breadcrumbs";

const getFilesTree = ({ server, cloner_key, file, repo, locale, idx }) => {
  const { error } = UIText[locale];

  return async (dispatch) => {
    dispatch(workerActions.start("node-" + file));
    try {

      // Getting the files tree through the /api/files handler
      const url = server + "api/files";
      const response = await axios.get(url, {
        headers: { cloner_key },
        params: { id: file, repoid: repo },
      });

      // Setting the root node
      dispatch(
        treesActions.setNodeKey({
          id: "repo-" + repo,
          idx,
          key: "loaded",
          value: true,
        })
      );

      // Adding the files to the tree
      for (const file of response.data.files) {
        if (!file.type) {
          dispatch(
            treesActions.addFVNode({
              id: "repo-" + repo,
              idx,
              data: {
                id: file.id,
                name: file.name,
                type: 0,
                deleted: file.deleted,
              },
            })
          );
        }
      }
      dispatch(workerActions.finish({ id: "node-" + file }));
    } catch (e) {
      console.log(e);
      dispatch(workerActions.finish({ id: "node-" + file, error: e.message }));
      enqueueSnackbar(error, { variant: "error" });
    }
  };
};

export default getFilesTree;
