import { CircularProgress, DialogTitle, Grid2, IconButton, Typography } from "@mui/material";
import { CloudSync } from "@mui/icons-material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { getMailId } from "../../../../Actions/getMail";
import { useWorker } from "../../../../../../Hooks/useWorker";
import restoreMails from "../../../../Actions/restoreMails";

const UIText = {
  "es-CL": { fixedTooltip: "Restaurar correo" },
  "en-US": { fixedTooltip: "Restore email" },
};

const Header = ({ id: mailId, subject, receivedDateTime, sentDateTime }) => {
  const { auth, dispatch, settings } = useUIBp();
  const { working } = useWorker("restore-" + mailId);
  const { done, error } = useWorker(getMailId);

  const localDate = new Date(receivedDateTime ?? sentDateTime).toLocaleString(
    settings.locale
  );

  const onClick = (e) => {
    dispatch(
      restoreMails({
        ...auth,
        workerId: "restore-" + mailId,
        mailIDs: [mailId],
        locale: settings.locale,
      })
    );
  };

  const icon = working ? (
    <CircularProgress size={20} />
  ) : (
    <CloudSync sx={{ fontSize: 20 }} />
  );

  const disabled = !done || error || working;

  return (
    <DialogTitle sx={{ padding: 2, paddingTop: 1, paddingBottom: 1 }}>
      <Grid2 container alignItems="center" columnGap={2}>
        <Grid2 size={8.5}>
          <Typography fontSize={18} noWrap>
            {subject}
          </Typography>
        </Grid2>
        <Grid2 container size="grow" justifyContent="end">
          <Typography fontSize={15}>{localDate}</Typography>
        </Grid2>
        <Grid2 container size={0.5} justifyContent="end">
          <IconButton
            {...{
              disabled,
              UIText,
              onClick,
              icon,
              sx: {
                "&:hover": {
                  color: "primary.main",
                },
              },
            }}
          />
        </Grid2>
      </Grid2>
    </DialogTitle>
  );
};

export default Header;
