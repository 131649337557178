import DrawerButton from "./DrawerButton";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  moreCategoriesUI,
  shouldExpandUI,
} from "../MailCategories/MailCategories";
import { Fragment } from "react";
import Divider from "../../../../../UI/GeneralPurpose/Divider";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../store/slices/UI";

const UIText1 = {
  "es-CL": { label: "Mas" },
  "en-US": { label: "More" },
};
const UIText2 = {
  "es-CL": { label: "Menos" },
  "en-US": { label: "Less" },
};

const MoreLess = () => {
  const { dispatch, UI } = useUIBp();
  const more = UI[moreCategoriesUI];
  const shouldExpand = UI[shouldExpandUI] ?? [];
  const icon1 = <KeyboardArrowDown {...{ sx: { color: "primary.light" } }} />;
  const icon2 = <KeyboardArrowUp {...{ sx: { color: "primary.light" } }} />;

  let UIText = more ? UIText2 : UIText1;
  let icon = more ? icon2 : icon1;

  const handleClick = () => {
    dispatch(UIactions.toggle(moreCategoriesUI));
  };

  if (!shouldExpand) {
    return;
  }

  return (
    <Fragment>
      <Divider {...{ color: "secondary.light" }} />
      <DrawerButton
        {...{
          UIText,
          handleClick,
          icon,
          fontSize: 14,
          accentColor: "primary.light",
        }}
      />
    </Fragment>
  );
};

export default MoreLess;
