import { useMemo } from "react";
import { deleteClientsId } from "../../../../../../../store/actions/microsoft365/deleteClients";
import Button from "../../../../../../UI/GeneralPurpose/Button";
import { arrayId } from "../../UsersList/UsersList";
import { postClientsId } from "../../../../../../../store/actions/microsoft365/postClients";
import { suggestMailId } from "../../../../../../../store/actions/microsoft365/suggestM365Mails";
import { validateMailId } from "../../../../../../../store/actions/microsoft365/validateM365Mails";
import { mailLocksId } from "../../UsersList/UserRow/M365mail";
import { UIactions } from "../../../../../../../store/slices/UI";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../../Hooks/useWorker";

const UITextDelete = {
  "es-CL": {
    label: "Resetear Clientes",
    fixedTooltip:
      "Desvincula el usuario cloner del correo de Windows 365 asingnado, deteniendo el respaldo",
  },
  "en-US": {
    label: "Reset Clients",
    fixedTooltip:
      "Unlink the cloner user from the assigned Windows 365 email, stopping the backup",
  },
};

export const newUsersUI = "newUsers";

const ResetClient = () => {
  const { dispatch, UI } = useUIBp();
  const { working } = useWorker(deleteClientsId);
  const { working: w1 } = useWorker(postClientsId);
  const { working: w2 } = useWorker(suggestMailId);
  const { working: w3 } = useWorker(validateMailId);
  const selectedUsers = useMemo(()=> UI[arrayId] ?? [], [UI]);
  const lockedMails = useMemo(()=>UI[mailLocksId] ?? [],[UI]);
  
  let trimmedSelected = useMemo(() => {
    let tryouts = [];

    for (const user of selectedUsers) {
      for (const locked of lockedMails) {
        if (locked === user) {
          tryouts.push(user);
          break;
        }
      }
    }
    return tryouts;
  }, [lockedMails, selectedUsers]);

  const handleDelete = (e) => {
    dispatch(
      UIactions.openModal2({
        type: "delete-w365-clients-confirmation",
        payload: { selectedUsers: trimmedSelected },
      })
    );
  };

  return (
    <Button
      {...{
        disabled: trimmedSelected.length === 0 || w1 || w2 || w3,
        UIText: UITextDelete,
        onClick: handleDelete,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
        color: "error",
      }}
    />
  );
};

export default ResetClient;
