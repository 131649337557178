import useUIBp from "../../../../../Hooks/useUIBoilerplate"
import { UIactions } from "../../../../../store/slices/UI"
import Button from "../../../../UI/GeneralPurpose/Button"

const UIText = {
    'es-CL':{label:'Cerrar'},
    'en-US':{label:'Close'},
}

const Close = ()=>{
    const {dispatch} = useUIBp()
    
    const onClick = ()=>{
        dispatch(UIactions.closeModal())
    }

    return (
        <Button {...{UIText, onClick, variant:'text'}}/>
    )
}

export default Close