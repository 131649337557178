import { Grid2 } from "@mui/material";
import { UIactions } from "../../../store/slices/UI";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import Button from "../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Cerrar" },
  "en-US": { label: "Close" },
};

const Buttons = () => {
  const { dispatch } = useUIBp();

  const handleClose = (e, reason) => {
    dispatch(UIactions.closeModal());
  };

  return (
    <Grid2 container {...{ justifyContent: "end", paddingTop: 2 }}>
      <Button {...{ UIText, onClick: handleClose, variant:"outlined" }} />
    </Grid2>
  );
};

export default Buttons;
