import { Grid2, IconButton as MUIIconButton } from "@mui/material";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import AutoHelp from "../Wrappers/AutoHelp";
import useUIBp from "../../../Hooks/useUIBoilerplate";
// const UIText = {
//   "es-CL": {
//     tooltip: "",
//   },
// };

const IconButton = (props) => {
  const {
    disabled,
    onClick,
    navTo,
    navReplace,
    icon,
    UIText = {},
    size,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "icon",
    "UIText",
    "navTo",
    "navReplace",
    "xs"
  );
  const { navigate, settings } = useUIBp();
  const locale = settings.locale;

  const X = UIText[locale] ?? UIText.universal;
  const { tooltip, fixedTooltip } = X;

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (navTo) {
      navigate(navTo, {
        replace: navReplace,
      });
    }
  };

  return (
    <Grid2 container {...{ size, sx: { alignContent: "center" } }}>
      <AutoHelp {...{ disabled, tooltip, fixedTooltip }}>
        <MUIIconButton {...filteredProps} {...{ onClick: handleClick }}>
          {icon}
        </MUIIconButton>
      </AutoHelp>
    </Grid2>
  );
};

export default IconButton;
