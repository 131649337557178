import { Dialog } from "@mui/material";
import Form, {
  appIdUI,
  clientIdUI,
  emailUI,
  secretKeyUI,
} from "./Components/Form";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import Close from "./Components/Close";
import Activate from "./Components/Activate";
import { postOrgId } from "../../../../store/actions/microsoft365/postOrganization";
import { useEffect } from "react";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../Hooks/useWorker";
import { UIactions } from "../../../../store/slices/UI";
import { workerActions } from "../../../../store/slices/workers";

const UIText = {
  "es-CL": "Registrar organización de Windows 365",
  "en-US": "Register Windows 365 organization",
};

const Microsoft365OrgEnable = () => {
  const { dispatch, settings } = useUIBp();
  const { done, error } = useWorker(postOrgId);

  const header = UIText[settings.locale];

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal2());
    }
  };

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal2());
    }
  }, [dispatch, done, error]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(appIdUI));
      dispatch(UIactions.clear(clientIdUI));
      dispatch(UIactions.clear(secretKeyUI));
      dispatch(UIactions.clear(emailUI));
      dispatch(UIactions.clear("vc0"));
      dispatch(UIactions.clear("vc1"));
      dispatch(UIactions.clear("vc2"));
      dispatch(UIactions.clear("vc3"));
      dispatch(UIactions.clear("vc4"));
      dispatch(UIactions.clear("vc5"));
      dispatch(workerActions.clear(postOrgId));
    };
  }, [dispatch]);

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Form />
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        <Activate />
      </BasicDialogActions>
    </Dialog>
  );
};

export default Microsoft365OrgEnable;
