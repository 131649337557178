import BasicHeaders from "../../../../UI/BasicLayout/Components/BasicHeaders";

const headersData = {
  headers: [
    {
      UIText: {
        "es-CL": { label: "Nombre" },
        "en-US": { label: "Name" },
      },
      filterKey: "name",
      size: 6,
    },
    {
      UIText: {
        "es-CL": { label: "Fecha" },
        "en-US": { label: "Date" },
      },
      filterKey: "created",
      size: 3,
    },
    {
      UIText: {
        "es-CL": { label: "Tamaño" },
        "en-US": { label: "Size" },
      },
      filterKey: "size",
      size: 2,
    },
    {
      UIText: {
        "es-CL": { label: "Mas" },
        "en-US": { label: "More" },
      },
      justify: "end",
      filterKey: "",
      size: "grow",
    },
  ],
};

const Headers = () => {
  return <BasicHeaders {...headersData} />;
};

export default Headers;
