import { Clear } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { UIactions } from "../../../store/slices/UI";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import IconButton from "../GeneralPurpose/IconButton";

const UIText = {
    'es-CL':{
        tooltip:'Despejar'
    },
    'en-US':{
        tooltip:'Clear'
    }
}

const ClearInsert = (props)=>{
  const { id,disabled, position = "end",fontSize = '1rem' } = props;
  const {dispatch } = useUIBp();

  const handleClick = ()=>{
    dispatch(UIactions.clear(id))
  }

  return (
    <InputAdornment {...{ position }}>
        <IconButton
            {...{
                icon:<Clear sx={{fontSize}}/>,
                disabled,
                UIText,
                onClick:handleClick,
            }}
        />
    </InputAdornment>
  );
}

export default ClearInsert