import axios from "axios";
import { workerActions } from "../../slices/workers";
import { enqueueSnackbar } from "notistack";
import { devicesLoadedId } from "./getRootDevices";

const UIText = {
  "es-CL": {
    success: "Dispositivo guardado exitosamente",
    error: "Error al modifical el dispositivo:",
  },
  "en-US": {
    success: "Device saved successfully",
    error: "Error trying to modify device:",
  },
};

export const postDeviceId = "postDevice";

const postDeviceInfo = (data) => {
  const {
    server,
    cloner_key,
    worldAdmin,
    devicename,
    config,
    password,
    suspended,
    locale,
  } = data;
  const { success, error } = UIText[locale];
  const id = postDeviceId;
  console.log("Posting device info")

  return async (dispatch) => {
    dispatch(workerActions.start(id));

    try {
      if (devicename.update) {
        // Posting the device machine info through the /api/machines endpoint
        const bodyData = JSON.stringify(devicename.payload);
        const url = server + "api/machines";
        await axios.post(url, bodyData, {
          headers: { "Content-Type": "application/json", cloner_key },
        });
      }

      if (config.update) {
        // Posting the device config through the /api/user/setconf endpoint
        const bodyData = JSON.stringify(config.payload);
        const url = server + "api/user/setconf";
        await axios.post(url, bodyData, {
          headers: { "Content-Type": "application/json", cloner_key },
        });
      }

      if (password.update) {
        // Posting the device password through the /api/machine/chgcpasswd endpoint
        const bodyData = JSON.stringify(password.payload);
        const url = server + "api/machine/chgcpasswd";
        await axios.post(url, bodyData, {
          headers: { "Content-Type": "application/json", cloner_key },
        });
      }

      if (suspended.update && worldAdmin) {
        // Posting the device status through the /api/service/status endpoint (This is only for worldAdmin, also it defines a suspended devices status)
        const bodyData = JSON.stringify(suspended.payload);
        const url = server + "api/service/status";
        await axios.post(url, bodyData, {
          headers: { "Content-Type": "application/json", cloner_key },
        });
      }
      dispatch(workerActions.finish({ id }));
      dispatch(workerActions.clear(devicesLoadedId));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
      enqueueSnackbar(error + e.message, { variant: "error" });
    }
  };
};

export default postDeviceInfo;
