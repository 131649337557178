import { Grid2, TextField } from "@mui/material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": { label: "Usuario" },
  "en-US": { label: "User" },
};

const User = ({ user }) => {
  const { settings } = useUIBp();
  const locale = settings.locale;
  const { label } = UIText[locale];

  return (
    <Grid2 container {...{ size: 12 }}>
      <TextField
        disabled
        fullWidth
        {...{ label, value: user, size: "small" }}
      />
    </Grid2>
  );
};

export default User;
