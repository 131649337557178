import { useDispatch } from "react-redux";
import { Backup } from "@mui/icons-material";
import IconButton from "../../../../../UI/GeneralPurpose/IconButton";
import { UIactions } from "../../../../../../store/slices/UI";

const UIText = {
  "es-CL": { fixedTooltip: "Microsoft365" },
  "en-US": { fixedTooltip: "Microsoft365" },
};

const CloudBackup = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(UIactions.openModal({ type: "cloud-backup" }));
  };

  return (
    <IconButton
      {...{
        UIText,
        icon: <Backup color="primary" />,
        onClick: handleClick,
      }}
    />
  );
};

export default CloudBackup;
