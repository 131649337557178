import { Grid2, Typography } from "@mui/material";
import Repository from "./Repository/Repository";

const UIText = {
  "es-CL": { header: "Repositorios", },
  "en-US": { header: "Summary", },
};

const Repositories = ({ readOnly, recovery, locale }) => {
  const { header } = UIText[locale];
  const { repositories } = recovery;

  const repositoriesList = repositories.map((repo) => (
    <Repository key={repo.id} {...{ ...repo, readOnly }} />
  ));

  return (
    <Grid2
      container
      {...{
        paddingTop: 2,
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        size: 12,
        rowGap: 2,
      }}
    >
      <Grid2 container {...{ size: 12 }}>
        <Typography fontWeight="bold" fontSize={18}>
          {header}
        </Typography>
      </Grid2>
      {repositoriesList}
    </Grid2>
  );
};

export default Repositories;
