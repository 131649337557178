import { Grid2 } from "@mui/material";
import notEmptyPassword from "../../../utility/validations/notEmptyPassword";
import PassField from "../../UI/GeneralPurpose/PassField";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import SlimAlert from "../../UI/GeneralPurpose/SlimAlert";

const UIText1 = {
  "es-CL": { label: "Nueva contraseña", placeholder: "**********" },
  "en-US": { label: "New password", placeholder: "**********" },
};
const UIText2 = {
  "es-CL": { label: "Repetir contraseña", placeholder: "**********" },
  "en-US": { label: "Repeat password", placeholder: "**********" },
};

export const pass1UI = "password1";
export const pass2UI = "password2";

const Password = () => {
  const { UI } = useUIBp();
  const { error: e1 } = UI[pass1UI] ?? {};
  const { error: e2 } = UI[pass2UI] ?? {};

  return (
    <Grid2 container {...{ size: 12, columnSpacing: 2, rowGap: 2 }}>
      <PassField
        clearOnUnmount
        {...{
          id: pass1UI,
          UIText: UIText1,
          twinId: pass2UI,
          twinValidation: notEmptyPassword,
          size: {
            sm: 6,
            xs: 12,
          },
        }}
      />
      <PassField
        clearOnUnmount
        {...{
          id: pass2UI,
          UIText: UIText2,
          twinId: pass1UI,
          twinValidation: notEmptyPassword,
          size: {
            sm: 6,
            xs: 12,
          },
        }}
      />
      <SlimAlert
        {...{
          messages: [e1, e2],
          size: 12,
          sx: { paddingLeft: 2 },
        }}
      />
    </Grid2>
  );
};

export default Password;
