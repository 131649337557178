import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";
import { getM365OrgId } from "./getOrganization";

export const UIText = {
  "es-CL": {
    success: "Organización de Microsoft 365 habilitada",
    error: "Error al intentar habilitar organizacion de Microsoft 365: ",
  },
  "en-US": {
    success: "Microsoft 365 organization enabled",
    error: "Error while enabling Microsoft 365 organization: ",
  },
};

export const postOrgId = "post365org";

export const postOrganization = ({ M365server, cloner_key, payload,locale }) => {
  return async (dispatch) => {
    const id = postOrgId;
    dispatch(workerActions.start(id));
    const bodyData = JSON.stringify(payload);
    const {success,error}= UIText[locale]

    try {
      const url = M365server+"mailapi/organization";
      await axios.post(url, bodyData, { headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(workerActions.finish({ id }));
      dispatch(workerActions.clear(getM365OrgId));
    } catch (e) {
      console.error(e);
      const message = `${error} ${e.message}`;
      enqueueSnackbar(message, { variant: "error" });
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};
