import { Card, Grid2 } from "@mui/material";
import useVH from "../../../Hooks/useVH";

const BasicContainer = ({
  children,
  height = 92,
  width = "100%",
  fixedWidth,
  overflowX = "hidden",
}) => {
  const vh = useVH(height);
  const vh2 = useVH(height - 4);

  return (
    <Grid2
      container
      {...{
        position: "relative",
        sx: {
          minWidth: fixedWidth,
          padding: "2vh",
          maxHeight: vh,
          overflowX,
        },
        alignContent: "start",
      }}
    >
      <Grid2 container {...{ size: 12 }}>
        <Card {...{ sx: { width } }}>
          <Grid2
            container
            {...{
              sx: {
                maxHeight: vh2,
                width: "100%",
                overflowY: "auto",
              },
            }}
          >
            {children}
          </Grid2>
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default BasicContainer;
