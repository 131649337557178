import { ChevronRight } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Grid2,
  Grow,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";
import Bottom from "./Components/Bottom";
import Top from "./Components/Top";
import Picker from "./Components/Picker/Picker";
import loadGroupsTree, {
  currentGroupId,
  currentGroupnameId,
  getGroupsListId,
  groupsTreeId,
  selectedGroupId,
  selectedGroupIdx,
} from "./Actions/loadGroupsTree";
import { viewmodeUI } from "../../App";
import useUIBp from "../../Hooks/useUIBoilerplate";
import useURLQuery from "../../Hooks/useURLQuery";
import { UIactions } from "../../store/slices/UI";
import { workerActions } from "../../store/slices/workers";
import { treesActions } from "../../store/slices/trees";
import { recursiveRequestId } from "../UI/BasicLayout/Components/FilterPlusActions/Recursive";
import { devicesLoadedId } from "../../store/actions/devices/getRootDevices";
import { groupsRecursiveId } from "../../store/actions/users/getGroupsRecursive";
import { useWorker } from "../../Hooks/useWorker";

export const travelerOpenUI = "group-traveler-open";
export const reloadUI = "group-traveler-reload";

const UIText = {
  "es-CL": { loading: "Cargando..." },
  "en-US": { loading: "Loading..." },
};

const GroupsTraveler = (props) => {
  const { auth, dispatch, UI, settings } = useUIBp();
  const { group } = useURLQuery();
  const ref = useRef(null);
  const { done, working } = useWorker(getGroupsListId);
  const { loading } = UIText[settings.locale];
  const viewmode = UI[viewmodeUI];
  const groupname = UI[currentGroupnameId] ?? "";
  const open = UI[travelerOpenUI] ?? false;
  const reload = UI[reloadUI] ?? false;
  const modal = UI.modal ?? {};

  // Load whole groups tree every group change or when opening the component
  useEffect(() => {
    if (group || reload) {
      dispatch(
        loadGroupsTree({
          ...auth,
          startGroup: group,
          mode: "topbar",
        })
      );
    }
  }, [dispatch, auth, group, reload]); 

  // Open or close the popper
  const handleToggle = () => {
    dispatch(UIactions.toggle(travelerOpenUI));
  };

  // Definitely close the popper
  const close = () => {
    dispatch(UIactions.clear(travelerOpenUI));
  };

  // Handles the clickAwayListener, prevents closing if a modal is open
  const handleClick = (event) => {
    if (!modal.open) {
      if (ref.current && ref.current.contains(event.target)) {
        return;
      }
      close();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(selectedGroupId));
      dispatch(UIactions.clear(selectedGroupIdx));
      dispatch(UIactions.clear(travelerOpenUI));
      dispatch(UIactions.clear(currentGroupId));
      dispatch(UIactions.clear(currentGroupnameId));
      dispatch(UIactions.clear(recursiveRequestId));
      dispatch(workerActions.clear(getGroupsListId));
      dispatch(workerActions.clear(devicesLoadedId));
      dispatch(workerActions.clear(groupsRecursiveId));
      dispatch(treesActions.removeTree(groupsTreeId));
    };
  }, [dispatch]);

  return (
    <Grid2 container {...{ props }}>
      <div>
        <Button
          {...{
            ref,
            id: "travelerButton",
            disabled: !(done && !working),
            startIcon: <ChevronRight sx={{ color: "secondary.main" }} />,
            onClick: handleToggle,
            variant: "outlined",
            sx: { width: viewmode === "desktop" ? 300 : 250 },
            style: { justifyContent: "flex-start" },
          }}
        >
          <Typography fontSize="1rem" color="secondary.main" noWrap>
            {groupname && done && !working ? groupname : loading}
          </Typography>
        </Button>
      </div>
      <Popper
        transition
        {...{
          open,
          anchorEl: ref.current,
          placement: "bottom-start",
          sx: { zIndex: 1300 },
          modifiers: [{ name: "offset", options: { offset: [0, 5] } }],
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Paper elevation={2} sx={{ border: "1px #e0e0e0 solid" }}>
              <ClickAwayListener onClickAway={handleClick}>
                <Grid2 container {...{ sx: { width: 700 } }}>
                  <Top />
                  <Picker />
                  <Bottom {...{ close }} />
                </Grid2>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid2>
  );
};

export default GroupsTraveler;
