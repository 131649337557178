export const parseFileTree = (nodes, rawTreeNode) => {
  const idx = nodes.length;
  let newNode = {
    idx,
    parentIdx: rawTreeNode.parentIdx,
    childrenIdx: [],
    name: rawTreeNode.rawNodeData.name,
    scanfolder: rawTreeNode.rawNodeData.scanfolder,
  };

  nodes.push(newNode);

  if (rawTreeNode.rawNodeData.next) {
    const nextTreeNode = {
      parentIdx: rawTreeNode.parentIdx,
      rawNodeData: rawTreeNode.rawNodeData.next,
    };
    nodes[rawTreeNode.parentIdx].childrenIdx.push(nodes.length);
    parseFileTree(nodes, nextTreeNode);
  }
  if (rawTreeNode.rawNodeData.subtree) {
    const subtreeNode = {
      parentIdx: idx,
      rawNodeData: rawTreeNode.rawNodeData.subtree,
    };
    nodes[idx].childrenIdx.push(nodes.length);
    parseFileTree(nodes, subtreeNode);
  }
};

export const calculateSelection = (tree, idx) => {
  let fullSelection = true;
  let isEmpty = true;

  const node = tree[idx];

  if (node.childrenIdx.length) {
    for (const childIdx of node.childrenIdx) {
      const { full, empty } = calculateSelection(tree, childIdx);
      if (!full) {
        fullSelection = false;
      }
      if (!empty) {
        isEmpty = false;
      }
    }
  } else {
    fullSelection = node.scanfolder
    isEmpty = !node.scanfolder
  }

  if (node.scanfolder) {
    if (fullSelection) {
      node.backup = 3;
    } else {
      node.backup = 2;
    }
  } else {
    if (isEmpty) {
      node.backup = 0;
    } else {
      node.backup = 1;
    }
  }

  return { full: fullSelection, empty: isEmpty };
};

export const recursiveNodeChange = (tree,idx,selected) => {
  const node = tree[idx]

  node.backup = selected ? 3:0
  
  for (const childIdx of node.childrenIdx){
    recursiveNodeChange(tree,childIdx,selected)
  }
}

export const calculateSelection2 = (tree, idx) => {
  let fullSelection = true;
  let isEmpty = true;

  const node = tree[idx];

  if (node.childrenIdx.length) {
    for (const childIdx of node.childrenIdx) {
      const { full, empty } = calculateSelection2(tree, childIdx);
      if (!full) {
        fullSelection = false;
      }
      if (!empty) {
        isEmpty = false;
      }
    }
  } else {
    fullSelection = node.backup >= 2
    isEmpty = !node.backup
  }

  if (node.backup >= 2) {
    if (fullSelection) {
      node.backup = 3;
    } else {
      node.backup = 2;
    }
  } else {
    if (isEmpty) {
      node.backup = 0;
    } else {
      node.backup = 1;
    }
  }

  return { full: fullSelection, empty: isEmpty };
};
