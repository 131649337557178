import { Grid2, Typography } from "@mui/material";
import { notEmptyName } from "../../../../../../../utility/validations/notEmptyName";
import { postDeviceId } from "../../../../../../../store/actions/devices/postDeviceInfo";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import SlimAlert from "../../../../../../UI/GeneralPurpose/SlimAlert";
import TextField from "../../../../../../UI/GeneralPurpose/SimpleTextField";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import HelpTooltip from "../../../../../../UI/GeneralPurpose/HelpTooltip";

const UIText = {
  "es-CL": { tooltip: "Nombre asignado al dispositivo respaldado" },
  "en-US": { tooltip: "Name assigned to the backed up device" },
};
const UIText1 = {
  "es-CL": { label: "Nuevo nombre" },
  "en-US": { label: "New name" },
};
const UIText2 = {
  "es-CL": { label: "Nombre del dispositivo" },
  "en-US": { label: "Device name" },
};

export const deviceNameUI = "deviceName";

const NewName = ({locale}) => {
  const { UI } = useUIBp();
  const { working } = useWorker(postDeviceId);
  const field = UI[deviceNameUI] ?? {};
  const error = field.error;
  const { label } = UIText1[locale];

  return (
    <Grid2 container {...{size:12, alignItems:"center"}}>
      <Grid2 container {...{size:7, columnGap:1, alignItems:"center"}}>
        <Typography>{label}</Typography>
        <HelpTooltip {...{ UIText }} />
      </Grid2>
      <TextField
        clearOnUnmount
        {...{
          id: deviceNameUI,
          UIText:UIText2,
          disabled: working,
          validation: notEmptyName,
          size: 5,
        }}
      />
      <SlimAlert {...{ messages: [error], size:12, paddingTop:1 }} />
    </Grid2>
  );
};

export default NewName;
