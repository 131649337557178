import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import addRecoveries from "../../../../../store/actions/recoveries/addRecoveries";
import { recoveriesActions } from "../../../../../store/slices/recoveries";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Iniciar nueva solicitud" },
  "en-US": { label: "Start new request" },
};

const Continue = ({ devices = [] }) => {
  const { auth, dispatch, settings } = useUIBp();
  const locale = settings.locale;

  const onClick = () => {
    dispatch(recoveriesActions.reset());
    dispatch(
      addRecoveries({
        ...auth,
        locale,
        devices,
      })
    );
    dispatch(UIactions.closeModal());
  };

  return <Button {...{ UIText, onClick }} />;
};

export default Continue;
