import { GroupsOutlined } from "@mui/icons-material";
import { Grid2 } from "@mui/material";
import IconButton from "../../../../../UI/GeneralPurpose/IconButton";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { userPickerId } from "../../../../../UI/Specialized/UserPicker";

const UIText = {
  "es-CL": { fixedTooltip: "Ir al grupo contenedor" },
  "en-US": { fixedTooltip: "Go to the container group" },
};

const GoToGroup = () => {
  const { UI, warehouse } = useUIBp();
  const usrPicker = UI[userPickerId] ?? {};
  const usrId = usrPicker.id ?? "";
  const storage = warehouse ?? {};
  const users = storage.users ?? [];

  const fullUser = users.find((u) => u.login === usrId) ?? {};
  const navTo = `/dashboard/users?group=` + fullUser.group_id;

  return (
    <Grid2>
      <IconButton
        {...{
          sx: { color: "secondary.light" },
          UIText: UIText,
          icon: <GroupsOutlined />,
          navTo,
        }}
      ></IconButton>
    </Grid2>
  );
};

export default GoToGroup;
