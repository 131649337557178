import { Edit } from "@mui/icons-material";
import useTrees from "../../../../../../Hooks/useTrees";
import { groupsTreeId } from "../../../../../GroupsTraveler/Actions/loadGroupsTree";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../store/slices/UI";
import IconButton from "../../../../../UI/GeneralPurpose/IconButton";

const UIText = {
  "es-CL": { fixedTooltip: "Editar grupo" },
  "en-US": { fixedTooltip: "Edit group" },
};

const EditGroup = () => {
  const { group } = useURLQuery();
  const { dispatch } = useUIBp();
  const trees = useTrees();
  const groupsTree = trees[groupsTreeId] ?? [];
  const self = groupsTree.find((g) => g.id === parseInt(group))??{}
  const parent = groupsTree.find((g) => g.id === self.parentId)??{}

  const handleClick = () => {
    dispatch(
      UIactions.openModal({
        type: "edit-group",
        payload: { selfGroupId: self.id, group: parent.id, name: self.name },
      })
    );
  };

  return (
    <IconButton
      {...{
        UIText,
        icon: <Edit sx={{ color: "primary.main" }} />,
        onClick:handleClick,
      }}
    />
  );
};

export default EditGroup;
