import Username from "./Components/Username";
import Name from "./Components/Name";
import Devices from "./Components/Devices";
import Connected from "./Components/Connected";
import Actions from "./Components/Actions";
import Admin from "./Components/Admin";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import BasicRow from "../../../../../UI/BasicLayout/Components/BasicRow";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };
const fontSize = 13;

const UserRow = (props) => {
  const { auth, warehouse } = useUIBp();
  const { login, name, machines: devices = 0, level,suspensions } = props;
  const { tunnels: allTunnels = {} } = warehouse;
  const tunnels = allTunnels[login] ?? [];
  const connected = tunnels.length;

  const data = {
    id: login,
    disabled: login === auth.user,
    elements: [
      { content: <Admin {...{ login,level }} />, justify: "center", size: 0.7 },
      { content: <Username {...{ login, fontSize }} />, size: 3.5 },
      { content: <Name {...{ name, fontSize }} />, size: 3.2 },
      {
        content: <Devices {...{ devices, suspensions, fontSize }} />,
        justify: "center",
        size: 1.5,
      },
      {
        content: <Connected {...{ connected, fontSize }} />,
        justify: "center",
        size: 1.5,
      },
      {
        content: <Actions {...props} {...{ tunnels }} />,
        justify: "end",
        size: "grow",
      },
    ],
  };

  return <BasicRow {...data} />;
};

export default UserRow;
