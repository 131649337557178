import { Grid2, TextField as MUITextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef } from "react";
import SlimAlert from "./SlimAlert";
import VisibilityInsert from "../TextFieldInserts/VisibilityInsert";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import { UIactions } from "../../../store/slices/UI";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import AutoHelp from "../Wrappers/AutoHelp";

/* e.g. UIText
    UIText = {
        es-CL:{
            label:'',
            placeholder:'',
            tooltip:'',
        }
    }
*/

const twinFieldErrorMsg = {
  "es-CL": "Los campos no coinciden",
  "en-US": "The fields do not match",
};

const PassField = (props) => {
  const {
    id,
    onChange = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
    componentSize = "small",
    size,
    fontSize = "1rem",
    validation,
    twinValidation,
    twinId,
    tagError,
    disabled,
    forceError,
    forceShrink,
    hardValidate,
    submit,
    UIText = {},
    fullWidth,
    constraint,
    clearOnUnmount,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "xl",
    "lg",
    "md",
    "sm",
    "xs",
    "validation",
    "twinValidation",
    "twinId",
    "tagError",
    "forceLowerCase",
    "forceError",
    "hardValidate",
    "submit",
    "help",
    "UIText",
    "fontSize",
    "forceShrink",
    "clearOnUnmount",
    "componentSize"
  );

  const ref = useRef(null);
  const { dispatch, UI, settings } = useUIBp();
  const locale = settings.locale;
  const show = UI[id + "-show"] ?? false;
  const { value = null, error } = UI[id] ?? {};
  const { label, tooltip, fixedTooltip, placeholder } =
    UIText[locale] ?? UIText.universal;

  // Update non-bound field to reflect an existing value
  useEffect(() => {
    ref.current.value = value;
  }, [value]);

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        console.log("clearing passfield");
        dispatch(UIactions.clear(id));
      }
    };
  }, [dispatch, id, clearOnUnmount]);

  // Update error message if locale changes
  useEffect(() => {
    if (locale && validation) {
      dispatch(UIactions.validateField({ id, fn: validation, locale }));
    }
    if (locale && twinValidation) {
      dispatch(
        UIactions.twinFieldValidate({
          id1: id,
          id2: twinId,
          fn: twinValidation,
          errorMsg: twinFieldErrorMsg,
          locale,
        })
      );
    }
  }, [dispatch, locale, id, twinId, validation, twinValidation]);

  // Validate field on blur. Also execute any onBlur function set from outside
  const handleBlur = () => {
    if (validation) {
      dispatch(UIactions.validateField({ id, fn: validation, locale }));
    }
    if (twinValidation) {
      dispatch(
        UIactions.twinFieldValidate({
          id1: id,
          id2: twinId,
          fn: twinValidation,
          errorMsg: twinFieldErrorMsg,
          locale,
        })
      );
    }
    onBlur();
  };

  // Hard validate field on enter key press
  const handleHardValidate = (e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      onKeyDown();
      if (hardValidate) {
        dispatch(
          UIactions.validateField({
            id,
            fn: validation,
            force: true,
            locale,
          })
        );
      }
    }
  };

  const debouncedChange = debounce((e) => {
    let value = e.target.value;
    if (constraint) {
      value = constraint(value);
    }
    dispatch(UIactions.setField({ id, value }));
    onChange(e);
  }, 200);

  const handleChange = (e) => {
    debouncedChange(e);
  };

  const debouncedSubmit = debounce((e) => {
    if (submit && (e.key === "Enter" || e.key === "NumpadEnter")) {
      submit(e);
    }
  }, 200);

  const handleSubmit = (e) => {
    debouncedSubmit(e);
  };

  let typeX = "password";

  if (show) {
    typeX = "text";
  }

  return (
    <Grid2 container size={size}>
      <AutoHelp {...{ tooltip, fixedTooltip, disabled }}>
        <MUITextField
          {...filteredProps}
          {...{
            fullWidth: size || fullWidth ? true : undefined,
            label,
            placeholder,
            inputRef: ref,
            onChange: handleChange,
            onBlur: handleBlur,
            onKeyDown: handleHardValidate,
            onKeyUp: handleSubmit,
            size: componentSize,
            type: typeX,
            error: !!error || forceError,
            InputLabelProps: {
              ...filteredProps.InputLabelProps,
              shrink: value || forceShrink ? true : undefined,
            },
            InputProps: {
              ...filteredProps.InputProps,
              endAdornment: <VisibilityInsert {...{ id, disabled }} />,
              sx: {
                fontSize,
                paddingRight: 0.3,
                borderRadius:
                  !!error && tagError ? "4px 4px 0px 0px" : undefined,
              },
            },
          }}
        />
      </AutoHelp>
      <SlimAlert errors={!!tagError ? [error] : []} fuseTop />
    </Grid2>
  );
};

export default PassField;
