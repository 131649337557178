import { Grid2, Typography } from "@mui/material";
import { useEffect } from "react";
import SlimAlert from "../../../../../../UI/GeneralPurpose/SlimAlert";
import { UIactions } from "../../../../../../../store/slices/UI";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import TimePckr from "../../../../../../UI/GeneralPurpose/Time";
import dayjs from "dayjs";

const UITextDatesError = {
  "es-CL": {
    error1: "Las fechas no pueden ser iguales",
    error2:
      "La hora de inicio para la recepción debe ser anterior a la hora de termino",
  },
  "en-US": {
    error1: "Dates cannot be equal",
    error2: "The start time for the reception must be before the end time",
  },
};

const UIText = {
  "es-CL": { subtitle3: "Horario de recepción (Lunes a Viernes)" },
  "en-US": { subtitle3: "Delivery window (Lunes a Viernes)" },
};

const UITextFrom = {
  "es-CL": { label: "Desde:" },
  "en-US": { label: "From:" },
}

const UITextTo = {
  "es-CL": { label: "Hasta:" },
  "en-US": { label: "To:" },
}

export const deliveryWindowFromUI = "deliveryWindowFrom";
export const deliveryWindowToUI = "deliveryWindowTo";

const DeliveryWindow = () => {
  const { dispatch, UI, settings } = useUIBp();
  const { value: from, error: e1 } = UI[deliveryWindowFromUI] ?? {};
  const { value: to, error: e2 } = UI[deliveryWindowToUI] ?? {};
  const { error1, error2 } = UITextDatesError[settings.locale];
  const { subtitle3 } = UIText[settings.locale];

  let errors = [e1, e2];
  if (from === to && from >= 0 && to >= 0) {
    errors.push(error1);
  } else if (from > to) {
    errors.push(error2);
  }

  useEffect(() => {
    if (typeof from === "undefined") {
      dispatch(UIactions.setField({ id: deliveryWindowFromUI, value: dayjs("08:00", "HH:mm") }));
    }
    if (typeof to === "undefined") {
      dispatch(
        UIactions.setField({ id: deliveryWindowToUI, value: dayjs("19:30", "HH:mm") })
      );
    }
  }, [dispatch, from, to]);

  return (
    <Grid2
      container
      {...{
        rowGap: 2,
        columnSpacing: 2,
        size: { xl: 12, lg: 12, md: 12, sm: 12, xs: 12 },
        paddingTop: 2,
      }}
    >
      <Grid2 container size={12}>
        <Typography fontWeight="bold">{subtitle3}</Typography>
      </Grid2>
      <TimePckr
        {...{
          id: deliveryWindowFromUI,
          UIText:UITextFrom,
          minTime: dayjs("08:00", "HH:mm"),
          maxTime: dayjs("18:30", "HH:mm"),
          size: { xl:3,lg:3,md:3,sm:3,xs:6 }
        }}
      />
      <TimePckr
        {...{
          id: deliveryWindowToUI,
          UIText:UITextTo,
          minTime: dayjs("09:00", "HH:mm"),
          maxTime: dayjs("19:30", "HH:mm"),
          size: { xl:3,lg:3,md:3,sm:3,xs:6 }
        }}
      />
      <SlimAlert {...{ errors, size: 12 }} />
    </Grid2>
  );
};

export default DeliveryWindow;
