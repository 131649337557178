import { Grid2 } from "@mui/material";
import { Pagination } from "../../GeneralPurpose/Pagination";
import { defaultPagination } from "../../GeneralPurpose/genericIDs";
import useVH from "../../../../Hooks/useVH";
import useUIBp from "../../../../Hooks/useUIBoilerplate";

const BasicFooter = ({ children, noPagination, onPaginationChange }) => {
  const { UI } = useUIBp();
  const vh = useVH(6);
  const pagination = UI[defaultPagination] ?? { count: 0 };

  return (
    <Grid2
      container
      {...{
        paddingLeft: "2vh",
        paddingRight: "2vh",
        height: vh,
        alignItems: "center",
        justifyContent: "end",
        size:12,
        columnGap: 2,
      }}
    >
      {children}
      {noPagination || !pagination.count ? null : (
        <Pagination {...{ size: "auto", onChange: onPaginationChange }} />
      )}
    </Grid2>
  );
};

export default BasicFooter;
