import {
  CircularProgress,
  Grid2,
  Switch as MUISwitch,
  Typography,
} from "@mui/material";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import AutoHelp from "../Wrappers/AutoHelp";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";
import { useEffect } from "react";

const Switch = (props) => {
  const {
    id,
    onChange: fn = () => {},
    disableDefault,
    UIText,
    labelProps = {},
    labelRight,
    working,
    size,
    clearOnUnmount,
    noLabel,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "xs",
    "onChange",
    "disableDefault",
    "UIText",
    "labelRight",
    "labelProps",
    "working",
    "size",
    "switchSize",
    "clearOnUnmount",
    "noLabel",
  );
  const { dispatch, UI, settings } = useUIBp();
  const active = UI[id];
  const { label, fixedTooltip, tooltip } = UIText[settings.locale];

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        dispatch(UIactions.clear(id));
      }
    };
  }, [dispatch, id, clearOnUnmount]);

  const onChange = (e, value) => {
    if (!disableDefault) {
      dispatch(UIactions.setValue({ id, value }));
    }
    fn(value);
  };

  return (
    <AutoHelp {...{ fixedTooltip, tooltip }}>
      <Grid2 container alignItems="center" size={size}>
        {noLabel ? null : labelRight ? null : (
          <Grid2 container size="grow">
            <Typography
              {...{ fontWeight: "bold", fontSize: "0.9rem", ...labelProps }}
            >
              {label}
            </Typography>
          </Grid2>
        )}
        <Grid2 container size="auto" justifyContent="center">
          {working ? (
            <CircularProgress size={20} sx={{ marginRight: 2 }} />
          ) : (
            <MUISwitch
              {...filteredProps}
              {...{ onChange, checked: !!active }}
            />
          )}
        </Grid2>
        {noLabel ? null : labelRight ? (
          <Grid2 container size="grow">
            <Typography
              {...{
                fontWeight: "bold",
                fontSize: "0.9rem",
                sx: { cursor: "default" },
                ...labelProps,
              }}
            >
              {label}
            </Typography>
          </Grid2>
        ) : null}
      </Grid2>
    </AutoHelp>
  );
};

export default Switch;
