import {
  Button as MUIButton,
  CircularProgress,
  Typography,
  Grid2,
} from "@mui/material";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import AutoHelp from "../Wrappers/AutoHelp";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import React from "react";

// const UIText = {
//     'es-CL':{
//         label:'',
//         tooltip:'',
//     }
// }

const Button = (props) => {
  const {
    UIText,
    navTo,
    navReplace,
    working,
    labelSx = { fontSize: "0.8rem" },
    onClick,
    disabled,
    size,
    progressSize = 14,
    variant = "contained",
    btnSize = "small",
    fullWidth,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "UIText",
    "navTo",
    "navReplace",
    "working",
    "labelSx",
    "progressSize",
    "btnSize"
  );
  const { navigate, settings } = useUIBp();
  const locale = settings.locale ?? "es-CL";

  const X = UIText[locale] ?? UIText.universal;
  const { label, tooltip, fixedTooltip } = X;

  let progress = <CircularProgress size={progressSize} />;

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (navTo) {
      navigate(navTo, {
        replace: navReplace,
      });
    }
  };

  return (
    <Grid2 container size={size}>
      <AutoHelp {...{ tooltip, fixedTooltip, disabled: disabled || working }}>
        <MUIButton
          {...filteredProps}
          {...{
            fullWidth: size || fullWidth ? true : undefined,
            variant,
            size: btnSize,
            startIcon: working ? progress : filteredProps.startIcon,
            onClick: handleClick,
            disabled: disabled || working,
          }}
        >
          <Typography noWrap sx={labelSx} >
            {label}
          </Typography>
        </MUIButton>
      </AutoHelp>
    </Grid2>
  );
};

export default Button;
