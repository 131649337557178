import { ArrowDropDown, ArrowRight, Remove } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import getFilesTree from "../../../Actions/getFilesTree";
import IconButton from "../../../../UI/GeneralPurpose/IconButton";
import { treesActions } from "../../../../../store/slices/trees";

const Expand = ({
  loaded,
  auth,
  id: file,
  repository: repo,
  expanded,
  noChildren,
  idx,
  locale,
}) => {
  const dispatch = useDispatch();

  // handle expand click
  const onClick = () => {
    if (!loaded) {
      dispatch(getFilesTree({ ...auth, idx, file, repo, locale }));
    }
    dispatch(treesActions.toggleExpandFVT({ id: 'repo-'+repo, idx }));
  };

  let icon;
  if (!loaded) {
    icon = <ArrowRight sx={{ fontSize: 19 }} />;
  } else {
    if (noChildren) {
      icon = <Remove sx={{ fontSize: 19 }} color="disabled" />;
    } else if (expanded) {
      icon = <ArrowDropDown sx={{ fontSize: 19 }} />;
    } else {
      icon = <ArrowRight sx={{ fontSize: 19 }} />;
    }
  }

  return (
    <IconButton
      {...{
        UIText: { universal: {} },
        disabled: loaded && noChildren,
        onClick,
        icon,
        sx: { padding: 0.1 },
        size: "auto",
      }}
    />
  );
};

export default Expand;
