import { Chip } from "@mui/material";
import AutoHelp from "../../../../../UI/Wrappers/AutoHelp";

const TTChip = ({data = {}}) => {
  const fixedTooltip = `(${data.address}) ${data.name}`;

  return (
    <AutoHelp {...{ fixedTooltip }}>
      <Chip label={data.name} color="primary" />
    </AutoHelp>
  );
};

export default TTChip;
