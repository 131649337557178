import axios from "axios";
import { workerActions } from "../../../store/slices/workers";
import { warehouseActions } from "../../../store/slices/warehouse";

export const getMailsId = "getMails";
export const mailsData = "mails";

const getMails = ({
  M365server,
  cloner_key,
  query = "",
  folderId,
  controller,
}) => {
  const id = getMailsId;

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      // Getting the mail through the /api/folder/{folderId}/message handler
      const url =
        M365server +
        `mailapi/folder/${folderId}/message` +
        (query ? query + "&limit=999999" : "?limit=999999");
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
      });

      const data = response.data;
      // Loading the mail in the warehouse
      if (data) {
        dispatch(warehouseActions.load({ id: mailsData, data: data.messages }));
      } else {
        dispatch(warehouseActions.unload(mailsData));
      }
      dispatch(workerActions.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        dispatch(workerActions.clear(id));
        return;
      }
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};

export default getMails;
