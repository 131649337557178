import { useEffect } from "react"
import useUIBp from "../../Hooks/useUIBoilerplate"

const RootReroute = ()=>{
    const {auth,navigate} = useUIBp()

    useEffect(()=>{
        navigate('/login',{
            replace:true,
          })
    },[navigate,auth])
}

export default RootReroute