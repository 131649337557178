import { useEffect } from "react";
import getDeviceConfig, {
  configDataId,
} from "../../../../store/actions/devices/getConfig";
import { Dialog, Grid2 } from "@mui/material";
import Details from "./Components/Details";
import Edit from "./Components/Edit";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import { devicesData } from "../../View/Main/Devices";

const UIText = {
  "es-CL": { preheader: "Detalles del dispositivo " },
  "en-US": { preheader: "Device details " },
};

const DeviceConfig = () => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBp();
  const { payload } = UI.modal;
  const { mid: machine } = payload;
  const locale = settings.locale;
  const data = warehouse[configDataId] ?? {};
  const devices = warehouse[devicesData] ?? {};
  const device = devices[machine] ?? {};
  const { user, name, croppedVersion } = device;

  const { preheader } = UIText[locale] ?? "";
  const header = preheader + name;

  useEffect(() => {
    const controller = new AbortController();
    dispatch(
      getDeviceConfig({
        ...auth,
        controller,
        payload: { user, machine, token: auth.token },
      })
    );
  }, [dispatch, user, machine, auth]);

  useEffect(() => {
    return () => {
      //   dispatch(workers.clear(postGroupId));
      //   dispatch(UIactions.clear(groupNameId));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    dispatch(UIactions.closeModal());
  };

  return (
    <Dialog {...{ open: true, onClose }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2 container {...{ sx: { width: "100%" } }}>
          <Details {...data} {...{ version: croppedVersion, locale }} />
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Edit {...device} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default DeviceConfig;
