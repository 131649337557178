import { Dialog, Grid2 } from "@mui/material";
import UserPicker from "./Components/UserPicker";
import DevicePicker from "./Components/DevicePicker";
import Details from "./Components/Details";
import Options, {
  recoveryDateUI,
  recoveryOptionUI,
} from "./Components/Options";
import Close from "./Components/Close";
import Add from "./Components/Add";
import { useEffect } from "react";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import Divider from "../../../UI/GeneralPurpose/Divider";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import User from "./Components/User";
import DeviceName from "./Components/DeviceName";
import Warning from "./Components/Warning";
import Save from "./Components/Save";

const UIText = {
  "es-CL": {
    newDevice: "Agregar dispositivo a la solicitud",
    editDevice: "Editar dispositivo",
    editDevices: "Editar dispositivos",
  },
  "en-US": {
    newDevice: "Add device to request",
    editDevice: "Edit device",
    editDevices: "Edit devices",
  },
};

const CUrecoveries = () => {
  const { dispatch, settings, UI } = useUIBp();
  const UITextLocalized = UIText[settings.locale];
  const { payload } = UI.modal ?? {};
  const recoveries = payload ?? [];
  const singleRecovery = recoveries[0] ?? { user: "", device: "" };

  let header = "";
  if (recoveries.length === 0) {
    header = UITextLocalized.newDevice;
  } else if (recoveries.length === 1) {
    header = UITextLocalized.editDevice;
  } else {
    header = UITextLocalized.editDevices;
  }

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(recoveryOptionUI));
      dispatch(UIactions.clear(recoveryDateUI));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2
          container
          {...{ rowGap: 2, paddingTop: 2, sx: { width: "100%" } }}
        >
          {recoveries.length > 1 ? <Warning {...{ recoveries }} /> : null}
          {recoveries.length === 1 ? (
            <User {...{ user: singleRecovery.user }} />
          ) : null}
          {recoveries.length === 0 ? <UserPicker /> : null}
          {recoveries.length === 1 ? (
            <DeviceName {...{ device: singleRecovery.device }} />
          ) : null}
          {recoveries.length === 0 ? <DevicePicker /> : null}
          {recoveries.length > 1 ? null : (
            <Details
              {...{
                fullSize: singleRecovery.full_size ?? singleRecovery.fullSize,
                lastBkp: singleRecovery.lastBkp,
              }}
            />
          )}
          {recoveries.length > 1 ? null : <Divider />}
          <Options {...{ recoveries }} />
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        {recoveries.length === 0 ? <Add /> : null}
        {recoveries.length > 0 ? <Save {...{ recoveries }} /> : null}
      </BasicDialogActions>
    </Dialog>
  );
};

export default CUrecoveries;
