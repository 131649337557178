import axios from "axios";
import { getTunnelData } from "./getTunnelData";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

export const usersData = "users";

const getGroupData = ({ id, controller, server, cloner_key, group }) => {
  console.log("fetching group data!");
  return async (dispatch) => {
    dispatch(workerActions.start(id));
    const tunnelsController = new AbortController();
    try {
      const url = server + "api/groups";
      const response = await axios.get(url, {
        signal: controller.signal,
        params: { group },
        headers: { cloner_key },
      });
      const data = response.data;
      dispatch(warehouseActions.load({ id: usersData, data: data.users }));

      for (const user of data.users) {
        dispatch(
          getTunnelData({
            controller: tunnelsController,
            server,
            cloner_key,
            user: user.login,
          })
        );
      }
      dispatch(workerActions.finish({ id }));
    } catch (error) {
      dispatch(workerActions.finish({ id, error: error.message }));
      tunnelsController.abort();
    }
  };
};

export default getGroupData;
