import { Grid2, Typography } from "@mui/material";
import CollapseGrid from "../../../../../UI/GeneralPurpose/CollapseGrid";
import TextField from "../../../../../UI/GeneralPurpose/SimpleTextField";
import SuggestMail from "./SuggestMail";
import VerifyMail from "./VerifyMail";
import { useEffect, useMemo } from "react";
import {
  getPlatformAndServicesId,
  platformServices,
} from "../../../../../../store/actions/microsoft365/getPlatformsAndServices";
import { userUI } from "../User";
import { bkpMailCountId } from "../../../UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { bkpDriveSizeId } from "../../../UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { humanBytes } from "../../../../../../utility/misc/humanizers";
import { m365validated } from "../Buttons/Save";
import SlimAlert from "../../../../../UI/GeneralPurpose/SlimAlert";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import {
  getM365ClientId,
  m365clientData,
} from "../../../../../../store/actions/microsoft365/getClient";
import { useWorker } from "../../../../../../Hooks/useWorker";
import { m365Switch } from "./M365";
import { m365orgData } from "../../../../../../store/actions/microsoft365/getOrganization";
import { UIactions } from "../../../../../../store/slices/UI";
import { workerActions } from "../../../../../../store/slices/workers";
import Switch from "../../../../../UI/GeneralPurpose/Switch";

export const UIText = {
  "es-CL": {
    mails: "correos",
    explanation:
      "Para poder guardar un nuevo usuario Microsoft 365, primero debes verificar que el correo que deseas use es válido. La sugerencia ofrecida por el sistema retorna un correo válido en caso de encontrar uno. Adicionalmente, al menos uno de los servicios de respaldo debe estar activado",
  },
  "en-US": {
    mails: "mails",
    explanation:
      "In order to save a new Microsoft 365 user, you must first verify that the email you wish to use is valid. The system's suggestion returns a valid email if it finds one. Additionally, at least one of the backup services must be enabled",
  },
};

export const mailLockId = "365maillock";
export const m365MailId = "365mail";
export const bkpMailSwitchId = "365mailbackup";
export const bkpDriveSwitchId = "365filesbackup";
export const m365setup = "m365setup";

const M365Userform = () => {
  const { dispatch, UI, warehouse, settings } = useUIBp();
  const { done } = useWorker(getM365ClientId);
  const { done: done2 } = useWorker(m365setup);
  const { done: done3 } = useWorker(getPlatformAndServicesId);
  const open = UI[m365Switch];
  const mailLocked = UI[mailLockId] ?? false;
  const Userfield = UI[userUI] ?? { value: "" };
  const login = Userfield.value;
  const bkpMailCount = UI[bkpMailCountId + login] ?? 0;
  const bkpDriveSize = UI[bkpDriveSizeId + login] ?? 0;
  const { mails, explanation } = UIText[settings.locale];
  const services = useMemo(
    () => warehouse[platformServices] ?? [],
    [warehouse]
  );
  const clientData = useMemo(
    () => warehouse[m365clientData] ?? {},
    [warehouse]
  );
  const isNew = !clientData.client_mail;

  const w365org = warehouse[m365orgData] ?? {};

  const own = w365org.own ?? { mail_account: "" };
  const parent = w365org.parent ?? { mail_account: "" };

  const availableCert = own.client_id ? own : parent;
  const domain = availableCert.mail_account
    ? availableCert.mail_account.split("@")[1]
    : null;

  const org = {
    id: own.mail_account ? own.id : parent.id,
    domain,
  };

  useEffect(() => {
    if (done && !done2 && done3 && clientData.cloner_user) {
      dispatch(
        UIactions.setValue({
          id: mailLockId,
          value: true,
        })
      );
      dispatch(
        UIactions.setField({
          id: m365MailId + "-" + login,
          value: clientData.client_mail.split("@")[0],
        })
      );
      for (let cService of clientData.services) {
        for (let service of services) {
          if (service.id !== cService.service_id) {
            continue;
          }
          if (service.name === "Microsoft Mail") {
            dispatch(
              UIactions.setValue({
                id: bkpMailSwitchId,
                value: cService.enabled,
              })
            );
          } else if (service.name === "Microsoft Drive") {
            dispatch(
              UIactions.setValue({
                id: bkpDriveSwitchId,
                value: cService.enabled,
              })
            );
          }
        }
      }
      dispatch(workerActions.finish({ id: m365setup }));
    }
  }, [dispatch, clientData, done, done2, done3, services, login]);

  return (
    <CollapseGrid
      {...{
        open,
        innerProps: { sx: { paddingBottom: 2, rowGap: 2 } },
      }}
    >
      {isNew ? (
        <SlimAlert
          notCollapse
          {...{ messages: [explanation], severity: "info", xs: 12 }}
        />
      ) : null}
      {isNew ? (
        <Grid2 container {...{ size: 12, columnSpacing: 2, justifyContent:"space-between" }}>
          <VerifyMail {...{ org, login }} />
          <SuggestMail {...{ org, login }} />
        </Grid2>
      ) : null}

      <Grid2 container {...{ size: 12, columnSpacing: 2 }}>
        <TextField
          {...{
            id: m365MailId + "-" + login,
            disabled: mailLocked,
            onChange: (e) => {
              dispatch(UIactions.clear(m365validated));
            },
            UIText: {
              "es-CL": {
                label: "Correo Microsoft 365",
              },
              "en-US": {
                label: "Microsoft 365 Mail",
              },
            },
            size: 12,
          }}
        />
      </Grid2>
      <Grid2 container {...{ size: 12, columnSpacing: 2 }}>
        <Switch
          labelRight
          {...{
            id: bkpMailSwitchId,
            UIText: {
              "es-CL": {
                label: "Respaldar correos",
              },
              "en-US": {
                label: "Backup emails",
              },
            },
            size: 6,
          }}
        />
        <Grid2 container {...{ size: "grow", sx: { alignItems: "center" } }}>
          <Typography sx={{ fontSize: "0.9rem" }}>{`${
            bkpMailCount ? bkpMailCount : "--"
          } ${mails}`}</Typography>
        </Grid2>
      </Grid2>
      <Grid2 container {...{ size: 12, columnSpacing: 2 }}>
        <Switch
          labelRight
          {...{
            id: bkpDriveSwitchId,
            UIText: {
              "es-CL": {
                label: "Respaldar archivos",
              },
              "en-US": {
                label: "Backup files",
              },
            },
            size: 6,
          }}
        />
        <Grid2 container {...{ size: "grow", sx: { alignItems: "center" } }}>
          <Typography sx={{ fontSize: "0.9rem" }}>
            {humanBytes(bkpDriveSize) ?? "-- bytes"}
          </Typography>
        </Grid2>
      </Grid2>
    </CollapseGrid>
  );
};

export default M365Userform;
