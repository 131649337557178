import emailCompliant from "../constraints/emailCompliant";

const UIText = {
  "es-CL": {
    error: "El correo contiene caracteres no permitidos",
  },
  "en-US": {
    error: "The email contains forbidden characters",
  },
};

const compliantEmail = (email, locale) => {
  const { error } = UIText[locale];

  const compliant = emailCompliant(email);

  if (compliant === email) {
    return "";
  }

  return error;
};

export default compliantEmail;
