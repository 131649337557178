import { Grid2 } from "@mui/material";
import BasicHeaders from "../../../../../UI/BasicLayout/Components/BasicHeaders";
import HeadersData from "./Headers";
import { sortObjByKey } from "../../../../../../utility/misc/sortObjByKey";
import { useMemo } from "react";
import UserRow from "./UserRow/UserRow";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";

export const arrayId = "integrationUsers";
export const sortId = "createdSort";

const UsersList = (props) => {
  const { UI } = useUIBp();
  const { users } = props;
  const sortRaw = UI[sortId]

  const usersRows = useMemo(() => {
    let usrs = [...(users ?? [])];
  const sort = sortRaw ?? { value: "mail", descending: false };

    return usrs
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending))
      .map((data, idx) => <UserRow {...data} key={idx} arrayId={arrayId} />);
  }, [users, sortRaw]);

  const usersID = users.map((user) => user.login);

  return (
    <Grid2 container {...{ size: 12 }}>
      <BasicHeaders
        {...{
          arrayId: arrayId,
          sortId,
          fullArray: usersID,
          headers: HeadersData,
        }}
      />
      <Grid2
        container
        {...{
          paddingLeft: 2,
          paddingRight: 2,
          size: 12,
          sx: { height: 400, overflow: "scroll" },
        }}
        alignContent="start"
      >
        {usersRows}
      </Grid2>
    </Grid2>
  );
};

export default UsersList;
