import { Grid2, Typography } from "@mui/material";
import { Fragment } from "react";

const UIText = {
  "es-CL": { label: "Version de Cloner" },
  "en-US": { label: "Cloner version" },
};

const Version = ({ version, locale }) => {
  const { label } = UIText[locale];

  return (
    <Fragment>
      <Grid2 container size={6}>
        <Typography>{label}</Typography>
      </Grid2>
      <Grid2 container size={6}>
        <Typography>{version}</Typography>
      </Grid2>
    </Fragment>
  );
};

export default Version;
