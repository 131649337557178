import { Block } from "@mui/icons-material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../store/slices/UI";
import { warehouseActions } from "../../../../../../store/slices/warehouse";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";
import { devicesDataId } from "../../../../../../store/actions/devices/getAllDevices";
import { currentExclusionDevicesUI, exclusionDevicesUI } from "../../../Exclusions/Components/DevicesList/Components/Headers";
import { exclusionDevicesData } from "../../../Exclusions/Components/DevicesList/DevicesList";

const UIText = {
  "es-CL": { label: "Exclusiones" },
  "en-US": { label: "Exclusions" },
};

const Exclusions = () => {
  const { dispatch, UI, warehouse: Warehouse } = useUIBp();
  const selected = UI.selected ?? [];
  const devices = Warehouse[devicesDataId];

  const onClick = () => {
    let selectedDevices = [];
    for (const mid of selected) {
      selectedDevices.push(devices[mid]);
    }

    dispatch(UIactions.setValue({ id: exclusionDevicesUI, value: selected })); 
    dispatch(
      UIactions.setValue({ id: currentExclusionDevicesUI, value: selected }) 
    );
    dispatch(
      warehouseActions.load({ id: exclusionDevicesData, data: selectedDevices }) 
    );
  };

  const navTo = "/dashboard/devices/exclusions";

  return (
    <ActionButton
      {...{
        disabled: !selected.length,
        navTo,
        onClick,
        UIText,
        icon: <Block />,
      }}
    />
  );
};

export default Exclusions;
