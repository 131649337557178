import { Grid2 } from "@mui/material";
import ContactInfo from "./Components/ContactInfo/ContactInfo";
import Reason from "./Components/Reason/Reason";
import DeliveryMode from "./Components/DeliveryMode/DeliveryMode";

const Form = () => {
  return (
    <Grid2 container {...{ padding: 2, columnSpacing: 2, rowGap: 2, size: 12 }}>
      <ContactInfo />
      <DeliveryMode />
      <Reason />
    </Grid2>
  );
};

export default Form;
