import { Divider, Grid2 } from "@mui/material";
import DownloadSheet from "./Components/DownloadSheet";
import UploadSheet from "./Components/UploadSheet";
import SelectEmpty from "./Components/SelectEmpty";
import VerifyMail from "./Components/VerifyMail";
import SuggestMail from "./Components/SuggestMail";
import More from "./Components/More";
import ResetClient from "./Components/ResetClient";

const TopBar = (props) => {
  return (
    <Grid2
      container
      {...{
        padding: 2,
        paddingTop: 0,
        paddingBottom: 2,
        columnSpacing: 2,
        rowGap: 2,
        size: 12,
        justifyContent: "space-between",
      }}
    >
      <Grid2
        container
        size={12}
        alignItems="center"
        justifyContent="space-between"
        columnSpacing={2}
      >
        <UploadSheet {...props} />
        <DownloadSheet />
      </Grid2>
      <Grid2 container size={12} alignItems="center" columnGap={2}>
        <SelectEmpty {...props} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <VerifyMail {...props} />
        <SuggestMail {...props} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <ResetClient {...props} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <More />
      </Grid2>
    </Grid2>
  );
};

export default TopBar;
