import { Language } from "@mui/icons-material";
import { Grid2, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { settingsSliceActions } from "../../../../../store/slices/settings";
import { viewmodeUI } from "../../../../../App";
import { useLocation } from "react-router-dom";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const options = [
  { value: "es-CL", label: "es-CL", tooltip: "(español - chile)" },
  { value: "en-US", label: "en-US", tooltip: "(english - US)" },
];

const Locale = () => {
  const { dispatch, settings, UI } = useUIBp();
  const location = useLocation() ?? {};
  const viewmode = UI[viewmodeUI];
  const locale = settings.locale;

  const buttons = options.map((x, idx) => (
    <MenuItem value={x.value} key={idx} dense>
      <Tooltip title={x.tooltip} placement="left" key={idx}>
        <Typography fontSize="0.8rem" alignContent="center">
          {x.label}
        </Typography>
      </Tooltip>
    </MenuItem>
  ));

  const handleChange = (e) => {
    dispatch(settingsSliceActions.setLocale(e.target.value));
  };

  let show = true;
  switch (location.pathname) {
    case "/dashboard/users":
      show = false;
      break;
    case "/dashboard/devices":
      show = false;
      break;
    case "/dashboard/reports":
      show = false;
      break;
    case "/dashboard/files":
      show = false;
      break;
    default:
      break;
  }

  return (
    <Grid2 container direction="row" alignItems="center" size={{xs:"auto"}} >
      {viewmode === "desktop" || show ? (
        <Language sx={{ color: "secondary.light" }} />
       ) : null} 
      <Select
        {...{
          size: "small",
          value: locale,
          onChange: handleChange,
          sx: {
            color: "secondary.light",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0px",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
              visibility: "hidden",
              width: 10,
            },
          },
        }}
      >
        {buttons}
      </Select>
    </Grid2>
  );
};

export default Locale;
