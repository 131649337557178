import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../../../../store/slices/workers";

const UIText = {
  "es-CL": { error: "Error descargando archivo .xlsx: " },
  "en-US": { error: "Error downloading .xlsx file: " },
};

export const downloadTemplateUI = "downloadTemplate";

const downloadM365Template = (locale) => {
  const { error } = UIText[locale];
  const id = downloadTemplateUI;
  // https://itnext.io/how-to-download-files-with-javascript-d5a69b749896
  return async (dispatch) => {
    try {
      dispatch(workerActions.start(id));
      const url = "https://instaladores.cloner.cl/templado_usuarios_cloner365.xlsx"

      const anchor = document.createElement("a");
      anchor.href = url;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Dispatch finishDownload action
      dispatch(workerActions.finish({ id }));
    } catch (e) {
      console.error(e);
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};

export default downloadM365Template;