import { Grid2 } from "@mui/material";
import Empty from "./Empty";
import Loading from "./Loading";
import Error from "./Error";
import useVH from "../../../../../Hooks/useVH";
import { useEffect, useRef } from "react";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../store/slices/UI";
import { viewmodeUI } from "../../../../../App";

export const resetScrollId = "resetScroll";

const Core = ({
  hasHeaders,
  empty,
  working,
  error,
  done,
  elements,
  contentSize = 65,
}) => {
  const {dispatch,UI} = useUIBp();
  const viewmode = UI[viewmodeUI] ?? "desktop";
  const resetScroll = UI[resetScrollId] ?? false;
  const rawVH = hasHeaders ? contentSize - 6 : contentSize;
  const vh1 = useVH(rawVH);
  const vh2 = useVH(rawVH-2);
  const vh = viewmode === "desktop" ? vh1 : vh2;
  const ref = useRef(null);

  useEffect(() => {
    if (resetScroll) {
      ref.current.scrollTop = 0;
      dispatch(UIactions.clear(resetScrollId));
    }
  }, [resetScroll,dispatch]);

  return (
    <Grid2
      ref={ref}
      container
      {...{
        paddingLeft: "2vh",
        paddingRight: "2vh",
        alignContent: "start", //This might be a problem?
        sx: {
          maxHeight: vh,
          overflowY: "auto",
          overflowX: "hidden",
        },
        size: { xs: 12 },
      }}
    >
      <Loading {...{ working }} />
      {done && !error.error ? <Empty {...empty} /> : null}
      <Error {...error} />
      {done && !error.error ? elements : null}
    </Grid2>
  );
};

export default Core;
