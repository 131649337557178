import { Card, Grid2 } from "@mui/material";
import Back from "./Components/Back";
import Next, { miscErrorsUI } from "./Components/Next";
import { RecoverySteps } from "../Components/Steps";
import Form from "./Components/Form/Form";
import Reset from "./Components/Reset";
import { useEffect } from "react";
import { contactNameUI } from "./Components/Form/Components/ContactInfo/ContactName";
import { contactPhoneUI } from "./Components/Form/Components/ContactInfo/ContactPhone";
import { contactEmailUI } from "./Components/Form/Components/ContactInfo/ContactEmail";
import { deliveryOfficeUI } from "./Components/Form/Components/AddressInfo/DeliveryOffice";
import { deliveryStreetUI } from "./Components/Form/Components/AddressInfo/DeliveryStreet";
import { deliveryStreetNumberUI } from "./Components/Form/Components/AddressInfo/DeliveryStreetNumber";
import { regionSelectUI } from "./Components/Form/Components/AddressInfo/Region";
import { districtSelectUI } from "./Components/Form/Components/AddressInfo/District";
import { citySelectUI } from "./Components/Form/Components/AddressInfo/City";
import {
  deliveryWindowFromUI,
  deliveryWindowToUI,
} from "./Components/Form/Components/DeliveryWindow";
import { otherReasonUI } from "./Components/Form/Components/Reason/OtherReason";
import { reasonUI } from "./Components/Form/Components/Reason/Reasons";
import timeToMinutes from "../../../../utility/misc/timeToMinutes";
import { defaultRadioSelect } from "../../../UI/GeneralPurpose/genericIDs";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import useRecoveries from "../../../../Hooks/useRecoveries";
import { UIactions } from "../../../../store/slices/UI";
import StepsBar from "../RecoveryStep1/Components/StepsBar";
import BasicHeader from "../../../UI/BasicLayout/Components/Header";

const RecoveryStep2 = () => {
  const { dispatch, settings } = useUIBp();
  const recoveries = useRecoveries();

  const steps = RecoverySteps[settings.locale];
  const header = steps[1];

  useEffect(() => {
    const deliveryData = recoveries.deliveryData;
    if (deliveryData.contactName) {
      dispatch(
        UIactions.setField({
          id: contactNameUI,
          value: deliveryData.contactName,
        })
      );
    }
    if (deliveryData.contactPhone) {
      dispatch(
        UIactions.setField({
          id: contactPhoneUI,
          value: deliveryData.contactPhone,
        })
      );
    }
    if (deliveryData.contactEmail) {
      dispatch(
        UIactions.setField({
          id: contactEmailUI,
          value: deliveryData.contactEmail,
        })
      );
    }
    if (deliveryData.deliveryOffice) {
      dispatch(
        UIactions.setField({
          id: deliveryOfficeUI,
          value: deliveryData.deliveryOffice,
        })
      );
    }
    if (deliveryData.deliveryStreet) {
      dispatch(
        UIactions.setField({
          id: deliveryStreetUI,
          value: deliveryData.deliveryStreet,
        })
      );
    }
    if (deliveryData.deliveryStreetNumber) {
      dispatch(
        UIactions.setField({
          id: deliveryStreetNumberUI,
          value: deliveryData.deliveryStreetNumber,
        })
      );
    }
    if (deliveryData.region) {
      dispatch(
        UIactions.setValue({
          id: regionSelectUI,
          value: deliveryData.region,
        })
      );
    }
    if (deliveryData.district) {
      dispatch(
        UIactions.setValue({
          id: districtSelectUI,
          value: deliveryData.district,
        })
      );
    }
    if (deliveryData.city) {
      dispatch(
        UIactions.setValue({
          id: citySelectUI,
          value: deliveryData.city,
        })
      );
    }
    if (deliveryData.deliveryWindowFrom) {
      dispatch(
        UIactions.setField({
          id: deliveryWindowFromUI,
          value: deliveryData.deliveryWindowFrom,
        })
      );
    }
    if (deliveryData.deliveryWindowTo) {
      dispatch(
        UIactions.setField({
          id: deliveryWindowToUI,
          value: deliveryData.deliveryWindowTo,
        })
      );
    }
    if (deliveryData.otherReason) {
      dispatch(
        UIactions.setField({
          id: otherReasonUI,
          value: deliveryData.otherReason,
        })
      );
    }
    if (deliveryData.reason) {
      dispatch(
        UIactions.setValue({
          id: reasonUI,
          value: deliveryData.reason,
        })
      );
    }
    if (!deliveryData.deliveryMethod) {
      dispatch(
        UIactions.setValue({
          id: defaultRadioSelect,
          value: "link",
        })
      );
    } else {
      dispatch(
        UIactions.setValue({
          id: defaultRadioSelect,
          value: deliveryData.deliveryMethod,
        })
      );
    }

    return () => {
      dispatch(UIactions.clear(contactNameUI));
      dispatch(UIactions.clear(contactPhoneUI));
      dispatch(UIactions.clear(contactEmailUI));
      dispatch(UIactions.clear(defaultRadioSelect));
      dispatch(UIactions.clear(deliveryOfficeUI));
      dispatch(UIactions.clear(deliveryStreetUI));
      dispatch(UIactions.clear(deliveryStreetNumberUI));
      dispatch(UIactions.clear(regionSelectUI));
      dispatch(UIactions.clear(districtSelectUI));
      dispatch(UIactions.clear(citySelectUI));
      dispatch(UIactions.clear(deliveryWindowFromUI));
      dispatch(UIactions.clear(deliveryWindowToUI));
      dispatch(UIactions.clear(otherReasonUI));
      dispatch(UIactions.clear(reasonUI));
      dispatch(UIactions.clear(miscErrorsUI));
    };
  }, [dispatch, recoveries.deliveryData]);

  return (
    <Grid2
      container
      {...{
        sx: {
          padding: "2vh",
          paddingTop: 0,
          paddingBottom: 0,
          height: "92vh",
          overflow: "scroll",
          width: "100%",
        },
        alignContent: "start",
      }}
    >
      <StepsBar idx={1} />
      <Grid2 container {...{ size: 12, justifyContent: "center" }}>
        <Card {...{ sx: { maxWidth: 800, width: "100%" } }}>
          <Grid2 container {...{ sx: { width: "100%" } }}>
            <BasicHeader>{header}</BasicHeader>
            <Form />
          </Grid2>
        </Card>
      </Grid2>
      <Grid2
        container
        {...{
          justifyContent: "space-between",
          alignItems: "center",
          sx: { height: "6vh" },
          size:12,
        }}
      >
        <Back />
        <Reset />
        <Next />
      </Grid2>
    </Grid2>
  );
};

export default RecoveryStep2;
