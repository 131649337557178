import DateTime from "../../../../../UI/GeneralPurpose/DateTime";

const UIText = {
  "es-CL": { label: "Hasta" },
  "en-US": { label: "Until" },
};

export const filterToUI = "filterTo";

const FilterTo = () => {
  return (
    <DateTime
      clearOnUnmount
      {...{
        id: filterToUI,
        UIText,
        size: 6,
      }}
    />
  );
};

export default FilterTo;
