import { Grid2, TextField as MUITextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import SlimAlert from "./SlimAlert";
import AutoHelp from "../Wrappers/AutoHelp";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";

/* e.g. UIText
    UIText = {
        es-CL:{
            label:'',
            placeholder:'',
            tooltip:'',
        }
    }
*/

const TextField = (props) => {
  const {
    id,
    onChange = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
    fontSize = "1rem",
    componentSize = "small",
    size,
    validation,
    twinValidation,
    twinId,
    tagError,
    forceLowerCase,
    disabled,
    forceError,
    forceShrink,
    hardValidate,
    submit,
    UIText = {},
    inputSx,
    constraint,
    clearOnUnmount,
    multiline,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "multiline",
    "componentSize",
    "validation",
    "twinValidation",
    "twinId",
    "tagError",
    "forceLowerCase",
    "forceError",
    "hardValidate",
    "submit",
    "help",
    "UIText",
    "fontSize",
    "inputSx",
    "hide",
    "forceShrink",
    "constraint",
    "clearOnUnmount"
  );

  const ref = useRef(null);
  const { dispatch, UI, settings } = useUIBp();
  const [forceUpdate, setForceUpdate] = useState(false);
  const locale = settings.locale;
  const { value = null, error } = UI[id] ?? {};
  const { label, tooltip, fixedTooltip, placeholder } =
    UIText[locale] ?? UIText.universal;

  // Update non-bound field to reflect an existing value
  useEffect(() => {
    ref.current.value = value;
    setForceUpdate(false);
  }, [value, forceUpdate]);

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        dispatch(UIactions.clear(id));
      }
    };
  }, [dispatch, clearOnUnmount, id]);

  // Update error message if locale changes
  useEffect(() => {
    if (locale && validation) {
      dispatch(UIactions.validateField({ id, fn: validation, locale }));
    }
  }, [dispatch, locale, id, twinId, validation, twinValidation]);

  // Validate field on blur. Also execute any onBlur function set from outside
  const handleBlur = () => {
    if (validation) {
      dispatch(UIactions.validateField({ id, fn: validation, locale }));
    }
    onBlur();
  };

  // Hard validate field on enter key press
  const handleHardValidate = (e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      onKeyDown();
      if (hardValidate) {
        dispatch(
          UIactions.validateField({
            id,
            fn: validation,
            force: true,
            locale,
          })
        );
      }
    }
  };

  const debouncedChange = debounce((e) => {
    let value = e.target.value;
    if (constraint) {
      value = constraint(value);
    }
    setForceUpdate(true);
    dispatch(UIactions.setField({ id, value }));
    onChange(e);
  }, 200);

  const handleChange = (e) => {
    debouncedChange(e);
  };

  const debouncedSubmit = debounce((e) => {
    if (submit && (e.key === "Enter" || e.key === "NumpadEnter")) {
      submit(e);
    }
  }, 200);

  const handleSubmit = (e) => {
    debouncedSubmit(e);
  };

  return (
    <Grid2 container {...{ justifyContent: "center", size }}>
      <Grid2
        container
        size={12}
        {...{ alignContent: tagError ? "start" : undefined }}
      >
        <AutoHelp {...{ tooltip, fixedTooltip, disabled }}>
          <MUITextField
            {...filteredProps}
            {...{
              multiline,
              fullWidth: true,
              label,
              placeholder,
              inputRef: ref,
              onChange: handleChange,
              onBlur: handleBlur,
              onKeyDown: handleHardValidate,
              onKeyUp: handleSubmit,
              size: componentSize,
              error: !!error || forceError,
              InputLabelProps: {
                ...filteredProps.InputLabelProps,
                shrink: value || forceShrink ? true : undefined,
              },
              InputProps: {
                ...filteredProps.InputProps,
                sx: {
                  fontSize,
                  borderRadius:
                    !!error && tagError
                      ? "4px 4px 0px 0px"
                      : undefined /*, ...inputSx*/,
                },
              },
              inputProps: {
                sx: {
                  textTransform: forceLowerCase ? "lowercase" : undefined,
                  ...inputSx,
                },
              },
            }}
          />
        </AutoHelp>
      </Grid2>
      <Grid2
        container
        {...{ size:12, alignContent: tagError ? "start" : undefined }}
      >
        <SlimAlert
          errors={!!tagError ? [error] : []}
          fuseTop
          size={12}
        />
      </Grid2>
    </Grid2>
  );
};

export default TextField;
