import { Chip } from "@mui/material";
import { useDispatch } from "react-redux";
import { selectedCommonUI } from "./Category";
import { UIactions } from "../../../../../../store/slices/UI";

const ExtChip = ({ label, value, selected }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.toggleSingle({ id: selectedCommonUI, key: value }));
  };

  const selectedProps = {
    color: "primary",
    variant: "outlined",
    sx: { backgroundColor: "rgba(0, 167, 132, 0.1)", border: "none" },
  };

  return selected ? (
    <Chip
      {...{
        label,
        onClick,
        onDelete:onClick,
      }}
      {...selectedProps}
    />
  ) : (
    <Chip
      {...{
        label,
        onClick,
      }}
    />
  );
};

export default ExtChip;
