import { Dialog, Grid2 } from "@mui/material";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import TopBar from "./Components/TopBar/TopBar";
import Create from "./Components/Create";
import Cancel from "./Components/Cancel";
import UsersList, { arrayId } from "./Components/UsersList/UsersList";
import Empty from "./Components/Empty";
import { useEffect, useMemo } from "react";
import { bkpOneArrayId } from "./Components/UsersList/UserRow/BackupOnedrive";
import { bkpMailArrayId } from "./Components/UsersList/UserRow/BackupMail";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import getClients, {
  getM365ClientsId,
  m365clientsData,
} from "../../../../store/actions/microsoft365/getClients";
import {
  getPlatformAndServicesId,
  getPlatformsAndServices,
  platformServices,
} from "../../../../store/actions/microsoft365/getPlatformsAndServices";
import {
  getM365OrgsId,
  getOrganizations,
  m365orgsData,
} from "../../../../store/actions/microsoft365/getOrganizations";
import { UIactions } from "../../../../store/slices/UI";
import getClientsSize, {
  getM365ClientsSizeId,
} from "../../../../store/actions/microsoft365/getClientsSize";
import { workerActions } from "../../../../store/slices/workers";
import { warehouseActions } from "../../../../store/slices/warehouse";
import { mailLocksId } from "./Components/UsersList/UserRow/M365mail";
import { postClientId } from "../../../../store/actions/microsoft365/postClient";
import { useWorker } from "../../../../Hooks/useWorker";
import { validateMailId } from "../../../../store/actions/microsoft365/validateM365Mails";
import { suggestMailId } from "../../../../store/actions/microsoft365/suggestM365Mails";

const UITextHeader = {
  "es-CL": "Respaldar información de nubes externas",
  "en-US": "Backup external cloud information",
};

const UsersCloudIntegration = () => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBp();
  const { cloner_key, M365server } = auth;
  const { done: done2 } = useWorker(getM365ClientsId);
  const { done: done3 } = useWorker(getPlatformAndServicesId);
  const { done: done4 } = useWorker(getM365OrgsId);
  const locale = settings.locale;
  const header = UITextHeader[locale];
  const { payload } = UI.modal;
  const { users = [] } = payload;
  const m365organizations = useMemo(
    () => warehouse[m365orgsData] ?? {},
    [warehouse]
  );
  const clients = useMemo(() => warehouse[m365clientsData] ?? {}, [warehouse]);
  const services = useMemo(
    () => warehouse[platformServices] ?? [],
    [warehouse]
  );

  let uniqueGroups = useMemo(() => {
    let x = [];
    for (const user of users) {
      if (!x.includes(user.group)) {
        x.push(user.group);
      }
    }
    return x;
  }, [users]);

  useEffect(() => {
    if (!done4) {
      dispatch(
        getOrganizations({
          groups: uniqueGroups,
          cloner_key,
          M365server,
          locale,
        })
      );
    }
  }, [dispatch, uniqueGroups, cloner_key, M365server, locale, done4]);

  useEffect(() => {
    if (!done3) {
      dispatch(getPlatformsAndServices({ M365server, cloner_key, locale }));
    }
  }, [dispatch, done3, M365server, cloner_key, locale]);

  useEffect(() => {
    if (!done2) {
      let orgIDs = [];
      for (const key in m365organizations) {
        orgIDs.push(m365organizations[key].id);
      }

      if (orgIDs.length > 0) {
        dispatch(
          getClients({
            cloner_key,
            M365server,
            orgIDs,
          })
        );
      }
    }
  }, [dispatch, m365organizations, cloner_key, M365server, done2]);

  useEffect(() => {
    if (done2 && done3) {
      if (clients.length > 0 && users.length > 0) {
        for (const user of users) {
          for (const client of clients) {
            if (user.login === client.cloner_user) {
              dispatch(
                UIactions.add({
                  id: mailLocksId,
                  key: user.login,
                })
              );
              dispatch(
                UIactions.setField({
                  id: "365mail-" + user.login,
                  value: client.client_mail.split("@")[0],
                })
              );
              dispatch(
                getClientsSize({
                  cloner_key,
                  M365server,
                  orgID: client.organization_id,
                  user: client.cloner_user,
                  userMail: client.client_mail,
                })
              );

              for (const cService of client.services) {
                for (const service of services) {
                  if (service.id === cService.service_id) {
                    switch (service.name) {
                      case "Microsoft Drive":
                        if (cService.enabled) {
                          dispatch(
                            UIactions.add({
                              id: bkpOneArrayId,
                              key: user.login,
                            })
                          );
                        } else {
                          dispatch(
                            UIactions.remove({
                              id: bkpOneArrayId,
                              key: user.login,
                            })
                          );
                        }
                        break;
                      case "Microsoft Mail":
                        if (cService.enabled) {
                          dispatch(
                            UIactions.add({
                              id: bkpMailArrayId,
                              key: user.login,
                            })
                          );
                        } else {
                          dispatch(
                            UIactions.remove({
                              id: bkpMailArrayId,
                              key: user.login,
                            })
                          );
                        }
                        break;
                      case "Google Drive":
                        break;
                      case "Google Mail":
                        break;
                      default:
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [
    dispatch,
    clients,
    users,
    done2,
    done3,
    services,
    cloner_key,
    M365server,
  ]);

  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(getM365OrgsId));
      dispatch(workerActions.clear(getM365ClientsId));
      dispatch(workerActions.clear(getPlatformAndServicesId));
      dispatch(workerActions.clear(postClientId));
      dispatch(workerActions.clear(getM365ClientsSizeId));
      dispatch(workerActions.clear(validateMailId));
      dispatch(workerActions.clear(suggestMailId));
      dispatch(warehouseActions.unload(m365clientsData));
      dispatch(UIactions.clear(arrayId));
      dispatch(UIactions.clear(mailLocksId));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    console.log("reason", reason);
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "lg" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2 container {...{ sx: { width: "100%" } }}>
          <TopBar {...{ users }} />
          {!!users.length ? <UsersList {...{ users }} /> : <Empty />}
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel />
        <Create {...{ users }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default UsersCloudIntegration;
