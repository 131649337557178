import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { getM365ClientsId } from "./getClients";
import { bkpOneArrayId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { bkpMailArrayId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { arrayId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UsersList";
import { mailLocksId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/M365mail";
import { workerActions } from "../../slices/workers";
import { UIactions } from "../../slices/UI";

const UIText = {
  "es-CL": {
    success: "Remoción del usuario de Microsoft 365 exitosa",
    skipped: "Se intentó activar un usuario sin activar ningun servicio: ",
    error: "Error al activar usuario de Microsoft 365: ",
    fullSuccess: "Todos los usuarios fueron removidos exitosamente",
    partialSuccess:
      "Solo algunos usuarios fueron activados o modificados exitosamente, revisar detalles",
    fullFail: "Todos los usuarios fallaron al activar o modificar",
    success2: "Exito",
    ignored: "Ignorado",
    fail: "Fallo",
  },
  "en-US": {
    success: "Microsoft 365 user removal successful",
    skipped:
      "An attempt was made to activate a user without activating any services: ",
    error: "Error while activating Microsoft 365 user: ",
    fullSuccess: "All users were successfully removed",
    partialSuccess:
      "Only some users were successfully activated or modified, check details",
    fullFail: "All users failed to activate or modify",
    success2: "Success",
    ignored: "Ignored",
    fail: "Failed",
  },
};

export const deleteClientsId = "delete365client";

const deleteClients = ({ M365server, cloner_key, payloads, locale }) => {
  const id = deleteClientsId;
  const {
    success,
    error,
    fullSuccess,
    partialSuccess,
    fullFail,
    success2,
    fail,
  } = UIText[locale];

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    let results = [];

    // Deleting the clients from C365 through the Cloner API
    for (let user of payloads) {
      // Deleting the client from C365 through the Cloner API
      try {
        const url = `${M365server}mailapi/client/${user}`;
        await axios.delete(url, {
          headers: { cloner_key },
        });

        results.push({ user, status: success2 });
        enqueueSnackbar(success, { variant: "success" });
      } catch (e) {
        console.error(e);
        results.push({ user, status: fail });
        enqueueSnackbar(error + e.message, { variant: "error" });
      }
    }

    let fullOk = true;
    let fullFail2 = true;
    for (let result of results) {
      if (result.status === success2) {
        fullFail2 = false;
      } else {
        fullOk = false;
      }
    }

    // Show the snackbar dependent on the operation results
    if (fullOk) {
      enqueueSnackbar(fullSuccess, { variant: "success" });
    } else if (fullFail2) {
      enqueueSnackbar(fullFail, { variant: "error" });
    } else {
      enqueueSnackbar(partialSuccess, { variant: "warning" });
    }
    dispatch(workerActions.finish({ id }));
    dispatch(workerActions.clear(getM365ClientsId));

    for (let result of results) {
      if (result.status === success2) {
        dispatch(UIactions.clear("365mail-" + result.user));
        dispatch(UIactions.remove({ id: bkpOneArrayId, key: result.user }));
        dispatch(UIactions.remove({ id: bkpMailArrayId, key: result.user }));
        dispatch(UIactions.remove({ id: arrayId, key: result.user }));
        dispatch(UIactions.remove({ id: mailLocksId, key: result.user }));
      }
    }
  };
};

export default deleteClients;
