import { Edit } from "@mui/icons-material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../store/slices/UI";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";
import useRecoveries from "../../../../../../Hooks/useRecoveries";

const UIText = {
  "es-CL": { label: "Editar" },
  "en-US": { label: "Edit" },
};

const EditBtn = () => {
  const { dispatch, UI } = useUIBp();
  const recoveriesStore = useRecoveries();
  const selected = UI.selected ?? [];
  const recoveries = recoveriesStore.recoveries ?? [];

  const onClick = () => {
    let payload = []

    for (let i = 0; i < selected.length; i++) {
      let device = recoveries.find((recovery) => recovery.id === selected[i]);
      payload.push(device)
    }

    dispatch(
      UIactions.openModal({
        type: "create-update-recoveries",
        payload,
      })
    );
  };

  return (
    <ActionButton
      {...{
        disabled: !selected.length,
        onClick,
        UIText,
        icon: <Edit />,
      }}
    />
  );
};

export default EditBtn;
