
const headersData = {
  headers: [
    {
      UIText: {
        "es-CL": { label: "Usuario" },
        "en-US": { label: "User" },
      },
      filterKey: "user",
      size: "grow",
    },
    {
      UIText: {
        "es-CL": { label: "Dispositivo" },
        "en-US": { label: "Device" },
      },
      filterKey: "name",
      size: 3,
    },
    {
      UIText: {
        "es-CL": { label: "Tamaño" },
        "en-US": { label: "Size" },
      },
      filterKey: "space_active",
      size: 2,
    },
    {
      UIText: {
        "es-CL": { label: "Último respaldo" },
        "en-US": { label: "Last backup" },
      },
      filterKey: "lastBkp",
      size: 2,
    },
    {
      UIText: {
        "es-CL": { label: "Mas" },
        "en-US": { label: "More" },
      },
      filterKey: "",
      size: 1,
      justify: "end",
    },
  ],
};

export default headersData;
