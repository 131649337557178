import { AddToQueue } from "@mui/icons-material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../store/slices/UI";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";

const UIText = {
  "es-CL": { label: "Agregar dispositivo" },
  "en-US": { label: "Add device" },
};

const AddDevice = () => {
  const { dispatch } = useUIBp();

  const onClick = () => {
    dispatch(UIactions.openModal({ type: "create-update-recoveries" }));
  };

  return (
    <ActionButton
      {...{
        onClick,
        UIText,
        icon: <AddToQueue />,
      }}
    />
  );
};

export default AddDevice;
