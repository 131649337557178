import { Dialog } from "@mui/material";
import { w365shareUI } from "../Share";
import BasicDialogTitle from "../../../../../../UI/BasicDialog/BasicDialogTitle";
import Cancel from "./Components/Cancel";
import BasicDialogActions from "../../../../../../UI/BasicDialog/BasicDialogActions";
import Confirm from "./Components/Confirm";
import { useEffect } from "react";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { updateM365OrgId } from "../../../Actions/changeM365Sharing";
import { UIactions } from "../../../../../../../store/slices/UI";
import { workerActions } from "../../../../../../../store/slices/workers";

const M365SharingToggle = () => {
  const { dispatch, settings, UI } = useUIBp();
  const { done, error } = useWorker(updateM365OrgId);
  const active = UI[w365shareUI];
  const locale = settings.locale;
  const { payload: p } = UI.modal2;
  const { payload, groupname } = p;

  const UITextActivate = {
    "es-CL": {
      header: `Compartir credenciales del dominio '@${payload.domain}' de Microsoft 365 con los subgrupos de '${groupname}'?`,
    },
    "en-US": {
      header: `Share Microsoft 365 credentials from the domain '@${payload.domain}' with subgroups of '${groupname}'?`,
    },
  };
  const UITextDeactivate = {
    "es-CL": {
      header: `Dejar de compartir credenciales del dominio '@${payload.domain}' de Microsoft 365 con los subgrupos de '${groupname}'?`,
    },
    "en-US": {
      header: `Stop sharing Microsoft 365 credentials from the domain '@${payload.domain}' with subgroups of '${groupname}'?`,
    },
  };
  const UIText = active ? UITextDeactivate : UITextActivate;
  const { header } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal2());
      dispatch(workerActions.clear(updateM365OrgId));
    }
  }, [dispatch, done, error]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal2());
    }
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogActions>
        <Cancel />
        <Confirm {...{ payload, locale }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default M365SharingToggle;
