import { Alert, Grid2 } from "@mui/material";
import useUIBp from "../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": {
    part1:
      "Para realizar el reseteo de la contraseña se enviará un correo con las instrucciones al usuario cuya constraseña se desea resetear.",
    part2:
      "Si el correo utilizado para crear el usuario no existe, o no es capaz de recibir un correo de Cloner, pongase en contacto con nuestro equipo de soporte para solicitar el reseteo.",
    mail: "Correo",
    phone: "Teléfono",
  },
  "en-US": {
    part1: "An email will be sent to the user in order to reset the password.",
    part2:
      "If the email used to create the user does not exist, or it cannot  receive an email from Cloner, get in contact with our support team.",
    mail: "Email",
    phone: "Phone",
  },
};

const Opener = () => {
  const { settings } = useUIBp();
  const { part1, part2, mail, phone } = UIText[settings.locale];

  return (
    <Grid2 container size={12} justifyContent="center">
      <Grid2 container item size={{ xl: 3, lg: 4, md: 6, sm: 8, xs: 10 }}>
        <Alert severity="info" sx={{ textAlign: "justify" }}>
          {part1}
          <br />
          {part2}
          <br /> <b>{mail}:</b> soporte@cloner.cl
          <br /> <b>{phone}:</b> +562 2980 53 52
        </Alert>
      </Grid2>
    </Grid2>
  );
};

export default Opener;
