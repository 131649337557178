import notEmptyQRCode from "../../../../../utility/validations/notEmptyQRCode";
import onlyNumbers from "../../../../../utility/constraints/onlyNumbers";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import loginWithGAuth, { gauthWrkID } from "../../../Actions/loginWithGAuth";
import { loginUserID } from "../../User/User";
import { useWorker } from "../../../../../Hooks/useWorker";
import { Grid2 } from "@mui/material";
import TextField from "../../../../UI/GeneralPurpose/SimpleTextField";

const UIText = {
  "es-CL": {
    label: "Codigo de autentificación",
    placeholder: "123456",
  },
  "en-US": {
    label: "Authentication code",
    placeholder: "123456",
  },
};

export const authFieldID = "authCode";

const AuthCode = () => {
  const { dispatch, UI, settings } = useUIBp();
  const user = UI[loginUserID] ?? {};
  const authCode = UI[authFieldID] ?? {};
  const { working } = useWorker(gauthWrkID);

  const handleAuthentication = () => {
    dispatch(
      loginWithGAuth({
        user: user.value,
        code: authCode.value,
        locale: settings.locale,
      })
    );
  };

  return (
    <Grid2 container {...{ size: 12, justifyContent: "center" }}>
      <TextField
        hardValidate
        required
        tagError
        autoFocus
        clearOnUnmount
        {...{
          id: authFieldID,
          UIText,
          constraint: onlyNumbers,
          validation: notEmptyQRCode,
          submit: handleAuthentication,
          disabled: working,
          size: {
            lg: 3,
            md: 4,
            sm: 6,
            xs: 8,
          },
        }}
      />
    </Grid2>
  );
};

export default AuthCode;
