import { Grid2 } from "@mui/material";
import { useDispatch } from "react-redux";
import { UIactions } from "../../../../../../store/slices/UI";
import { workerActions } from "../../../../../../store/slices/workers";
import {
  getGroupsListId,
  selectedGroupIdx,
} from "../../../../../GroupsTraveler/Actions/loadGroupsTree";
import Switch from "../../../../../UI/GeneralPurpose/Switch";

const UIText = {
  "es-CL": { label: "Cambiar grupo" },
  "en-US": { label: "Change group" },
};

export const changeGroupUI = "changeGroup";

const ChangeGroup = () => {
  const dispatch = useDispatch();

  const onChange = () => {
    dispatch(UIactions.clear(selectedGroupIdx));
    dispatch(workerActions.clear(getGroupsListId));
  };

  return (
    <Grid2 container {...{ size: 12, alignItems: "center", columnGap: 2 }}>
      <Switch
        labelRight
        {...{
          id: changeGroupUI,
          UIText,
          onChange,
          labelProps: {
            fontSize: "1.1rem",
            fontWeight: "bold",
          },
          size: "auto",
        }}
      />
    </Grid2>
  );
};

export default ChangeGroup;
