import { useDispatch } from "react-redux";
import { recoveriesActions } from "../../../../../store/slices/recoveries";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Mantener", fixedTooltip: "Mantener solicitud actual" },
  "en-US": { label: "Keep", fixedTooltip: "Keep current request" },
};

const Keep = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(recoveriesActions.dontAskAgain());
    dispatch(UIactions.closeModal());
  };

  return <Button {...{ UIText, onClick }} />;
};

export default Keep;
