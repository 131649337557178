import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../Hooks/useWorker";
import { suggestMailId } from "../../../../../../store/actions/microsoft365/suggestM365Mails";
import validateM365Mails, { validateMailId } from "../../../../../../store/actions/microsoft365/validateM365Mails";
import Button from "../../../../../UI/GeneralPurpose/Button";
import { m365MailId } from "./M365Userform";

const UITextVerify = {
  "es-CL": {
    label: "Verificar correo",
    fixedTooltip: "Verifica que el correo para Windows 365 es valido",
  },
  "en-US": {
    label: "Verify mail",
    fixedTooltip: "Verify that the email for Windows 365 is valid",
  },
};

export const newUsersUI = "newUsers";

const VerifyMail = ({ login,org }) => {
  const { auth, dispatch, settings, UI } = useUIBp();
  const { working } = useWorker(validateMailId);
  const { working:w2 } = useWorker(suggestMailId);
  const { cloner_key, M365server } = auth;
  const { locale } = settings;
  const mailField = UI[m365MailId + "-" + login] ?? {};
  const mail = mailField.value;

  let payload = [{
    mail,
    login,
    org,
  }]

  const handleVerify = (e) => {
    dispatch(
      validateM365Mails({ tryouts: payload, cloner_key, M365server, locale })
    );
  };

  return (
    <Button
      {...{
        disabled: w2,
        UIText: UITextVerify,
        onClick: handleVerify,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
        size:6,
      }}
    />
  );
};

export default VerifyMail;
