import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import AutoHelp from "../../../../../../UI/Wrappers/AutoHelp";
import { AdminPanelSettings, Person } from "@mui/icons-material";

const UITextAdmin = {
  "es-CL": { fixedTooltip: "Administrador" },
  "en-US": { fixedTooltip: "Administrator" },
};
const UITextRegular = {
  "es-CL": { fixedTooltip: "Usuario regular" },
  "en-US": { fixedTooltip: "Regular user" },
};

const Admin = ({ level }) => {
  const { settings } = useUIBp();

  const UIText = !!level ? UITextAdmin[settings.locale] : UITextRegular[settings.locale];
  const icon = !!level ? <AdminPanelSettings color="primary" />:<Person color='disabled'/>

  return <AutoHelp {...UIText}>{icon}</AutoHelp>;
};

export default Admin;
