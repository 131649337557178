import FilterPlusActions from "./Components/FilterPlusActions/FilterPlusActions";
import Core from "./Components/Core/Core";
import BasicContainer from "./BasicContainer";
import BasicHeader from "./Components/Header";
import BasicFooter from "./Components/BasicFooter";
import BasicHeaders from "./Components/BasicHeaders";

const BasicLayout = ({
  header,
  plusActions,
  headersData,
  coreData,
  fullArray,
  overflowX,
  fixedWidth,
  plusActionsGrid,
  onPaginationChange,
  noRecursive
}) => {
  return (
    <BasicContainer {...{ overflowX, fixedWidth }}>
      <BasicHeader>{header}</BasicHeader>
      <FilterPlusActions {...{noRecursive, plusActionsGrid }}>
        {plusActions}
      </FilterPlusActions>
      {headersData ? (
        <BasicHeaders {...headersData} {...{ fullArray }} />
      ) : null}
      <Core hasHeaders={!!headersData} {...coreData} />
      <BasicFooter {...{ onPaginationChange }} />
    </BasicContainer>
  );
};

export default BasicLayout;
