import axios from "axios";
import { warehouseActions } from "../../slices/warehouse";
import { workerActions } from "../../slices/workers";
import { bkpDriveSizeId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { bkpMailCountId } from "../../../components/Users/Dialogs/UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { UIactions } from "../../slices/UI";

export const getM365ClientId = "getM365Client";
export const m365clientData = "microsoft365client";

const getClient = ({ M365server, cloner_key, user, orgID }) => {
  const id = getM365ClientId;

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      const url = `${M365server}mailapi/organization/${orgID}/clients`;
      const response = await axios.get(url, {
        headers: { cloner_key },
      });
      const data = response.data.data;

      for (const client of data) {
        if (client.cloner_user === user) {
          let url2 = `${M365server}mailapi/organization/${orgID}/client/${client.client_mail}/space`;
          const response2 = await axios.get(url2, {
            headers: { cloner_key },
          });
          const data2 = response2.data.data;

          dispatch(
            UIactions.setValue({
              id: bkpMailCountId + user,
              value: data2.count_mails,
            })
          );
          dispatch(
            UIactions.setValue({
              id: bkpDriveSizeId + user,
              value: data2.drive,
            })
          );
          dispatch(warehouseActions.load({ id: m365clientData, data: client }));
          break;
        }
      }
      dispatch(workerActions.finish({ id }));
    } catch (error) {
      dispatch(workerActions.finish({ id, error }));
    }
  };
};

export default getClient;
