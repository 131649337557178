import { Dialog } from "@mui/material";
import BasicDialogTitle from "../../../../../../UI/BasicDialog/BasicDialogTitle";
import Cancel from "./Components/Cancel";
import BasicDialogActions from "../../../../../../UI/BasicDialog/BasicDialogActions";
import Confirm from "./Components/Confirm";
import { useEffect } from "react";
import { currentGroupnameId } from "../../../../../../GroupsTraveler/Actions/loadGroupsTree";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import { deleteOrgId } from "../../../../../../../store/actions/microsoft365/deleteOrganization";
import { UIactions } from "../../../../../../../store/slices/UI";
import { workerActions } from "../../../../../../../store/slices/workers";

const ClearW365Org = () => {
  const { dispatch, settings, UI } = useUIBp();
  const { done, error } = useWorker(deleteOrgId);
  const locale = settings.locale;
  const groupname = UI[currentGroupnameId];

  const UIText = {
    "es-CL": {
      header: `Remover las credenciales de Windows365 del grupo ${groupname}?`,
    },
    "en-US": {
      header: `Remove Windows365 credentials from group ${groupname}?`,
    },
  };
  const { header } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal2());
      dispatch(workerActions.clear(deleteOrgId));
    }
  }, [dispatch, done, error]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal2());
    }
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogActions>
        <Cancel />
        <Confirm />
      </BasicDialogActions>
    </Dialog>
  );
};

export default ClearW365Org;
