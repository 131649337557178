import { Fragment, useEffect, useRef } from "react";
import { Add } from "@mui/icons-material";
import AutoHelp from "../../../Wrappers/AutoHelp";
import {
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import MoreActionsButtons from "./MoreActionsButton";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../store/slices/UI";

const MoreActions = ({
  id,
  buttonsData = [],
  UIText = {},
  startIcon = <Add />,
  disabled,
}) => {
  const { dispatch, UI, settings } = useUIBp();
  const ID = `actions-${id}`;
  const open = UI[ID] ?? false;
  const ref = useRef(null);
  const { label } = UIText[settings.locale] ?? {};

  const handleToggle = () => {
    dispatch(UIactions.toggle(ID));
  };

  const close = () => {
    dispatch(UIactions.setValue({ id: ID, value: false }));
  };

  const handleClick = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      return;
    }
    close();
  };

  // Garbage collection
  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(ID));
    };
  }, [dispatch, ID]);

  const buttons = buttonsData.map((data, idx) => {
    if (data.divider) {
      return <Divider key={idx} />;
    } else {
      let onClick;
      if (data.onClick) {
        onClick = () => {
          data.onClick();
          close();
        };
      }

      return <MoreActionsButtons key={idx} {...data} {...{ onClick }} />;
    }
  });

  return (
    <Fragment>
      <AutoHelp {...{ disabled }}>
        <Button
          {...{
            ref,
            onClick: handleToggle,
            size: "small",
            startIcon,
            disabled,
          }}
        >
          <Typography
            noWrap
            {...{ sx: { fontSize: "0.8rem", fontWeight: "bold" } }}
          >
            {label}
          </Typography>
        </Button>
      </AutoHelp>
      <Popper
        transition
        {...{
          open,
          anchorEl: ref.current,
          placement: "bottom-end",
          sx: { zIndex: 2000 },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper elevation={6}>
              <ClickAwayListener onClickAway={handleClick}>
                <MenuList disablePadding>{buttons}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default MoreActions;
