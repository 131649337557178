import { useEffect, useMemo } from "react";
import Core from "./Components/Core";
import { sortObjByKey } from "../../../utility/misc/sortObjByKey";
import { lowerMatch } from "../../../utility/misc/filter";
import CalculatePagination from "../../UI/Utilities/CalculatePagination";
import { showDeletedUI } from "./Components/PlusActions/Components/Deleted";
import { defaultPagination } from "../../UI/GeneralPurpose/genericIDs";
import useURLQuery from "../../../Hooks/useURLQuery";
import { getDevicesId } from "../../../store/actions/devices/getDevices";
import { userPickerId } from "../../UI/Specialized/UserPicker";
import { devicePickerId } from "../../UI/Specialized/DevicePicker";
import { filesDataId, getFiles } from "../../../store/actions/devices/getFiles";
import { UIactions } from "../../../store/slices/UI";
import { treesActions } from "../../../store/slices/trees";
import { warehouseActions } from "../../../store/slices/warehouse";
import { workerActions } from "../../../store/slices/workers";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../Hooks/useWorker";
import getRepositories, { getRepoId, repoDataId } from "../../../store/actions/repositories/getRepositories2";

const Files = () => {
  const { auth, dispatch, navigate, UI, settings, warehouse } =
    useUIBp();
  const { user: urlUser, file, repo } = useURLQuery();
  const { done: dDone } = useWorker(getDevicesId);
  const { done: rDone } = useWorker(getRepoId);
  const { user: authUser } = auth;
  const userPicker = UI[userPickerId] ?? {};
  const devicePicker = UI[devicePickerId] ?? {};
  const files = warehouse[filesDataId];
  const repositories = warehouse[repoDataId];
  const user = userPicker.id ? userPicker.id : auth.user;
  const mid = devicePicker.mid;
  const locale = settings.locale;
  const sort = UI.sort ?? {};
  const filter = UI.filter ?? "";
  const pagination = UI.pagination ?? { page: 0, count: 0, rowsPerPage: 0 };
  const deleted = !!UI[showDeletedUI];

  useEffect(() => {
    if (!urlUser) {
      console.log("No user in URL, redirecting to user picker", urlUser);
      navigate("/dashboard/files?user=" + encodeURIComponent(authUser), {
        replace: true,
      });
    }
  }, [navigate, urlUser, authUser]);

  // NO repositories? get repositories if there is a user and a machine!
  useEffect(() => {
    const controller = new AbortController();
    if (!rDone && dDone && user && mid) {
      dispatch(getRepositories({ controller, ...auth, user, mid }));
    }
  }, [dispatch, auth, rDone, dDone, user, mid]);

  useEffect(() => {
    const controller = new AbortController();
    if (file && repo) {
      dispatch(
        getFiles({
          controller,
          ...auth,
          file,
          repo,
          locale,
        })
      );
    }
    return () => {
      controller.abort();
    };
  }, [dispatch, auth, file, repo, locale]);

  const fileItems = useMemo(() => {
    if (!files) {
      return [];
    }
    return [...files]
      .filter(
        (f) =>
          (!!deleted || !f.deleted) &&
          lowerMatch(f.name, filter) &&
          f.id !== "" &&
          f.name !== "[Subir]" &&
          f.name.toLowerCase().match("")
      )
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending))
      .map((data) => {
        return { ...data, repo };
      });
  }, [files, repo, deleted, filter, sort.value, sort.descending]);

  const repoItems = useMemo(() => {
    if (!repositories) {
      return [];
    }
    return [...repositories]
      .filter(
        (f) =>
          (!!deleted || !f.deleted) &&
          lowerMatch(f.name, filter) &&
          f.id !== "" &&
          f.name !== "[Subir]" &&
          f.name.toLowerCase().match("")
      )
      .sort((a, b) => sortObjByKey(a, b, "name"))
      .map((data) => {
        return {
          id: data.root,
          repo: data.id,
          name: data.name,
          num_versions: 0,
          created: data.last_changes,
          size: data.active_space,
          deleted: data.deleted,
          type: 99,
        };
      });
  }, [repositories, deleted, filter]);

  let filteredFiles = file ? fileItems : repoItems;

  useEffect(() => {
    if (filteredFiles.length > 0) {
      dispatch(UIactions.setPagination({ count: filteredFiles.length }));
    }
  }, [dispatch, filteredFiles.length]);

  // Calculating effective pagination
  const { from, to } = CalculatePagination(pagination);
  const paginatedFiles = useMemo(() => {
    let directories = [];
    let Files = [];
    var sortedFiles = [...filteredFiles]

    for (const item of sortedFiles) {
      if (item.type === 0) {
        directories.push(item);
      } else {
        Files.push(item);
      }
    }
    var allFiles = [...directories, ...Files];

    return allFiles.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredFiles]);

  useEffect(() => {
    return () => {
      dispatch(treesActions.removeTreeGroup("repo-"));
      dispatch(UIactions.clear(defaultPagination));
      dispatch(warehouseActions.clear(repoDataId));
      dispatch(workerActions.clear(getRepoId));
      dispatch(workerActions.clearGroup("node-"));
      dispatch(workerActions.clearGroup("file-"));
    };
  }, [dispatch]);

  return <Core {...{ files: paginatedFiles, allFiles: filteredFiles }} />;
};

export default Files;
