import {
  getGreylistId,
  greylistDataId,
} from "../../../../../UI/Specialized/Actions/getGreylist";
import RepositoryPicker from "../../../../../UI/Specialized/RepositoryPicker";
import { greylistTreeReady } from "./GreylstPicker";
import { devicePickerId } from "../../../../../UI/Specialized/DevicePicker";
import { workerActions } from "../../../../../../store/slices/workers";
import { warehouseActions } from "../../../../../../store/slices/warehouse";
import { UIactions } from "../../../../../../store/slices/UI";
import { treesActions } from "../../../../../../store/slices/trees";
import { greylistPickerId } from "../../../../../UI/Specialized/GreylistPicker";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";

const RepoPicker = (props) => {
  const { user } = props;
  const { dispatch, UI } = useUIBp();
  const { mid = "" } = UI[devicePickerId] ?? {};

  const onChange = () => {
    dispatch(workerActions.clear(getGreylistId));
    dispatch(workerActions.clear(greylistTreeReady));
    dispatch(warehouseActions.unload(greylistDataId));
    dispatch(UIactions.clear(greylistPickerId));
    dispatch(treesActions.clearForest());
  };

  return <RepositoryPicker {...{ user, mid, onChange, xs: 6 }} />;
};

export default RepoPicker;
