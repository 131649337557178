import useUIBp from "../../../Hooks/useUIBoilerplate";
import deleteClients from "../../../store/actions/microsoft365/deleteClients";
import GenericConfirmation2 from "./AllPurpose2/DialogConfirmation";

const DeleteM365ClientsConfirmation = () => {
  const { auth, dispatch, settings, UI } = useUIBp();
  const { cloner_key, M365server } = auth;
  const locale = settings.locale;
  const payload = UI.modal2.payload ?? { payloads: [] };

  const UIText = {
    "es-CL": {
      header: "Esta seguro que desea resetear estos usuarios de Windows365?",
      message: `Se resetearán ${payload.selectedUsers.length} usuarios de windows 365. La información respaldada previamente podrá ser accesada posteriormente, pero se detendrá cualquier respaldo del sistema externo Windows365. Una vez finalizado el proceso será posible descargar un archivo PDF con el detalle de la operación.`,
    },
    "en-US": {
      header: "Are you sure you want to reset these users from Windows365?",
      message: `A total of ${payload.selectedUsers.length} windows 365 users will be reset. The previously backed up information will be accessible later, but any backup of the Windows365 external system will be stopped. Once finished a PDF file detailing the operation will be available to download.`,
    },
  };
  const { header, message } = UIText[locale];

  const confirmation = () => {
    dispatch(deleteClients({ payloads:payload.selectedUsers, cloner_key, M365server, locale }));
  };


  return (
    <GenericConfirmation2
      {...{ header, message, severity: "error", confirmation }}
    />
  );
};

export default DeleteM365ClientsConfirmation;