import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../../store/slices/workers";

const UIText = {
  "es-CL": {
    success:
      "Correos restaurados! Esto podría demorarse varios minutos en reflejarse",
    error: "Error al restaurar los correos",
  },
  "en-US": {
    success: "Emails restored! This might take a bit before it is effective",
    error: "Error while restoring the emails",
  },
};

export const restoreMailsId = "restoreMails";

const restoreMails = ({
  workerId,
  mailIDs,
  M365server,
  cloner_key,
  locale,
}) => {
  const id = workerId;
  const { success, error } = UIText[locale];
  console.log("Restoring mail")

  return async (dispatch) => {
    dispatch(workerActions.start(id));

    try {
      // Restoring the mail through the {server}/mailapi/message endpoint
      const url = `${M365server}mailapi/message`;
      const bodyData = JSON.stringify({ mail_list: mailIDs });
      await axios.post(url, bodyData, {
        headers: { cloner_key, "Content-Type": "application/json" },
      });

      dispatch(workerActions.finish({ id }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
      enqueueSnackbar(error, { variant: "error" });
    }
  };
};

export default restoreMails;
