import { Dialog, Grid2 } from "@mui/material";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import SlimAlert from "../../../UI/GeneralPurpose/SlimAlert";
import Close from "./Components/Close";
import Continue from "./Components/Continue";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";

const NewUsersRecoveryRequest = () => {
  const { dispatch, settings, UI } = useUIBp();
  const { payload } = UI.modal ?? {};
  const { users = [] } = payload ?? {};
  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  const UIText = {
    "es-CL": {
      headerMulti: `Iniciar una nueva solicitud de recuperacion con los ${users.length} usuarios seleccionados?`,
      headerSingle: `Iniciar una nueva solicitud de recuperacion para el usuario ${users[0]}?`,
      alert:
        "Actualmente existe una solicitud de recuperación que no ha sido enviada. Si continua se iniciará una nueva solicitud de recuperación y la solicitud actual se perderá.",
    },
    "en-US": {
      headerMulti: `Start a new recovery request with the ${users.length} selected users?`,
      headerSingle: `Start a new recovery request for the user ${users[0]}?`,
      alert:
        "Currently exists a recovery request that hasn't been sent. If you continue a new recovery request will be started and the current request will be lost.",
    },
  };

  const { headerMulti, headerSingle, alert } = UIText[settings.locale];

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle
        {...{ header: users.length > 1 ? headerMulti : headerSingle }}
      />
      <BasicDialogContent>
        <Grid2
          container
          {...{ rowGap: 2, paddingTop: 2, sx: { width: "100%" } }}
        >
          <SlimAlert
            notCollapse
            {...{ size: 12 }}
            {...{ errors: [alert], severity: "warning" }}
          />
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        <Continue {...{ users }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default NewUsersRecoveryRequest;
