import {
  AddBoxOutlined,
  CheckBoxOutlined,
  CropSquareOutlined,
  Folder,
  IndeterminateCheckBoxOutlined,
  InsertDriveFile,
  Storage,
} from "@mui/icons-material";
import { Divider, Grid2, Typography } from "@mui/material";
import AutoHelp from "../../../../../UI/Wrappers/AutoHelp";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": {
    legend: "Leyenda",
    repository: "Repositorio",
    normalFolder: "Directorio regular",
    emptyFolder: "Directorio vacío",
    badFolder: "Directorio inaccesible",
    file: "Archivo",
    emptySelection: {
      fixedTooltip: "Selección vacía",
      tooltip:
        "Selección vacia. El directorio/archivo y sus contenidos no son respaldados",
    },
    pinpointSelection: {
      fixedTooltip: "Selección especifica",
      tooltip:
        "Selección especifica. Solo los directorios/archivos especificamente seleccionados son respaldados",
    },
    generalSelection: {
      fixedTooltip: "Selección con exclusiones",
      tooltip:
        "Selección con exclusions. Solo los directorios/archivos especificamente seleccionados serán excluidos del respaldo",
    },
    fullSelection: {
      fixedTooltip: "Selección completa",
      tooltip:
        "Selección completa. El directorio/archivo y sus contenidos serán respaldados completamente",
    },
  },
  "en-US": {
    legend: "Leyend",
    repository: "Repository",
    normalFolder: "Normal folder",
    emptyFolder: "Empty folder",
    badFolder: "Locked out folder",
    file: "File",
    emptySelection: "",
    pinpointSelection: "",
    generalSelection: "",
    fullSelection: "",
  },
};

const Legend = () => {
  const { settings } = useUIBp();
  const {
    legend,
    repository,
    normalFolder,
    emptyFolder,
    badFolder,
    file,
    emptySelection,
    pinpointSelection,
    generalSelection,
    fullSelection,
  } = UIText[settings.locale];

  return (
    <Grid2
      container
      {...{
        size: 12,
        columnGap: 2,
        padding: 2,
        alignContent: "center",
      }}
    >
      <Typography>{legend}:</Typography>
      <AutoHelp {...{ fixedTooltip: repository }}>
        <Storage color="primary" sx={{ fontSize: 20 }} />
      </AutoHelp>
      <AutoHelp {...{ fixedTooltip: normalFolder }}>
        <Folder color="primary" sx={{ fontSize: 20 }} />
      </AutoHelp>
      <AutoHelp {...{ fixedTooltip: emptyFolder }}>
        <Folder color="disabled" sx={{ fontSize: 20 }} />
      </AutoHelp>
      <AutoHelp {...{ fixedTooltip: badFolder }}>
        <Folder color="error" sx={{ fontSize: 20 }} />
      </AutoHelp>
      <AutoHelp {...{ fixedTooltip: file }}>
        <InsertDriveFile color="primary" sx={{ fontSize: 20 }} />
      </AutoHelp>
      <Divider orientation="vertical" flexItem />
      <AutoHelp {...emptySelection}>
        <CropSquareOutlined sx={{ fontSize: 20 }} />
      </AutoHelp>
      <AutoHelp {...pinpointSelection}>
        <IndeterminateCheckBoxOutlined color="warning" sx={{ fontSize: 20 }} />
      </AutoHelp>
      <AutoHelp {...generalSelection}>
        <AddBoxOutlined color="success" sx={{ fontSize: 20 }} />
      </AutoHelp>
      <AutoHelp {...fullSelection}>
        <CheckBoxOutlined color="info" sx={{ fontSize: 20 }} />
      </AutoHelp>
    </Grid2>
  );
};

export default Legend;
