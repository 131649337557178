import axios from "axios";
import { workerActions } from "../../../store/slices/workers";
import { warehouseActions } from "../../../store/slices/warehouse";

const activitiesID = "activities";
export const activitiesDataID = "activitiesData";

const fetchActivities = ({ server, cloner_key }) => {
  const id = activitiesID;

  return async (dispatch) => {
    dispatch(workerActions.start(id));

    try {
      // Getting all devices through groups recursive
      const url = server + "api/activities";
      const response = await axios.get(url, { headers: { cloner_key } });

      let activities = [];

      for (const key in response.data) {
        // console.log(response.data[key]);
        let splitKey = key.split("#");
        let login = splitKey[0];
        let repository = splitKey[1];

        var newActivity = {
          login,
          repository,
          path: response.data[key].Path.replace(/\\\//g, "/"),
          size: response.data[key].Size,
          uploaded: response.data[key].Uploaded,
        };

        activities.push(newActivity);
      }

      // Filling the warehouse with the parsed data
      dispatch(
        warehouseActions.load({
          id: activitiesDataID,
          data: activities,
        })
      );

      dispatch(workerActions.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        dispatch(workerActions.clear(id));
        return;
      }
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};

export default fetchActivities;
