import { Grid2 } from "@mui/material";
import DeleteLink from "./Delete";

export const plusActionsGrid = {
  xl: 6,
  lg: 6,
  md: 6,
  sm: 12,
  xs: 12,
};

const PlusActions = () => {
  return (
    <Grid2
      container
      {...{
        size: { xl: 6, lg: 6, md: 6, sm: 12, xs: 12 },
        justifyContent: "end",
        columnGap: 2,
      }}
    >
      <DeleteLink />
    </Grid2>
  );
};

export default PlusActions;
