import Top from "./Components/Top";
import Back from "./Components/Back";
import AuthenticateBtn from "./Components/AuthenticateBtn";
import AuthCode from "./Components/AuthCode";
import { Grid2 } from "@mui/material";

const TFAView = () => {
  return (
    <Grid2
      container
      {...{
        justifyContent: "center",
        alignItems: "center",
        rowGap: 2,
        size:12,
      }}
    >
      <Top />
      <AuthCode />
      <AuthenticateBtn />
      <Back />
    </Grid2>
  );
};

export default TFAView;
