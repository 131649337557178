import { Delete } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { UIactions } from "../../../../../../store/slices/UI";
import Button from "../../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Eliminar solicitud" },
  "en-US": { label: "Delete request" },
};

const Reset = () => {
  const dispatch = useDispatch()

  const onClick = ()=>{
    dispatch(UIactions.openModal({type:'reset-recovery-request',payload:{}}))
  }
  return <Button {...{ UIText, startIcon: <Delete />, onClick }} />;
};

export default Reset;
