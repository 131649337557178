import xlsx from "json-as-xlsx";
import {
  humanBandwidth,
  humanBytes,
  timeDiff,
} from "../../../utility/misc/humanizers";
import getStatus from "../Components/ReportRow/Components/utils";

const UIText = {
  "es-CL": {
    sheet1: "Reporte completo",
    sheet2: "Usuarios seleccionados",
    userCol: "Usuarios",
    deviceCol: "Dispositivos",
    sizeCol: "Tamaño",
    lastBkpCol: "Último respaldo",
    lastSentCol: "Último envío",
    bandwidthCol: "Ancho de banda",
    versionCol: "Versión",
    connectedCol: "Conexión",
    statusCol: "Estado",
    neverCompleted: "Nunca completado",
    since: "hace ",
    emptyConn: "Usuario no tiene dispositivos",
    connected: "Conectado: ",
    disconnected: "Desconectado: ",
    groupReport:'Cloner-Reporte de grupo'
  },
  "en-US": {
    sheet1: "Full report",
    sheet2: "Selected users",
    userCol: "Users",
    deviceCol: "Devices",
    sizeCol: "Size",
    lastBkpCol: "Last backup",
    lastSentCol: "Last update",
    bandwidthCol: "Bandwidth",
    versionCol: "Version",
    connectedCol: "Connected",
    statusCol: "Status",
    neverCompleted: "Never completed",
    since: "since ",
    emptyConn: "User does not have devices",
    connected: "Connected: ",
    disconnected: "Disconnected: ",
    groupReport:'Cloner-Group report'
  },
};

const exportReport = (data) => {
  const { devices, onlySelected, locale } = data;
  return async (dispatch) => {
    const now = Date.now();

    const text = UIText[locale];

    const columns = [
      { label: text.userCol, value: "user" }, // Top level data
      {
        label: text.deviceCol,
        value: (row) => (!!row.name ? row.name : "--"),
      }, // Custom format
      { label: text.sizeCol, value: (row) => humanBytes(row.space_active) }, // Custom format
      {
        label: text.lastBkpCol,
        value: (row) => {
          return row.lastBkp <= 0
            ? text.neverCompleted
            : timeDiff(now - row.lastBkp, locale);
        },
      }, // Custom format
      {
        label: text.lastSentCol,
        value: (row) => {
          return row.lastBkp <= 0
            ? text.neverCompleted
            : timeDiff(now - row.lastBkp, locale);
        },
      },
      {
        label: text.bandwidthCol,
        value: (row) => humanBandwidth(row.bandwidth),
      }, // Custom format
      {
        label: text.versionCol,
        value: (row) => (!!row.croppedVersion ? row.croppedVersion : "--"),
      }, // Custom format
      {
        label: text.connectedCol,
        value: (row) => {
          const lapse = !!row.mid
            ? text.since + timeDiff(now - row.lastConnected, locale)
            : text.emptyConn;
          return (row.connected ? text.connected : text.disconnected) + lapse;
        },
      },
      {
        label: text.statusCol,
        value: (row) => {
          const { fixedTooltip } = getStatus({
            status: row.status,
            locale,
          });
          return fixedTooltip;
        },
      }, // Custom format
    ];

    let data = [
      {
        sheet: text.sheet1,
        columns,
        content: devices,
      },
      {
        sheet: text.sheet2,
        columns,
        content: onlySelected,
      },
    ];

    let settings = {
      fileName: text.groupReport, // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      // writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      // writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      // RTL: true, // Display the columns from right-to-left (the default value is false)
    };

    xlsx(data, settings); // Will download the excel file
  };
};

export default exportReport;
