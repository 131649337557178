import clonerWhite from "../../../../Resources/iconwhite.png";
import clonerWhiteFull from "../../../../Resources/CLONER-WHITE.png";
import { Box, Collapse, Fade, Grid2 } from "@mui/material";
import { useDispatch } from "react-redux";
import { UIactions } from "../../../../../store/slices/UI";

export const closeDrawerUI = "closeDrawer";
export const logoModeUI = "logoMode";

const Logo = (props) => {
  const { open } = props;
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.toggle(closeDrawerUI));
  };

  return (
    <Grid2
      container
      {...{
        onClick,
        size: "auto",
        padding: 1,
        paddingTop: 2.5,
        paddingLeft: 2,
        sx: { cursor: "pointer", height: "8vh" },
      }}
    >
      <Grid2 size={12}>
        <Box position="absolute">
          <Collapse
            {...{ orientation: "horizontal", in: open, collapsedSize: 45 }}
          >
            <Fade in={open}>
              <img
                {...{
                  src: `${clonerWhiteFull}`,
                  alt: "cloner-logo-full",
                  height: 30,
                }}
              />
            </Fade>
          </Collapse>
        </Box>
        <Box {...{ position: "absolute", paddingLeft: 0, marginTop: -0.5 }}>
          <Collapse
            {...{ orientation: "horizontal", in: open, collapsedSize: 45 }}
          >
            <Fade in={!open}>
              <img
                {...{ src: `${clonerWhite}`, alt: "cloner-logo", height: 36 }}
              />
            </Fade>
          </Collapse>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default Logo;
