import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import SlimAlert from "../../../../UI/GeneralPurpose/SlimAlert";

const UIText = {
  "es-CL": "Uno o mas dispositivos seleccionados han sido modificados previamente con la opción avanzada. Si guarda, se perderán los cambios avanzados realizados.",
  "en-US": "One or more selected devices have been previously modified with the advanced option. If you save, the advanced changes made will be lost.",
};

const Warning = ({recoveries = []}) => {
  const { settings } = useUIBp();
  const text = UIText[settings.locale];
  console.log('reacoveries', recoveries)

  let warning = ''
  for (const recovery of recoveries){
    if (recovery.mode === 'advanced'){
        warning = text
        break
    }
  }

  if (!warning) return null;

  return (
    <SlimAlert
      notCollapse
      {...{ errors: [warning], severity: "warning", size:12 }}
    />
  );
};

export default Warning;
