import { useEffect } from "react";
import BackBtn from "./Components/Buttons/BackBtn";
import ResetBtn from "./Components/Buttons/ResetBtn";
import Header from "./Components/Header";
import Done from "./Components/Info/Done";
import Opener from "./Components/Info/Opener";
import User from "./Components/User";
import Locale from "./Components/Buttons/Locale";
import Logo from "./Components/Logo";
import useUIBp from "../../Hooks/useUIBoilerplate";
import { useWorker } from "../../Hooks/useWorker";
import { passRecoveryId } from "./Actions/resetPassword";
import { worker } from "../../store/slices/workers";
import { Grid2 } from "@mui/material";

const PassRecovery = () => {
  const { dispatch } = useUIBp();
  const { done, error } = useWorker(passRecoveryId);
  const height = window.innerHeight;

  useEffect(() => {
    return () => {
      dispatch(worker.clear(passRecoveryId));
    };
  }, [dispatch]);

  return (
    <Grid2
      container
      sx={{ width: "100vw", height }}
      alignContent="space-between"
    >
      <Locale />
      <Grid2
        container
        {...{
          justifyContent: "center",
          alignItems: "center",
          spacing: 2,
          size: 12,
        }}
      >
        <Logo />
        <Header />
        {done && !error ? <Done /> : <Opener />}
        <User />
        <ResetBtn />
        <BackBtn />
      </Grid2>
      <Grid2 size={12} />
      <Grid2 size={12} />
    </Grid2>
  );
};

export default PassRecovery;
