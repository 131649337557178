import { Typography } from "@mui/material";
import { humanBytes } from "../../../../../utility/misc/humanizers";

const Size = ({ fontSize, space_active }) => {
  return (
      <Typography {...{ fontSize, sx: { cursor: "default" } }}>
        {humanBytes(space_active)}
      </Typography>
  );
};

export default Size;
