import Select from "../../../../../../../UI/GeneralPurpose/Select";
import { reasons } from "./motives";

const UIText = {
  "es-CL": { label: "Motivo", tooltip: "Motivo de la solicitud" },
  "en-US": { label: "Reason", tooltip: "Request reason" },
};

export const reasonUI = "reason";

const Reasons = () => {
  return (
    <Select
      {...{
        id: reasonUI,
        UIText,
        options: reasons,
        defaultIdx: 0,
        size: {
          xl: 4,
          lg: 4,
          md: 4,
          sm: 4,
          xs: 12,
        },
      }}
    />
  );
};

export default Reasons;
