// parseGroupsListRecursive: recursive function to parse the groups list
export const parseGroupsListRecursive = (node, parentIdx, parentId, idx) => {
  let newNodes = [];
  const newNode = {
    id: node.id,
    idx,
    parentId,
    parentIdx,
    name: node.name,
    children: [],
  };
  newNodes.push(newNode);
  let currIdx = idx + 1;
  for (const child of node.sub_groups) {
    newNodes[0].children.push(child.id);
    const childNodes = parseGroupsListRecursive(
      child,
      newNode.idx,
      newNode.id,
      currIdx
    );
    newNodes = [...newNodes, ...childNodes];
    currIdx += childNodes.length
  }
  return newNodes;
};

export const fixChildrenIdx = (nodes) => {
    let newNodes = []
    for (const node of nodes){
        let newNode = {...node, childrenIdx:[]}
        for (const childId of node.children){
            const child = nodes.find( x=> x.id === childId)
            newNode.childrenIdx.push(child.idx)
        }
        newNodes.push(newNode)
    }
    return newNodes
};
