import notEmptyField from "../../../../../utility/validations/notEmpty";
import TextField from "../../../../UI/GeneralPurpose/SimpleTextField";

const UIText = {
  "es-CL": { label: "Nombre del usuario", placeholder: "Juan Soto" },
  "en-US": { label: "Username", placeholder: "John Doe" },
};

export const usernameUI = "username";

const Username = (props) => {
  const { disabled, isNew } = props;

  return (
    <TextField
      tagError
      {...{
        id: usernameUI,
        UIText,
        required: isNew,
        validation: notEmptyField,
        disabled,
        size: 12,
      }}
    />
  );
};

export default Username;
