import { Card, Grid2 } from "@mui/material";
import DeviceRow from "./Components/DeviceRow/DeviceRow";
import Actions, { plusActionsGrid } from "./Components/PlusActions/PlusActions";
import headersData from "./Components/Headers";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import useVH from "../../../../Hooks/useVH";
import useRecoveries from "../../../../Hooks/useRecoveries";
import FilterPlusActions from "../../../UI/BasicLayout/Components/FilterPlusActions/FilterPlusActions";
import BasicFooter from "../../../UI/BasicLayout/Components/BasicFooter";
import BasicHeaders from "../../../UI/BasicLayout/Components/BasicHeaders";
import BasicHeader from "../../../UI/BasicLayout/Components/Header";
import Core from "../../../UI/BasicLayout/Components/Core/Core";
import StepsBar from "./Components/StepsBar";
import { RecoverySteps } from "../Components/Steps";
import { viewmodeUI } from "../../../../App";
import BottomButtons from "./Components/Buttons/BottomButtons";
import { useEffect, useMemo } from "react";
import { UIactions } from "../../../../store/slices/UI";
import { containsEscaped } from "../../../../utility/misc/filter";
import CalculatePagination from "../../../UI/Utilities/CalculatePagination";
import { sortObjByKey } from "../../../../utility/misc/sortObjByKey";

const RecoveryStep1 = () => {
  const { dispatch,settings, UI } = useUIBp();
  const vh = useVH(91);
  const vh3 = useVH(88);
  const working = false;
  const done = true;
  const workerId = "placeholder";
  const error = "";
  const requestData = useRecoveries() ?? {};
  const { recoveries } = requestData;
  const viewmode = UI[viewmodeUI] ?? "desktop";
  const filter = UI.filter ?? "";
  const sort = UI.sort ?? { value: "user" };
  const pagination = UI.pagination ?? {};

  // filtering devices
  const filteredRecoveries = useMemo(() => {
    return recoveries
      .filter(
        (rec) =>
          containsEscaped(rec.user, filter) || containsEscaped(rec.device, filter)
      )
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending));
  }, [recoveries, filter, sort.value, sort.descending]);

  // Array to select all
  const allRecoveries = useMemo(() => {
    return filteredRecoveries.map((x) => x.id);
  }, [filteredRecoveries]);

  // Initializing pagination
  useEffect(() => {
    dispatch(UIactions.setPagination({ count: filteredRecoveries.length }));
  }, [dispatch, filteredRecoveries.length]);

  // Trimming devices to only current page ones
  const { from, to } = CalculatePagination(pagination);
  const paginatedRecoveries = useMemo(() => {
    return filteredRecoveries.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredRecoveries]);


  const trimmedRecoveries = paginatedRecoveries.map((r) => {
    let full_size = 0;
    for (const repo of r.repositories) {
      full_size += repo.size;
    }

    return {
      id: r.id,
      user: r.user,
      mid: r.mid,
      device: r.device,
      full_size,
      lastBkp: r.lastBkp,
      repositories: r.repositories,
      mode: r.mode,
    };
  });

  const fullArray = allRecoveries;
  const isEmpty = trimmedRecoveries.length === 0;

  const elements = trimmedRecoveries.map((data, idx) => (
    <DeviceRow key={idx} {...data} />
  ));
  const plusActions = <Actions />;
  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: { "es-CL": { message: "" }, "en-US": { message: "" } },
    },
    empty: {
      isEmpty,
      UIText: {
        "es-CL": { message: "No se ha agregado ninguna recuperación" },
        "en-US": { message: "No recovery has been added" },
      },
    },
    done,
    elements,
  };

  const steps = RecoverySteps[settings.locale];
  const header = steps[0];

  let fixedWidth;

  if (viewmode === "verycompact") {
    fixedWidth = "100vw";
  } else if (viewmode === "mobile") {
    fixedWidth = "175vw";
  }

  return (
    <Grid2
      container
      {...{
        sx: {
          minWidth: fixedWidth,
          padding: "2vh",
          paddingTop: 0,
          paddingBottom: 0,
          height: vh,
          overflowX: viewmode === "desktop" ? "hidden" : "auto",
        },
        alignContent: "start",
      }}
    >
      <StepsBar idx={0} />
      <Grid2 container>
        <Card sx={{ width: "100%" }}>
          <Grid2
            container
            {...{
              sx: {
                maxHeight: vh3,
                width: "100%",
              },
            }}
          >
            <BasicHeader>{header}</BasicHeader>
            <FilterPlusActions noRecursive {...{ plusActionsGrid }}>
              {plusActions}
            </FilterPlusActions>
            {headersData ? (
              <BasicHeaders {...headersData} {...{ fullArray }} />
            ) : null}
            <Core hasHeaders={!!headersData} {...coreData} contentSize={52} />
            <BasicFooter />
          </Grid2>
        </Card>
      </Grid2>
      <BottomButtons />
    </Grid2>
  );
};

export default RecoveryStep1;
