import {
  AccountTree,
  Assessment,
  Cloud,
  Delete,
  Edit,
  History,
  InsertDriveFile,
  Laptop,
} from "@mui/icons-material";
import { defaultPreFilter } from "../../../../../../UI/GeneralPurpose/genericIDs";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../../../Hooks/useURLQuery";
import { UIactions } from "../../../../../../../store/slices/UI";
import MoreActions from "../../../../../../UI/BasicLayout/Components/MoreActions/MoreActions";

const Actions = ({
  login,
  group,
  name,
  machines,
  tunnels = [],
  level,
  has_access,
  receives_mail,
}) => {
  const { auth, dispatch } = useUIBp();
  const { group: uGroup } = useURLQuery();

  const data = {
    id: login,
    buttonsData: [
      {
        icon: <Edit sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Editar usuario" },
          "en-US": { label: "Edit user" },
        },
        disabled: auth.user === login,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "edit-user",
              payload: {
                group,
                users: [
                  {
                    login,
                    name,
                    group,
                    level,
                    has_access,
                    receives_mail,
                    machines,
                    tunnels,
                  },
                ],
              },
            })
          );
        },
      },
      {
        icon: <Cloud sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Integraciones Cloud" },
          "en-US": { label: "Cloud Integrations" },
        },
        disabled: auth.user === login,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "edit-user-cloud",
              payload: { group, users: [{ login, group, machines, tunnels }] },
            })
          );
        },
      },
      { divider: true },
      {
        icon: <History sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Historial" },
          "en-US": { label: "History" },
        },
        disabled: !machines,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "selection-history",
              payload: { user: login },
            })
          );
        },
      },
      {
        icon: <AccountTree sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Selección remota" },
          "en-US": { label: "Remote selection" },
        },
        disabled: !tunnels.length,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "remote-selection",
              payload: { user: login },
            })
          );
        },
      },
      { divider: true },
      {
        icon: <Assessment sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Reportes" },
          "en-US": { label: "Reports" },
        },
        disabled: !machines,
        navTo: `/dashboard/reports?group=${uGroup}`,
        onClick: () => {
          if (login) {
            dispatch(
              UIactions.setValue({ id: defaultPreFilter, value: login })
            );
          }
        },
      },
      {
        icon: <InsertDriveFile sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Archivos" },
          "en-US": { label: "Files" },
        },
        disabled: !machines,
        navTo: `/dashboard/files?user=${encodeURIComponent(login)}`,
      },
      {
        icon: <Laptop sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Dispositivos" },
          "en-US": { label: "Devices" },
        },
        disabled: !machines,
        navTo: `/dashboard/devices?group=${uGroup}`,
        onClick: () => {
          if (login) {
            dispatch(
              UIactions.setValue({ id: defaultPreFilter, value: login })
            );
          }
        },
      },
      {
        icon: <Delete sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Eliminar" },
          "en-US": { label: "Delete" },
        },
        disabled: auth.user === login,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "delete-users",
              payload: { users: [login] },
            })
          );
        },
      },
    ],
  };

  return <MoreActions {...data} />;
};

export default Actions;
