import {  Download } from "@mui/icons-material";
import { devicesDataId } from "../../../../store/actions/devices/getAllDevices";
import { forceRescanId } from "../../Actions/forceRescan";
import exportReport from "../../Actions/exportReport";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../Hooks/useWorker";
import ActionButton from "../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";

const UIText = {
  "es-CL": { label: "Exportar" },
  "en-US": { label: "Export" },
};

const Export = () => {
  const { dispatch, UI, warehouse, settings } = useUIBp();
  const { working } = useWorker(forceRescanId);
  const selected = UI.selected ?? [];
  const devicesObjs = warehouse[devicesDataId]??{}


  const onClick = () => { 
    let devices = []
    for (const key in devicesObjs){
      devices.push(devicesObjs[key])
    }
    let onlySelected = []
    for (const mid of selected){
      const dev = devices.find(x=> x.mid === mid)
      onlySelected.push(dev)
    }
    dispatch(exportReport({devices,onlySelected,locale:settings.locale}))
  };


  return (
    <ActionButton
      {...{
        working,
        onClick,
        UIText,
        icon: <Download />,
        variant:'outlined',
      }}
    />
  );
};

export default Export;
