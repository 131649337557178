import Sender from "./Components/Sender";
import Subject from "./Components/Subject";
import ClickableRow from "../../../UI/BasicLayout/Components/ClickableRow";
import Date from "./Components/Date";
import Restore from "./Components/Restore";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };
const fontSize = 13;

const MailRow = (props) => {
  const { dispatch } = useUIBp();
  const {
    id,
    fromM,
    toRecipients,
    subject,
    bodyPreview,
    receivedDateTime,
    hasAttachments,
  } = props;

  const onClick = () => {
    dispatch(
      UIactions.openModal({
        type: "view-mail-content",
        payload: { ...props },
      })
    );
  };

  const data = {
    id,
    elements: [
      { content: <Sender {...{ fromM, toRecipients, fontSize }} />, size: 2 },
      {
        content: (
          <Subject {...{ subject, bodyPreview, fontSize, hasAttachments }} />
        ),
        size: "grow",
      },
      {
        content: <Date {...{ receivedDateTime, fontSize }} />,
        size: { xl: 2, lg: 2, md: 3, sm: 3.5, xs: 4 },
      },
      { content: <Restore {...{ id }} />, justify: "end", size: 0.5 },
    ],
  };

  return <ClickableRow {...data} {...{ onClick }} />;
};

export default MailRow;
