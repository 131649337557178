import { useEffect } from "react";
import { deleteDevicesId } from "../../../store/actions/devices/deleteDevices";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../Hooks/useURLQuery";
import { UIactions } from "../../../store/slices/UI";
import { workerActions } from "../../../store/slices/workers";
import { devicesLoadedId } from "../../../store/actions/devices/getRootDevices";
import { useWorker } from "../../../Hooks/useWorker";
import DialogConfirmation from "../../Dialogs/Confirmations/AllPurpose/DialogConfirmation";

const DeleteDevices = () => {
  const { dispatch, navigate, settings, UI } = useUIBp();
  const { group } = useURLQuery();
  const { done, error } = useWorker(deleteDevicesId);
  const locale = settings.locale;
  const { payload } = UI.modal;
  const { devices } = payload;

  const UIText = {
    "es-CL": {
      header:
        devices.length > 1
          ? `¿Esta seguro que desea eliminar estos ${devices.length} dispositivos?`
          : `¿Esta seguro que desea eliminar el dispositivo "${devices[0].name}"?`,
      message: `Importante: Ten en cuenta que esta operación es definitiva e irreversible. Al confirmar, ${
        devices.length > 1
          ? `los dispositivos seran eliminados`
          : `el dispositivo será eliminado`
      } junto con toda su información. La información eliminada podrá ser recuperada dentro de un plazo de 90 días`,
    },
    "en-US": {
      header:
        devices.length > 1
          ? `Are you sure you want delete these ${devices.length} devices?`
          : `Are you sure you want delete the device "${devices[0].name}"?`,
      message: `Important: Beware that this operation is definitive and irreversible. Once confirmed, the device${
        devices.length > 1 ? "s" : ""
      } will be deleted, along with all it's data. The deleted data can be recovered before 90 days have passed`,
    },
  };
  const { header, message } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
      dispatch(UIactions.clear("selected"));
      dispatch(workerActions.clear(devicesLoadedId));
    }
  }, [dispatch, navigate, done, error, group]);

  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(deleteDevicesId));
    };
  }, [dispatch]);

  const confirmation = () => {
    let toBeDeleted = [];
    for (const device of devices) {
      toBeDeleted.push({
        mid: device.mid,
        user: device.user,
        name: device.name,
      });
    }
    dispatch(
      UIactions.openModal2({
        type: "delete-devices-confirmation",
        payload: { devices: toBeDeleted },
      })
    );
  };

  return (
    <DialogConfirmation
      alternativeText
      {...{
        header,
        message,
        severity: "warning",
        confirmation,
        workerId: deleteDevicesId,
      }}
    />
  );
};

export default DeleteDevices;
