import { LocalShipping } from "@mui/icons-material";
import { recoveriesActions } from "../../../../../../store/slices/recoveries";
import { useEffect, useMemo } from "react";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import useRecoveries from "../../../../../../Hooks/useRecoveries";
import { useWorker } from "../../../../../../Hooks/useWorker";
import createUsersRequest, { createUsersRequestID } from "../../../../../../store/actions/recoveries/createUsersRequest";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";
import { UIactions } from "../../../../../../store/slices/UI";

const UIText = {
  "es-CL": { label: "Recuperar" },
  "en-US": { label: "Recover" },
};

const Recover = () => {
  const { auth, dispatch, navigate, UI, warehouse, settings } =
    useUIBp();
  const requestData = useRecoveries();
  const { working, done, error } = useWorker(createUsersRequestID);
  const { initialized } = requestData;
  const locale = settings.locale;
  const selected = UI.selected;
  const { users = [] } = warehouse;

  let machines = useMemo(() => {
    let machines = 0;
    if (selected) {
      for (const userId of selected) {
        const user = users.find((user) => user.login === userId);
        if (user) {
          machines += user.machines;
        }
      }
    }
    return machines;
  }, [selected, users]);

  useEffect(() => {
    if (done & !error) {
      dispatch(recoveriesActions.dontAskAgain());
      navigate("/dashboard/recovery/step1");
    }
  }, [dispatch, navigate, done, error]);

  const onClick = () => {
    if (!initialized) {
      dispatch(recoveriesActions.reset());
      dispatch(
        createUsersRequest({
          ...auth,
          users: selected,
          locale,
        })
      );
    } else {
      dispatch(
        UIactions.openModal({
          type: "new-users-recovery-request",
          payload: { users: selected },
        })
      );
    }
  };

  return (
    <ActionButton
      {...{
        working,
        disabled: !machines,
        onClick,
        UIText,
        icon: <LocalShipping />,
      }}
    />
  );
};

export default Recover;
