import { enqueueSnackbar } from "notistack";
import { generateGreylist2 } from "../../../../../utility/misc/remoteSelection";
import axios from "axios";
import { rootsDataId } from "./getMachineStatus";
import { warehouseActions } from "../../../../../store/slices/warehouse";
import { workerActions } from "../../../../../store/slices/workers";

export const postGreylistsId = "postGreylists";

const UIText = {
  "es-CL": { success: "Selección guardada exitosamente" },
  "en-US": { success: "Selection saved successfully" },
};

const postGreylists = ({ server, tunnelToken, password, trees, roots, locale }) => {
  return async (dispatch) => {
    const { success } = UIText[locale];
    dispatch(workerActions.start(postGreylistsId));
    try {
      let newRoots = [];
      for (const root of roots) {
        let newRoot = { ...root };
        if (root.id || trees[root.root][0].backup) {
          if (!root.id) {
            console.log("initialize repository", root.root);
            const url = server.slice(0, -1) + ":5107/repo/add";
            let formData = new FormData();
            formData.append("name", root.root);
            formData.append("path", root.root);
            const response = await axios.post(url, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                tunnel_token: tunnelToken,
              },
            });
            newRoot.id = response.data.id;

            const url2 = server.slice(0, -1) + ":5107/repo/resume";
            let formData2 = new FormData();
            formData2.append("id", newRoot.id);
            await axios.post(url2, formData2, {
              headers: {
                "Content-Type": "multipart/form-data",
                tunnel_token: tunnelToken,
              },
            });
          }

          const gl = generateGreylist2(trees[root.root], [0]);
          const url = server.slice(0, -1) + ":5107/repo/greylist/set";
          let formData = new FormData();
          formData.append("id", newRoot.id);
          formData.append("path", JSON.stringify(gl));
          formData.append("cpass", password);
          await axios.post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              tunnel_token: tunnelToken,
            },
          });
        }
        newRoots.push(newRoot);
      }
      dispatch(warehouseActions.load({ id: rootsDataId, data: newRoots }));
      dispatch(workerActions.finish({ id: postGreylistsId }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id: postGreylistsId, error: e.message }));
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
};

export default postGreylists;
