import { PushPin } from "@mui/icons-material";
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { useState } from "react";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import swapReducer from "../../Actions/swapReducer";

const UIText = {
  "es-CL": { fixedTooltip: "Recordar sesión" },
  "en-US": { fixedTooltip: "Remember session" },
};
const icon1 = <PushPin sx={{ fontSize: "1rem" }} />;
const icon2 = <PushPin color="error" sx={{ fontSize: "1rem" }} />;

const PersistSession = (props) => {
  const { disabled } = props;
  const { dispatch, settings } = useUIBp();
  const startSelected = localStorage.getItem("remember-session") ? true : false;
  const [selected, setSelected] = useState(startSelected);
  const { fixedTooltip: title } = UIText[settings.locale];

  const handleClick = () => {
    dispatch(swapReducer(!selected));
    setSelected((selected) => !selected);
  };

  const base = (
    <IconButton {...{ disabled, onClick: handleClick }}>
      {selected ? icon2 : icon1}
    </IconButton>
  );

  if (disabled) {
    return <InputAdornment position="end">{base}</InputAdornment>;
  } else {
    return (
      <InputAdornment position="end">
        <Tooltip {...{ title }}>{base}</Tooltip>
      </InputAdornment>
    );
  }
};

export default PersistSession;
