import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

export const getM365OrgsId = "microsoft365organizations";
export const m365orgsData = "microsoft365organizations";

const UIError = {
  "es-CL": {
    error: "Error al obtener organizaciones",
  },
  "en-US": {
    error: "Error getting organizations",
  },
};

export const getOrganizations = ({ M365server, cloner_key, groups, locale }) => {
  const id = getM365OrgsId;

  console.log("getting orgs for groups:", groups);

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    let lastError = null;

    for (let group_id of groups) {
      try {
        const url = `${M365server}mailapi/organization`;
        const response = await axios.get(url, {
          params: { group_id },
          headers: { cloner_key },
        });

        const org = response.data.data;
        const parent = org.parent ?? {};
        const domain = parent.mail_account
          ? parent.mail_account.split("@")[1]
          : null;

        dispatch(
          warehouseActions.add({
            id: m365orgsData,
            key: group_id,
            data: {
              id: org.own.client_id?org.own.id:org.parent.id,
              domain
            },
          })
        );
      } catch (e) {
        console.error(e);
        lastError = e;
        // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
        // dispatch(worker.finish({ id, error: e.message }));
      }
    }

    if (lastError) {
      enqueueSnackbar(UIError[locale].error, { variant: "error" });
      dispatch(workerActions.finish({ id, error: lastError.message }));
    } else {
      dispatch(workerActions.finish({ id }));
    }
  };
};
