import {  Telegram } from "@mui/icons-material";
import ActionButton from "../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";
import { devicesDataId } from "../../../../store/actions/devices/getAllDevices";
import requestLogs, { requestLogsId } from "../../Actions/requestLogs";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../Hooks/useWorker";

const Logs = () => {
  const { auth, dispatch, UI, warehouse, settings } = useUIBp();
  const { working } = useWorker(requestLogsId);
  const selected = UI.selected ?? [];
  const devices = warehouse[devicesDataId];

  const onClick = () => { 
    let payload = [];
    for (const mid of selected) {
      const device = devices[mid];
      const payloadItem = {
        user: device.user,
        machine: device.mid,
        token: auth.token,
      };
      payload.push(payloadItem);
    }
    dispatch(
      requestLogs({
        ...auth,
        payload,
        locale: settings.locale,
      })
    );
  };

  const UIText = {
    "es-CL": { label: "Solicitar logs" },
    "en-US": { label: "Request logs" },
  };

  return (
    <ActionButton
      {...{
        working,
        disabled: !selected.length,
        onClick,
        UIText,
        icon: <Telegram />,
        variant:'outlined',
      }}
    />
  );
};

export default Logs;
