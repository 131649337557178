import { useEffect, useMemo } from "react";
import { sortObjByKey } from "../../../../../../../../utility/misc/sortObjByKey";
import { citySelectUI } from "./City";
import { regionSelectUI } from "./Region";
import { addressData, removeArrayDuplicates } from "./addressUtils";
import { emptyCityUI, emptyDistrictUI, miscErrorsUI } from "../../../Next";
import useUIBp from "../../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../../store/slices/UI";
import Select from "../../../../../../../UI/GeneralPurpose/Select";

const UIText = {
  "es-CL": { label: "Comuna" },
  "en-US": { label: "District" },
};

export const districtSelectUI = "districtSelect";

const District = () => {
  const { dispatch, UI } = useUIBp();
  const miscErrors = UI[miscErrorsUI] ?? [];
  const region = UI[regionSelectUI] ?? "";

  const districts = useMemo(() => {
    const rawDistrict = addressData
      .filter((x) => x.region === region)
      .map((x) => ({
        label: x.comuna,
        value: x.comuna,
      }));

    const deduplicatedDistrict = removeArrayDuplicates(rawDistrict);

    const sortedDistrict = deduplicatedDistrict.sort((a, b) =>
      sortObjByKey(a, b, "label")
    );

    return sortedDistrict.map((x) => ({
      universal: x,
      value: x.value,
    }));
  }, [region]);

  useEffect(() => {
    if (districts.length === 1) {
      dispatch(
        UIactions.setValue({
          id: districtSelectUI,
          value: districts[0].value,
        })
      );
    }
  }, [dispatch, districts]);

  const handleChange = () => {
    dispatch(UIactions.clear(citySelectUI));
    dispatch(UIactions.remove({ id: miscErrorsUI, key: emptyDistrictUI }));
    dispatch(UIactions.remove({ id: miscErrorsUI, key: emptyCityUI }));
  };

  const error = !!miscErrors.find((x) => x === emptyDistrictUI);

  return (
    <Select
      {...{
        id: districtSelectUI,
        options: districts,
        UIText,
        onChange: handleChange,
        disabled: districts.length <= 1,
        error,
        size: {
          xl: 4,
          lg: 4,
          md: 4,
          sm: 12,
          xs: 12,
        },
      }}
    />
  );
};

export default District;
