import { Grid2 } from "@mui/material";
import CustomDrawer from "./Components/Drawer/Drawer";
import { useEffect } from "react";
import DashBody from "./Components/DashBody";
import { viewportHeightUI } from "../../App";
import useUIBp from "../../Hooks/useUIBoilerplate";
import useURLQuery from "../../Hooks/useURLQuery";
import { workerActions } from "../../store/slices/workers";
import { devicesLoadedId } from "../../store/actions/devices/getRootDevices";
import DialogsSecondaryOutlet from "../Dialogs/Outlets/Secondary";
import DialogsPrimaryOutlet from "../Dialogs/Outlets/Primary";

const Dashboard = () => {
  const { auth, dispatch, navigate, UI } = useUIBp();
  const { group } = useURLQuery();
  const height = UI[viewportHeightUI];


  useEffect(() => {
    if (!auth.token) {
      navigate("/login", { replace: true });
    }
  }, [navigate, auth.token]);

  useEffect(() => {
    if (group) {
      dispatch(workerActions.clear(devicesLoadedId));
    }
  }, [dispatch, group]);

  return (
    <Grid2
      container
      {...{
        sx: {
          width: "100vw",
          height,
          columnGap: 0,
          overflow: "visible",
          overflowY: "hidden",
          backgroundColor: "red",
        },
      }}
    >
      <DialogsSecondaryOutlet />
      <DialogsPrimaryOutlet />
      <Grid2
        container
        {...{
          sx: { width: "100vw", height, columnGap: 0 },
          overflow: "visible",
          wrap: "nowrap",
        }}
      >
        <CustomDrawer />
        <DashBody height={height} />
      </Grid2>
    </Grid2>
  );
};

export default Dashboard;
