import { Delete, Folder, Storage } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Fragment } from "react";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../Hooks/useURLQuery";
import { workerActions } from "../../../../../store/slices/workers";
import Button from "../../../../UI/GeneralPurpose/Button";

const Name = ({ idx, name, deleted, working, repository, id }) => {
  const { dispatch, navigate } = useUIBp();
  const { user, mid } = useURLQuery();

  const startIcon = idx ? <Folder /> : <Storage />;
  const endIcon = deleted ? <Delete color="error" /> : null;
  const endIcon2 = working ? <CircularProgress size={14} /> : null;

  // handle folder click
  const onClick = () => {
    const url = `/dashboard/files?user=${encodeURIComponent(user)}&mid=${mid}&repo=${repository}&file=${id}`;
    navigate(url);
    dispatch(workerActions.clear(id));
  };

  return (
    <Button
      fullWidth
      {...{
        UIText: { universal: { label: name } },
        onClick,
        startIcon,
        endIcon: (
          <Fragment>
            {endIcon}
            {endIcon2}
          </Fragment>
        ),
        style: { justifyContent: "flex-start" },
        labelSx: { fontSize: 13, textTransform: "none", color: "#555" },
        sx: { padding: 0, paddingLeft: 1, paddingRight: 1, marginRight: 1 },
        variant: "text",
        size: "auto",
      }}
    />
  );
};

export default Name;
