import emailCompliant from "../../../../../utility/constraints/emailCompliant";
import notEmptyUser from "../../../../../utility/validations/notEmptyUser";
import TextField from "../../../../UI/GeneralPurpose/SimpleTextField";


const UIText = {
  "es-CL": {
    label: "Usuario",
    placeholder: "usuario1@cloner.cl",
  },
  "en-US": {
    label: "User",
    placeholder: "user1@cloner.cl",
  },
};

export const userUI = "userLogin";

const User = (props) => {
  const { isNew } = props;

  return (
    <TextField
      tagError
      forceLowerCase
      {...{
        id: userUI,
        UIText,
        required: isNew,
        disabled: !isNew,
        validation: notEmptyUser,
        constraint: emailCompliant,
        size: 12,
      }}
    />
  );
};

export default User;
