import { Grid2, Typography } from "@mui/material";

const Block = ({ label, value, size }) => {
  return (
    <Grid2
      container
      {...{
        wrap: "nowrap",
        size,
        columnGap: 2,
      }}
    >
      <Typography fontWeight="bold" fontSize="1.05rem">
        {label}
      </Typography>
      <Typography noWrap fontSize="1rem">
        {value}
      </Typography>
    </Grid2>
  );
};

export default Block;
