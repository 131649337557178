import { Grid2 } from "@mui/material";
import { Fragment } from "react";
import Divider from "../../UI/GeneralPurpose/Divider";
import GroupFinder from "../../UI/Specialized/GroupFinder";

const Top = () => {
  return (
    <Fragment>
      <Grid2
        container
        {...{ justifyContent: "space-between", padding: 2, size: 12 }}
      >
        <GroupFinder {...{ size: 6, travelerType: "modal" }} />
      </Grid2>
      <Divider />
    </Fragment>
  );
};

export default Top;
