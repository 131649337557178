import { InsertDriveFile } from "@mui/icons-material";
import { Chip, Grid2 } from "@mui/material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import downloadAttachment from "../../../../Actions/downloadAttachment";

const Attachs = ({ hasAttachments, attachments }) => {
  const { auth, dispatch, settings } = useUIBp();

  if (!hasAttachments) {
    return null;
  }

  const Attachments = attachments.map((data) => {
    const onClick = () => {
      dispatch(
        downloadAttachment({
          ...auth,
          id: data.id,
          name: data.name,
          locale: settings.locale,
        })
      );
    };
    return (
      <Chip {...{key:data.id, onClick, icon: <InsertDriveFile />, label: data.name }} />
    );
  });

  return (
    <Grid2
      container
      {...{
        gap: 2,
        padding: 2,
        size: 12,
        sx: { borderTop: "1px solid lightgrey" },
      }}
    >
      {Attachments}
    </Grid2>
  );
};

export default Attachs;
