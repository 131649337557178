import { Grid2 } from "@mui/material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { Pagination } from "../../../../UI/GeneralPurpose/Pagination";

export const versionsPaginationUI = "versionsPagination";

const Footer = () => {
  const { UI } = useUIBp();
  const pagination = UI[versionsPaginationUI] ?? { count: 0 };

  return (
    <Grid2
      container
      {...{
        paddingLeft: "2vh",
        paddingRight: "2vh",
        height: "6vh",
        alignItems: "center",
        justifyContent: "end",
        size: 12,
        columnGap: 2,
      }}
    >
      {!pagination.count ? null : (
        <Pagination {...{ id: versionsPaginationUI, xs: "auto" }} />
      )}
    </Grid2>
  );
};

export default Footer;
