import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { getM365OrgId } from "../../../../../store/actions/microsoft365/getOrganization";
import { updateM365OrgId } from "./changeM365Sharing";
import { workerActions } from "../../../../../store/slices/workers";

export const UIText1 = {
  "es-CL": {
    success:
      "La organización de Microsoft 365 ahora está siendo obtenida del grupo padre",
    error:
      "No se pudo habilitar la obtención de la organizacion de Microsoft 365: ",
  },
  "en-US": {
    success:
      "The Microsoft 365 organization is being fetched from the parent group",
    error: "Microsoft 365 organization couldn't be fetched from parent group: ",
  },
};
export const UIText2 = {
  "es-CL": {
    success:
      "Se dejó de obtener la organización de Microsoft 365 del grupo padre",
    error:
      "No se pudo dejar de obtener la organizacion de Microsoft 365 del grupo padre: ",
  },
  "en-US": {
    success: "Microsoft 365 organization fetching was stopped",
    error: "Microsoft 365 organization fetching couln't be stopped: ",
  },
};

const changeM365GroupLock = ({ M365server, cloner_key, locale, payload }) => {
  const id = updateM365OrgId;
  const UIText = payload.group_lock ? UIText2 : UIText1;
  const { success, error } = UIText[locale];
  console.log("Changing Microsoft 365 group lock");

  return async (dispatch) => {
    dispatch(workerActions.start(id));

    try {
      // Updating the organization with the new group lock value
      const bodyData = JSON.stringify(payload);
      const url = M365server + "mailapi/organization";
      await axios.put(url, bodyData, { headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(workerActions.finish({ id }));
      dispatch(workerActions.clear(getM365OrgId));
    } catch (e) {
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(workerActions.finish({ id, error }));
    }
  };
};

export default changeM365GroupLock;
