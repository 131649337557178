import { Fragment, useEffect, useMemo } from "react";
import { Grid2, Typography } from "@mui/material";
import { generateHexString } from "../../../../../../../utility/misc/generateString";
import SelectButton from "./Select";
import ExpandButton from "./Expand";
import { useDispatch } from "react-redux";
import Loading from "./Loading";
import { TypeIcon } from "./utils";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import useTrees from "../../../../../../../Hooks/useTrees";
import { workerActions } from "../../../../../../../store/slices/workers";
import CollapseGrid from "../../../../../../UI/GeneralPurpose/CollapseGrid";

const SelectionNode = (props) => {
  const {
    backup,
    idx,
    isDir,
    level,
    loaded,
    name,
    status,
    expanded,
    root,
    repo,
    path,
    childrenIdx: children = [],
  } = props;
  const rnd = useMemo(() => generateHexString(20), []);
  const id = level ? rnd : "root" + root;
  const dispatch = useDispatch();
  const { working } = useWorker(id);
  const trees = useTrees();
  const tree = trees[root] ?? [];
  const fullPath = level > 1 ? path + "/" + name : path + name;

  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(id));
    };
  }, [dispatch, id]);

  const typeIcon = TypeIcon(
    idx,
    isDir,
    isDir && status === 1,
    working,
    null,
  );

  const directories = tree
    .filter((x) => x.isDir && x.parentIdx === idx)
    .map((data) => (
      <SelectionNode
      key={data.idx}
        {...data}
        {...{ root, repo, path: fullPath, level: level + 1 }}
      />
    ));
  const files = tree
    .filter((x) => !x.isDir && x.parentIdx === idx)
    .map((data) => (
      <SelectionNode
      key={data.idx}
        {...data}
        {...{ root, repo, path: fullPath, level: level + 1 }}
      />
    ));

  return (
    <Fragment>
      <Grid2
        container
        {...{
          size: 12,
          wrap: "nowrap",
          paddingLeft: level * 2.7,
          sx: { backgroundColor: `rgba(39, 181, 245, ${level * 0.05})` },
          alignItems: "center",
        }}
      >
        {working ? (
          <Loading />
        ) : (
          <SelectButton {...{ treeId: root, idx, working, backup }} />
        )}
        <ExpandButton
          {...{
            id,
            fullPath,
            treeId: root,
            level,
            idx,
            loaded,
            expanded,
            isDir,
            hasChildren: !!children.length,
            working,
            status,
          }}
        />
        <Grid2 container {...{ size: true, alignItems: "center" }}>
          {typeIcon}
          <Typography
            noWrap
            fontSize={14}
            paddingLeft={1}
            color={working ? "grey" : undefined}
          >
            {name}
          </Typography>
        </Grid2>
      </Grid2>
      <CollapseGrid open={expanded} outerProps={{ size: 12 }} unmountOnExit>
        {directories}
        {files}
      </CollapseGrid>
    </Fragment>
  );
};

export default SelectionNode;
