const headersData = {
  headers: [
    {
      UIText: {
        "es-CL": { label: "De/Para:" },
        "en-US": { label: "From/To" },
      },
      filterKey: "subject",
      size: 2,
    },
    {
      UIText: {
        "es-CL": { label: "Asunto" },
        "en-US": { label: "Subject" },
      },
      filterKey: "bodyPreview",
      size: "grow",
    },
    {
      UIText: {
        "es-CL": { label: "Fecha" },
        "en-US": { label: "Date" },
      },
      filterKey: "receivedDateTime",
      size: { xl: 2, lg: 2, md: 3, sm: 3.5, xs: 4 },
    },
    {
      UIText: {
        "es-CL": { label: "" },
        "en-US": { label: "" },
      },
      size: 0.5,
      justify: "end",
    },
  ],
};

export default headersData;
