import notEmptyQRCode from "../../../../../utility/validations/notEmptyQRCode";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { loginUserID } from "../../User/User";
import loginWithGAuth, { gauthWrkID } from "../../../Actions/loginWithGAuth";
import { authFieldID } from "./AuthCode";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";
import { Grid2 } from "@mui/material";
import { useWorker } from "../../../../../Hooks/useWorker";

const UIText = {
  "es-CL": { label: "Autenticar codigo" },
  "en-US": { label: "Authenticate code" },
};

const AuthenticateBtn = () => {
  const { dispatch, UI, settings } = useUIBp();
  const user = UI[loginUserID] ?? {};
  const authCode = UI[authFieldID] ?? {};
  const { working } = useWorker(gauthWrkID);

  const handleHardValidation = () => {
    const payload = { id: authFieldID, fn: notEmptyQRCode, force: true };
    dispatch(UIactions.validateField(payload));
  };

  const handleAuthentication = () => {
    const payload = {
      user: user.value,
      code: authCode.value,
      locale: settings.locale,
    };
    dispatch(loginWithGAuth(payload));
  };

  return (
    <Grid2 container {...{ size: 12, justifyContent: "center" }}>
      <Button
        fullWidth
        {...{
          UIText,
          onMouseDown: handleHardValidation,
          onClick: handleAuthentication,
          working,
          disabled: !!authCode.error,
          size: {
            xl: 3,
            lg: 3,
            md: 4,
            sm: 6,
            xs: 8,
          },
        }}
      />
    </Grid2>
  );
};

export default AuthenticateBtn;
