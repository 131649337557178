import { Save } from "@mui/icons-material";
import { recoverRepoUI } from "./Repositories/Repository/Repository";
import {
  aditionalOptionUI,
  recoveryDateUI,
} from "./Repositories/Repository/AditionalOptions";
import { aditionalInfoUI } from "./Repositories/Repository/AditionalDetails";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { recoveriesActions } from "../../../../../store/slices/recoveries";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const SaveBtn = (props) => {
  const { repositories } = props;
  const { dispatch, navigate, UI } = useUIBp();

  const onClick = () => {
    let newRecovery = { ...props, mode: "advanced", repositories: [] };
    for (const repo of repositories) {
      let recover = UI[`${recoverRepoUI}-${repo.id}`];
      let aditionalOption = UI[`${aditionalOptionUI}-${repo.id}`];
      let date = UI[`${recoveryDateUI}-${repo.id}`] ?? {};
      let details = UI[`${aditionalInfoUI}-${repo.id}`]??{};
      const newRepo = {
        ...repo,
        recover,
        option: aditionalOption ? aditionalOption : "",
        bkpDate: date.value ? date.value : "",
        details: details.value ? details.value : null,
      };
      newRecovery.repositories.push(newRepo);
    }

    dispatch(recoveriesActions.updateRecovery(newRecovery));
    navigate("/dashboard/recovery/step1");
  };

  let disabled = false;
  let empty = true;
  let issues = false;
  for (const repo of repositories) {
    if (UI[`${recoverRepoUI}-${repo.id}`]) {
      empty = false;
    }
    if (UI[`${aditionalOptionUI}-${repo.id}`] === "dated") {
      if (!UI[`${recoveryDateUI}-${repo.id}`]) {
        issues = true;
      }
    }
  }
  disabled = empty || issues;

  return <Button {...{ disabled, UIText, onClick, endIcon: <Save /> }} />;
};

export default SaveBtn;
