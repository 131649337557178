import { CircularProgress, Grid2 } from "@mui/material";

const Loading = ({ working }) => {
  if (!working) {
    return;
  }

  return (
    <Grid2
      container
      {...{
        justifyContent: "center",
        alignItems: "center",
        sx: { height: "30vh" },
        size: 12,
      }}
    >
      <CircularProgress size={60} />
    </Grid2>
  );
};

export default Loading;
