import BasicDialogActions from "../../../../UI/BasicDialog/BasicDialogActions";
import Clear from "./Borrar";
import Close from "./Close";
import Send from "./Send";

const Buttons = () => {
  return (
    <BasicDialogActions>
      <Clear />
      <Close />
      <Send />
    </BasicDialogActions>
  );
};

export default Buttons;
