import BasicRow from "../../../../../../UI/BasicLayout/Components/BasicRow";
import { notEmptyName } from "../../../../../../../utility/validations/notEmptyName";
import { useEffect } from "react";
import Username from "./Username";
import { arrayId } from "../UsersList";
import W365mail from "./M365mail";
import BackupMail, { bkpMailArrayId } from "./BackupMail";
import BackupOnedrive, { bkpOneArrayId } from "./BackupOnedrive";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../store/slices/UI";

export const rowErrorsUI = "rowErrors";
const fontSize = 13;

const UserRow = (props) => {
  const { login } = props;
  const { dispatch, settings, UI } = useUIBp();
  let w365mailfield = UI["365mail-"+login] ?? {};
  let w365mail = w365mailfield.value;
  let bkpMailArray = UI[bkpMailArrayId] ?? [];
  let bkpMailEnabled = !!bkpMailArray.find((x) => x === login);
  let bkpOneArray = UI[bkpOneArrayId] ?? [];
  let bkpOneEnabled = !!bkpOneArray.find((x) => x === login);
  const locale = settings.locale;

  let errors = [];

  const nameError = notEmptyName(w365mail, locale);
  if ((nameError && ( bkpOneEnabled || bkpMailEnabled)) || !!w365mailfield.error) {
    errors.push(nameError);
  }

  const error = !!errors.length;

  useEffect(() => {
    if (error) {
      dispatch(UIactions.add({ id: rowErrorsUI, key: login }));
    } else {
      dispatch(UIactions.remove({ id: rowErrorsUI, key: login }));
    }
  }, [dispatch, error, login]);

  const rowData = {
    id: login,
    arrayId,
    error,
    elements: [
      { content: <Username {...{ login, fontSize }} />, size: 3.6 },
      { content: <W365mail {...{ login, fontSize }} />, size: 3.6 },
      { content: <BackupMail {...{ login, fontSize }} />, size: 2.4 },
      { content: <BackupOnedrive {...{ login, fontSize }} />, size:2.4 },
    ],
  };
  return <BasicRow {...rowData} />;
};

export default UserRow;
