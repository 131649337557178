import { useEffect, useMemo } from "react";
import { sortObjByKey } from "../../../utility/misc/sortObjByKey";
import DynamicSelect from "../GeneralPurpose/DynamicSelect";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../Hooks/useWorker";
import { UIactions } from "../../../store/slices/UI";
import { workerActions } from "../../../store/slices/workers";
import getGreylist, { getGreylistId, greylistDataId } from "./Actions/getGreylist";

const UIText = {
  "es-CL": {
    normalTxt: "Fecha de selección",
    loadingTxt: "Cargando...",
    errorTxt: "Error al obtener repositorios",
    emptyTxt: "No se encontró repositorios",
  },
  "en-US": {
    normalTxt: "Selection date",
    loadingTxt: "Loading...",
    errorTxt: "Error loading repositories",
    emptyTxt: "No repositories found",
  },
};

export const greylistPickerId = "greylistPicker";

const GreylistPicker = ({ repoId, onChange, onDismount, onLoaded, size }) => {
  const { auth, dispatch, warehouse } = useUIBp();
  const { done, working, error } = useWorker(getGreylistId);

  let options = useMemo(() => {
    const greylists = warehouse[greylistDataId] ?? [];
    return [...greylists]
      .sort((a, b) => sortObjByKey(a, b, "value", true))
      .map((x) => {
        return { universal: { value: x.milliseconds, label: x.localCreated } };
      });
  }, [warehouse]);

  // Load data
  useEffect(() => {
    const controller = new AbortController();
    if (!done && !!repoId) {
      dispatch(getGreylist({ ...auth, repoId, controller }));
    }
    return () => {
      if (!done) {
        controller.abort();
      }
    };
  }, [dispatch, auth, repoId, done]);

  // Fill blank
  useEffect(() => {
    if (!!repoId && !!options.length && done) {
      if (onLoaded) {
        onLoaded(options, repoId);
      } else {
        const value = options[0].universal.value;
        dispatch(UIactions.setValue({ id: greylistPickerId, value }));
      }
    }
  }, [dispatch, repoId, options, onLoaded, done]);

  // Cleanup
  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(getGreylistId));
      if (onDismount) {
        onDismount();
      }
    };
  }, [dispatch, onDismount]);

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(UIactions.setValue({ id: greylistPickerId, value }));

    if (onChange) {
      onChange();
    }
  };

  return (
    <DynamicSelect
      fullWidth
      {...{
        id: greylistPickerId,
        UIText,
        options,
        disabled: options.length <= 1,
        onChange: handleChange,
        size,
        done,
        working,
        error,
      }}
    />
  );
};

export default GreylistPicker;
