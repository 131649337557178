import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { devicesDataId } from "../../../../../store/actions/devices/getDevices";
import addRecovery from "../../../../../store/actions/recoveries/addRecovery";
import Button from "../../../../UI/GeneralPurpose/Button";
import { devicePickerId } from "../../../../UI/Specialized/DevicePicker";
import { userPickerId } from "../../../../UI/Specialized/UserPicker";
import { recoveryDateUI, recoveryOptionUI } from "./Options";

const UIText = {
  "es-CL": { label: "Agregar recuperación" },
  "en-US": { label: "Add recovery" },
};

const Add = () => {
  const { auth, dispatch, UI, settings, warehouse } = useUIBp();
  const locale = settings.locale;
  const { id } = UI[userPickerId] ?? {};
  const { mid, label } = UI[devicePickerId] ?? {};
  const { value: bkpDateRaw, error: dateErr } = UI[recoveryDateUI] ?? "";
  const option = UI[recoveryOptionUI] ?? "";
  const devices = warehouse[devicesDataId] ?? [];
  const device = devices.find((d) => d.mid === mid) ?? {};

  const onClick = () => {
    let bkpDate = "";

    if (option === "dated") {
      bkpDate = bkpDateRaw.format("YYYY-MM-DD HH:mm:ss");
    }
    dispatch(
      addRecovery({
        ...auth,
        user: id,
        mid,
        device: label,
        option,
        bkpDate,
        fullSize: device.space_active,
        lastBkp: device.lastBkp,
        locale,
      })
    );
  };

  let disabled = false;
  if ((option === "dated" && !bkpDateRaw) || dateErr) {
    disabled = true;
  } else if (devices.length === 0) {
    disabled = true;
  }

  return <Button {...{ disabled, UIText, onClick }} />;
};

export default Add;
