import { Grid2 } from "@mui/material";
import SlimAlert from "../../../GeneralPurpose/SlimAlert";
import Button from "../../../GeneralPurpose/Button";
import { Cached } from "@mui/icons-material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { workerActions } from "../../../../../store/slices/workers";

// const x = {
//     hasError: true,
//     UIText: {
//         universal: { message: "????" }
//     }
// };

const UIText2 = {
  "es-CL": { label: "Reintentar" },
  "en-US": { label: "Retry" },
};

const Error = ({ workerId, error, UIText = { universal: {} } }) => {
  const { dispatch, settings } = useUIBp();
  const { message } = UIText[settings.locale] ?? UIText.universal;

  if (!error) {
    return;
  }

  const onClick = () => {
    dispatch(workerActions.clear(workerId));
  };

  return (
    <Grid2
      container
      {...{
        height: "30vh",
        alignItems: "center",
        justifyContent: "center",
        size: { xs: 12 },
      }}
    >
      <Grid2
        container
        {...{ size: { xs: 6 }, rowGap: 3, justifyContent: "center" }}
      >
        <SlimAlert notCollapse {...{ errors: [message], size: { xs: 12 } }} />
        <Button
          {...{
            startIcon: <Cached />,
            onClick,
            UIText: UIText2,
            variant: "outlined",
            size: "medium",
            xs: "auto",
          }}
        />
      </Grid2>
    </Grid2>
  );
};

export default Error;
