import { useDispatch } from "react-redux";
import Button from "../../../../UI/GeneralPurpose/Button";
import { UIactions } from "../../../../../store/slices/UI";
import { authFieldID } from "./AuthCode";
import { Grid2 } from "@mui/material";

const UIText = {
  "es-CL": { label: "Volver" },
  "en-US": { label: "Back" },
};

const Back = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.clear("twofactor"));
    dispatch(UIactions.clear(authFieldID));
  };

  return (
    <Grid2 container {...{ size: 12, justifyContent: "center" }}>
      <Button
        fullWidth
        {...{
          UIText,
          onClick,
          size: {
            xl: 3,
            lg: 3,
            md: 4,
            sm: 6,
            xs: 8,
          },
          variant: "outlined",
        }}
      />
    </Grid2>
  );
};

export default Back;
