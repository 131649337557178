import { Download } from "@mui/icons-material";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import downloadM365Template, { downloadTemplateUI } from "../../../Actions/downloadM365template";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import Button from "../../../../../../UI/GeneralPurpose/Button";

const UITextDownload = {
  "es-CL": {
    label: "Descargar .xlsx",
    fixedTooltip: "Descargar hoja .xslx templado para la carga de preferencias",
  },
  "en-US": {
    label: "Download .xlsx",
    fixedTooltip: "Download .xslx template sheet needed to upload settings",
  },
};

const DownloadSheet = () => {
  const { dispatch, settings } = useUIBp();
  const {working} = useWorker(downloadTemplateUI)

  const onClick = () => {
    dispatch(downloadM365Template(settings.locale));
  };

  return (
    <Button
      {...{
        onClick,
        UIText: UITextDownload,
        working,
        variant: "text",
        startIcon: <Download />,
        labelSx:{ fontSize: "0.8rem", fontWeight: "bold" },
        size: "auto",
      }}
    />
  );
};

export default DownloadSheet;
