import Actions, { plusActionsGrid } from "./PlusActions/PlusActions";
import headersData from "./Headers";
import SharedRow from "./SharedRow/SharedRow";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import BasicLayout from "../../UI/BasicLayout/BasicLayout";
import { viewmodeUI } from "../../../App";
import { UIactions } from "../../../store/slices/UI";
import { resetScrollId } from "../../UI/BasicLayout/Components/Core/Core";

// hasHeaders, empty, working, error, done, elements
const Core = ({ links, allLinks, done, error, working, workerId }) => {
  const { dispatch, settings, UI } = useUIBp();
  const viewmode = UI[viewmodeUI] ?? "desktop";

  const UIText = {
    "es-CL": `Archivos compartidos`,
    "en-US": `Shared files`,
  };

  let fixedWidth;

  if (viewmode === "verycompact") {
    fixedWidth = "100vw";
  } else if (viewmode === "mobile") {
    fixedWidth = "200vw";
  }

  const header = UIText[settings.locale];
  const plusActions = <Actions />;

  const elements = links.map((data, idx) => <SharedRow key={idx} {...data} />);

  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: {
        "es-CL": {
          message: "Error al cargar los archivos compartidos: " + error,
        },
        "en-US": { message: "Error loading the shared files: " + error },
      },
    },
    empty: {
      isEmpty: !links.length && done && !error,
      UIText: {
        "es-CL": { message: "No hay archivos compartidos disponibles" },
        "en-US": { message: "There are no shared files available" },
      },
    },
    done,
    elements,
  };

  return (
    <BasicLayout
      {...{
        plusActions,
        plusActionsGrid,
        header,
        headersData,
        fullArray: allLinks,
        coreData,
        overflowX: viewmode === "desktop" ? "hidden" : "auto",
        fixedWidth: fixedWidth,
        onPaginationChange: () => {
          dispatch(UIactions.setValue({id:resetScrollId, value:true}));
        },
      }}
    />
  );
};

export default Core;
