import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useRecoveries from "../../Hooks/useRecoveries";
import { UIactions } from "../../store/slices/UI";
import { recoveriesActions } from "../../store/slices/recoveries";

const RecoveryRequest = () => {
  const dispatch = useDispatch();
  const recoveriesData = useRecoveries() ?? {};
  const { initialized, skipAsk } = recoveriesData;

  useEffect(() => {
    if (initialized && !skipAsk) {
      dispatch(
        UIactions.openModal({
          type: "reset-recovery-request",
        })
      );
    }
  }, [dispatch, initialized, skipAsk]);

  useEffect(() => {
    return () => {
      dispatch(recoveriesActions.askAgain());
    };
  }, [dispatch]);

  return <Outlet />;
};

export default RecoveryRequest;
