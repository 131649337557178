import { enqueueSnackbar } from "notistack";
import axios from "axios";
import { workerActions } from "../../../store/slices/workers";

export const requestLogsId = "requestLogs";

const UIText = {
  "es-CL": {
    start: "Solicitando logs de equipos",
    success: "Logs solicitados exitosamente",
    serverError: "Servidor retornó error",
    error: "Error al intentar solicitar un log",
  },
  "en-US": {
    start: "Requesting logs from devices",
    success: "Log requested successfully",
    serverError: "Server returned error",
    error: "Error while trying to request a log",
  },
};

const requestLogs = (data) => {
  const { server, cloner_key, payload, locale } = data;
  return async (dispatch) => {
    const { start, success, serverError, error } = UIText[locale];
    dispatch(workerActions.start(requestLogsId));
    enqueueSnackbar(start, { variant: "info" });
    try {
      for (const item of payload) {
        const bodyData = JSON.stringify(item);
        const url = server + "api/pulllog";
        const response = await axios.post(url, bodyData, {
          headers: { cloner_key },
        });
        if (!response.data) {
          throw new Error(serverError);
        }
      }
      dispatch(workerActions.finish({ id: requestLogsId }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id: requestLogsId, error: e.msg }));
      enqueueSnackbar(error, { variant: "error" });
    }
  };
};

export default requestLogs;
