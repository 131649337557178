import { Grid2, Typography } from "@mui/material";

const Block = ({ label, value }) => {
  return (
    <Grid2
      container
      {...{
        wrap: "nowrap",
        size: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 3,
        },
        columnGap: 2,
      }}
    >
      <Typography fontWeight="bold" fontSize={15}>
        {label}
      </Typography>
      <Typography noWrap fontSize={15}>
        {value}
      </Typography>
    </Grid2>
  );
};

export default Block;
