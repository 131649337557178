import { Shield } from "@mui/icons-material";
import { gauthCodeUI } from "../TwoFactorAuthentication";
import notEmptyField from "../../../../../utility/validations/notEmpty";
import { enableTFA, enableTFAId } from "../../../../../store/actions/TFA/enableTFA";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../Hooks/useWorker";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Activar" },
  "en-US": { label: "Activate" },
};

const Activate = () => {
  const { auth, dispatch, settings, UI } = useUIBp();
  const { working } = useWorker(enableTFAId);
  const { error, value: code } = UI[gauthCodeUI] ?? {};
  const locale = settings.locale;

  const onMouseDown = () => {
    dispatch(
      UIactions.validateField({
        id: gauthCodeUI,
        fn: notEmptyField,
        force: true,
        locale,
      })
    );
  };

  const onClick = () => {
    dispatch(
      enableTFA({
        ...auth,
        code,
        locale,
      })
    );
  };

  const disabled = !!error;

  return (
    <Button
      {...{
        disabled,
        startIcon: <Shield />,
        UIText,
        onClick,
        onMouseDown,
        working,
      }}
    />
  );
};

export default Activate;
