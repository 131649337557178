import axios from "axios";
import { workerActions } from "../../slices/workers";
import { warehouseActions } from "../../slices/warehouse";

export const getM365ClientsId = "getM365Clients";
export const m365clientsData = "microsoft365clients";

const getClients = ({ M365server, cloner_key, orgIDs }) => {
  const id = getM365ClientsId;
  console.log("trying to get clients");

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    let clients = [];
    for (const orgId of orgIDs) {
      try {
        const url = `${M365server}mailapi/organization/${orgId}/clients`;
        const response = await axios.get(url, {
          headers: { cloner_key },
        });
        const data = response.data.data;
        clients.push(...data);

      } catch (error) {
        console.error(error);
      }
    }

    dispatch(warehouseActions.load({ id: m365clientsData, data: clients }));
    dispatch(workerActions.finish({ id }));
  };
};

export default getClients;
