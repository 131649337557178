import { Grid2, Typography } from "@mui/material";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../store/slices/UI";
import RadioSelect from "../../../../../../UI/GeneralPurpose/RadioSelect";
import DateTime from "../../../../../../UI/GeneralPurpose/DateTime";
import dayjs from "dayjs";

const UIText = {
  "es-CL": {
    aditionalOptions: "Opciones adicionales",
    includeDeleted: "Incluir archivos eliminados",
    recoverDate: "Fecha especifica",
  },
  "en-US": {
    aditionalOptions: "Additional options",
    includeDeleted: "Include deleted files",
    recoverDate: "Specific date",
  },
};

const UITextDeleted = {
  "es-CL": { label: "Incluir archivos eliminados" },
  "en-US": { label: "Include deleted files" },
};

const UITextDate = {
  "es-CL": { label: "Fecha de recuperacion" },
  "en-US": { label: "Recovery date" },
};

export const aditionalOptionUI = "aditionalOption";
export const recoveryDateUI = "recoveryDate";

const AditionalOptions = ({ id, readOnly }) => {
  const { dispatch, settings, UI } = useUIBp();
  const selected = UI[`${aditionalOptionUI}-${id}`];
  const { aditionalOptions } =
    UIText[settings.locale];

  const onClick = () => {
    dispatch(UIactions.clear(`${recoveryDateUI}-${id}`));
  };

  return (
    <Grid2 container {...{ alignItems: "center", size: 12 }}>
      <Grid2 {...{ size: 12 }}>
        <Typography fontWeight="bold" fontSize="1rem">
          {aditionalOptions}
        </Typography>
      </Grid2>
      <Grid2
        container
        {...{
          size: { xl: 6, lg: 6, md: 12, sm: 12, xs: 12 },
        }}
      >
        <RadioSelect
          allowEmpty
          {...{
            id: `${aditionalOptionUI}-${id}`,
            disabled: readOnly,
            value: "deleted",
            UIText: UITextDeleted,
            onClick,
            labelProps: { noWrap: true },
            size: { xl: "auto", lg: "auto", md: 6, sm: 6, xs: 12 },
          }}
        />
      </Grid2>
      <Grid2
        container
        {...{
          size: { xl: 6, lg: 6, md: 12, sm: 12, xs: 12 },
          columnSpacing: 2,
        }}
      >
        <RadioSelect
          allowEmpty
          {...{
            id: `${aditionalOptionUI}-${id}`,
            disabled: readOnly,
            value: "dated",
            UIText: UITextDate,
            onClick,
            labelProps: { noWrap: true },
            size: { lg: "auto", md: 6, sm: 6, xs: 12 },
          }}
        />
        {selected === "dated" ? (
          <DateTime
            clearOnUnmount
            {...{
              id: `${recoveryDateUI}-${id}`,
              disabled: readOnly,
              clearOnUnmount: true,
              size: "auto",
              maxDateTime: dayjs(),
              UIText: UITextDate,
            }}
          />
        ) : null}
      </Grid2>
    </Grid2>
  );
};

export default AditionalOptions;
