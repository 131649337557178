import { Grid2 } from "@mui/material";
import SlimAlert from "../../UI/GeneralPurpose/SlimAlert";
import { notEmptyName } from "../../../utility/validations/notEmptyName";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import TextField from "../../UI/GeneralPurpose/SimpleTextField";

const UIText1 = {
  "es-CL": { label: "Usuario" },
  "en-US": { label: "User" },
};

const UIText2 = {
  "es-CL": { label: "Nombre" },
  "en-US": { label: "Name" },
};

export const userUI = "userlogin";
export const usernameUI = "username";

const User = () => {
  const { UI } = useUIBp();
  const { error } = UI[usernameUI] ?? {};

  return (
    <Grid2 container {...{ size: 12, columnSpacing: 2, rowGap: 2 }}>
      <TextField
        disabled
        clearOnUnmount
        {...{ id: userUI, UIText: UIText1, size: { sm: 6, xs: 12 } }}
      />
      <TextField
        clearOnUnmount
        {...{
          id: usernameUI,
          UIText: UIText2,
          validation: notEmptyName,
          size: { sm: 6, xs: 12 },
        }}
      />
      <SlimAlert
        {...{
          messages: [error],
          size: 12,
          sx: { paddingLeft: 2 },
        }}
      />
    </Grid2>
  );
};

export default User;
