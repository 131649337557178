import { useLocation } from "react-router-dom";
import DrawerButton from "./DrawerButton";
import { InsertDriveFile } from "@mui/icons-material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": { label: "Archivos" },
  "en-US": { label: "Files" },
};

const Files = () => {
  const { auth } = useUIBp();
  const location = useLocation().pathname.split("/")[2];
  const selected = location === "files";
  const navTo = `/dashboard/files?user=${encodeURIComponent(auth.user)}`;
  const accentColor = selected ? "select.accent" : "primary.light";
  const icon = <InsertDriveFile {...{ sx: { color: accentColor } }} />;

  return <DrawerButton {...{ UIText, selected, navTo, icon, accentColor }} />;
};

export default Files;
