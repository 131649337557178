import { useEffect, useMemo } from "react";
import BasicHeader from "../../../UI/BasicLayout/Components/Header";
import Repositories from "./Components/Repositories/Repositories";
import Summary from "./Components/Summary/Summary";
import {
  aditionalOptionUI,
  recoveryDateUI,
} from "./Components/Repositories/Repository/AditionalOptions";
import { aditionalInfoUI } from "./Components/Repositories/Repository/AditionalDetails";
import { recoverRepoUI } from "./Components/Repositories/Repository/Repository";
import { Card, Grid2 } from "@mui/material";
import Cancel from "./Components/Cancel";
import SaveBtn from "./Components/Save";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../Hooks/useURLQuery";
import useRecoveries from "../../../../Hooks/useRecoveries";
import { UIactions } from "../../../../store/slices/UI";

const UIText = {
  "es-CL": "Opciones avanzadas",
  "en-US": "Advanced options",
};

const AdvancedDeviceOptions = ({ readOnly }) => {
  const { dispatch, settings } = useUIBp();
  const locale = settings.locale;
  const header = UIText[locale];
  const { id } = useURLQuery();
  const recoveriesStore = useRecoveries() ?? {};
  const { recoveries } = recoveriesStore ?? [];
  const recovery = useMemo(() => {
    return recoveries.find((r) => r.id === id) ?? {};
  }, [id, recoveries]);

  useEffect(() => {
    for (const repo of recovery.repositories) {
      dispatch(
        UIactions.setValue({
          id: `${recoverRepoUI}-${repo.id}`,
          value: repo.recover,
        })
      );
      dispatch(
        UIactions.setValue({
          id: `${aditionalOptionUI}-${repo.id}`,
          value: repo.option,
        })
      );
      dispatch(
        UIactions.setField({
          id: `${recoveryDateUI}-${repo.id}`,
          value: repo.bkpDate,
        })
      );
      dispatch(
        UIactions.setField({
          id: `${aditionalInfoUI}-${repo.id}`,
          value: repo.details,
        })
      );
    }
    return () => {
      for (const repo of recovery.repositories) {
        dispatch(UIactions.clear(`${recoverRepoUI}-${repo.id}`));
        dispatch(UIactions.clear(`${aditionalOptionUI}-${repo.id}`));
        dispatch(UIactions.clear(`${recoveryDateUI}-${repo.id}`));
        dispatch(UIactions.clear(`${aditionalInfoUI}-${repo.id}`));
      }
    };
  }, [dispatch, recovery]);

  return (
    <Grid2
      container
      {...{
        sx: {
          padding: "2vh",
          maxHeight: "92vh",
        },
        alignContent: "start",
      }}
    >
      <Grid2 container>
        <Card {...{ sx: { width: "100%" } }}>
          <BasicHeader>{header}</BasicHeader>
          <Grid2
            container
            {...{
              sx: {
                maxHeight: "71vh",
                width: "100%",
                overflow: "scroll",
              },
            }}
          >
            <Summary {...{ recovery, locale }} />
            <Repositories {...{ readOnly, recovery, locale }} />
          </Grid2>
          <Grid2
            {...{
              size: 12,
              sx: { height: "4vh", borderTop: "1px solid lightgrey" },
            }}
          />
        </Card>
        <Grid2
          container
          {...{
            justifyContent: "space-between",
            alignItems: "center",
            sx: { height: "6vh" },
            size:12,
          }}
        >
          <Cancel {...{ readOnly }} />
          {readOnly ? null : <SaveBtn {...recovery} />}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default AdvancedDeviceOptions;
