import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import SingleTwinSelect from "../Utilities/SingleTwinSelect";
import { Grid2, IconButton as MUIIconButton } from "@mui/material";
import { useEffect } from "react";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";

const CheckButton = (props) => {
  const {
    id,
    arrayId = "selected",
    componentSize = "small",
    size,
    fontSize,
    clearOnExit,
  } = props;
  const filteredProps = filterObjKeys(props, "arrayId", "xs", "clearOnExit");
  const { dispatch, UI } = useUIBp();
  const selected = UI[arrayId] ?? [];

  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(UIactions.toggleSingle({ id: arrayId, key: id }));
  };
  const isSelected = typeof selected.find((x) => x === id) !== "undefined";

  const icon = SingleTwinSelect(
    <CheckBoxOutlineBlank sx={{ fontSize: `${fontSize}rem` }} />,
    <CheckBox color="primary" sx={{ fontSize: `${fontSize}rem` }} />,
    isSelected
  );

  useEffect(() => {
    return () => {
      if (clearOnExit) {
        dispatch(UIactions.remove({ id: arrayId, key: id }));
      }
    };
  }, [dispatch, clearOnExit, arrayId, id]);

  return (
    <Grid2 size={size}>
      <MUIIconButton {...filteredProps} {...{ onClick: handleClick, size: componentSize }}>
        {icon}
      </MUIIconButton>
    </Grid2>
  );
};

export default CheckButton;
