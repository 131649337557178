import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { CustomError } from "../../../utility/misc/response";
import { worker } from "../../../store/slices/workers";
import { authActions } from "../../../store/slices/authentication";

const UIText = {
  "es-CL": { badAuth: "Codigo de autentificación erroneo" },
  "en-US": { badAuth: "Bad authentication code" },
};

export const gauthWrkID = "gauth";
const director = "https://login.cloner.cl/api/server";
export const M365server = "https://c365.cloner.cl:4022/";
export const Dev365server = "https://devel.cloner.cl:4022/";

const loginWithGAuth = ({ user, code, locale }) => {
  const id = gauthWrkID;

  return async (dispatch) => {
    dispatch(worker.start(id));
    const { badAuth } = UIText[locale];
    try {
      // GET login server
      const url = director;
      const response = await axios.get(url, { params: { login: user } });
      const loginServer = response.data;
      const payload = { user, mode: 1, has_tfa: 2, code, server: loginServer };
      const bodyData = JSON.stringify(payload);

      const url2 = loginServer + "api/user/twofactor";
      const response2 = await axios.post(url2, bodyData);

      if (response2.data.resp === 9) {
        // Bad auth code
        throw CustomError(badAuth, "ERR_BAD_GAUTH");
      } else if (response2.data.resp === 0) {
        // Auth Ok, proceding with regular login
        const url2 = loginServer + "api/groups";
        const response3 = await axios.get(url2, {
          params: { group: response2.data.group },
          headers: { cloner_key: response2.data.token },
        });
        const groupName = response3.data.name;

        let server;
        if (loginServer === "https://zifre.cloner.cl/") {
          server = M365server;
        } else {
          server = Dev365server;
        }
        const url = `${server}mailapi/login`;

        const response4 = await axios.post(url, bodyData, {
          headers: { cloner_key: response2.data.token },
        });

        dispatch(
          authActions.setAuthentication({
            data: { ...response2.data, groupName },
            server: loginServer,
          })
        );
        dispatch(authActions.setO365Data({ ...response4.data, server }));
      }
      dispatch(worker.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_BAD_GAUTH") {
        enqueueSnackbar(badAuth, { variant: "error" });
      } else {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};

export default loginWithGAuth;
