import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Grid2, Typography } from "@mui/material";
import React from "react";
import { defaultSort } from "./genericIDs";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";

// const UIText = {
//   'es-CL':{
//     label:''
//   }
// }

const SortHeader = ({
  id = defaultSort,
  filterKey: key,
  size,
  UIText = {},
  fontSize = 14,
  justify = "start",
  disabled,
}) => {
  const { dispatch, UI, settings } = useUIBp();
  const sort = UI[id] ?? { value: "" };

  const { label } = UIText[settings.locale] ?? UIText.universal;

  let icon;
  if (sort.descending) {
    icon = <ArrowDropUp sx={{ fontSize: fontSize * 1.5 }} />;
  } else {
    icon = <ArrowDropDown sx={{ fontSize: fontSize * 1.5 }} />;
  }

  const handleToggle = () => {
    dispatch(UIactions.toggleSort({ id, key }));
  };

  return (
    <Grid2
      container
      {...{
        alignItems: "center",
        wrap: "nowrap",
        onClick: !disabled ? handleToggle : undefined,
        size,
        sx: { cursor: key ? "pointer" : undefined },
        justifyContent: justify,
      }}
    >
      {justify === "center" ? (
        <Grid2
          container
          {...{
            size: "grow",
            alignItems: "center",
          }}
        ></Grid2>
      ) : null}
      <Grid2
        container
        {...{
          size: "auto",
          alignItems: "center",
        }}
      >
        <Typography
          {...{
            fontSize,
            sx: { cursor: key ? "pointer" : "default" },
            fontWeight: "bold",
          }}
        >
          {label}
        </Typography>
      </Grid2>
      <Grid2
        container
        {...{
          size: justify === "end" ? "auto" : "grow",
          alignItems: "center",
          sx: {
            visibility: key === sort.value ? undefined : "hidden",
          },
        }}
      >
        {disabled ? null : icon}
      </Grid2>
    </Grid2>
  );
};

export default SortHeader;
