import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../slices/workers";

const UIText = {
  "es-CL": {
    success: "Dispositivos eliminados exitosamente",
    error: "Error al intentar eliminar el dispositivo ",
    start: "Eliminando dispositivos...",
  },
  "en-US": {
    success: "Devices deleted successfully",
    error: "Error trying to delete the device ",
    start: "Deleting devices...",
  },
};

export const deleteDevicesId = "deleteDevices";

const deleteDevices = ({
  server,
  cloner_key,
  user: admin,
  devices = [],
  locale,
}) => {
  const id = deleteDevicesId;
  const { success, error, start } = UIText[locale];
  console.log("Deleting devices");

  return async (dispatch) => {
    dispatch(workerActions.start(deleteDevicesId));
    enqueueSnackbar(start, { variant: "info" });
    let currentOp = {};
    try {

      // Iterate over devices to delete them
      for (const device of devices) {
        const { mid, user, name } = device;
        let currentOp = { ...device };
        console.log(`Deleting device ${name}`);

        // Get repositories of the device
        const url = server + "api/repositories";
        const response = await axios.get(url, {
          headers: { cloner_key },
          params: { id: mid, user },
        });
        const repositories = response.data.repositories;

        // Iterate over repositories to delete them
        for (const repository of repositories) {
          console.log(`Deleting repository ${repository.id}`);
          currentOp.repo = repository.id;
          const { id } = repository;

          // Delete repository
          const url = server + "api/repository";
          await axios.delete(url, { headers: { cloner_key }, params: { id } });
          const bodyData = JSON.stringify({
            machine: mid,
            user,
            status: `equipo eliminado por ${admin}`,
          });
          console.log("Updating device status");

          // Update status of the device
          const url2 = server + "api/service/status";
          await axios.post(url2, bodyData, {
            headers: { "Content-Type": "application/json", cloner_key },
          });
        }
      }
      dispatch(workerActions.finish({ id }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      console.log("failed operation:", currentOp);
      dispatch(workerActions.finish({ id, error: e.messsage }));
      enqueueSnackbar(error + e.messsage, {
        variant: "error",
      });
    }
  };
};

export default deleteDevices;
