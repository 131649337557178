import { Grid2 } from "@mui/material";
import FilterField from "../../../GeneralPurpose/FilterField";
import useVH from "../../../../../Hooks/useVH";
import RecursiveSwitch from "./Recursive";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { viewmodeUI } from "../../../../../App";

const UIText = {
  "es-CL": { placeholder: "Filtrar" },
  "en-US": { placeholder: "Filter" },
};

const FilterPlusActions = ({ children, plusActionsGrid, noRecursive }) => {
  const { UI } = useUIBp();
  const viewmode = UI[viewmodeUI] ?? "desktop";
  const vh = useVH(8);
  const vh2 = useVH(10);

  return (
    <Grid2
      container
      {...{
        height: viewmode === "desktop" ? vh : vh2,
        alignItems: "center",
        size: 12,
        columnSpacing: 2,
        rowGap: 1,
        paddingTop: "1vh",
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      <Grid2 container size={plusActionsGrid}>
        <Grid2 container {...{ size: "auto" }}>
          <FilterField fullWidth {...{ UIText, size: 12 }} />
        </Grid2>
        {noRecursive ? null : <RecursiveSwitch {...{ size: "auto" }} />}
      </Grid2>
      {children}
    </Grid2>
  );
};

export default FilterPlusActions;
