// import { w365orgsData } from "../../../../store/actions/microsoft365/getOrganizations";
import { arrayId } from "../../UsersList/UsersList";
// import {
//   validateMailId,
//   validateW365Mails,
// } from "../../../../store/actions2/Windows365/validateW365Mails";
import { mailLocksId } from "../../UsersList/UserRow/M365mail";
import { useMemo } from "react";
import { postClientsId } from "../../../../../../../store/actions/microsoft365/postClients";
// import { suggestMailId } from "../../../../store/actions2/Windows365/suggestW365Mails";
import { deleteClientsId } from "../../../../../../../store/actions/microsoft365/deleteClients";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import validateM365Mails, { validateMailId } from "../../../../../../../store/actions/microsoft365/validateM365Mails";
import { suggestMailId } from "../../../../../../../store/actions/microsoft365/suggestM365Mails";
import Button from "../../../../../../UI/GeneralPurpose/Button";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import { m365orgsData } from "../../../../../../../store/actions/microsoft365/getOrganizations";

const UITextUpload = {
  "es-CL": {
    label: "Verificar correo",
    fixedTooltip: "Verifica que el correo para Windows 365 es valido",
  },
  "en-US": {
    label: "Verify mail",
    fixedTooltip: "Verify that the email for Windows 365 is valid",
  },
};

export const newUsersUI = "newUsers";

const VerifyMail = ({ users }) => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBp();
  const { M365server, cloner_key } = auth;
  const { working } = useWorker(validateMailId);
  const { working: w1 } = useWorker(postClientsId);
  const { working: w2 } = useWorker(suggestMailId);
  const { working: w3 } = useWorker(deleteClientsId);
  const { locale } = settings;
  const selectedUsers = useMemo(()=>UI[arrayId] ?? [], [UI]);
  const lockedMails = useMemo(()=>UI[mailLocksId] ?? [], [UI]);

  let payload = useMemo(() => {
    let tryouts = [];

    for (const user of selectedUsers) {
      let skipUser = false;
      for (const locked of lockedMails) {
        if (locked === user) {
          skipUser = true;
          break;
        }
      }
      if (skipUser) continue;

      let mailField = UI["365mail-" + user] ?? {};
      let mail = mailField.value ?? "";

      console.log("vmail users", users);

      for (const user2 of users) {
        if (user2.login === user ) {
          tryouts.push({
            login: user2.login,
            mail,
            org: warehouse[m365orgsData][user2.group],
          });
        }
      }
    }
    return tryouts;
  }, [lockedMails, selectedUsers, users, UI, warehouse]);

  const handleVerify = (e) => {
    dispatch(
      validateM365Mails({ tryouts: payload, cloner_key, M365server, locale })
    );
  };

  return (
    <Button
      {...{
        disabled:
          selectedUsers.length === 0 || payload.length === 0 || w1 || w2 || w3,
        UIText: UITextUpload,
        onClick: handleVerify,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
      }}
    />
  );
};

export default VerifyMail;
