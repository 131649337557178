import {
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { humanBytes } from "../../../../../utility/misc/humanizers";

const UIText = {
  "es-CL": {
    key: "Llave",
    value: "Valor",
    repos: "Repositorios",
    device: "Dispositivo",
    name: "Nombre",
    repoId: "repoID",
    size: "Tamaño",
    rawSize: "Tamaño bruto",
    bkp: "Ultimo respaldo",
  },
  "en-US": {
    key: "Key",
    value: "Value",
    repos: "Repositories",
    device: "Devices",
    name: "Name",
    repoId: "repoID",
    size: "Size",
    rawSize: "Raw size",
    bkp: "Ultimo respaldo",
  },
};

const Data = ({ data, locale, repositories }) => {
  const { key, value, repos, device, name, repoId, size, rawSize, bkp } =
    UIText[locale];
  const trimmedData = {
    name: data.name,
    version: data.version,
    mid: data.mid,
    size: data.space_active,
    human_size: humanBytes(data.space_active),
  };

  let rows = [];
  for (const key in trimmedData) {
    rows.push(
      <TableRow key={key}>
        <TableCell>{key}</TableCell>
        <TableCell sx={{ wordBreak: "break-all" }}>
          {trimmedData[key]}
        </TableCell>
      </TableRow>
    );
  }

  let rows2 = [];
  for (const repo of repositories) {
    rows2.push(
      <TableRow key={repo.id} sx={{ wordBreak: "break-all" }}>
        <TableCell>{repo.name}</TableCell>
        <TableCell>{repo.id}</TableCell>
        <TableCell>{humanBytes(repo.active_space)}</TableCell>
        <TableCell>{repo.active_space}</TableCell>
        <TableCell>
          {new Date(repo.last_backup).toLocaleString(locale)}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Grid2 container rowGap={2}>
      <Grid2 container size={12}>
        <Typography fontSize={18} fontWeight="bold">
          {device}
        </Typography>
      </Grid2>
      <Grid2 container size={12}>
        <TableContainer sx={{ overflow: "auto" }}>
          <Table sx={{ width: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ wordWrap: "break-word" }}>
                <TableCell>{key}</TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Grid2>
      <Grid2 container size={12}>
        <Typography fontSize={18} fontWeight="bold">
          {repos}
        </Typography>
      </Grid2>
      <Grid2 container size={12}>
        <TableContainer sx={{ overflow: "auto" }}>
          <Table sx={{ width: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{name}</TableCell>
                <TableCell>{repoId}</TableCell>
                <TableCell>{size}</TableCell>
                <TableCell>{rawSize}</TableCell>
                <TableCell>{bkp}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows2}</TableBody>
          </Table>
        </TableContainer>
      </Grid2>
    </Grid2>
  );
};

export default Data;
