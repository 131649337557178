import { CircularProgress, Grid2 } from "@mui/material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const QRScan = () => {
  const { warehouse } = useUIBp();
  const { QR } = warehouse;

  const src = "data:image/png;base64," + QR;
  return (
    <Grid2
      container
      {...{
        size: 12,
        justifyContent: "center",
        alignItems: "start",
        height: 300,
      }}
    >
      <Grid2
        container
        {...{
          size: { xs: "auto" },
          sx: { height: "100%" },
          alignItems: "center",
        }}
      >
        {QR ? (
          <img
            ng-src={src}
            src={src}
            width="100%"
            height="100%"
            alt="placeholder"
          />
        ) : (
          <CircularProgress size={60} />
        )}
      </Grid2>
    </Grid2>
  );
};

export default QRScan;
