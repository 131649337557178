import { Help, HelpOutline } from "@mui/icons-material";
import { Grid2, IconButton, Tooltip } from "@mui/material";
import { UIactions } from "../../../../../store/slices/UI";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import SingleTwinSelect from "../../../../UI/Utilities/SingleTwinSelect";

const UIText = {
  "es-CL": {
    tooltip1: "Activar modo de ayuda",
    tooltip2: "Desactivar modo de ayuda",
  },
  "en-US": {
    tooltip1: "Activate help mode",
    tooltip2: "Deactivate help mode",
  },
};

const icon1 = <HelpOutline />;
const icon2 = <Help />;

const HelpMode = () => {
  const { dispatch, UI, settings } = useUIBp();
  const selected = UI["helpMode"] ?? false;
  const locale = settings.locale

  const { tooltip1, tooltip2 } = UIText[locale];

  const handleClick = () => {
    dispatch(UIactions.toggle("helpMode"));
  };

  let finalIcon = SingleTwinSelect(icon1, icon2, selected);
  let tooltip = SingleTwinSelect(tooltip1, tooltip2, selected);

  return (
    <Grid2 container>
      <Tooltip title={tooltip}>
        <IconButton {...{ onClick: handleClick, sx: { color: "secondary.light" } }}>
          {finalIcon}
        </IconButton>
      </Tooltip>
    </Grid2>
  );
};

export default HelpMode;
