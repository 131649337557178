import Button from "../../../../UI/GeneralPurpose/Button";
import { Telegram } from "@mui/icons-material";
import {
  contactEmailUI,
  detailsUI,
  requirementsUI,
  supportFocusUI,
} from "../Content/Content";
import notEmptyUser from "../../../../../utility/validations/notEmptyUser";
import notEmptyField from "../../../../../utility/validations/notEmpty";
import { about, requirements } from "../Content/options";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../../../Hooks/useWorker";
import { UIactions } from "../../../../../store/slices/UI";
import { postSupportId, postSupportTicket } from "../../../Actions/postSupportTicket";

const UIText = {
  "es-CL": { label: "Enviar" },
  "en-US": { label: "Send" },
};

const Send = () => {
  const { auth, dispatch, settings, UI } = useUIBp();
  const { working } = useWorker(postSupportId);
  const { value: contact, error: e1 } = UI[contactEmailUI] ?? {};
  const { value: details, error: e2 } = UI[detailsUI] ?? {};
  const type = UI[requirementsUI] ?? requirements[0].value;
  const scope = UI[supportFocusUI] ?? about[0].value;
  const locale = settings.locale;

  const onMouseDown = () => {
    dispatch(
      UIactions.validateField({
        id: contactEmailUI,
        fn: notEmptyUser,
        force: true,
        locale,
      })
    );
    dispatch(
      UIactions.validateField({
        id: detailsUI,
        fn: notEmptyField,
        force: true,
        locale,
      })
    );
  };

  const onClick = () => {
    dispatch(
      postSupportTicket({
        ...auth,
        contact,
        details,
        type,
        scope,
        navigator: navigator.userAgent,
        locale,
      })
    );
  };

  const disabled = !!e1 || !!e2;

  return (
    <Button
      {...{ working,disabled, startIcon: <Telegram />, UIText, onClick, onMouseDown }}
    />
  );
};

export default Send;
