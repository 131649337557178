import { Grid2, Divider as MUIDivider } from "@mui/material";

const Divider = ({ size = 12, color }) => {

  return (
    <Grid2 {...{ size }}>
      <MUIDivider {...{ sx: { bgcolor: color } }} />
    </Grid2>
  );
};

export default Divider;
