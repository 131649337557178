import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workerActions } from "../../../store/slices/workers";

const UIText = {
  "es-CL": {
    success: "Ticket de soporte enviado exitosamente!",
    error: "Error al enviar ticker de soporte: ",
  },
  "en-US": {
    success: "Support ticket successfully sent!",
    error: "Error sending support ticket: ",
  },
};

export const postSupportId = "postSupport";

export const postSupportTicket = ({
  server,
  cloner_key,
  user,
  contact,
  navigator,
  type,
  scope,
  details,
  locale,
}) => {
  const id = postSupportId;
  const { success, error } = UIText[locale];

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      const url = server + "api/support";
      const bodyData = JSON.stringify({
        msg: `Usuario: ${user}\nCorreo de contacto: ${contact}\nNavegador: ${navigator}\nTipo de requerimiento: ${type}\nAlcance del requerimiento: ${scope}\n============== Detalles Adicionales ==============\n${details}`,
      });
      await axios.post(url, bodyData, { headers: { cloner_key } });

      dispatch(workerActions.finish({ id }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
      enqueueSnackbar(error + e.message, { variant: "error" });
    }
  };
};
