import { Grid2 } from "@mui/material";
import { default as LocaleMenu } from "../../../Dashboard/Components/TopBar/Buttons/Locale";

const Locale = () => {
  return (
    <Grid2 container size={12} padding={2} justifyContent="end">
      <LocaleMenu />
    </Grid2>
  );
};

export default Locale;
