import { Grid2, Typography } from "@mui/material";
import CheckButton from "../../../../../../UI/Specialized/Check";
import { useEffect } from "react";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../store/slices/UI";

const UIText = {
  "en-US": { mails: "mails", },
  "es-CL": { mails: "correos", },
};

export const bkpMailArrayId = "bkpMail";
export const bkpMailCountId = "bkpMailCount-";

const BackupMail = ({ login }) => {
  const { dispatch,UI, settings } = useUIBp();
  const mailsCount = UI[bkpMailCountId + login] ?? 0;
  const { mails } = UIText[settings.locale];

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(bkpMailCountId + login));
    };
  },[dispatch, login]);

  return (
    <Grid2
      container
      {...{ size: 12, alignItems: "center" }}
    >
      <CheckButton
        {...{
          id: login,
          arrayId: bkpMailArrayId,
          size: "auto",
          fontSize: 1,
          clearOnExit: true,
        }}
      />
      <Grid2 container  {...{ size: "auto", justifyContent: "center" }}>
        <Typography sx={{ fontSize: "0.8rem" }}>
          {mailsCount ?`(${mailsCount} ${mails})`:null}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default BackupMail;
