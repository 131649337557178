import { Dialog } from "@mui/material";
import Cancel from "./Components/Cancel";
import Confirm from "./Components/Confirm";
import { useDispatch } from "react-redux";
import { useWorker } from "../../../../Hooks/useWorker";
import { UIactions } from "../../../../store/slices/UI";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import SlimAlert from "../../../UI/GeneralPurpose/SlimAlert";

const DialogConfirmation = (
  {
    header,
    message,
    severity,
    confirmation = () => {
      console.log("no confirmation fn()");
    },
    alternativeText,
    workerId,
  }
) => {
  const dispatch = useDispatch();
  const { working } = useWorker(workerId);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <SlimAlert notCollapse {...{ errors: [message], severity }} />
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel {...{ working }} />
        <Confirm {...{ confirmation, alternativeText, working }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
