import { Grid2 } from "@mui/material";
import CheckButton from "../../../UI/Specialized/Check";
import { defaultSelected } from "../../GeneralPurpose/genericIDs";
import useVH from "../../../../Hooks/useVH";
import useUIBp from "../../../../Hooks/useUIBoilerplate";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };

const BasicRow = ({
  id,
  disabled,
  elements = [],
  arrayId = defaultSelected,
  error,
  unbindHeight
}) => {
  const { UI } = useUIBp();
  const height1 = useVH(6);
  const height2 = useVH(2);
  const selectedArray = UI[arrayId] ?? [];
  const selected = selectedArray.find((x) => x === id);

  let backgroundColor;
  if (error) {
    backgroundColor = "rgba(255, 0, 0, 0.1)";
  } else if (selected) {
    backgroundColor = "rgba(0, 167, 132, 0.1)";
  }

  const gridProps = {
    alignItems: "center",
    columnSpacing: 1,
    size:12,
    wrap: "nowrap",
    sx: {
      fontSize: 20,
      height: !unbindHeight? height1:undefined,
      paddingLeft: height2,
      paddingRight: height2,
      borderBottom: "1px solid lightGrey",
      backgroundColor,
    },
  };

  const columns = elements.map((data, idx) => {
    const { size, content, justify } = data;
    return (
      <Grid2
        container
        key={idx}
        {...{
          size,
          justifyContent: justify,
          wrap: "nowrap",
        }}
      >
        {content}
      </Grid2>
    );
  });

  return (
    <Grid2 container {...gridProps}>
      {id || disabled ? (
        <CheckButton
          {...{
            id,
            arrayId,
            disabled,
            fontSize: 1,
            size: "auto",
          }}
        />
      ) : null}
      {columns}
    </Grid2>
  );
};

export default BasicRow;
