import axios from "axios";
import { warehouseActions } from "../../slices/warehouse";
import { workerActions } from "../../slices/workers";

export const versionsDataId = "versionsData";
export const getVersionsId = "getVersions";

const loadVersions = ({ server, file, repo, cloner_key }) => {
  const id = getVersionsId;
  console.log("Getting versions!");

  return async (dispatch) => {
    dispatch(workerActions.start(getVersionsId));
    try {
      // GET group data
      const url = server + "api/fileshistory";
      const response = await axios.get(url, {
        params: { id: file, repoid: repo },
        headers: { cloner_key },
      });

      // Filling the warehouse with the files version (starting from the oldest ones)
      const data = [...response.data.files].reverse();
      dispatch(warehouseActions.load({ id: versionsDataId, data }));

      dispatch(workerActions.finish({ id }));
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
    }
  };
};

export default loadVersions;
