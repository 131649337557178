import { Grid2, Typography } from "@mui/material";
import Breadcrumbs from "./Breadcrumbs";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const UIText2 = {
  "es-CL": { header: "Archivos" },
  "en-US": { header: "Files" },
};

export const filesFilterUI = "filesFilter";

const Header = () => {
  const { settings } = useUIBp();
  const { header } = UIText2[settings.locale];

  return (
    <Grid2
      container
      {...{
        columnGap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        padding: 0.4,
        paddingLeft: 2,
        size: 12,
      }}
    >
      <Typography fontSize={20}>{header}</Typography>
      <Breadcrumbs />
    </Grid2>
  );
};

export default Header;
