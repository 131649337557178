import { Grid2 } from "@mui/material";
import CheckButton from "../../Specialized/Check";
import useVH from "../../../../Hooks/useVH";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };

const ClickableRow = ({ id, disabled, elements = [], onClick = () => {} }) => {
  const click = (e) => {
    onClick();
  };

  const height1 = useVH(6);
  const height2 = useVH(2);

  const gridProps = {
    alignItems: "center",
    columnSpacing: 1,
    size: 12,
    wrap: "nowrap",
    onClick: click,
    sx: {
      fontSize: 20,
      height: height1,
      paddingLeft: height2,
      paddingRight: height2,
      borderBottom: "1px solid lightGrey",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0, 167, 132,0.1)",
      },
    },
  };

  const columns = elements.map((data, idx) => {
    const { size, content, justify } = data;
    return (
      <Grid2
        container
        key={idx}
        {...{
          size,
          justifyContent: justify,
          wrap: "nowrap",
        }}
      >
        {content}
      </Grid2>
    );
  });

  return (
    <Grid2 container {...gridProps}>
      {id || disabled ? (
        <CheckButton
          {...{
            id,
            disabled,
            fontSize: 1,
            size: "auto",
          }}
        />
      ) : null}
      {columns}
    </Grid2>
  );
};

export default ClickableRow;
