import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { reloadUI } from "../../../components/GroupsTraveler/GroupsTraveler";
import { workerActions } from "../../slices/workers";
import { UIactions } from "../../slices/UI";

const UIText = {
  "es-CL": {
    success: "Grupo guardado exitosamente",
    error: "Error al guardar grupo: ",
  },
  "en-US": {
    success: "Group successfully saved",
    error: "Error saving group: ",
  },
};

export const postGroupId = "postGroup";

export const postGroup = ({ server, cloner_key, payload, locale }) => {
  console.log("posting group data!");

  return async (dispatch) => {
    const { success, error } = UIText[locale];
    const id = postGroupId;
    dispatch(workerActions.start(id));
    try {
      const bodyData = JSON.stringify(payload);
      const url = server + "api/groups";
      await axios.post(url, bodyData, { headers: { cloner_key } });
      dispatch(workerActions.finish({ id }));
      dispatch(UIactions.setValue({ id: reloadUI, value: true }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      dispatch(workerActions.finish({ id, error: e.message }));
      const message = error + e.message;
      enqueueSnackbar(message, { variant: "error" });
    }
  };
};
