import { enqueueSnackbar } from "notistack";
import axios from "axios";
import { workerActions } from "../../../../../store/slices/workers";

const UIText = {
  "es-CL": { success: "Exclusiones actualizadas", error: "Error actualizando las exclusiones" },
  "en-US": { success: "Exclusions updated", error: "Error updating exclusions" },
};

export const updateExclusionsUI = "updateExclusions";

const updateExclusions = ({ server, cloner_key, updateData, locale }) => {
  const { success, error } = UIText[locale];
  const id = updateExclusionsUI;

  return async (dispatch) => {
    dispatch(workerActions.start(id));
    try {
      for (const data of updateData) {
        const bodyData = JSON.stringify(data);
        const url = server + "api/user/exclusions/set_exclusions";
        await axios.post(url, bodyData, { headers: { cloner_key } });
      }
      dispatch(workerActions.finish({ id }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workerActions.finish({ id, error: e.message }));
      enqueueSnackbar(error + e.message, { variant: "error" });
    }
  };
};

export default updateExclusions;
