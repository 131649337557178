import { Edit } from "@mui/icons-material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { usersDataId } from "../../../../../../store/actions/users/getAllUsers";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import { UIactions } from "../../../../../../store/slices/UI";
import ActionButton from "../../../../../UI/BasicLayout/Components/FilterPlusActions/ActionButton";

const UIText = {
  "es-CL": { label: "Editar" },
  "en-US": { label: "Edit" },
};
const EditUsers = () => {
  const { dispatch, UI, warehouse } = useUIBp();
  const { group } = useURLQuery();
  const usersData = warehouse[usersDataId]??[]
  const selected = UI.selected ?? [];

  let selectedUsers = []
  for (const user of selected){
    const userData = usersData.find(x=> x.login === user)
    selectedUsers.push(userData)
  }

  const onClick = () => {
    dispatch(
      UIactions.openModal({
        type: "edit-user",
        payload: { group, users: selectedUsers },
      })
    );
  };

  return (
    <ActionButton
      {...{ disabled: !selected.length, onClick, UIText, icon: <Edit /> }}
    />
  );
};

export default EditUsers;
