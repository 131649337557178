import { Grid2 } from "@mui/material";
import CheckButton from "../../../UI/Specialized/CheckAll";
import SortHeader from "../../GeneralPurpose/SortHeader";
import useVH from "../../../../Hooks/useVH";

// EXAMPLE headers
//   { sourceId:'',
//     warehouseId:'',
//     headers:[{
//      UIText: data.UIText, ===> {'locale':{label:''}}
//      filterKey: data.filterKey,
//      xs: data.xs,
//      justify: data.justify,
//     }]
//   }}

const BasicHeaders = ({
  sortId,
  arrayId,
  sourceId,
  warehouseId,
  headers = [],
  fullArray,
}) => {
  const height1 = useVH(6);
  const height2 = useVH(2);

  const gridProps = {
    alignItems: "center",
    columnSpacing: 1,
    size: 12,
    wrap: "nowrap",
    sx: {
      height: height1,
      paddingLeft: height2,
      paddingRight: height2,
      borderBottom: "1px solid lightGrey",
    },
  };

  const columns = headers.map((data, idx) => (
    <SortHeader
      key={idx}
      {...{
        id: sortId,
        UIText: data.UIText,
        filterKey: data.filterKey,
        size: data.size,
        disabled: !data.filterKey,
        justify: data.justify,
      }}
    />
  ));

  return (
    <Grid2
      container
      {...{
        sx: {
          height: height1,
          paddingLeft: height2,
          paddingRight: height2,
        },
        size: 12,
      }}
    >
      <Grid2 container {...gridProps}>
        {sourceId || warehouseId || fullArray ? (
          <CheckButton
            {...{
              arrayId,
              sourceId,
              warehouseId,
              fullArray,
              fontSize: 1,
              size: "auto",
            }}
          />
        ) : null}
        {columns}
      </Grid2>
    </Grid2>
  );
};

export default BasicHeaders;
