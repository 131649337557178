import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import Close from "./Components/Cerrar";
import { Dialog, Grid2 } from "@mui/material";
import Windows365, { w365switchUI } from "./Components/Windows365/Windows365";
import { useEffect } from "react";
import {
  getOrganization,
  getM365OrgId,
  m365orgData,
} from "../../../../store/actions/microsoft365/getOrganization";
import SlimAlert from "../../../UI/GeneralPurpose/SlimAlert";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../Hooks/useURLQuery";
import { useWorker } from "../../../../Hooks/useWorker";
import { UIactions } from "../../../../store/slices/UI";
import { warehouseActions } from "../../../../store/slices/warehouse";
import { workerActions } from "../../../../store/slices/workers";

const UIText = {
  "es-CL": {
    header: "Respaldo de nubes",
    message:
      "Las credenciales utilizadas para el servicio de Microsoft 365 solo aplican a los usuarios directos del grupo, lo que excluye los usuarios pertenecientes a subgrupos del mismo. Para otorgarle acceso a los usuarios de subgrupos es necesario activar la opción 'Compartir' en el grupo padre y la opción 'Obtener' en los grupos hijos.",
  },
  "en-US": {
    header: "Clouds backup",
    message:
      "The credentials used to enable Microsoft 365 credentials only work for users belonging directly to the group, this excludes any user inside children groups. To grant access to subgroup users the 'Share' option must be enabled, as well as the 'Obtain' in any subgroup.",
  },
};

const OrgCloudBackup = () => {
  const { auth, dispatch, settings } = useUIBp();
  const { group } = useURLQuery();
  const { done } = useWorker(getM365OrgId);

  const { header, message } = UIText[settings.locale];

  useEffect(() => {
    if (!done) {
      console.log("getOrganization dispatched");
      dispatch(
        getOrganization({
          ...auth,
          groupId: group,
        })
      );
    }
  }, [auth, dispatch, group,done]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(w365switchUI));
      dispatch(workerActions.clear(getM365OrgId));
      dispatch(warehouseActions.unload(m365orgData));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    dispatch(UIactions.closeModal());
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "md" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2 container {...{ rowGap: 2, sx: { width: "100%" } }}>
          <SlimAlert notCollapse {...{ errors: [message], size: 12, severity:'info' }} />
          <Windows365 />
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
      </BasicDialogActions>
    </Dialog>
  );
};

export default OrgCloudBackup;
