import { Grid2, Typography } from "@mui/material";
import { Fragment } from "react";
import { humanBandwidth } from "../../../../../../utility/misc/humanizers";

const UIText = {
  "es-CL": { label: "Velocidad máxima de subida" },
  "en-US": { label: "Maximum upload speed" },
};

const Bandwidth = ({ throttling, network_packet_size, locale }) => {
  const { label } = UIText[locale];

  return (
    <Fragment>
      <Grid2 container size={6}>
        <Typography>{label}</Typography>
      </Grid2>
      <Grid2 container size={6}>
        <Typography>
          {throttling ? humanBandwidth(network_packet_size) : "Ilimitada"}
        </Typography>
      </Grid2>
    </Fragment>
  );
};

export default Bandwidth;
