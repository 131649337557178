import { Avatar, Grid2, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import Share, { w365shareUI } from "./Share";
import GroupLock, { w365fetchUI } from "./GroupLock";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import { useWorker } from "../../../../../../Hooks/useWorker";
import { UIactions } from "../../../../../../store/slices/UI";
import Switch from "../../../../../UI/GeneralPurpose/Switch";
import {
  getM365OrgId,
  m365orgData,
} from "../../../../../../store/actions/microsoft365/getOrganization";

const UIText = {
  "es-CL": "Sin credenciales disponibles",
  "en-US": "No credentials available",
};

export const w365switchUI = "w365active";

const Windows365 = () => {
  const { auth, dispatch, warehouse, settings } = useUIBp();
  const { group } = useURLQuery();
  const { working } = useWorker(getM365OrgId);
  const { done } = useWorker(getM365OrgId);
  const noCreds = UIText[settings.locale];
  const w365org = warehouse[m365orgData] ?? {};

  const own = w365org.own ?? {};
  const parent = w365org.parent ?? {};

  const isRoot = parseInt(group) === auth.group;
  const ownCert = parent.id === own.id ? parent.client_id : null;
  const domain = parent.mail_account ? parent.mail_account.split("@")[1] : null;

  useEffect(() => {
    dispatch(
      UIactions.setValue({ id: w365switchUI, value: isRoot && ownCert })
    );
    if (own.id) {
      dispatch(
        UIactions.setValue({
          id: w365shareUI,
          value: !own.prevent_propagation,
        })
      );
      dispatch(
        UIactions.setValue({
          id: w365fetchUI,
          value: !own.group_locked,
        })
      );
    }

    if (auth.group !== parseInt(group)) {
      dispatch(
        UIactions.setValue({
          id: "ph2",
          value: ownCert ? !own.group_locked : false,
        })
      );
    }

    return () => {
      dispatch(UIactions.clear(w365switchUI));
      dispatch(UIactions.clear(w365shareUI));
      dispatch(UIactions.clear(w365fetchUI));
    };
  }, [
    dispatch,
    auth,
    isRoot,
    ownCert,
    group,
    own.prevent_propagation,
    own.group_locked,
    own.id,
  ]);

  const onChange = (value) => {
    if (value) {
      dispatch(UIactions.openModal2({ type: "get-m365code", payload: {} }));
    } else {
      dispatch(
        UIactions.openModal2({
          type: "m365-org-delete",
          payload: { orgId: own.id },
        })
      );
    }
  };

  return (
    <Fragment>
      <Grid2 container {...{ size: 12, justifyContent: "space-between" }}>
        <Grid2
          container
          {...{ alignItems: "center", columnSpacing: 2, size: "grow" }}
        >
          {auth.group === parseInt(group) ? (
            <Grid2 container {...{ size: 2, justifyContent: "center" }}>
              <Switch
                disableDefault
                noLabel
                {...{
                  working,
                  id: w365switchUI,
                  onChange,
                  UIText: { "es-CL": {}, "en-US": {} },
                }}
              />
            </Grid2>
          ) : null}
          <Grid2
            container
            {...{
              size: auth.group === parseInt(group) ? 10 : 12,
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Avatar
              alt="Windows365-icon"
              src="https://upload.wikimedia.org/wikipedia/commons/0/0e/Microsoft_365_%282022%29.svg"
              variant="rounded"
              sx={{ height: 30, width: 27.2 }}
            />
            <Typography fontSize={18} fontWeight="bold">
              Windows 365:
            </Typography>
            <Typography fontSize={18}>
              {done ? (domain ? domain : noCreds) : ""}
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2
          container
          {...{ alignItems: "center", columnGap: 2, size: "auto" }}
        >
          {auth.group !== parseInt(group) ? (
            <GroupLock {...{ domain, own, working }} />
          ) : null}
          <Share {...{ domain, own, working }} />
        </Grid2>
      </Grid2>
    </Fragment>
  );
};

export default Windows365;
