import { Grid2, Typography } from "@mui/material";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const Top = () => {
  const { settings } = useUIBp();

  const UIText = {
    "es-CL": { header: "Modificar exclusiones" },
    "en-US": { header: "Modify exclusions" },
  };

  const { header } = UIText[settings.locale];

  return (
    <Grid2
      container
      {...{
        padding: 1,
        paddingLeft: 2,
        paddingRight: 2,
        size: 12,
        columnGap: 2,
        alignItems: "center",
      }}
    >
      <Typography fontSize={22}>{header}</Typography>
    </Grid2>
  );
};

export default Top;
