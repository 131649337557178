import { Grid2 } from "@mui/material";
import Interval from "./Fields/Interval";
import StealthMode from "./Fields/StealthMode";
import Bandwidth from "./Fields/Bandwidth";
import SelectionBlock from "./Fields/SelectionBlock";
import General from "./Fields/General";
import NewName from "./Fields/NewName";
import Suspended from "./Fields/Suspended";
import Load from "./Fields/Load";
import Multiupload from "./Fields/Multiupload";
import Security from "./Fields/Security";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import Divider from "../../../../../UI/GeneralPurpose/Divider";

const Form = ({ multi, locale }) => {
  const { auth } = useUIBp();

  return (
    <Grid2 container {...{ sx: { width: "100%" } }}>
      <Grid2 container {...{ rowGap: 2, alignItems: "center" }}>
        <General {...{ locale }} />
        {multi ? null : <NewName {...{ locale }} />}
        <Interval {...{ locale }} />
        {auth.admin === 1 ? <Suspended {...{ locale }} /> : null}
        {false ? <StealthMode {...{ locale }} /> : null}
        <Divider />
        <Load {...{ locale }} />
        <Bandwidth {...{ locale }} />
        {false ? <Multiupload {...{ locale }} /> : null}
        <Divider />
        <Security {...{ locale }} />
        <SelectionBlock {...{ locale }} />
      </Grid2>
    </Grid2>
  );
};

export default Form;
