import { createSlice } from "@reduxjs/toolkit";
import {
  calculateSelection,
  calculateSelection2,
  parseFileTree,
  recursiveNodeChange,
} from "./trees2-utils";

const initialState = {
  data: {},
};
const treesSlice = createSlice({
  name: "newTrees",
  initialState,
  reducers: {
    // ======================= Basics ===========================
    clearForest(state) {
      state.data = {};
    },
    clearForestWithException(state,action) {
      const exception = action.payload
      for (const key in state.data) {
        if (key !== exception) {
          delete state.data[key];
        }
      }
    },
    createTree(state, action) {
      const { id, type, data, root } = action.payload;
      state.data[id] = {
        data,
        type,
        nodes: [{ ...root, idx: 0, parentIdx: null, childrenIdx: [] }],
      };
    },
    removeTree(state, action) {
      const id = action.payload;
      delete state.data[id];
    },
    removeTreeGroup(state, action) {
      const group = action.payload;
      for (const key in state.data) {
        if (key.match(group)) {
          delete state.data[key];
        }
      }
    },
    loadTree(state, action) {
      const { id, tree } = action.payload;
      state.data[id] = tree;
    },
    toggleExpand(state, action) {
      const { id, idx } = action.payload;
      const node = state.data[id][idx];
      if (node.expanded) {
        node.expanded = false;
      } else {
        node.expanded = true;
      }
    },
    setNodeKey(state, action) {
      const { id, idx, key, value } = action.payload;
      state.data[id].nodes[idx][key] = value;
    },
    // ======================= Basics ===========================
    // ===================== FileViewer =========================
    addFVNode(state, action) {
      const { id, idx, data } = action.payload;

      let tree = state.data[id];

      let node = tree.nodes[idx];

      const newIdx = tree.nodes.length;

      tree.nodes.push({
        ...data,
        idx: newIdx,
        parentIdx: idx,
        childrenIdx: [],
      });
      node.childrenIdx.push(newIdx);
    },
    // ===================== FileViewer =========================
    // ===================== FileViewer Tree =========================
    toggleExpandFVT(state, action) {
      const { id, idx } = action.payload;
      const node = state.data[id].nodes[idx];
      if (node.expanded) {
        node.expanded = false;
      } else {
        node.expanded = true;
      }
    },

    // ===================== FileViewer Tree =========================
    // =================== GreylistViewer =========================
    loadGreylistTree(state, action) {
      const { id, rawTree } = action.payload;

      let tree = state.data[id];

      let nodes = [];

      const rawTreeNode = {
        parentIdx: null,
        rawNodeData: rawTree,
      };

      parseFileTree(nodes, rawTreeNode);
      calculateSelection(nodes, 0);

      tree.nodes = nodes;
    },
    // =================== GreylistViewer =========================
    // ================== Remote Selection ========================
    addRSnode(state, action) {
      const { id, idx, node } = action.payload;

      let tree = state.data[id];

      let parent = tree[idx];

      const newIdx = tree.length;

      let newChild = {
        idx: newIdx,
        parentIdx: idx,
        name: node.Name,
        isDir: node.IsDir,
        size: node.Size,
        status: node.Status,
        childrenIdx: [],
      };

      if (parent.backup >= 2) {
        newChild.backup = 3;
      } else if (parent.backup <= 1) {
        newChild.backup = 0;
      } else {
        console.error("this should not be happening. ever.");
      }

      parent.childrenIdx.push(newIdx);
      tree.push(newChild);
    },
    RSnodeLoaded(state, action) {
      const { id, idx } = action.payload;
      state.data[id][idx].loaded = true;
      state.data[id][idx].expanded = true;
    },
    RSnodeSelect(state, action) {
      const { id, idx } = action.payload;

      const tree = state.data[id];
      const node = tree[idx];

      if (node.backup === 3) {
        recursiveNodeChange(tree, idx, false);
      } else if (node.backup === 0 || node.backup === 2) {
        recursiveNodeChange(tree, idx, true);
      } else if (node.backup === 1) {
        node.backup = 2;
      }
      calculateSelection2(tree, 0);
    },

  },
});

export const treesActions = treesSlice.actions;
export default treesSlice.reducer;
