import { useDispatch } from "react-redux";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};

const Cancel = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.closeModal2());
  };
  return <Button {...{ UIText, onClick, variant:'text'}} />;
};

export default Cancel;
