import {  Typography } from "@mui/material";
import AutoHelp from "../../../../UI/Wrappers/AutoHelp";

const Device = ({ fontSize, name }) => {
  return (
    <AutoHelp {...{ fixedTooltip: name }}>
      <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
        {name}
      </Typography>
    </AutoHelp>
  );
};

export default Device;
