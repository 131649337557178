import { useDispatch } from "react-redux";
import { adminNameUI, adminUserUI, organizationUI } from "../../../Views/RecoveryStep3/Components/DeliverySumary";
import { recoveriesActions } from "../../../../../store/slices/recoveries";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Nueva", fixedTooltip: "Iniciar una solicitud nueva" },
  "en-US": { label: "New", fixedTooltip: "Start a new request" },
};

const Reset = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(recoveriesActions.reset());
    dispatch(UIactions.clear('selected'));
    dispatch(UIactions.clear(adminNameUI));
    dispatch(UIactions.clear(adminUserUI));
    dispatch(UIactions.clear(organizationUI));
    dispatch(UIactions.closeModal());
  };

  return <Button {...{ UIText, onClick, variant:'text' }} />;
};

export default Reset;
