import { Grid2, Typography } from "@mui/material";
import { Fragment } from "react";
import { postDeviceId } from "../../../../../../../store/actions/devices/postDeviceInfo";
import { useWorker } from "../../../../../../../Hooks/useWorker";
import HelpTooltip from "../../../../../../UI/GeneralPurpose/HelpTooltip";
import WarningTooltip from "../../../../../../UI/GeneralPurpose/WarningTooltip";
import Switch from "../../../../../../UI/GeneralPurpose/Switch";

const UIText = {
  "es-CL": { label: "Modo oculto" },
  "en-US": { label: "Stealth mode" },
};

const UITextA = {
  "es-CL": { label: "Activo" },
  "en-US": { label: "Active" },
};

const UIText1 = {
  "es-CL": {
    tooltip:
      "El modo oculto remueve la interfaz grafica de cloner en el dispositivo del usuario",
  },
  "en-US": {
    tooltip:
      "Stealth mode allows the device to remove the cloner interface from the user device",
  },
};
const UIText2 = {
  "es-CL": {
    tooltip:
      "Esta función solo se encuentra implementada en las versiones mas nuevas de la aplicacion de respaldo",
  },
  "en-US": {
    tooltip:
      "This feature is only available in the newest versions of the backup application",
  },
};

export const stealthModeUI = "stealthMode";

const StealthMode = ({ locale }) => {
  const { label } = UIText[locale];
  const { working } = useWorker(postDeviceId);

  return (
    <Fragment>
      <Grid2 container {...{ size: 7, alignItems: "center", columnGap: 1 }}>
        <Typography>{label}</Typography>
        <HelpTooltip {...{ UIText: UIText1 }} />
        <WarningTooltip {...{ UIText: UIText2 }} />
      </Grid2>
      <Switch
        clearOnUnmount
        labelRight
        {...{
          id: stealthModeUI,
          UIText: UITextA,
          disabled: working,
          size: 5,
          labelProps: { sx: { fontSize: undefined, fontWeight: undefined } },
        }}
      />
    </Fragment>
  );
};

export default StealthMode;
