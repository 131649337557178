import { viewmodeUI } from "../../../../../App";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../store/slices/UI";
import { currentGroupnameId } from "../../../../GroupsTraveler/Actions/loadGroupsTree";
import BasicLayout from "../../../../UI/BasicLayout/BasicLayout";
import { resetScrollId } from "../../../../UI/BasicLayout/Components/Core/Core";
import headersData from "./Headers";
import PlusActions, { plusActionsGrid } from "./PlusActions/PlusActions";
import UserRow from "./UserRow/UserRow";

const Core = ({ users, allUsers, done, working, error, workerId }) => {
  const { dispatch, settings, UI } = useUIBp();
  const groupname = UI[currentGroupnameId];
  const viewmode = UI[viewmodeUI] ?? "desktop";

  const UIText = {
    "es-CL": `Usuarios del grupo ${groupname}`,
    "en-US": `Users in group ${groupname}`,
  };

  let fixedWidth;

  if (viewmode === "verycompact") {
    fixedWidth = "120vw";
  } else if (viewmode === "mobile") {
    fixedWidth = "150vw";
  }

  const header = UIText[settings.locale];
  const plusActions = <PlusActions />;

  const elements = users.map((data, idx) => <UserRow key={idx} {...data} />);

  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: {
        "es-CL": { message: "Error al cargar los usuarios: " + error },
        "en-US": { message: "Error loading the users: " + error },
      },
    },
    empty: {
      isEmpty: !users.length && done && !error,
      UIText: {
        "es-CL": { message: "No hay usuarios disponibles" },
        "en-US": { message: "There are no users available" },
      },
    },
    done,
    elements,
  };

  return (
    <BasicLayout
      {...{
        plusActions,
        plusActionsGrid,
        header,
        headersData,
        fullArray: allUsers,
        coreData,
        overflowX: viewmode === "desktop" ? "hidden" : "auto",
        fixedWidth,
        onPaginationChange: () => {
          dispatch(UIactions.setValue({id:resetScrollId, value:true}));
        },
      }}
    />
  );
};

export default Core;
