import { options } from "./selectValues";
import { humanBandwidth } from "../../../../../../../utility/misc/humanizers";
import { postDeviceId } from "../../../../../../../store/actions/devices/postDeviceInfo";
import useUIBp from "../../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../../store/slices/UI";
import Select from "../../../../../../UI/GeneralPurpose/Select";
import { Grid2 } from "@mui/material";
import SlimAlert from "../../../../../../UI/GeneralPurpose/SlimAlert";
import HelpTooltip from "../../../../../../UI/GeneralPurpose/HelpTooltip";
import Switch from "../../../../../../UI/GeneralPurpose/Switch";
import { useWorker } from "../../../../../../../Hooks/useWorker";

const UIText = {
  "es-CL": {
    label: "Ancho de banda",
    tooltip:
      "Establece un limite al ancho de banda que puede ser utilizado durante el respaldo de información",
  },
  "en-US": {
    label: "Bandwidth",
    tooltip:
      "Set a limit to the bandwidth that can be used during the backup process",
  },
};

const UIText3 = {
  "es-CL": {
    warning:
      "El intervalo de respaldo actual no coincide con ninguna de las opciones disponibles. Valor actual: ",
  },
  "en-US": {
    warning:
      "The current backup interval does not match with any available option. Actual value: ",
  },
};

export const throttleActiveUI = "throttleActive";
export const bandwidthLimitUI = "bandwidthLimit";

const Bandwidth = ({ locale }) => {
  const { dispatch, UI } = useUIBp();
  const { working } = useWorker(postDeviceId);
  const bandwidth = UI[bandwidthLimitUI];
  const active = UI[throttleActiveUI];
  const { warning } = UIText3[locale];

  const found = options.findIndex((opt) => opt.value === bandwidth);

  const warningFull =
    found === -1 && typeof bandwidth === "number" && active
      ? [warning + humanBandwidth(bandwidth)]
      : [];

  const reset = () => {
    dispatch(
      UIactions.setValue({ id: bandwidthLimitUI, value: options[0].value })
    );
  };

  return (
    <Grid2 container {...{ size: 12, alignItems: "center" }}>
      <Grid2 container {...{ size: 7, alignItems: "center", columnGap: 1 }}>
        <Switch
          clearOnUnmount
          labelRight
          {...{
            id: throttleActiveUI,
            UIText,
            disabled: working,
            onChange: reset,
            size: "auto",
            labelProps: { sx: { fontSize: undefined, fontWeight: undefined } },
          }}
        />
        <HelpTooltip {...{ UIText }} />
      </Grid2>
      <Grid2 container {...{ size: 5, justifyContent: "flex-end" }}>
        <Select
          clearOnUnmount
          {...{
            id: bandwidthLimitUI,
            UIText,
            disabled: working,
            options,
            hide: !active,
          }}
        />
      </Grid2>
      <SlimAlert
        {...{ errors: warningFull, severity: "warning", paddingTop: 1 }}
      />
    </Grid2>
  );
};

export default Bandwidth;
