import { Grid2, TextField } from "@mui/material";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import filterObjKeys from "../../../utility/misc/filterObjectKeys";
import { useEffect } from "react";
import { UIactions } from "../../../store/slices/UI";
import AutoHelp from "../Wrappers/AutoHelp";

const MultilineTextField = (props) => {
  const { dispatch, UI, settings } = useUIBp();
  const {
    id,
    // onChange = () => {},
    // onBlur = () => {},
    // onKeyDown = () => {},
    fontSize = "1rem",
    componentSize = "small",
    size,
    disabled,
    UIText = {},
    clearOnUnmount,
    rows = 4,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "size",
    "componentSize",
    "validation",
    "tagError",
    "forceError",
    "submit",
    "UIText",
    "fontSize",
    "inputSx",
    "forceShrink",
    "constraint",
    "clearOnUnmount"
  );

  const locale = settings.locale;
  const { tooltip, fixedTooltip, placeholder } =
    UIText[locale] ?? UIText.universal;
  let { value } = UI[id] ?? { value:""};

  value = !!value ? value: "";

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        dispatch(UIactions.clear(id));
      }
    };
  }, [dispatch, clearOnUnmount, id]);

  const handleChange = (e) => {
    let value = e.target.value;
    dispatch(UIactions.setField({ id, value }));
  };

  return (
    <Grid2 container {...{ size }}>
      <AutoHelp {...{ tooltip, fixedTooltip, disabled }}>
        <TextField
          fullWidth
          {...{
            ...filteredProps,
            size: componentSize,
            placeholder,
            value,
            onChange: handleChange,
            sx: { fontSize },
            InputProps: {
              multiline: true,
              rows,
              inputComponent: "textarea",
            },
          }}
        />
      </AutoHelp>
    </Grid2>
  );
};

export default MultilineTextField;
