import { Grid2, Tooltip, Typography } from "@mui/material";
import { Fragment } from "react";

const Repositories = ({ fontSize, repositories }) => {
  let allRepoNames = repositories
    .map((repo, idx) => {
      return repo.name;
    })
    .join(", ");

  let repoText = repositories.map((repo, idx) => {
    return (
      <Fragment key={idx + "A"}>
        {idx > 0 ? ", " : ""}
        <span
          key={idx}
          style={{
            color: repo.recover ? "black" : "grey",
            textDecoration: repo.recover ? "none" : "line-through",
          }}
        >
          {repo.name}
        </span>
      </Fragment>
    );
  });

  return (
    <Tooltip title={allRepoNames}>
      <Grid2 container size={12} alignItems="center">
        <Typography
          {...{
            fontSize,
            sx: {
              cursor: "default",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },
          }}
        >
          {repoText}
        </Typography>
      </Grid2>
    </Tooltip>
  );
};

export default Repositories;
