import { Grid2 } from "@mui/material";
import Button from "../../../UI/GeneralPurpose/Button";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { loginWrkID } from "../../Actions/loginToCloner";
import { loginUserID } from "../User/User";
import { useWorker } from "../../../../Hooks/useWorker";
import { gauthWrkID } from "../../Actions/loginWithGAuth";

const UIText = {
  "es-CL": { label: "Recuperar contraseña" },
  "en-US": { label: "Recover password" },
};

const ResetBtn = () => {
  const { UI } = useUIBp();
  const { working: w1 } = useWorker(loginWrkID);
  const { working: w2 } = useWorker(gauthWrkID);
  const working = w1 || w2;
  const user = UI[loginUserID] ?? {};

  const url = `/newpass?${user.value ? "user=" + user.value : ""}`;

  return (
    <Grid2 container {...{ size: 12, justifyContent: "center" }}>
      <Button
        fullWidth
        {...{
          disabled: working,
          UIText,
          navTo: url,
          labelSx: { fontSize: 12 },
          size: {
            xl: 3,
            lg: 3,
            md: 4,
            sm: 6,
            xs: 8,
          },
          variant: "outlined",
        }}
      />
    </Grid2>
  );
};

export default ResetBtn;
