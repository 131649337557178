import { Fragment, useCallback, useEffect, useRef } from "react";
import { MoreHoriz } from "@mui/icons-material";
import {
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import MoreActionsButtons from "./MoreActionsButton";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../store/slices/UI";

const MoreActions = ({
  id,
  buttonsData = [],
  icon = <MoreHoriz sx={{ fontSize: 18 }} />,
  placement = "bottom-end",
}) => {
  const { dispatch, UI } = useUIBp();
  const ID = `actions-${id}`;
  const open = UI[ID] ?? false;
  const ref = useRef(null);

  const handleToggle = (event) => {
    event.preventDefault();
    dispatch(UIactions.toggle(ID));
  };

  const close = useCallback(() => {
    dispatch(UIactions.clear(ID));
  }, [ID, dispatch]);

  const handleClick = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      return;
    }
    close();
  };

  useEffect(() => {
    window.addEventListener("scroll", close, true);
  }, [close]);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", close, true);
    };
  }, [close]);

  // Garbage collection
  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(ID));
    };
  }, [dispatch, ID]);

  const buttons = buttonsData.map((data, idx) => {
    if (data.divider) {
      return <Divider key={idx} />;
    } else {
      let onClick;
      if (data.onClick) {
        onClick = () => {
          data.onClick();
          close();
        };
      }

      return <MoreActionsButtons {...data} key={idx} {...{ onClick }} />;
    }
  });

  let transformOrigin = "";

  if (placement === "bottom-end") {
    transformOrigin = "right top";
  } else if (placement === "bottom-start") {
    transformOrigin = "left top";
  } else if (placement === "top-end") {
    transformOrigin = "right bottom";
  } else if (placement === "top-start") {
    transformOrigin = "left bottom";
  }

  return (
    <Fragment>
      <IconButton
        {...{
          ref,
          onMouseDown: (event) => {
            event.preventDefault();
          },
          onMouseUp: (event) => {
            event.preventDefault();
          },
          onClick: handleToggle,
          tabIndex: -1,
          size: "small",
        }}
      >
        {icon}
      </IconButton>
      <Popper
        transition
        {...{
          open,
          anchorEl: ref.current,
          placement,
          sx: { zIndex: 2000 },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin,
            }}
          >
            <Paper elevation={6}>
              <ClickAwayListener onClickAway={handleClick}>
                <MenuList disablePadding>{buttons}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default MoreActions;
