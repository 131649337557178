import { AccountTree } from "@mui/icons-material";
import { currentGroupnameId } from "../../../../../GroupsTraveler/Actions/loadGroupsTree";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import { UIactions } from "../../../../../../store/slices/UI";
import ToggleButton from "../../../../../UI/GeneralPurpose/ToggleButton";

const UIText = {
  "es-CL": {
    label: "Compartir",
    fixedTooltip: "Compartir credenciales a subgrupos",
  },
  "en-US": { label: "Share", fixedTooltip: "Share credentials with subgroups" },
};

export const w365shareUI = "microsoft365share";

const Share = ({ domain, own,working }) => {
  const { dispatch, UI } = useUIBp();
  const { group } = useURLQuery();
  const groupname = UI[currentGroupnameId];


  const onClick = () => {
    dispatch(
      UIactions.openModal2({
        type: "m365-share",
        payload: {
          groupname,
          payload: {
            domain,
            id: own.id,
            group_id: group,
            prevent_propagation: !own.prevent_propagation,
            group_locked: own.group_locked,
          },
        },
      })
    );
  };

  return (
    <ToggleButton
      {...{
        disabled:working,
        id: w365shareUI,
        UIText,
        onClick,
        startIcon: <AccountTree sx={{ marginRight: 1, fontSize: 20 }} />,
        color: "primary",
        variant: "outlined",
      }}
    />
  );
};

export default Share;
