import { default as CustomDevicePicker } from "../../../../../UI/Specialized/DevicePicker";
import { tunnelsDataId } from "../../../../../../store/actions/users/getTunnelData";
import { UIactions } from "../../../../../../store/slices/UI";
import { tunnelTokenId } from "../../Actions/getMachineStatus";
import { selectionBlockUI } from "../../RemoteSelection";
import { passFieldId } from "../Blocked/PassField";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";

const DevicePicker = ({ user }) => {
  const { dispatch, warehouse } = useUIBp();
  const tunnels = warehouse[tunnelsDataId] ?? {};
  const whitelist = tunnels[user] ?? undefined;

  const onChange = () => {
    dispatch(UIactions.clear(tunnelTokenId));
    dispatch(UIactions.clear(selectionBlockUI));
    dispatch(UIactions.clear(passFieldId));
  };

  return (
    <CustomDevicePicker
      autoload
      useWhitelist
      clearOnUnmount
      {...{ user, whitelist, onChange, size: 12 }}
    />
  );
};

export default DevicePicker;
