import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { getM365ClientsId } from "./getClients";
import { UIactions } from "../../slices/UI";
import { workerActions } from "../../slices/workers";

const UIText = {
  "es-CL": {
    success: "Activacion del usuario de Microsoft 365 exitosa",
    skipped: "Se intentó activar un usuario sin activar ningun servicio: ",
    error: "Error al activar usuario de Microsoft 365: ",
    fullSuccess:
      "Todos los usuarios fueron activados o modificados exitosamente",
    partialSuccess:
      "Solo algunos usuarios fueron activados o modificados exitosamente, revisar detalles",
    fullFail: "Todos los usuarios fallaron al activar o modificar",
    success2: "Exito",
    ignored: "Ignorado",
    fail: "Fallo",
  },
  "en-US": {
    success: "Microsoft 365 user activation successful",
    skipped:
      "An attempt was made to activate a user without activating any services: ",
    error: "Error while activating Microsoft 365 user: ",
    fullSuccess: "All users were successfully activated or modified",
    partialSuccess:
      "Only some users were successfully activated or modified, check details",
    fullFail: "All users failed to activate or modify",
    success2: "Success",
    ignored: "Ignored",
    fail: "Failed",
  },
};

export const postClientsId = "post365client";
export const postClientsData = "365clientsData";

const postClients = ({ M365server, cloner_key, payloads, locale }) => {
  const id = postClientsId;
  const {
    success,
    skipped,
    error,
    fullSuccess,
    partialSuccess,
    fullFail,
    ignored,
    success2,
    fail,
  } = UIText[locale];
  return async (dispatch) => {
    dispatch(workerActions.start(id));
    let results = [];
    for (let payload of payloads) {
      const bodyData = JSON.stringify(payload);
      try {
        if (!payload.found) {
          let any = false;
          for (const service of payload.services) {
            if (service.enabled) {
              any = true;
              break;
            }
          }
          if (!any) {
            results.push({ ...payload, status: ignored });
            enqueueSnackbar(skipped + payload.cloner_user, {
              variant: "warning",
            });
            continue;
          }
        }

        const url = `${M365server}mailapi/client`;
        await axios.post(url, bodyData, {
          headers: { cloner_key },
        });

        results.push({ ...payload, status: success2 });
        enqueueSnackbar(success, { variant: "success" });
      } catch (e) {
        console.error(e);
        results.push({ ...payload, status: fail });
        enqueueSnackbar(error + e.message, { variant: "error" });
      }
    }

    let fullOk = true;
    let fullFail2 = true;
    for (let result of results) {
      if (result.status === success2) {
        fullFail2 = false;
      } else {
        fullOk = false;
      }
    }


    if (fullOk) {
      enqueueSnackbar(fullSuccess, { variant: "success" });
    } else if (fullFail2) {
      enqueueSnackbar(fullFail, { variant: "error" });
    } else {
      enqueueSnackbar(partialSuccess, { variant: "warning" });
    }
    dispatch(workerActions.finish({ id }));

    if (!fullFail2) {
      console.log("clearing clients");
      dispatch(workerActions.clear(getM365ClientsId));
      dispatch(
        UIactions.openModal2({
          type: "download-m365-post-results",
          payload: { results },
        })
      );
    }
  };
};

export default postClients;
