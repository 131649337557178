import { viewmodeUI } from "../../../../../App";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../store/slices/UI";
import { currentGroupnameId } from "../../../../GroupsTraveler/Actions/loadGroupsTree";
import BasicLayout from "../../../../UI/BasicLayout/BasicLayout";
import { resetScrollId } from "../../../../UI/BasicLayout/Components/Core/Core";
import DeviceRow from "./DeviceRow/DeviceRow";
import headersData from "./Headers";
import PlusActions, { plusActionsGrid } from "./PlusActions/PlusActions";

const Core = ({ devices, allDevices, done, working, error, workerId }) => {
  const {dispatch, settings, UI } = useUIBp();
  const groupname = UI[currentGroupnameId];
  const viewmode = UI[viewmodeUI] ?? "desktop"; 

  const UIText = {
    "es-CL": `Dispositivos del grupo ${groupname}`,
    "en-US": `Devices in group ${groupname}`,
  };

  let fixedWidth 

  if (viewmode === "verycompact"){
    fixedWidth = "100vw"
  } else if (viewmode === "mobile") {
    fixedWidth = "175vw"
  }

  const header = UIText[settings.locale];
  const plusActions = <PlusActions />;

  const elements = devices.map((data, idx) => (
    <DeviceRow key={idx} {...data} />
  ));

  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: {
        "es-CL": {
          message: "Error al cargar los dispositivos: " + error,
        },
        "en-US": { message: "Error loading the devices: " + error },
      },
    },
    empty: {
      isEmpty: !devices.length && done && !error,
      UIText: {
        "es-CL": { message: "No hay dispositivos disponibles" },
        "en-US": { message: "There are no available devices" },
      },
    },
    done,
    elements,
  };

  return (
    <BasicLayout
      {...{
        plusActions,
        plusActionsGrid,
        header,
        headersData,
        fullArray: allDevices,
        coreData,
        overflowX: viewmode === "desktop" ? "hidden" : "auto",
        fixedWidth,
        onPaginationChange: () => {
          dispatch(UIactions.setValue({id:resetScrollId, value:true}));
        },
      }}
    />
  );
};

export default Core;
