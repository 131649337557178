import useUIBp from "../../../../../Hooks/useUIBoilerplate";
import { recoveriesActions } from "../../../../../store/slices/recoveries";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";
import { recoveryDateUI, recoveryOptionUI } from "./Options";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const Save = ({ recoveries }) => {
  const { dispatch, UI } = useUIBp();
  const option = UI[recoveryOptionUI] ?? "";
  const { value: bkpDate, error: dateErr } = UI[recoveryDateUI] ?? "";

  const handleClick = () => {
    for (const recovery of recoveries) {
      const repositories = recovery.repositories.map((repo) => ({
        ...repo,
        bkpDate,
        option,
        details: "",
        recover: true,
      }));
      const newRecovery = { ...recovery, repositories, mode: "simple" };
      dispatch(recoveriesActions.updateRecovery(newRecovery));
    }
    dispatch(UIactions.closeModal());
  };

  let disabled = false;
  if ((option === "dated" && !bkpDate) || dateErr) {
    disabled = true;
  }

  return <Button {...{ disabled, UIText, onClick: handleClick }} />;
};

export default Save;
