import { Dialog, Typography } from "@mui/material";
import Cancel from "./Components/Cancel";
import Confirm from "./Components/Confirm";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";
import TextField from "../../../UI/GeneralPurpose/SimpleTextField";

const UIText = {
  "es-CL": {
    header: "Confirmación de eliminación",
    instructions: "Escribe ELIMINAR para continuar",
    safeguard: "ELIMINAR",
    helper: "Ingresa el texto exactamente como se indica",
  },
  "en-US": {
    header: "Deletion confirmation",
    instructions: "Type DELETE to continue",
    safeguard: "DELETE",
    helper: "Type the text exactly as it is displayed",
  },
};

const UIText2 = { universal: {} };

export const confirmationFieldId = "confirmationField";

const DeleteConfirmation = ({
  confirmation = () => {
    console.log("fn()");
  },
}) => {
  const { dispatch, settings, UI } = useUIBp();
  const field = UI[confirmationFieldId] ?? {};
  const { header, instructions, safeguard, helper } = UIText[settings.locale];

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal2());
    }
  };

  const disabled = field.value !== safeguard;

  return (
    <Dialog
      fullWidth
      {...{
        open: true,
        onClose,
        maxWidth: "xs",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Typography fontSize={13} paddingBottom={1}>
          {instructions}
        </Typography>
        <TextField
          autoFocus
          clearOnUnmount
          {...{
            id: confirmationFieldId,
            UIText: UIText2,
            helperText: helper,
            componentSize: "medium",
            size:12,
          }}
        />
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel />
        <Confirm {...{ disabled, confirmation }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default DeleteConfirmation;
