import { useEffect, useMemo } from "react";
import { sortObjByKey } from "../../utility/misc/sortObjByKey";
import { lowerMatch } from "../../utility/misc/filter";
import Core from "./Components/Core";
import {
  defaultFilter,
  defaultPagination,
  defaultSelected,
  defaultSort,
} from "../UI/GeneralPurpose/genericIDs";
import { getSharedFiles, getSharedFilesId, sharedFilesData } from "./Actions/getSharedFiles";
import useUIBp from "../../Hooks/useUIBoilerplate";
import { useWorker } from "../../Hooks/useWorker";
import { UIactions } from "../../store/slices/UI";
import CalculatePagination from "../UI/Utilities/CalculatePagination";
import { warehouseActions } from "../../store/slices/warehouse";
import { workerActions } from "../../store/slices/workers";

const SharedFiles = () => {
  const { auth, dispatch, warehouse, UI } = useUIBp();
  const workerId = getSharedFilesId;
  const { done, working, error } = useWorker(workerId);
  const sort = UI.sort ?? {};
  const filter = UI.filter ?? "";
  const pagination = UI.pagination ?? { page: 0, count: 0, rowsPerPage: 0 };

  useEffect(() => {
    if (!done) {
      const controller = new AbortController();
      dispatch(getSharedFiles({ ...auth, controller }));
      return () => {
        controller.abort();
      };
    }
  }, [dispatch, auth, done]);

  const filteredLinks = useMemo(() => {
    const sharedFiles = warehouse[sharedFilesData] ?? [];
    return [...sharedFiles]
      .filter(
        (link) =>
          lowerMatch(link.name, filter) || lowerMatch(link.created, filter)
      )
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending));
  }, [warehouse, sort.value, sort.descending, filter]);

  // Array to select all
  const allLinks = useMemo(() => {
    return filteredLinks.map((x) => x.id);
  }, [filteredLinks]);

  useEffect(() => {
    if (filteredLinks.length > 0) {
      dispatch(UIactions.setPagination({ count: filteredLinks.length }));
    }
  }, [dispatch, filteredLinks.length]);

  // Calculating effective pagination
  const { from, to } = CalculatePagination(pagination);
  const paginatedLinks = useMemo(() => {
    return filteredLinks.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredLinks]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(defaultFilter));
      dispatch(UIactions.clear(defaultPagination));
      dispatch(UIactions.clear(defaultSelected));
      dispatch(UIactions.clear(defaultSort));
      dispatch(warehouseActions.unload(sharedFilesData));
      dispatch(workerActions.clear(getSharedFilesId));
    };
  }, [dispatch]);

  return (
    <Core
      {...{ links: paginatedLinks, allLinks, done, working, error, workerId }}
    />
  );
};

export default SharedFiles;
