import { Delete } from "@mui/icons-material";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import { UIactions } from "../../../../../../store/slices/UI";
import IconButton from "../../../../../UI/GeneralPurpose/IconButton";

const UIText = {
  "es-CL": { fixedTooltip: "Eliminar grupo" },
  "en-US": { fixedTooltip: "Delete group" },
};

const DeleteGroup = () => {
  const { auth, dispatch } = useUIBp();
  const { group } = useURLQuery
  ();

  const handleClick = () => {
    dispatch(UIactions.openModal({ type: "delete-group" }));
  };

  const disabled = auth.group === parseInt(group);
  if (disabled){
    return null
  }

  return (
    <IconButton
      {...{
        disabled,
        UIText,
        icon: <Delete sx={{ color: disabled ? "disabled" : "primary.main" }} />,
        onClick:handleClick,
      }}
    />
  );
};

export default DeleteGroup;
