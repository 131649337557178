import { Dialog, Grid2 } from "@mui/material";
import BasicDialogTitle from "../../../UI/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI/BasicDialog/BasicDialogActions";
import Close from "./Components/Close";
import Continue from "./Components/Continue";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../store/slices/UI";
import SlimAlert from "../../../UI/GeneralPurpose/SlimAlert";

const NewRepositoryNewRequest = () => {
  const { dispatch, settings, UI } = useUIBp();
  const locale = settings.locale;
  const { payload } = UI.modal ?? {};
  const { newRecovery, repoName } = payload ?? {};

  const UIText = {
    "es-CL": {
      header: `Iniciar una nueva solicitud de recuperacion para el repositorio "${repoName}" del usuario ${newRecovery.user}?`,
      alert:
        "Actualmente existe una solicitud de recuperación que no ha sido enviada. Si continua se iniciará una nueva solicitud de recuperación y la solicitud actual se perderá.",
    },
    "en-US": {
      header: `Start a new recovery request for the repository "${repoName}" of the user ${newRecovery.user}?`,
      alert:
        "Currently exists a recovery request that hasn't been sent. If you continue a new recovery request will be started and the current request will be lost.",
    },
  };
  const { header, alert } = UIText[locale];

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose: handleClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid2
          container
          {...{ rowGap: 2, paddingTop: 2, sx: { width: "100%" } }}
        >
          <SlimAlert
            notCollapse
            {...{ size: 12 }}
            {...{ errors: [alert], severity: "warning" }}
          />
        </Grid2>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        <Continue {...{ newRecovery }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default NewRepositoryNewRequest;