import { useDispatch } from "react-redux";
import { useWorker } from "../../../../../Hooks/useWorker";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";
import { postDeviceId } from "../../../../../store/actions/devices/postDeviceInfo";

const UIText = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};

const Cancel = () => {
  const dispatch = useDispatch();
  const { working } = useWorker(postDeviceId);

  const onClick = () => {
    dispatch(UIactions.closeModal());
  };

  return <Button {...{ UIText, onClick,disabled:working, variant:'text' }} />;
};

export default Cancel;
