import { useEffect } from "react";
import LoginBtn from "./Components/Buttons/LoginBtn";
import ResetBtn from "./Components/Buttons/ResetBtn";
import Header from "./Components/Header";
import Password, { loginPasswordID } from "./Components/Password";
import TFAView from "./Components/TFAView/TFAView";
import User, { loginUserID } from "./Components/User/User";
import Logo from "./Components/Logo";
import Locale from "./Components/Buttons/Locale";
import LegacyBtn from "./Components/Buttons/LegacyBtn";
import LegacyDialog from "./Components/Legacy/LegacyDialog";
import useUIBp from "../../Hooks/useUIBoilerplate";
import { workerActions } from "../../store/slices/workers";
import { UIactions } from "../../store/slices/UI";
import notEmptyUser from "../../utility/validations/notEmptyUser";
import notEmptyPassword from "../../utility/validations/notEmptyPassword";

import loginToCloner, { loginWrkID, TFA_ID } from "./Actions/loginToCloner";
import { gauthWrkID } from "./Actions/loginWithGAuth";
import { Grid2 } from "@mui/material";

// Defining the Login component
const Login = () => {
  // Importing necessary dependencies
  const { auth, dispatch, navigate, UI, settings } = useUIBp();
  const { admin, group } = auth;
  const locale = settings.locale ?? "es-CL";
  const { token } = auth; // This will allow us to redirect to the dashboard if the token exists
  const userField = UI[loginUserID] ?? {};
  const passwordField = UI[loginPasswordID] ?? {};
  const twofactor = UI[TFA_ID] ?? false;
  const disabled = twofactor;
  const height = window.innerHeight;

  // Clearing workers and UI state on unmount
  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(loginWrkID));
      dispatch(workerActions.clear(gauthWrkID));
    };
  }, [dispatch]);

  // Redirecting to dashboard if token exists
  useEffect(() => {
    if (token) {
      if (!!admin) {
        navigate(`/dashboard/reports?group=${group}`, { replace: true });
      } else {
        navigate(`/dashboard/files`, { replace: true });
      }
      dispatch(UIactions.clear(loginUserID));
      dispatch(UIactions.clear(loginPasswordID));
      dispatch(UIactions.clear(TFA_ID));
    }
  }, [dispatch, navigate, token, group, admin]);

  // Hard validation function
  const hardValidation = () => {
    const value = { id: loginUserID, fn: notEmptyUser, force: true, locale };
    dispatch(UIactions.validateField(value));
    const value2 = {
      id: loginPasswordID,
      fn: notEmptyPassword,
      force: true,
      locale,
    };
    dispatch(UIactions.validateField(value2));
  };

  // Login function
  const login = () => {
    if (
      !userField.error &&
      !passwordField.error &&
      userField.value &&
      passwordField.value
    ) {
      const payload = {
        user: userField.value,
        pass: passwordField.value,
        locale,
      };
      dispatch(loginToCloner(payload));
    }
  };

  // Rendering the Login component
  return (
    <Grid2
      container
      id="login-container"
      sx={{ width: "100vw", height }}
      alignContent="space-between"
    >
      <Locale />
      <Grid2 container size={12} rowGap={3}>
        <Logo />
        <Header {...{ locale }} />
        <User {...{ disabled, hardValidation, login }} />
        <Password {...{ disabled, hardValidation, login }} />
        {!twofactor ? (
          <Grid2
            container
            {...{ size: 12, justifyContent: "center", rowGap: 2 }}
          >
            <LoginBtn {...{ hardValidation, login }} />
            <ResetBtn />
          </Grid2>
        ) : (
          <TFAView />
        )}
      </Grid2>
      <Grid2
        container
        {...{
          size: 12,
          sx: { padding: 1 },
          justifyContent: "end",
        }}
      >
        <LegacyBtn />
      </Grid2>
      <LegacyDialog />
    </Grid2>
  );
};

export default Login;
