import { useEffect } from "react";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../Hooks/useURLQuery";
import { useWorker } from "../../../Hooks/useWorker";
import { deleteUsersId } from "../../../store/actions/users/deleteUsers";
import { UIactions } from "../../../store/slices/UI";
import { defaultSelected } from "../../UI/GeneralPurpose/genericIDs";
import { workerActions } from "../../../store/slices/workers";
import DialogConfirmation from "../../Dialogs/Confirmations/AllPurpose/DialogConfirmation";

const DeleteUsers = () => {
  const { dispatch, navigate, settings, UI } = useUIBp();
  const { group } = useURLQuery();
  const { done, error } = useWorker(deleteUsersId);
  const { payload = {} } = UI.modal ?? {};
  const { users } = payload;
  const locale = settings.locale;

  const UIText = {
    "es-CL": {
      header:
        users.length > 1
          ? `¿Esta seguro que desea eliminar estos ${users.length} usuarios?`
          : `¿Esta seguro que desea eliminar el usuario "${users[0]}"?`,
      message: `Importante: Ten en cuenta que esta operación es definitiva e irreversible. Al confirmar, ${
        users.length > 1
          ? `los usuarios seran eliminados`
          : `el usuario será eliminado`
      } junto con toda su información. La información eliminada podrá ser recuperada dentro de un plazo de 90 días`,
    },
    "en-US": {
      header:
        users.length > 1
          ? `Are you sure you want delete these ${users.length} users?`
          : `Are you sure you want delete the user "${users[0]}"?`,
      message: `Important: Beware that this operation is definitive and irreversible. Once confirmed, the user${
        users.length > 1 ? "s" : ""
      } will be deleted, along with all it's data. The deleted data can be recovered before 90 days have passed`,
    },
  };
  const { header, message } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
      dispatch(UIactions.clear(defaultSelected));
      dispatch(workerActions.clear(`groupData${group}`));
    }
  }, [dispatch, navigate, done, error, group]);

  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(deleteUsersId));
    };
  }, [dispatch]);

  const confirmation = () => {
    dispatch(
      UIactions.openModal2({
        type: "delete-users-confirmation",
        payload: { users },
      })
    );
  };

  return (
    <DialogConfirmation
      alternativeText
      {...{
        header,
        message,
        severity: "warning",
        confirmation,
        workerId: deleteUsersId,
      }}
    />
  );
};

export default DeleteUsers;
