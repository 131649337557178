import { Grid2, Typography } from "@mui/material";
import AddressInfo from "../AddressInfo/AddressInfo";
import DeliveryWindow from "../DeliveryWindow";
import { defaultRadioSelect } from "../../../../../../../UI/GeneralPurpose/genericIDs";
import useUIBp from "../../../../../../../../Hooks/useUIBoilerplate";
import RadioSelect from "../../../../../../../UI/GeneralPurpose/RadioSelect";
import CollapseGrid from "../../../../../../../UI/GeneralPurpose/CollapseGrid";

const UIText = {
  "es-CL": {
    subtitle: "Modalidad de recuperación",
  },
  "en-US": {
    subtitle: "Recovery mode",
  },
};

const UITextLink = {
  "es-CL": { label: "Vínculo de descarga" },
  "en-US": { label: "Download link" },
};

const UITextHDD = {
  "es-CL": { label: "Disco duro" },
  "en-US": { label: "Hard drive" },
};

const DeliveryMode = () => {
  const { settings, UI } = useUIBp();
  const mode = UI[defaultRadioSelect] ?? "link";
  const locale = settings.locale;
  const { subtitle } = UIText[locale];

  return (
    <Grid2 container size={12}>
      <Grid2 container {...{ size: 12, paddingBottom: 2 }}>
        <Typography fontWeight="bold">{subtitle}</Typography>
      </Grid2>
      <RadioSelect
        allowEmpty
        {...{
          id: defaultRadioSelect,
          value: "link",
          UIText: UITextLink,
          labelProps: { noWrap: true },
          size: 6,
        }}
      />
      <RadioSelect
        allowEmpty
        {...{
          id: defaultRadioSelect,
          value: "disk",
          UIText: UITextHDD,
          labelProps: { noWrap: true },
          size: 6,
        }}
      />
      <CollapseGrid open={mode === "link" ? false : true}>
        <AddressInfo />
        <DeliveryWindow />
      </CollapseGrid>
    </Grid2>
  );
};

export default DeliveryMode;
