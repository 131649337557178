import { Person } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  Grid2,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { sortObjByKey } from "../../../utility/misc/sortObjByKey";
import AutoHelp from "../Wrappers/AutoHelp";
import { AutocompleteFilter } from "./utils";
import { useWorker } from "../../../Hooks/useWorker";
import {
  getAllUsers,
  getUsersId,
  usersDataId,
} from "../../../store/actions/users/getAllUsers";
import { UIactions } from "../../../store/slices/UI";
import { warehouseActions } from "../../../store/slices/warehouse";
import { workerActions } from "../../../store/slices/workers";
import useUIBp from "../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": {
    normalTxt: "Usuarios",
    loadingTxt: "Cargando...",
    emptyTxt: "No se encontró usuarios",
    errorTxt: "Error al cargar los usuarios",
  },
  "en-US": {
    normalTxt: "Users",
    loadingTxt: "Loading...",
    emptyTxt: "No users found",
    errorTxt: "Error while loading users",
  },
};

export const userPickerId = "userPicker";

const UserPicker = (props) => {
  const {
    onChange,
    onLoaded,
    onDismount,
    autoload,
    disabled,
    size = 12,
    clearOnUnmount,
  } = props;
  const { auth, dispatch, UI, warehouse, settings } = useUIBp();
  const { normalTxt, loadingTxt, emptyTxt, errorTxt, tooltip } =
    UIText[settings.locale];
  const { done, working, error } = useWorker(getUsersId);
  const { userPicker = { id: "" } } = UI;
  const { id } = userPicker;
  const users = useMemo(() => {
    return warehouse[usersDataId] ?? [];
  }, [warehouse]);

  const options = useMemo(() => {
    return [...users]
      .sort((a, b) => sortObjByKey(a, b, "name"))
      .map((data) => {
        return { label: data.name, id: data.login };
      });
  }, [users]);

  let label = normalTxt;
  if (done && !error && !options.length) {
    label = emptyTxt;
  } else if (done && error) {
    label = errorTxt;
  } else if (working) {
    label = loadingTxt;
  }

  // Load data
  useEffect(() => {
    const controller = new AbortController();
    if (!done && autoload) {
      dispatch(getAllUsers({ ...auth, controller }));
    }
    return () => {
      if (!done) {
        controller.abort();
      }
    };
  }, [dispatch, done, auth, autoload]);

  // Fill blank
  useEffect(() => {
    if (!!options.length && done && !id) {
      if (onLoaded) {
        onLoaded(options);
      } else {
        dispatch(UIactions.setValue({ id: userPickerId, value: options[0] }));
      }
    }
  }, [dispatch, id, options, done, onLoaded]);

  // Cleanup
  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        dispatch(warehouseActions.unload(usersDataId));
        dispatch(UIactions.clear(userPickerId));
        dispatch(workerActions.clear(getUsersId));
        if (onDismount) {
          onDismount();
        }
      }
    };
  }, [dispatch, onDismount, clearOnUnmount]);

  const handleChange = (e, value) => {
    dispatch(UIactions.setValue({ id: userPickerId, value }));
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Grid2 size={size}>
      <AutoHelp {...{ tooltip, disabled: disabled || working }}>
        <Autocomplete
          blurOnSelect
          disableClearable
          fullWidth
          {...{
            disabled: (options.length > 1 ? false : true) || disabled,
            value: id ? userPicker : null,
            filterOptions: AutocompleteFilter,
            onChange: handleChange,
            options,
            size: "small",
            loading: working,
            renderOption: (props, option) => {
              return (
                <li {...props} key={option.id}>
                  <Tooltip title={option.id} placement="right">
                    <Typography>{option.label}</Typography>
                  </Tooltip>
                </li>
              );
            },
            renderInput: (params) => {
              params.InputProps.startAdornment = (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              );
              if (working) {
                params.InputProps.endAdornment = (
                  <InputAdornment position="end">
                    <CircularProgress size={14} />
                  </InputAdornment>
                );
              }
              return <TextField {...params} label={label} />;
            },
            isOptionEqualToValue: (option, value) => option.id === value.id,
          }}
        />
      </AutoHelp>
    </Grid2>
  );
};

export default UserPicker;
