import { useDispatch } from "react-redux";
import { tfaSwitch } from "../../../../Settings/Components/TFA";
import { UIactions } from "../../../../../store/slices/UI";
import Button from "../../../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};

const Cancel = () => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(UIactions.closeModal());
    dispatch(UIactions.clear(tfaSwitch))
  };

  return <Button {...{ UIText, onClick, variant: "text" }} />;
};

export default Cancel;
