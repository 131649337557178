import { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
} from "@mui/material";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { useWorker } from "../../../Hooks/useWorker";
import updateSharedLink, { updateSharedLinkId } from "../Actions/updateSharedLink";
import { getSharedFilesId, sharedFilesData } from "../Actions/getSharedFiles";
import { UIactions } from "../../../store/slices/UI";
import { workerActions } from "../../../store/slices/workers";
import SlimAlert from "../../UI/GeneralPurpose/SlimAlert";
import Button from "../../UI/GeneralPurpose/Button";

const UIText = {
  "es-CL": {
    header: "¿Esta seguro que desea remover proteccion del enlace de descarga?",
    instructions:
      "Se removerá la proteccion por contraseña del enlace de descarga permitiendo que cualquiera con acceso al enlace pueda descargar el archivo libremente",
  },
  "en-US": {
    header:
      "Are you sure you want to remove the protection from this download link?",
    instructions:
      "The download link's password protection will be removed, allowing anyone with access to the link to freely download the file",
  },
};
const UIText2 = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};
const UIText3 = {
  "es-CL": { label: "Confirmar" },
  "en-US": { label: "Confirm" },
};

const DeprotectSharedLink = (props) => {
  const { auth, dispatch, navigate, settings, UI, warehouse } =
    useUIBp();
  const { done, working, error } = useWorker(updateSharedLinkId);
  const locale = settings.locale;
  const { payload } = UI.modal;
  const { id: Id } = payload;
  const sharedFiles = warehouse[sharedFilesData];

  const { header, instructions } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
      dispatch(UIactions.clear("selected"));
      dispatch(workerActions.clear(getSharedFilesId));
    }
  }, [dispatch, navigate, done, error]);

  useEffect(() => {
    return () => {
      dispatch(workerActions.clear(updateSharedLinkId));
    };
  }, [dispatch]);

  const close = () => {
    dispatch(UIactions.closeModal());
  };

  const submit = () => {
    const { id, file, repo_id } = sharedFiles.find((x) => x.id === Id);
    dispatch(
      updateSharedLink({
        ...auth,
        locale,
        payload: {
          id,
          file,
          repo_id,
          password: "",
          password_set: false,
          public: true,
        },
      })
    );
  };

  return (
    <Dialog
      fullWidth
      {...{
        open: true,
        onClose: close,
        maxWidth: "sm",
      }}
    >
      <DialogTitle>{header}</DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Grid2 container rowGap={2} paddingLeft={2} paddingRight={2}>
          <SlimAlert
            notCollapse
            {...{ errors: [instructions], severity: "warning", size: 12 }}
          />
        </Grid2>
      </DialogContent>
      <DialogActions sx={{ paddingLeft: 3, paddingRight: 3 }}>
        <Button
          {...{
            UIText: UIText2,
            onClick: close,
            variant: "text",
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
        <Button
          {...{
            UIText: UIText3,
            onClick: submit,
            working,
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeprotectSharedLink;
