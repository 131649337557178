import { debounce } from "lodash";
import useUIBp from "../../../../Hooks/useUIBoilerplate";
import { passRecoveryUserID } from "../User";
import resetPassword, { passRecoveryId } from "../../Actions/resetPassword";
import notEmptyUser from "../../../../utility/validations/notEmptyUser";
import { Grid2 } from "@mui/material";
import { useWorker } from "../../../../Hooks/useWorker";
import Button from "../../../UI/GeneralPurpose/Button";
import { UIactions } from "../../../../store/slices/UI";

const UIText = {
  "es-CL": {
    label: "Resetear Contraseña",
  },
  "en-US": {
    label: "Reset password",
  },
};

const ResetBtn = () => {
  const { dispatch, UI, settings } = useUIBp();
  const  user  = UI[passRecoveryUserID] ?? { value:""};
  const { done, working } = useWorker(passRecoveryId);
  const locale = settings.locale;

  const debouncedReset = debounce(() => {
    if (!user.error) {
      dispatch(resetPassword({ login: user.value, locale }));
    }
  }, 200);
  const handleClick = () => {
    debouncedReset();
  };

  const debouncedValidation = debounce(() => {
    const payload = { id: passRecoveryUserID, fn: notEmptyUser, force: true, locale };
    dispatch(UIactions.validateField(payload));
  }, 200);
  const handleMouseDown = () => {
    debouncedValidation();
  };

  const disabled = working || !!user.error;

  if (!done) {
    return (
      <Grid2 container size={12} justifyContent="center">
        <Button
          fullWidth
          {...{
            UIText,
            working,
            onMouseDown: handleMouseDown,
            onClick: handleClick,
            disabled,
            lg: 3,
            md: 4,
            sm: 6,
            xs: 8,
          }}
        />
      </Grid2>
    );
  }
};

export default ResetBtn;
