import useUIBp from "../../../Hooks/useUIBoilerplate";
import deleteSharedLinks from "../../SharedFiles/Actions/deleteSharedLinks";
import DeleteConfirmation from "./Deletions/DeleteConfirmation";

const DeleteSharedLinksConfirmation = () => {
  const { auth,dispatch, settings, UI } = useUIBp();
  const {server,cloner_key} = auth
  const locale = settings.locale;
  const { payload } = UI.modal2;
  const { ids } = payload;

  const confirmation = () => {
    dispatch(deleteSharedLinks({
      server,
      cloner_key,
      links: ids,
      locale,
    }))
  };

  return <DeleteConfirmation {...{ confirmation }} />;
};

export default DeleteSharedLinksConfirmation;
