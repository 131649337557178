import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Grid2, Typography } from "@mui/material";
import IconButton from "./IconButton";
import CalculatePagination from "../Utilities/CalculatePagination";
import useUIBp from "../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../store/slices/UI";
import { defaultPagination } from "./genericIDs";

const UIText = {
  "es-CL": { of: "de" },
  "en-US": { of: "of" },
};
const UIText2 = {
  "es-CL": { tooltip: "Siguiente" },
  "en-US": { tooltip: "Next" },
};
const UIText3 = {
  "es-CL": { tooltip: "Anterior" },
  "en-US": { tooltip: "Previous" },
};

export const Pagination = ({ id = defaultPagination, size, onChange = ()=>{} }) => {
  const { dispatch, UI, settings } = useUIBp();
  const locale = settings.locale;
  const pagination = UI[id] ?? { page: 0, rowsPerPage: 0, count: 0 };
  const active = UI.activePagination ?? true;

  if (!active) {
    return;
  }

  const { of } = UIText[locale];
  const { from, to, total } = CalculatePagination(pagination);

  const handleNextPage = () => {
    onChange();
    dispatch(UIactions.nextPage(id));
  };

  const handlePreviousPage = () => {
    onChange();
    dispatch(UIactions.previousPage(id));
  };

  return (
    <Grid2
      container
      {...{
        direction: "row",
        wrap: "nowrap",
        alignItems: "center",
        columnGap: 1,
        size,
      }}
    >
      <Grid2 {...{ size: "auto", sx: { cursor: "default" } }}>
        <Typography fontSize="0.8rem">{`${from}-${to} ${of} ${total}`}</Typography>
      </Grid2>
      <IconButton
        {...{
          disabled: from <= 1,
          onClick: handlePreviousPage,
          icon: <ChevronLeft sx={{ fontSize: 20 }} />,
          UIText: UIText3,
          sx: { padding: 0.1 },
          size: "auto",
        }}
      />
      <IconButton
        {...{
          disabled: to >= total || !total,
          onClick: handleNextPage,
          icon: <ChevronRight sx={{ fontSize: 20 }} />,
          UIText: UIText2,
          sx: { padding: 0.1 },
          size: "auto",
        }}
      />
    </Grid2>
  );
};
