const HeadersData = [
  {
    UIText: {
      "es-CL": { label: "Usuario" },
      "en-US": { label: "User" },
    },
    filterKey:'mail',
    size: 3.6,
  },
  {
    UIText: {
      "es-CL": { label: "Correo W365" },
      "en-US": { label: "W365 email" },
    },
    filterKey:'w365mail',
    size: 3.6,
  },
  {
    UIText: {
      "es-CL": { label: "Respaldar correos" },
      "en-US": { label: "Backup Emails" },
    },
    size: 2.4,
  },
  {
    UIText: {
      "es-CL": { label: "Respaldar Onedrive" },
      "en-US": { label: "Backup Onedrive" },
    },
    size: 2.4,
  },
];

export default HeadersData