import {
  Folder,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { theme } from "../../../../../../App";
import { Fragment, useEffect } from "react";
import { currentCatsUI } from "./MailCategories";
import { getMailsId } from "../../../../../Mails/Actions/getMails";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { UIactions } from "../../../../../../store/slices/UI";
import { workerActions } from "../../../../../../store/slices/workers";

export const forceOpen = "forceDrawer";

const Category = ({ tree, idx, level, root }) => {
  const { dispatch, navigate, UI } = useUIBp();
  const { folderId, idx: urlIdx } = useURLQuery();
  const hover = UI[forceOpen];
  const self = tree[idx];
  const expanded = UI[self.id];

  const selected = folderId === root && urlIdx === `${idx}`;
  const accentColor = selected ? "select.accent" : "primary.light";

  useEffect(() => {
    dispatch(UIactions.add({ id: currentCatsUI, key: self.id }));
    return () => {
      dispatch(UIactions.remove({ id: currentCatsUI, key: self.id }));
    };
  }, [dispatch, self.id]);

  const newTheme = createTheme(theme, {
    palette: {
      action: {
        hover: "rgba(255, 255, 255, 0.8)",
      },
      primary: { main: "#ddd", light: "rgb(200,199,230)" },
      secondary: { main: "#ddd" },
      select: { main: "#18365a", accent: "#f0f0f0" },
    },
  });

  const toggleFolder = () => {
    dispatch(UIactions.toggle(self.id));
  };

  const onClick = () => {
    navigate(`/dashboard/mails?folderId=${root}&idx=${idx}`);
    dispatch(workerActions.clear(getMailsId))
  };

  let secondaryAction;
  if (self.childrenIdx.length) {
    secondaryAction = (
      <ThemeProvider theme={newTheme}>
        <IconButton
          {...{
            onClick: toggleFolder,
            sx: {
              padding: 0.5,
              backgroundColor: "rgba(255,255,255,0.15)",
              "&:hover": { backgroundColor: "rgba(255,255,255,0.3)" },
            },
          }}
        >
          {expanded ? (
            <KeyboardArrowDown
              {...{ sx: { fontSize: 16, color: accentColor } }}
            />
          ) : (
            <KeyboardArrowRight
              {...{ sx: { fontSize: 16, color: accentColor } }}
            />
          )}
        </IconButton>
      </ThemeProvider>
    );
  }

  const children = self.childrenIdx.map((idx) => (
    <Category {...{ tree, idx, level: level + 1, key: idx,root}} />
  ));

  return (
    <Fragment>
      <ListItem {...{ secondaryAction, sx: { padding: 0, height: "6vh" } }}>
        <ListItemButton
          {...{
            onClick,
            sx: {
              paddingLeft: hover ? 2 + level * 2 : 2,
              height: "6vh",
            },
          }}
        >
          <ListItemIcon>
            <Folder {...{ sx: { color: accentColor } }} />
          </ListItemIcon>
          <ListItemText
            {...{
              primary: self.displayName,
              primaryTypographyProps: {
                sx: { fontSize: "0.8rem", color: accentColor },
                noWrap: true,
              },
            }}
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={expanded} unmountOnExit>
        {children}
      </Collapse>
    </Fragment>
  );
};

export default Category;
