import { Tooltip, Typography } from "@mui/material";

const Username = ({ fontSize, user }) => {
  return (
    <Tooltip title={user}>
      <Typography {...{ fontSize, sx: { cursor: "default" }, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
        {user}
      </Typography>
    </Tooltip>
  );
};

export default Username;
