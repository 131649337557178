import { Alert, Grid2 } from "@mui/material";
import gauthLogo from "../../../../Resources/gauth-logo.png";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const UIText = {
  "es-CL": {
    content:
      "Esta cuenta se encuentra protegida mediante autentificación de 2 factores de Google. Por favor ingrese el codigo para poder continuar.",
  },
  "en-US": {
    content:
      "This account is protected by Google's two factors authentication. Please enter the code to proceed. ",
  },
};
const Top = () => {
  const { settings } = useUIBp();
  const { content } = UIText[settings.locale];

  return (
    <Grid2 container {...{ size: 12, justifyContent: "center" }}>
      <Grid2
        container
        {...{
          justifyContent: "center",
          alignItems: "center",
          size: { xl: 4, lg: 4, md: 6, sm: 8, xs: 10 },
        }}
      >
        <Grid2 container {...{ size: 2, justifyContent: "center" }}>
          <img src={gauthLogo} width="80%" alt="Google Auth" />
        </Grid2>
        <Grid2 container {...{ size: 10, alignItems: "center" }}>
          <Alert severity="info">{content}</Alert>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Top;
