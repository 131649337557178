import Username from "./Components/Username";
import Actions from "./Components/Actions";
import Device from "./Components/Device";
import Size from "./Components/Size";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import BasicRow from "../../../../../UI/BasicLayout/Components/BasicRow";
import Backup from "./Components/Backup";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };
const fontSize = 13;

const DeviceRow = (props) => {
  const { settings } = useUIBp();
  const { mid } = props;

  const data = {
    id: mid,
    elements: [
      { content: <Username {...props} {...{ fontSize,settings }} />, size:"grow"},
      { content: <Device {...props} {...{ fontSize }} />, size: 3 },
      { content: <Size {...props} {...{ fontSize }} />, size: 2 },
      { content: <Backup {...props} {...{ fontSize, settings }} />, size: 2 },
      { content: <Actions {...props} />, justify: "end", size: 1 },
    ],
  };

  return <BasicRow {...data} />;
};

export default DeviceRow;
