import { deleteGroup } from "../Actions/deleteGroup";
import DeleteConfirmation from "../../../../Dialogs/Confirmations/Deletions/DeleteConfirmation";
import useUIBp from "../../../../../Hooks/useUIBoilerplate";

const DeleteGroupConfirmation = () => {
  const { auth,dispatch, settings, UI } = useUIBp();
  const {server,cloner_key} = auth
  const { payload } = UI.modal2;
  const { group } = payload;

  const confirmation = () => {
    dispatch(deleteGroup({
      group,
      server,
      cloner_key,
      locale: settings.locale,
    }))
  };

  return <DeleteConfirmation {...{ confirmation }} />;
};

export default DeleteGroupConfirmation;
