const emailCompliant = (inputString) =>{
    console.log("emailCompliant running", inputString);
    let compliantString = ""

    if (inputString) {
        compliantString = inputString
            .replace(/[^a-z0-9@._-]/gi, "")
            .toLowerCase()
    }

    console.log("emailCompliant returning", compliantString)
    return compliantString
}

export default emailCompliant;