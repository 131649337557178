import { Button, Grid2, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import useTrees from "../../../../../../Hooks/useTrees";
import {
  getGroupsListId,
  groupsTreeId,
  selectedGroupIdx2,
} from "../../../../../GroupsTraveler/Actions/loadGroupsTree";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import useURLQuery from "../../../../../../Hooks/useURLQuery";
import { useWorker } from "../../../../../../Hooks/useWorker";
import { UIactions } from "../../../../../../store/slices/UI";

const GroupButton = ({ disabled, hideGroup, startGroup }) => {
  const { auth, dispatch, UI } = useUIBp();
  const { group } = useURLQuery();
  const { working } = useWorker(getGroupsListId);
  const isRootAndGroup = parseInt(group) === auth.group && hideGroup;
  const selected = UI[selectedGroupIdx2];
  const tree = useTrees()[groupsTreeId];
  const groupObj = typeof selected === "number" && tree ? tree[selected] : null;
  const groupname = groupObj ? groupObj.name : "Loading...";

  const onClick = () => {
    dispatch(
      UIactions.openModal2({
        type: "groups-traveler",
        payload: { startGroup, hideGroup },
      })
    );
  };

  return (
    <Grid2 container size={12}>
      <Button
        fullWidth
        {...{
          disabled: !!disabled || !!isRootAndGroup || working,
          id: "travelerButton",
          startIcon: (
            <ChevronRight
              sx={{
                color:
                  disabled || !!isRootAndGroup ? "disabled" : "secondary.main",
              }}
            />
          ),
          onClick,
          variant: "outlined",
          style: { justifyContent: "flex-start" },
        }}
      >
        <Typography
          fontSize={15}
          color={disabled || isRootAndGroup ? "disabled" : "secondary.main"}
        >
          {groupname}
        </Typography>
      </Button>
    </Grid2>
  );
};

export default GroupButton;
