import { Grid2 } from "@mui/material";
import DeleteBtn from "./Delete";
import Recover from "./Recover";
import Exclusions from "./Exclusions";
import useUIBp from "../../../../../../Hooks/useUIBoilerplate";
import { viewmodeUI } from "../../../../../../App";

export const plusActionsGrid = {
  xl: 5,
  lg: 6,
  md: 12,
  sm: 12,
  xs: 12,
};

const PlusActions = () => {
  const {UI} = useUIBp();
  const viewmode = UI[viewmodeUI] ?? "desktop";

  return (
    <Grid2
      container
      {...{
        columnGap: 2,
        justifyContent: viewmode === "desktop" ? "end" : "space-between",
        size: { xl: 7, lg: 6, md: 12, sm: 12, xs: 12 },
      }}
    >
      <Exclusions />
      <Recover />
      <DeleteBtn />
    </Grid2>
  );
};

export default PlusActions;
